import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import MainStyles from "../App.module.scss";
import "./tuto.css";

import Footer from '../components/Footer/Footer';


const dataTuto = [ 
  {
    id: 1,
    texte: "Rechercher des maisons grâce à notre carte intéractive et selon vos préférences."
  },
  {
    id: 2,
    texte: "Trouvez les agences immobilières près de chez vous. Vous pourrez contacter un professionnel qui connait très bien votre quartier."
  },
  {
    id: 3,
    texte: "Définissez vos préférences et les paramètres d'utilisation de l'application."
  },
  {
    id: 4,
    texte: "Postez votre annonce et un professionnel vous contactera."
  },
  {
    id: 5,
    texte: "Votre sélection de vos biens coup de cœur."
  },

]
const slide = {
  backgroundColor: "#fff"
};

const arrowStyles: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 40,
  height: 40,
  fontSize: '16pt',
  cursor: 'pointer',
  background: 'white',
  border: '1px solid #bda87d',
  borderRadius: 50,
};

const indicatorStyles: CSSProperties = {
  background: '#fff',
  width: 14,
  height: 14,
  borderRadius: 14,
  border: "1px solid #BDA87D",
  display: 'inline-block',
  margin: '0 10px',
  position: "relative",
  bottom: "0px"

};

class Tutoriel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: true,
      showInscription: false,
    };
  }

  notificationSystem = React.createRef();
  addNotification = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: 'Notification message',
      level: 'success'
    });
  };


  render() {
    const { cookies } = this.props;

    return (
      <section className={MainStyles.pageContainer}>
        <h1 className={MainStyles.pageTitle}>Le tutoriel d'Homecrush</h1>

        <article className={MainStyles.tutoContainer} >
          <Carousel 
          showThumbs={false}
          infiniteLoop
          statusFormatter={(current, total) => ``}
          renderArrowPrev={(
            onClickHandler, hasPrev, label) => hasPrev && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 20 }}>{"<"}</button>
            )
          }
          renderArrowNext={(
            onClickHandler, hasNext, label) => hasNext && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 20 }}>{">"}</button>
            )
          }
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li style={{ ...indicatorStyles, background: '#332e57', border: "1px solid #bda87d" }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li style={{ ...indicatorStyles}}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
              />
            );
          }}>



              {/*
      {props &&
        props.state.map((image) => {
          return (
            <div>
              <img src={"https://" + image} />
              <p className="legend">{image}</p>
            </div>
          )
        }
        )
      })*/}

            {dataTuto && dataTuto.map((image, index) => {
              return (<div className={MainStyles.slideContainer} key={index}>

              {index === 0 &&
                <svg className={MainStyles.iconTuto} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#332E57"}>
                    <path  d="M125.77,93.4l-.91-.91H117.6l-.91.91v22.88c0,2.66,0,9.73-15.08,9.73H49.3c-15.07,0-15.07-7.07-15.07-9.73V93.4l-.91-.91H26.05l-.91.91v22.88a16.74,16.74,0,0,0,10,16.14,33,33,0,0,0,14.2,2.67h52.31a32.93,32.93,0,0,0,14.2-2.67,16.72,16.72,0,0,0,10-16.14Z" />
                    <path  d="M52.94,85.23A22.53,22.53,0,1,0,75.46,62.7,22.52,22.52,0,0,0,52.94,85.23ZM75.46,71.79A13.44,13.44,0,1,1,62,85.23,13.43,13.43,0,0,1,75.46,71.79Z" />
                    <path  d="M74.25,14.89l-1.28,0-5,5.3,0,1.28c.5.47,50.46,47.43,68,65.58h1.28l5.24-5V80.77C125.11,62.69,74.76,15.36,74.25,14.89Z" />
                    <path  d="M46.44,59.42c10.79-10,20.11-18.72,23-21.66V36.48l-5.14-5.14H63.07c-2.82,2.82-12.52,11.87-22.79,21.41l-.06.06C27.41,64.75,12.88,78.27,7.49,83.68V85l5.14,5.13h1.28c5.27-5.27,19.73-18.73,32.5-30.61Z" />
                  </g>
                </svg>
              }

              {index === 1 &&
                <svg className={MainStyles.iconTuto} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#332E57"}>
                    <path  d="M24.94,98.62l.9.9h7.23l.91-.9V46.81c0-12,4.49-12,7.77-12h66.49c3.29,0,7.78,0,7.78,12V98.62l.9.9h7.24l.9-.9V46.81a25,25,0,0,0-4.19-15.66,15.19,15.19,0,0,0-12.63-5.41H41.75a15.18,15.18,0,0,0-12.62,5.41,25,25,0,0,0-4.19,15.66Z" />
                    <path  d="M117,110.73l-.9.9a3.63,3.63,0,0,1-3.62,3.62H37.6A3.63,3.63,0,0,1,34,111.63l-.9-.9H25.85l-.9.9A12.67,12.67,0,0,0,37.6,124.29h74.87a12.67,12.67,0,0,0,12.65-12.66l-.91-.9Z" />
                    <polygon  points="101.04 60.81 101.94 59.91 101.94 52.68 101.04 51.78 48.96 51.78 48.05 52.68 48.05 59.91 48.96 60.81 101.04 60.81" />
                    <polygon  points="48.05 79.44 48.96 80.34 101.04 80.34 101.94 79.44 101.94 72.21 101.04 71.31 48.96 71.31 48.05 72.21 48.05 79.44" />
                    <polygon  points="48.05 98.97 48.96 99.88 101.04 99.88 101.94 98.97 101.94 91.74 101.04 90.84 48.96 90.84 48.05 91.74 48.05 98.97" />
                  </g>
                </svg>
              }

              {index === 2 &&
                <svg className={MainStyles.iconTuto} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#332E57"}>
                    <path  d="M121,114.62h-7.25l-.91.9c0,2.66,0,9.71-15.06,9.71H52.33c-15,0-15-7-15-9.71l-.91-.9H29.12l-.92.9a16.7,16.7,0,0,0,10,16.12,32.89,32.89,0,0,0,14.18,2.67H97.81A32.91,32.91,0,0,0,112,131.64a16.7,16.7,0,0,0,10-16.12Z" />
                    <path  d="M105,52.21c-7.52,0-15.41,7.15-24.11,21.81-2,3.41-4,7.15-6,11.09-2-4-4-7.68-6-11.09-8.69-14.68-16.58-21.81-24.1-21.81a15.23,15.23,0,0,0-12.67,5.42,25,25,0,0,0-4.2,15.72v30.3l.9.91H36.2l.91-.91V73.35c0-12.08,4.51-12.08,7.8-12.08,4.43,0,11.32,8.94,16.26,17.31a174.55,174.55,0,0,1,9.64,19.06l.84.56h6.7l.84-.56a175.61,175.61,0,0,1,9.6-19c5-8.4,11.87-17.37,16.3-17.37,3.3,0,7.81,0,7.81,12.08v30.3l.9.91h7.26l.9-.91V73.32a25.17,25.17,0,0,0-4.25-15.69A15.23,15.23,0,0,0,105,52.21Z" />
                    <path  d="M75,56.51A20.42,20.42,0,1,0,54.54,36.09,20.42,20.42,0,0,0,75,56.51Zm0-31.76a11.35,11.35,0,1,1,0,22.69h0a11.35,11.35,0,0,1,0-22.69Z" />
                  </g>
                </svg>
              }

              {index === 3 &&
                <svg className={MainStyles.iconTuto} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#332E57"}>
                    <path  d="M78.64,53.54l-.16-.27c-.82-1-20.45-24.42-42.54-24.42a9.34,9.34,0,0,0-10,6.47,51.37,51.37,0,0,0-1,14.42V88.68l0,6.53.26.65,3.62,3.63.64.27c23.56,0,41.95,21,42.15,21.2l1,.26,6.37-2.38.59-.85V56.15l-.06-.31ZM70.48,107.4A67.73,67.73,0,0,0,34,90.89V49.74a55.7,55.7,0,0,1,.59-11.61l.06-.16a6.37,6.37,0,0,1,1.31-.08c15.47,0,31,16.08,34.54,20Z" />
                    <path  d="M124,35.32a9.34,9.34,0,0,0-10-6.48c-7.24,0-18.55,2.6-32.42,14.42l.64.64.64.64,3.86,3.86.64.64.6.59v.07a44.6,44.6,0,0,1,26-11.82,6.42,6.42,0,0,1,1.32.08l.06.16A55.7,55.7,0,0,1,116,49.73V90.89a63.07,63.07,0,0,0-28,10.26v11a55.28,55.28,0,0,1,32.55-12.47l.64-.27,3.62-3.63.26-.64,0-6.49v-39A51.27,51.27,0,0,0,124,35.32Z" />
                  </g>
                </svg>
              }

              {index === 4 &&
                <svg className={MainStyles.iconTuto} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#332E57"}>
                    <path  d="M39.78,67.9a14.44,14.44,0,0,1-2.64-20.24,14.79,14.79,0,0,1,2.38-2.43,15.73,15.73,0,0,1,20.07-1.07c.29.2.6.42.86.64l.65-.64.64-.64,3.85-3.85.64-.64.64-.64a22.29,22.29,0,0,0-2.07-1.62A25,25,0,0,0,47,32.23,23.76,23.76,0,0,0,33,39a26.79,26.79,0,0,0-7.72,14.72,24.39,24.39,0,0,0,8,20.49C45,86.42,78.25,117.65,78.58,118l1.28,0,5-5.27,0-1.29C83.45,110.08,51.13,79.7,39.78,67.9Z" />
                    <path  d="M117.48,39.86a24.15,24.15,0,0,0-29.87-3.78A73.5,73.5,0,0,0,69.1,50.25C63.36,56,52.81,65.39,52.71,65.49l-.07,1.27,4.81,5.41,1.28.07c.44-.39,10.81-9.64,16.76-15.6a65.2,65.2,0,0,1,16.1-12.46,15.43,15.43,0,0,1,19.49,2,15.25,15.25,0,0,1,3,18A51.44,51.44,0,0,1,103,80.06L85,98v1.27l5.12,5.12h1.27l17.93-17.93a60.72,60.72,0,0,0,13.17-18.93A24.09,24.09,0,0,0,117.48,39.86Z" />
                  </g>
                </svg>
              }
                    
              <p className="legend" style={{ fontSize: "1rem", fontFamily: "HermesReg", bottom: "50px" }}>{image.texte}</p>
              </div>)
            })
          }
          </Carousel>
        </article>

        <Footer />
      </section>
    )
  }
};

export default withCookies(Tutoriel);