import React, { Component, useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import MainStyles from "../App.module.scss";

import CarouselComp from "../components/Carousel/CarouselComp";
import Footer from "../components/Footer/Footer";

import ScrollerHorizontal from "../components/ScrollerHorizontal/ScrollerHorizontal"


const Agences = (props) => {

  const location = useLocation();

  const [state, setState] = useState("");

  const [oldSiteUrl, setOldSiteUrl] = useState("");
  const [newSiteUrl, setNewSiteUrl] = useState("");
  const [oldCp, setOldCp] = useState("");
  const [newCp, setNewCp] = useState("");

  const [lien, setLien] = useState("");

  const [fromNotifications, setDromNotifications] = useState(undefined);
  const [isFalseMandat, setIsFalseMandat] = useState(false);
  const [isFalseCopro, setIsFalseCopro] = useState(false);
  const [isFalseImpot, setIsFalseImpot] = useState(false);
  const [isFalsePerf, setIsFalsePerf] = useState(false);
  const [data, setData] = useState([]);


  const [biensAgence, setBiensAgence] = useState([])

  function validateURL(link) {
    if (link.includes("https://")) {
      setLien(link)
    }
    else {
      setLien('https://' + link)
    }
  }

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  useEffect(() => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    console.log(queryString)


    var bodyy = {
      blossom: location.pathname + location.search,
    };


    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response)
        response.agences[0].nom = response.agences[0].nomAgence;
        response.agences[0].Images = response.agences[0].imageAgence;
        response.agences[0].logo = response.agences[0].logoAgence;
        response.agences[0].adresse =
          response.agences[0].numeroAgence +
          " " +
          response.agences[0].rueAgence;
        response.agences[0].codePostale = response.agences[0].cpAgence;
        response.agences[0].ville = response.agences[0].villeAgence;
        response.agences[0].tel = response.agences[0].telAgence;
        response.agences[0].mail = response.agences[0].mailAgence;
        response.agences[0].nom = response.agences[0].nomAgence;

        if (isValidHttpUrl(response.agences[0].URLSite)) {
          console.log(isValidHttpUrl(response.agences[0].URLSite))
        } else {
          response.agences[0].URLSite = "https://" + response.agences[0].URLSite;
          console.log(isValidHttpUrl(response.agences[0].URLSite))
        }

        function validateCp(codePostal) {
          if (codePostal.length === 4) setNewCp(0 + oldCp);
          else setNewCp(oldCp);
        }

        if (oldCp !== null) {

          validateCp(oldCp)
        } else {
          validateCp(oldCp)
        }


        setData(response.agences[0]); // On ajoute les données recu au state
        getBiensAgenceAsync()
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });



  }, [oldCp]);

  const switchIsFalse = (value) => {
    var res;
    if (value === "mandat") {
      isFalseMandat === true ? (res = false) : (res = true);
      setIsFalseMandat(res);
    }
    if (value === "copro") {
      isFalseCopro === true ? (res = false) : (res = true);
      setIsFalseCopro(res);
    }
    if (value === "impot") {
      isFalseImpot === true ? (res = false) : (res = true);
      setIsFalseImpot(res);
    }
    if (value === "perf") {
      isFalsePerf === true ? (res = false) : (res = true);
      setIsFalsePerf(res);
    }
  };


  async function getBiensAgenceAsync() {
    console.log("props", props)
    var pathArray = location.pathname.split('/');
    var bodyy = {
      blossom: "/biens/" + pathArray[2] + "/",
    };

    console.log(bodyy)
    const response = await fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
    const data = await response.json();

    setBiensAgence(data)

    console.log(data)


    return data;
  }


  return (
    <section className={MainStyles.pageContainer}>
      {data && (
        <article>
          <section className={MainStyles.templateAgenceImgContainer}>
            {data.imagesBien && (
              <CarouselComp className={MainStyles.carrousel} state={data.imagesBien} />
            )}

            {data.Images ? (
              <img className={MainStyles.notCaroussel} alt={"devanture de l'agence " + data.nom} src={"https://" + data.Images} />
            ) : (
              <img className={MainStyles.notCaroussel} alt={"Image par défaut de la devanture de l'agence"} src={"https://api.homecrush.fr/imgDefaut/default.jpeg"} />
            )}

          </section>

          <section className={MainStyles.bottomLayout}>
            <article className={MainStyles.btmLeftContainer}>
              <div className={MainStyles.dirigeant}>
                <span style={{ fontWeight: "bold" }}>Dirigeant :</span>
                <span style={{ textTransform: "capitalize" }}>{data.nomDirigeant}</span>
              </div>

              <img 
                  className={MainStyles.imgDirigeant} 
                  src={data.imageDirigeant ? "https://" + data.imageDirigeant : "https://api.homecrush.fr/imgDefaut/default.jpeg"} 
                  alt={"Dirigeant de l'agence " + data.nom} 
              />

              <p>{data.telAgent}</p>

              <a className={MainStyles.ancreContact}
                href={"mailto:" + data.mail + "?subject=Votre bien m'intéresse" + "&body= Bonjour Madame, Monsieur, j'aimerais être recontacté par vous. Voici mes coordonnées : "}>Contacter l'agence</a>
            </article>

            <article className={MainStyles.btmRightContainer}>
              <p className={MainStyles.agenceName}>{data.nom}</p>

              <section className={MainStyles.agenceFlexLayout}>
                <article className={MainStyles.agenceInfosLayout}>
                  <div className={MainStyles.agenceSpanLayout} style={{ marginBottom: "1rem", fontFamily: "HermesBold" }}>
                    <span>Agence immobilière</span>
                    <span>{data.nom}</span>
                  </div>
                  <div className={MainStyles.agenceSpanLayout} style={{ marginBottom: "1rem" }}>
                    <span>{data.adresse}</span>
                    <span>{data.codePostale + " " + data.ville}</span>
                  </div>
                  <div className={MainStyles.agenceSpanLayout}>
                    <a className={MainStyles.lien} href={"tel:" + data.tel}>{data.tel}</a>
                    <a className={MainStyles.lien} href={"mailto:" + data.mail}>{data.mail}</a>
                    <span><a className={MainStyles.agenceWebsite}
                      href={data.URLSite} target="_blank" rel="noreferrer noopener"
                    >Site web</a></span>
                  </div>
                </article>

                <article className={MainStyles.agenceImgLayout}>
                {data.logo ? (
                      <img 
                          className={MainStyles.agenceImg} 
                          alt={"logo de l'agence " + data.nom} 
                          src={data.logo.startsWith("https://") ? data.logo : "https://" + data.logo} 
                      />
                  ) : (
                      <img 
                          className={MainStyles.agenceImg} 
                          alt={"logo par défaut pour l'agence"} 
                          src={"https://api.homecrush.fr/imgDefaut/default.jpeg"} 
                      />
                )}

                </article>
              </section>
            </article>
          </section>
          {/* SECTION SCROLL BIEN DE L'AGENT*/}
          {biensAgence.length >= 1 &&
            <section style={{ marginTop: "80px", marginBottom: "80px" }}>
              <h3 className={MainStyles.pageSubTitle}>Les autres biens proposés par l'agence</h3>
              <ScrollerHorizontal className={MainStyles.ScrollerHorizontal} data={biensAgence} />
            </section>
          }
        </article>
      )
      }
      <Footer />
    </section >
  );
};

export default withCookies(Agences);
