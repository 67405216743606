import React, { useEffect, useState , useCallback } from "react";
import Styles from "./DerniersBiensScroller.module.scss";
import 'react-horizontal-scrolling-menu/dist/styles.css';

import { withCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useDrag } from '@use-gesture/react';



function DerniersBiensScroller(props) {
    const [items, setItems] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [reloadKey, setReloadKey] = useState(0);

    let navigate = useNavigate()

    var user_code = props.cookies.get('user_code')
    //5.135.166.139
    //51.91.130.30
    const getItems = useCallback(() => {
    const bodyy = {
        blossom: "biensALaUne",
        nombre: 10,
        user_code: user_code || "0f0f10085e845c789f7aa4a64e39a474"
    };
    fetch('https://api.homecrush.fr/control_room.php', {
        method: 'POST',
        body: JSON.stringify(bodyy)
    })
    .then(response => response.json())
    .then(setItems)
    .catch(erreur => console.log('ERROR:', erreur));
}, [user_code]);

useEffect(() => {
    getItems();
}, [getItems]);


const bind = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
    distance = parseInt(distance);
    //console.log(`Mouse down: ${down}, Movement X: ${mx}, Direction X: ${xDir}, Distance: ${distance}`);
    if (down && distance > 100) { // Seuil de déplacement pour activer le glissement
        ///console.log("Drag Activated");
        cancel(); // Vous pouvez utiliser cancel pour arrêter le drag si nécessaire
    }
});


    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const handleClick = (id, showlink) =>
        ({ getItemById, scrollToItem }) => {

            console.log('ID du bien cliqué:', id);
            console.log('Show link:', showlink);

            navigate(showlink)
            const itemSelected = isItemSelected(id);

            setSelected((currentSelected) =>
                itemSelected
                    ? currentSelected.filter((el) => el !== id)
                    : currentSelected.concat(id)
            );
        };

    return (
    <div {...bind()} className={Styles.container}> {/* Utilisation de bind ici */}
        <ScrollMenu key={reloadKey} className={Styles.scroller} LeftArrow={LeftArrow} RightArrow={RightArrow}>
            {items.map(({ id, prix, photo, villeBien, showlink, pro, photoExternalLink }) => (
                <Card
                    itemId={id} 
                    title={id}
                    pro={pro}
                    prix={prix}
                    villeBien={villeBien}
                    photo={photo}
                    photoExternalLink={photoExternalLink}
                    key={id}
                    onClick={handleClick(id, showlink)}
                    selected={isItemSelected(id)}
                />
            ))}
        </ScrollMenu>
        <div className={Styles.fader}></div>
    </div>
    );
};

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    return (
        <div className={Styles.arrowLeft} disabled={isFirstItemVisible} onClick={() => {console.log("click Left"); scrollPrev()}}><span>{"<"}</span></div>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        <div className={Styles.arrowRight} disabled={isLastItemVisible} onClick={() => {console.log("click Right"); scrollNext();}}><span>{">"}</span></div>
    );
}

function Card({ onClick, selected, prix, photo, photoData, photoExternalLink, villeBien, pro, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, l => l.toUpperCase());
    }
    
    let photoUrl;
    if (photo) {
        photoUrl = `https://${photo}`;
    } else if (photoData) {
        photoUrl = photoData;
    } else if (photoExternalLink && photoExternalLink.length > 0) {
        photoUrl = photoExternalLink[0];
    } else {
        photoUrl = "https://api.homecrush.fr/imgDefaut/default.jpeg";
    }    

    return (
        <div className={Styles.card} onClick={() => onClick(visibility)} style={{ width: '250px' }} tabIndex={0}>
            <div className={Styles.cardEach}>
                {pro === "1" &&
                    <div className={Styles.etiquette}>Pro</div>
                }
                <img className={Styles.cardImage} src={photoUrl} alt={itemId} />
                <div className={Styles.prix}>{new Intl.NumberFormat('fr-FR', { style: 'currency', maximumFractionDigits: 0, currency: 'EUR' }).format(parseInt(prix))}</div>
                <div className={Styles.divider}></div>
                <div className={Styles.ville}>{capitalizeWords(villeBien.toLowerCase())}</div>
            </div>
        </div>  
    );
}

export default withCookies(DerniersBiensScroller);