import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';

import ParametrePub from './ParametrePub'
import MainStyles from '../../App.module.scss'

import LottieLoader from "../Loader/Loader"
import animationData from "../../assets/homecrush-logo.json"

import { Formik } from 'formik';

import NotifModalCss from '../../components/NotifModal/NotifModal.module.scss';
const MesPubs = (props) => {
  const [recupInfosCompte, setRecupInfosCompte] = React.useState({
    nom: '_',
    prenom: '_',
  });
  const [user_code, setUsercode] = React.useState("");
  const [rang, setRang] = React.useState("0");
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [numeroCPI, setNumeroCPI] = React.useState("false");
  const [idAgences, setIdAgence] = React.useState("");

  const [idPubSuppr, setIdPubSuppr] = React.useState("");
  const [titreSuppr, setTitreSuppr] = React.useState("");

  const [showLoader, setShowLoader] = React.useState(false);

  const [idBienSuppr, setIdBienSuppr] = React.useState("");

  const [idPhotoModified, setIdPhotoModified] = React.useState("");

  const [refresh, setRefresh] = React.useState(false);
  const [listePub, setListePub] = React.useState([]);


  const [imagePub, setImagePub] = React.useState("");
  const [extImagePub, setExtImagePub] = React.useState("");


  const [images, setImages] = React.useState([]);

  const [notif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [notifType, setNotifType] = useState("");

  const openBien = () => {
    console.log("redirection vers la page du bien (showlink?)")
  }

  const openModal = (titre, id) => {
    setShowModal(true)
    setIdPubSuppr(id)
    setTitreSuppr(titre)
  }
  const closeModal = () => {
    setShowModal(false)
  }
  const supprBien = () => {
    setShowModal(false)
  }

  const _listeInfos = (user_code) => {
    var bodyy2 = {
      blossom: "listeInfos",
    }
    if (user_code) {
      bodyy2.user_code = user_code
    } else {
      bodyy2.user_code = '0'
    }

    // Récupération des données de l'API
    fetch(
      "https://api.homecrush.fr/control_room.php",
      {
        method: 'POST',
        body: JSON.stringify(bodyy2)
      })
      .then(response => response.json())
      .then(response => {
        response = JSON.parse(response)
        setListePub(response)



        return response
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }


  const supprPub = () => {
    setShowLoader(true)
    var bodyy = {
      blossom: "supprimerPub",
      user_code: props.cookies.cookies.user_code,
      idPub: idPubSuppr
    }
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {

        if (response.httpstatus === 200) {
          setIdPubSuppr("")
          setTitreSuppr("")
          setShowModal(false)
          setShowLoader(false)
          setRefresh(refresh ? false : true)
        }

        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }


  const refreshList = () => {
    setRefresh(true)
  }


  useEffect(() => {

    var user_code = props.cookies.get('user_code')
    // var rang = props.cookies.get('rang')
    setRang(props.cookies.get('rang'))
    _listeInfos(user_code)
    return () => {

      if (rang >= "0") {
        var bodyy = {
          blossom: "monProfil",
          user_code,
        }

        fetch(
          'https://api.homecrush.fr/control_room.php',
          {
            method: 'POST',
            body: JSON.stringify(bodyy)
          })
          .then(response => response.json())
          .then(response => {

            response = JSON.parse(response)


            setNumeroCPI(response.numeroCPI)
            setIdAgence(response.idagence)

            return response;
          })
          .catch(erreur => {
            console.log('ERROR: ' + erreur);
          })

        setRang(rang)
        setUsercode(user_code)
      }
    }
  }, [props.cookies, rang, refresh])

  const _modifierPub = (values, infosPub) => {

    setShowModal(true)
    setShowLoader(true)

    var bodyy = {
      blossom: "modifPub",
      user_code: user_code,
      id: infosPub.id,
    }
    bodyy.titre = values.titre ? values.titre : infosPub.titre;
    bodyy.url = values.url ? values.url : infosPub.url;
    bodyy.description = values.description ? values.description : infosPub.description;
    if (images[infosPub.id]) {
      console.log("nouvelle image")
      bodyy.image = images[infosPub.id].base64;
      bodyy.ext = images[infosPub.id].ext;
    } else {
      console.log("pas de nouvelle image")
      bodyy.image = infosPub.image
    }

    console.log(bodyy);

    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {

        if (response.httpstatus === 200) {
          showNotif(response.info, "success")
        }
        else {
          showNotif(response.info, "error")
        }

        setShowLoader(false)
        setShowModal(false)
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      })

  }
  const showNotif = (message, type) => {
    setNotif(true);
    setNotifMsg(message);
    setNotifType(type);


    setTimeout(() => {
      setNotif(false);
    }, 3000);
  };

  const changeImage = async (e, idPub) => {

    console.log("e", e)
    console.log("pudId", idPub)
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (readerEvent) => {


      let imagesTemp = [...images];

      let image = { ...imagesTemp[idPub] };

      image.base64 = readerEvent.target.result.substring(reader.result.lastIndexOf("/9j/"));
      image.ext = readerEvent.target.result.substring("data:image/".length, reader.result.indexOf(";base64"))

      imagesTemp[idPub] = image;

      setImages(imagesTemp);

      var domImage = document.getElementById(`image` + `${idPub}`)
      domImage.src = reader.result;



    };


  };


  return (
    <section>
      {notif &&
        <div className={NotifModalCss.modalContainer} style={{
          backgroundColor: notifType === "success" ? "#bda87d" : "#F00",
          borderTop: notifType === "success" ? "5px solid white" : "5px solid #bda87d",
          borderRadius: "5px"
        }} id="notifModal">
          <p className={NotifModalCss.paragraph}>{notifMsg}</p>
        </div>
      }

      {showModal &&
        <div className={MainStyles.modalWrapper}>
          <div className={MainStyles.modalContainer}>
            <div className={MainStyles.listDeMesBiensClosebtn} onClick={() => closeModal()}>{"X"}</div>

            {showLoader && <LottieLoader animationData={animationData} width={"100%"} height={"auto"} />}


            {!showLoader &&
              <div>
                <p style={{ color: '#BDA87D', margin: "1rem 2rem 2rem 2rem" }}>Souhaitez-vous vraiment supprimer votre <br /> publicité du site Homecrush ?</p>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className={MainStyles.btnModal} style={{ backgroundColor: 'transparent', color: '#BDA87D', marginLeft: "0.5rem" }} onClick={() => closeModal()}>Annuler</div>
                  <div className={MainStyles.btnModal} style={{ backgroundColor: '#0DA8DB', color: '#fff', marginLeft: "0.5rem" }} onClick={() => supprPub()}>Valider</div>
                </div>
              </div>
            }
          </div>
        </div>
      }


      <article className={MainStyles.mesPubsLayout}>
        <ParametrePub refreshList={refreshList} />
        <section className={MainStyles.pubRightContainer}>
          <p style={{ paddingBottom: "1rem", paddingTop: "1rem", borderBottom: "1px solid #bda87d", width: "100%" }}>Mes publicités</p>

          <article className={MainStyles.listContainer}>
            {listePub.length > 0 &&
              listePub.map((pub, index) => {
                return (

                  <Formik className={MainStyles.formikContainer}
                    initialValues={{ titre: pub.titre, description: pub.description, url: pub.url, pub: pub.id, photo: "", extension: "" }}
                    validate={values => {
                      const errors = {};
                      if (idPhotoModified == pub.id) {
                        values.photo = imagePub;
                        values.extension = extImagePub;
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {

                        console.log(values)
                        _modifierPub(values, pub)

                      }, 400);
                    }}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit} id={"pub" + pub.id} key={index}>

                        <div>

                          <img id={`image` + `${pub.id}`} className={MainStyles.cardImagePub} src={"https://" + pub.image} />
                          <input style={{ width: "60%" }} className={MainStyles.inputFilePub} type="file" accept="image/jpg,image/jpeg" id="fileImagePub" onChange={(e) => changeImage(e, pub.id)} />

                          {/*       <div className={MainStyles.formBtn} onClick={() => { this._changerImage("modifierImagePub", "imagePub", pub.id) }}>Confirmer</div> */}

                        </div>


                        {/*<img onClick={() => openBien()} className={MainStyles.cardImage} src={"https://" + pub.image} />*/}


                        <input
                          className={MainStyles.formInput}
                          type="input"
                          name="titre"
                          placeholder={"Titre"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.titre}
                        />
                        <input
                          className={MainStyles.formInput}
                          type="textarea"
                          name="description"
                          placeholder={"Description"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        />
                        <textarea
                          className={MainStyles.formInput}
                          type="input"
                          name="url"
                          placeholder={"Lien"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.url}
                        />
                        <button className={MainStyles.boutonBleu} type="submit" disabled={isSubmitting}>Modifier la pub</button>
                        <div className={MainStyles.boutonRouge} onClick={() => openModal(pub.titre, pub.id)}>Supprimer la pub</div>

                        <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>

                      </form>
                    )}
                  </Formik>


                )
              })
            }
          </article>

        </section>
      </article>
    </section>
  )

};



export default withCookies(MesPubs);