import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { withCookies } from 'react-cookie';
import appStyles from "./App.module.scss"

import favicon from "./assets/icon.png"

// PAGES
import MainNav from './components/Navbar/MainNav';
import MaintenancePage from './pages/Maintenance';

import Accueil from './pages/Accueil';
import Bien from './templates/Bien';
 
import Agences from './pages/Agences';
import Agence from './templates/Agence';

import Compte from './pages/Compte';
import InfosVente from './pages/InfosVente';

import ListeCoeur from './pages/ListeCoeur';

import Ventes from './pages/Ventes';
import AjoutAnnonce from './components/AjoutAnnonce/AjoutAnnonce';

import Cgu from './pages/Cgu';
import Mentions from './pages/Mentions';
import Tutoriel from './pages/Tutoriel';

import Verify from './pages/Verify';

import RecuperationMotDePasse from './pages/RecuperationMotDePasse';
import MotDePasseOublie from './pages/MotDePasseOublie';
import Recherche from './pages/RecherchePage';

import NotFoundPage from './pages/NotFoundPage ';

import BackToTopButton from './components/BackToTopButton/BackToTopButton';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const maintenanceMode = false;

function App(props) {
  const location = useLocation();
  const history = useNavigate();
  
  // Définir l'état ici
  const [isSidebarOpen, setSidebarState] = useState(false);


  return (
    <div style={{ backgroundColor: '#332E57' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Homecrush</title>
        <meta name="description" content="Diffuseur d’annonces immobilières de professionnels ventes de maisons et appartements pour particuliers." />
        <link rel="canonical" href="https://homecrush.fr" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>

      {!maintenanceMode && 
        <MainNav 
          cookies={props.cookies} 
          isSidebarOpen={isSidebarOpen} 
          toggleSidebar={() => setSidebarState(!isSidebarOpen)}
        />
      }

      <main className={`${appStyles.routerContainer} ${isSidebarOpen ? appStyles.sidebarOpen : appStyles.sidebarClosed}`}>
        <ScrollToTop />
        <Routes history={history}>
          {maintenanceMode ? (
            <Route path="*" element={<MaintenancePage />} />
          ) : (
            <>
              <Route exact path="/" element={<Accueil cookies={props.cookies} />} />
              <Route path='/Biens/:handle/:handle' element={<Bien />} />
              <Route path='/Biens/:handle/:handle/:handle/:handle' element={<Bien />} />

              <Route exact path="/Agences" element={<Agences />} />
              <Route path='/Agences/:handle/:handle' element={<Agence />} />

              <Route exact path="/Compte" element={<Compte />} />

              <Route exact path="/InfosVentes" element={<InfosVente />} />
              <Route exact path="/InfosVentes#ajouterAnnonce" element={<AjoutAnnonce />} />

              <Route exact path="/VosCoupsdeCoeur" element={<ListeCoeur />} />

              <Route exact path="/Ventes" element={<Ventes />} />

              <Route exact path="/CGU" element={<Cgu />} />
              <Route exact path="/MentionsLegales" element={<Mentions />} />
              <Route exact path="/Tutoriel" element={<Tutoriel />} />
              <Route exact path="/Recherche" element={<Recherche />} />

              <Route exact path="/Verify" element={<Verify />} />

              <Route history path="/recuperation-mot-de-passe" element={<RecuperationMotDePasse />} />

              <Route exact path="/mot-de-passe-oublie" element={<MotDePasseOublie />} />

              <Route path="*" element={<NotFoundPage />} />
            </>
          )}
        </Routes>
      </main>
      <BackToTopButton />
    </div>
  );
}

export default withCookies(App);
