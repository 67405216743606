import React, { Component } from "react";
import { Formik } from "formik";

import MainStyles from "../../App.module.scss";

import NotificationSystem from "react-notification-system";
import notifStyles from "../NotifStyles";

import { withCookies } from "react-cookie";

class ParametreAgence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recupInfosCompte: {
        nom: "_",
        prenom: "_",
      },
    };
    this.notificationSystem = React.createRef();
  }
  componentDidMount() {
    var user_code = this.props.cookies.get("user_code");
    var rang = this.props.cookies.get("rang");
    this.setState({ rang: rang });

    if (rang >= "0") {
      var bodyy = {
        blossom: "monProfil",
        user_code,
      };


      fetch("https://api.homecrush.fr/control_room.php", {
        method: "POST",
        body: JSON.stringify(bodyy),
      })
        .then((response) => response.json())
        .then((response) => {
          response = JSON.parse(response);


          this.setState({
            numeroCPI: response.numeroCPI,
            idAgence: response.idagence,
          });

          return response;
        })
        .catch((erreur) => {
          console.log("ERROR: " + erreur);
        });

      this.setState({ user_code, rang });
    }
  }

  envoieLienPlateforme = (blossom, param, values) => {
    var bodyy = {
      blossom: blossom,
      [param]: values.lien,
      user_code: this.state.user_code,
    };

    if (this.state.idAgence) {
      bodyy.idagence = this.state.idAgence;
    }


    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.httpstatus === 200) {
          this.addNotif("Votre lien a bien été récupéré par Homecrush.");
        }
        if (response.error === "ok") {
          this.addNotif(
            "Une erreur est survenue lors l'envoi du lien. Veuillez essayer ultérieurement.",
            "error"
          );
        }

        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
  };

  _changerChamps = (blossom, param, values) => {
    if (blossom === "modifierMotDePasse") {
      var bodyy = {
        blossom: blossom,
        motDePasse: values.actuel,
        nouveauMotDePasse: values.mdp,
        user_code: this.state.user_code,
      };

    } else {
      bodyy = {
        blossom: blossom,
        [param]: values.field,
        user_code: this.state.user_code,
      };
    }
    if (this.state.idAgence) {
      bodyy.idagence = this.state.idAgence;
    }


    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        response = JSON.parse(response);

        if (response.error === "error wrong mdp") {
          return alert("mauvais identifiant");
        }
        if (response.error === "ok") {
          alert("Votre changement a été enregistré avec succès");
        }

        //
        window.location.reload();

        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
  };

  addNotif = (message, etat) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: message,
      level: etat ? etat : "success",
    });
  };

  _changerImage(blossom, param) {
    let bodyy = {
      blossom: blossom,
      user_code: this.state.user_code,
    };
    if (this.state.idAgence) {
      bodyy.idagence = this.state.idAgence;
    }

    if (param === "lelogo") {
      bodyy.image = this.state.monLogo;
      bodyy.extension = this.state.monExt;
    }

    if (param === "profilPro") {
      bodyy.image = this.state.monProfilPro;
      bodyy.extension = this.state.monExtProfilPro;
    }

    if (param === "profilPar") {
      bodyy.image = this.state.monProfilPar;
      bodyy.extension = this.state.monExtProfilPar;
    }

    if (param === "photoagence") {
      bodyy.image = this.state.maPhotoAgence;
      bodyy.extension = this.state.monExtAgence;
    }

    if (param === "photodir") {
      bodyy.image = this.state.maPhotoDir;
      bodyy.extension = this.state.monExtDir;
    }


    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        if(this.state.monLogo){
          this.addNotif("Votre image à été modifié avec succès");
        } else if(this.state.maPhotoAgence){
          this.addNotif("Votre image à été modifié avec succès");
        } else if(this.state.maPhotoDir){
          this.addNotif("Votre image à été modifié avec succès");
        } else {
          return
        }
        // if (response.httpstatus === 200) {
        //   this.addNotif("Votre image à été modifié avec succès");
        // }
        // if(bodyy.blossom === "modifierLogo" || bodyy.blossom === "modifierPhotoAgence" || bodyy.blossom === "modifierImageDirigeant"){
        //   this.addNotif("Votre image à été modifié avec succès");
        // }
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
  }

  render() {
    var rang = this.props.cookies.get("rang");

    var logo = document.getElementById("logo");
    if (logo) {
      const reader = new FileReader();
      const fileInput = document.getElementById("logo");
      const img = document.getElementById("imgLogo");

      reader.onload = (e) => {
        img.src = e.target.result;

        var imageLogoBase64 = reader.result.substring(reader.result.lastIndexOf("/9j/"));
        var ext = reader.result.substring("data:image/".length, reader.result.indexOf(";base64"));

        this.setState({ monLogo: imageLogoBase64, monExt: ext });
      };
      fileInput.addEventListener("change", (e) => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      });
    }

    var photoAgence = document.getElementById("fileAgence");
    if (photoAgence) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileAgence");
      const img = document.getElementById("imgAgence");

      reader.onload = (e) => {
        img.src = e.target.result;

        var imagePhotoAgenceBase64 = reader.result.substring(reader.result.lastIndexOf("/9j/"));
        var ext = reader.result.substring("data:image/".length, reader.result.indexOf(";base64"));

        this.setState({
          maPhotoAgence: imagePhotoAgenceBase64,
          monExtAgence: ext,
        });
      };
      fileInput.addEventListener("change", (e) => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      });
    }

    var photoDir = document.getElementById("fileDir");
    if (photoDir) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileDir");
      const img = document.getElementById("imgDir");

      reader.onload = (e) => {
        img.src = e.target.result;

        var imageDiriBase64 = reader.result.substring(reader.result.lastIndexOf("/9j/"));
        var ext = reader.result.substring("data:image/".length, reader.result.indexOf(";base64"));

        this.setState({ maPhotoDir: imageDiriBase64, monExtDir: ext });
      };
      fileInput.addEventListener("change", (e) => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      });
    }

    return (
      <section className={MainStyles.sousTitre}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        <p className={MainStyles.mainTitle}>Paramètres</p>

        {(rang === "1" || rang === "3") && (         
          <article className={MainStyles.form}>
            <div>
              <p className={MainStyles.labelParagraphFirst}>Modifier le numéro de rue</p>

              <Formik className={MainStyles.formikContainer}
              initialValues={{ field: "" }}
              validate={(values) => { 
                const errors = {};
                if (!values.field) {
                  errors.field = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._changerChamps(
                    "modifierNumeroAgence",
                    "nouveauNumero",
                    values
                  );
                  setSubmitting(false);
                }, 400);
              }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="field" placeholder="nouveau n° de rue"
                        onChange={handleChange} onBlur={handleBlur} value={values.field}
                        />
                      <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>                   
                    </div>
                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                  </form>
                )}
              </Formik>
            </div>

            <div>
              <p className={MainStyles.labelParagraph}>Modifier la rue de l'agence</p>

              <Formik className={MainStyles.formikContainer}
              initialValues={{ field: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.field) {
                  errors.field = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._changerChamps(
                    "modifierRueAgence",
                    "nouvelleRue",
                    values
                  );
                  setSubmitting(false);
                }, 400);
              }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="field" placeholder="nouveau nom de rue"
                        onChange={handleChange} onBlur={handleBlur} value={values.field}
                      />
                      <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>                  
                    </div>
                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                  </form>
                )}
              </Formik>
            </div>
              
            <div>    
              <p className={MainStyles.labelParagraph}>Modifier le code postal de l'agence</p>

              <Formik className={MainStyles.formikContainer}
              initialValues={{ field: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.field) {
                  errors.field = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._changerChamps(
                    "modifierCodePostaleAgence",
                    "nouveauCodePostale",
                    values
                  );
                  setSubmitting(false);
                }, 400);
              }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                       type="input" name="field" placeholder="nouveau code postal"
                        onChange={handleChange} onBlur={handleBlur} value={values.field}
                      />
                      <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>
                    </div>
                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                  </form>
                )}
              </Formik>
            </div>

            <div>
              <p className={MainStyles.labelParagraph}>Modifier la ville de l'agence</p>

              <Formik className={MainStyles.formikContainer}
              initialValues={{ field: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.field) {
                  errors.field = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._changerChamps(
                    "modifierVilleAgence",
                    "nouvelleVille",
                    values
                  );
                  setSubmitting(false);
                }, 400);
              }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                      type="input" name="field" placeholder="nouvelle ville"
                      onChange={handleChange} onBlur={handleBlur} value={values.field}
                      />
                      <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>
                    </div>
                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                  </form>
                )}
              </Formik>
            </div>
              
            <div>
              <p className={MainStyles.labelParagraph}>Modifier le Logo</p>
              <div className={MainStyles.formPhotoBtn}>
                <img id="imgLogo" alt="logo de l'agence" width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'}/>

                {!this.state.monLogo && (
                  <input className={MainStyles.inputFile} style={{ margin: "2rem auto 0rem auto"}} type="file" accept=".jpg,.jpeg" id="logo"/>
                )}
                {this.state.monLogo && (
                  <div className={MainStyles.formBtn} onClick={() => { this._changerImage("modifierLogo", "lelogo") }}>Confirmer</div>
                )}
              </div>
            </div>

            <div>
              <p className={MainStyles.labelParagraph}>Modifier la photo de l'agence</p>
              <div className={MainStyles.formPhotoBtn}>
                <img id="imgAgence" alt="Agence" width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'}/>

                {!this.state.maPhotoAgence && (
                  <input className={MainStyles.inputFile} type="file" accept="image/jpg,image/jpeg" id="fileAgence" style={{ margin: "2rem auto 0rem auto"}}/>
                )}
                {this.state.maPhotoAgence && (
                  <div className={MainStyles.formBtn} onClick={() => {this._changerImage( "modifierPhotoAgence", "photoagence");
                  }}>Confirmer</div>
                )}
              </div>
            </div>
              

            <div>
              <p className={MainStyles.labelParagraph}>Modifier l'image du dirigeant</p>
              <div className={MainStyles.formPhotoBtn}>
                <img id="imgDir" alt="dirigeant de l'agence" width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'}/>

                {!this.state.maPhotoDir && (
                  <input className={MainStyles.inputFile} type="file" accept="image/jpg,image/jpeg" id="fileDir" style={{ margin: "2rem auto 0rem auto"}}/>
                )}
                {this.state.maPhotoDir && (
                  <div className={MainStyles.formBtn} onClick={() => { this._changerImage( "modifierImageDirigeant", "photodir");
                  }}>Confirmer</div>
                )}
              </div>
            </div>

            

            <div>
              <p className={MainStyles.labelParagraph}>Modifier le mail de l'agence</p>

              <Formik  className={MainStyles.formikContainer}
              initialValues={{ field: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.field) {
                  errors.field = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._changerChamps(
                    "modifierMailAg",
                    "nouveauMail",
                    values
                  );
                  setSubmitting(false);
                }, 400);
              }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="field" placeholder="votre nouveau mail"
                        onChange={handleChange} onBlur={handleBlur} value={values.field}
                      />
                      <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>               
                    </div>
                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                  </form>
                )}
              </Formik>
            </div>

            <div>
              <p className={MainStyles.labelParagraph}>Modifier téléphone agence</p>

              <Formik className={MainStyles.formikContainer}
              initialValues={{ field: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.field) {
                  errors.field = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._changerChamps(
                    "modifierTelAg",
                    "nouveauTel",
                    values
                  );
                  setSubmitting(false);
                }, 400);
              }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="field" placeholder="Tel de l'agence"
                        onChange={handleChange} onBlur={handleBlur} value={values.field}
                      />
                      <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button> 
                    </div>
                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                  </form>
                )}
              </Formik>
            </div>

            <div>
              <p className={MainStyles.labelParagraph}>Modifier URL agence</p>

              <Formik className={MainStyles.formikContainer}
              initialValues={{ field: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.field) {
                  errors.field = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._changerChamps(
                    "modifierURLAg",
                    "nouveauURL",
                    values
                  );
                  setSubmitting(false);
                }, 400);
              }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="field" placeholder="nouvelle adresse du site"
                        onChange={handleChange} onBlur={handleBlur} value={values.field}
                      />
                      <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>
                    </div>
                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                  </form>
                )}
              </Formik>
            </div>
          </article>
        )}
      </section>
    )
  }
}

export default withCookies(ParametreAgence);