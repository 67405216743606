import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const buttonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: isHovered ? '#5A5384' : '#332e57', // Changement de couleur au hover
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        boxShadow: isHovered ? '0px 6px 12px rgba(0, 0, 0, 0.3)' : '0px 4px 8px rgba(0, 0, 0, 0.2)', // Changement d'ombre au hover
    };


    return (
        <div style={{ textAlign: 'center', marginTop: '20vh',position: 'relative', color: '#bda87d' }}>
            
            <h1>404 - Page Non Trouvée</h1>
            <p>Désolé, la page que vous cherchez n'existe pas.</p>
            <button 
                onClick={() => navigate('/')}
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                Retourner à la Page Principale
            </button>
        </div>
    );
};

export default NotFoundPage;