import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import VerifyStyles from './Verify.module.scss'
import MainStyles from '../App.module.scss';

import { withCookies } from 'react-cookie';

import LottieLoader from "../components/Loader/Loader"
import animationData from "../assets/homecrush-logo.json"
import { get } from 'react-scroll/modules/mixins/scroller';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "progressing"
    };
  }


  componentDidMount() {
    this.checkVerification();
  }


  checkVerification = () => {
    //On récupère les paramètres de l'URL (email et hash)
    const query = window.location.search;
    const dataQuery = new URLSearchParams(query)

    const email = dataQuery.get('email')
    const hash = dataQuery.get('hash')

    //On envoie la requête
    var bodyy = {
      blossom: "validationMail",
      email: email,
      hash: hash
    }
    if (email === null || hash === null) {
      this.setState({ message: 'Pas de paremetre(s), vérifiez le lien.', status: "error" })
    } else {
      fetch(
        "https://api.homecrush.fr/control_room.php",
        {
          method: 'POST',
          body: JSON.stringify(bodyy)
        })
        .then(response => response.json())
        .then(response => {

          if (response.httpstatus === 200) {
            this.setState({ message: response.info, status: "success" })
          } else {

            this.setState({ message: "Une erreur s'est produite, veuillez réessayer ultérieurement.", status: "error" })
          }
          return response
        })
        .catch(erreur => {
          console.log('ERROR: ' + erreur);
        });
    }
  }

  render() {
    return (
      <div className={MainStyles.pageContainer}>
        <h1 className={MainStyles.pageTitle}>Email validé</h1>

        {/* <div className={VerifyStyles.messageContainer}> */}
        {this.state.status === "progressing" &&
            <div>
              <LottieLoader animationData={animationData} width={300} height={300} />
              <p className={VerifyStyles.texteMiddle}>Votre demande de vérification d'email est en cours.</p>
              <p style={{color:"#332E57"}}>{this.state.message}</p>
            </div>
          }
          {this.state.status === "success" &&
            <h2 className={MainStyles.pageSubTitle}>Un email qui vous explique la procédure pour la synchronisation de vos biens avec la plateforme vous a été envoyé.</h2>
          }
          {this.state.status === "success" &&
            <div>
              <p className={MainStyles.pageSubTitle} style={{paddingBottom: "40px"}}>Vous pouvez maintenant parcourir l'application Homecrush.</p>
              <NavLink to={'/'} className={MainStyles.btnLink}>Parcourez l'application</NavLink>
            </div>
          }
          {this.state.status === "error" &&
            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
              <svg style={{ height: '4rem', width: "auto" }} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                      <g fill={"#332E57"}>
                        <polygon points="35.62 30.24 30.24 35.62 30.24 36.96 113.04 119.76 114.38 119.76 119.76 114.38 119.76 113.04 36.96 30.24 35.62 30.24" />
                        <polygon points="62.65 80.63 30.24 113.04 30.24 114.38 35.62 119.76 36.96 119.76 69.37 87.35 69.37 86.01 63.99 80.63 62.65 80.63" />
                        <polygon points="113.04 30.24 80.63 62.65 80.63 63.99 86.01 69.37 87.35 69.37 119.76 36.96 119.76 35.62 114.38 30.24 113.04 30.24" />
                      </g>
                    </svg>
              <p style={{color:"#332E57"}}>{this.state.message}</p>
            </div>
          }
        </div>
      // </div>
    )
  }
};

export default withCookies(Verify);