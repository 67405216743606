import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import MainStyles from "../../App.module.scss";
import { useNavigate } from 'react-router-dom';

import check from '../../assets/check.png';

// Barre de progression
const ProgressBar = ({ currentStep, totalSteps }) => {
  const width = ((currentStep - 1) / (totalSteps - 1)) * 100;
  return (
    <div style={{ position: 'absolute', backgroundColor: '#312B55', height: '1em', width: '100%', borderRadius: '0.5em', top: 0 }}>
      <div style={{ backgroundColor: '#A099C7', transition: 'width 0.5s', height: '100%', width: `${width}%`, borderRadius: '0.5em' }} />
    </div>
  );
};

const RechercheBienForm = () => {
  const [etape, setEtape] = useState(1);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      blossom: 'rechercheBien',
      nom: '',
      prenom: '',
      tel: '',
      mail: '',
      description: '',
      superficie: '',
      cp: '',
      particularite: '',
    },
    validate: (values) => {
      const errors = {};
      if (etape === 1) {
        if (!values.nom) {
          errors.nom = 'Le nom est requis.';
        }
        if (!values.tel) {
          errors.tel = 'Le téléphone est requis.';
        }
        if (!values.mail) {
          errors.mail = 'L\'email est requis.';
        }
      }
      if (etape === 2) {
        if (!values.cp) {
          errors.cp = 'Le Code Postal est requis.';
        }
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (etape === 2) {
        axios.post('https://api.homecrush.fr/control_room.php', values)
          .then(response => {
            console.log(response);
            setEtape(etape + 1);
          })
          .catch(error => {
            console.error(error);
          });
      }
      setSubmitting(false);
    },
  });

  const handleNext = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0 && etape === 1) {
      setEtape(etape + 1);
    } else {
      formik.handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (etape > 1) {
      setEtape(etape - 1);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '60vh', alignItems: 'center',marginBottom :'0', marginTop :'-5vh' }}>
      <form onSubmit={formik.handleSubmit} className={MainStyles.form1} style={{ maxHeight: '500px', height: '100%', maxWidth: '500px', width: '100%', margin: 'auto', marginRight: 0, background: 'linear-gradient(to bottom, #544E7B, #3F3A63)', padding: '20px', zIndex: 1, position: 'relative', paddingBottom: '0px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }}>
        {/* Ajoutez la barre de progression ici */}
        <ProgressBar currentStep={etape} totalSteps={3} style={{ position: 'absolute', top: '0', left: '0', right: '0' }} />
        {etape === 1 && (
          <div>
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="nom"
                placeholder="Nom *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nom}
              />
              <div className={MainStyles.tooltipHelper}>i
                <span className={MainStyles.tooltipTextHelper}>Ex: Doe</span>
              </div>
            </div>
            {formik.errors.nom && formik.touched.nom && formik.errors.nom}
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="prenom"
                placeholder="Prénom"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.prenom}
              />
              <div className={MainStyles.tooltipHelper}>i
                  <span className={MainStyles.tooltipTextHelper}>Ex: John</span>
              </div>
            </div>
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="tel"
                placeholder="Téléphone *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tel}
              />
              <div className={MainStyles.tooltipHelper}>i
                  <span className={MainStyles.tooltipTextHelper}>Ex: 0123456789</span>
              </div>
            </div>
            {formik.errors.tel && formik.touched.tel && formik.errors.tel}
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="mail"
                placeholder="Email *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mail}
              />
              <div className={MainStyles.tooltipHelper}>i
                  <span className={MainStyles.tooltipTextHelper}>Ex: example@mail.com</span>
              </div>
            </div>
            {formik.errors.mail && formik.touched.mail && formik.errors.mail}
          </div>
        )}

        {etape === 2 && (
          <div>
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="description"
                placeholder="Designation"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              <div className={MainStyles.tooltipHelper}>i
                <span className={MainStyles.tooltipTextHelper}>Ex:  </span>
              </div>
            </div>
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="superficie"
                type='number'
                min="0"
                step="1"
                placeholder="Superficie"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.superficie}
              />
              <div className={MainStyles.tooltipHelper}>i
                  <span className={MainStyles.tooltipTextHelper}>Ex: 100 m² </span>
              </div>
            </div>
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="cp"
                type="number"
                min="01000"
                max="99999"
                step="1"
                placeholder="Code postal *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cp}
              />
              <div className={MainStyles.tooltipHelper}>i
                  <span className={MainStyles.tooltipTextHelper}>Ex: 13001 </span>
              </div>
            </div>
            {formik.errors.cp && formik.touched.cp && formik.errors.cp}
            <div style={{display: "flex"}}>
              <input
                className={MainStyles.formInput}
                style={{ marginTop: "2rem", display: "block" }}
                name="particularite"
                placeholder="Particularité"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.particularite}
              />
              <div className={MainStyles.tooltipHelper}>i
                  <span className={MainStyles.tooltipTextHelper}>Ex:  </span>
              </div>
          </div>
        </div>
      )}
        {etape === 3 && (
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            {/* Placeholder pour l'image */}
            <img src={check} alt="Check icon" style={{ maxWidth: '50%', marginBottom: '0.5rem', marginTop: '-2rem' }} />
        
            <h2 style={{ color: 'white', fontFamily: 'Hermes Sans', marginBottom: '0.5rem' }}>Merci de votre confiance !</h2>
            <h3 style={{ color: '#A099C7', fontFamily: 'Hermes Sans', marginBottom: '1rem' }}>Un professionnel vous contactera rapidement.</h3>
            <button onClick={() => navigate('/')} className={MainStyles.formBtn} style={{ margin: 'auto', display: 'block' }}>
              Retour à l'accueil
            </button>
          </div>
        )}
        {etape < 3 && (
          <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {etape > 1 && (
              <button
                className={MainStyles.prevButton}
                type="button"
                onClick={handlePrevious}
              >
                &#8592;
              </button>
            )}

            {etape < 3 && (
              <button
                className={MainStyles.nextButton}
                type="button"
                onClick={handleNext}
              >
                Suivant
              </button>
            )}
          </div>
        )}
      </form>
      {/* Sidebar des étapes */}
      <div className={MainStyles.sidebar_step} style={{ maxWidth: '250px', maxHeight: '450px', height: '90%', backgroundColor: '#A099C7', paddingLeft: '10px', zIndex: 0, margin: 'auto auto auto 0', display: 'flex', flexDirection: 'column', alignItems: 'start', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
        <div className={`${MainStyles.step} ${etape === 1 ? MainStyles.activeStep : ''}`}>
          <div className={MainStyles.stepContent}>
            <div className={`${MainStyles.stepDot} ${etape === 1 ? MainStyles.activeStep : ''}`}></div>
            <div className={MainStyles.stepText}>Vos informations</div>
          </div>
          {etape === 1 && <p>Système de diffusion et de<br></br> recherche d'annonces<br></br> immobiliéres de particuliers.</p>}
        </div>

        <div className={`${MainStyles.step} ${etape === 2 ? MainStyles.activeStep : ''}`}>
          <div className={MainStyles.stepContent}>
            <div className={`${MainStyles.stepDot} ${etape === 2 ? MainStyles.activeStep : ''}`}></div>
            <div className={MainStyles.stepText}>Caractéristiques</div>
          </div>
          {etape === 2 && <p>Système de diffusion et de<br></br> recherche d'annonces.</p>}
        </div>
      </div>
    </div>
  );
};

export default RechercheBienForm;
