import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import MainStyles from "../App.module.scss";

import NotificationSystem from 'react-notification-system';
import notifStyles from "../components/NotifStyles";

import { Link } from "react-router-dom";
import AjoutBien from '../components/Ventes/AjoutBien';
import MesBiens from '../components/Ventes/MesBiens';
import Footer from '../components/Footer/Footer';

class Ventes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: true,
      showInscription: false,
    };
    this.notificationSystem = React.createRef();
    this.detectAnchor = this.detectAnchor.bind(this)

    this.showMesBiens = this.showMesBiens.bind(this);
  }

  componentDidMount() {
    var user_code = this.props.cookies.get('user_code')
    if (user_code) {
      this.setState({ showAjoutBien: true, showConnexion: false, showInscription: false, showParam: false })
    }
  }

  detectAnchor(event) {
    if (event === "#profil") {
      this.setState({ showAjoutBien: true, showConnexion: false, showInscription: false, showParam: false })
    }
  }

  addNotif = () => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: 'Vous avez bien été déconnecté',
      level: 'success'
    });
  };


  showMesBiens = (value) => {
    if (value === true) {
      this.setState({ showConnexion: false, showInscription: false, showPlateform: false, showParam: false, showAjoutBien: false, showBien: true, showParamPub: false, showParamAgence: false }) }

  }

  render() {
    const { cookies } = this.props;

    return (
      <section className={MainStyles.pageContainer}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        {cookies.get('user_code') === undefined &&      
          <article style={{ marginBottom: '25vh' }}>
            <h1 className={MainStyles.pageTitle}>Il semblerait que vous ne soyez pas connecté.</h1>
            <h2 className={MainStyles.pageSubTitle}>Connectez-vous à votre compte pour accéder à cette fonctionnalité.</h2>
            <Link to="/Compte" id="Compte" className={MainStyles.btnLink}>
              <p style={{ marginTop: "0.6rem", marginBottom: "0.7rem", fontSize: 16, color: '#fff', fontFamily: 'HermesReg', zIndex:'1' , position: 'relative'}}>Accéder à mon compte</p>
            </Link>
          </article>
        }


        {cookies.get('user_code') !== undefined && 
          <h1 className={MainStyles.pageTitle}>Bien à la vente</h1>
        }
        {cookies.get('user_code') !== undefined && 
          <h2 className={MainStyles.pageSubTitle}>Ajoutez votre bien à la vente, ou consultez sa fiche dans l'onglet "mes biens"</h2>
        }
        {cookies.get('user_code') !== undefined &&
          <article style={{ marginBottom: "80px" }} className={MainStyles.buttonBar}>
            <span className={MainStyles.btnFiltre}
              style={{ backgroundColor: this.state.showAjoutBien ? '#0DA8DB' : 'transparent', color: this.state.showAjoutBien ? '#fff' : '#BDA87D',marginLeft: "0.5rem" }}
              onClick={() => this.setState({ showConnexion: false, showInscription: false, showPlateform: false, showParam: false, showBien: false, showAjoutBien: true, showParamPub: false, showParamAgence: false })}
            >Ajouter un bien</span>

            <span className={MainStyles.btnFiltre}
              style={{ backgroundColor: this.state.showBien ? '#0DA8DB' : 'transparent', color: this.state.showBien ? '#fff' : '#BDA87D', marginLeft: "0.5rem" }}
              onClick={() => this.setState({ showConnexion: false, showInscription: false, showPlateform: false, showParam: false, showAjoutBien: false, showBien: true, showParamPub: false, showParamAgence: false })}
            >Mes biens en vente</span>
          </article>
        }

        {this.state.showAjoutBien &&
          <article className={MainStyles.toggleAjoutBienContainer}
            style={{
              backgroundColor: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "transparent" : "#fff",
              borderTop: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
              borderLeft: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
              minHeight: "40rem",
              borderRight: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
            }}>
            <AjoutBien usercode={this.state.user_code} showMesBiens={this.showMesBiens} />
          </article>
        }

        {this.state.showBien &&
          <article className={MainStyles.toggleAjoutBienContainer}
            style={{
              backgroundColor: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "transparent" : "#fff",
              borderTop: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
              borderLeft: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
              minHeight: "40rem",
              borderRight: (this.state.showAjoutBien || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
            }}>
            <MesBiens />
          </article>
        }
        <Footer />
      </section>
    )
  }
}

export default withCookies(Ventes);