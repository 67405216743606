import React, { Component } from "react";
import { Formik, Field } from "formik";
import { NavLink } from "react-router-dom";
import { withCookies } from "react-cookie";

import MainStyles from "../../App.module.scss";
import ConnexionStyles from "../Connexion/Connexion.module.scss"

import NotificationSystem from "react-notification-system";
import notifStyles from "../NotifStyles";


import LottieLoader from "../Loader/Loader"
import animationData from "../../assets/homecrush-logo.json"


import LogoAc3 from "../../assets/logo-plateforme/Logo-ac3.png"
import LogoTwimmo from "../../assets/logo-twimmo-homecrush.png"


// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: animationData,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

class FormProfessionel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInit: true,
      formPro: false,
      formAgence: false,
      init: 0,
      loader: false,
      etape: 0
    };
    this.notificationSystem = React.createRef();
  }

  //const [activeStepIndex, setActiveStepIndex] = useState(0);

  getLogo(files) {
    this.setState({ imageLogo: files });
  }

  getImgAgence(files) {
    this.setState({ imageAgenceBase64: files });
  }

  getImgDiri(files) {
    this.setState({ imageDiriBase64: files });
  }

  getImgPro(files) {
    this.setState({ imageProBase64: files });
  }
  _validationAgence(values) {
    var bodyy = {
        blossom: "agenceValidation",
        nom: values.nomAgence.replace("\'", " "),
    };
    // Récupération des données de l'API
    return fetch("https://api.homecrush.fr/control_room.php", {  // <-- Ajoutez 'return' ici
        method: "POST",
        body: JSON.stringify(bodyy),
    })
    .then((response) => response.json())
    .then((response) => {
        response = JSON.parse(response);
        if (response.idagence) {
            this.addNotif(
                "L'agence à bien été trouvé, veuillez remplir le formulaire d'inscription du professionnel"
            );
            this.setState({
                idAgence: response.idagence,
                formPro: true,
                formInit: false,
            }); // On ajoute les données recu au state
        } else {
            this.addNotif(
                "Aucune agence n'est enregistré à ce nom, veuillez remplir le formulaire d'inscription d'une agence"
            );
            this.setState({ formAgence: true, formInit: false }); // On ajoute les données recu au state
        }
        return response;
    })
    .catch((erreur) => {
        console.log("ERROR: " + erreur);
        this.setState({ formAgence: true, formInit: false }); // On ajoute les données recu au state
    });
}

  addNotif = (message) => {
    this.notificationSystem.current.addNotification({
      message: message,
      level: "success",
    });
  };

  _inscriptionPro(values) {
    let imagePro = this.state.monProfilPro;
    var extLogo = this.state.monExtProfilPro; // myString[myString.length - 3] + myString[myString.length - 2] + myString[myString.length - 1];

    var bodyy = {
      blossom: "sInscrirePro",
      prenom: values.prenom,
      nom: values.nom,
      mail: values.mail,
      motDePasse: values.motDePasse,
      idagence: this.state.idAgence,
      cgu: true,
      numeroDeCPI: values.numeroDeCPI,
    };

    if (imagePro) {
      bodyy.image = imagePro;
      bodyy.extension = extLogo;
    }


    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        response = JSON.parse(response);
        this.setState({ reponseInscriptionPro: response }); // On ajoute les données recu au state
        if (response.error === "error mdp") {
          return this.addNotif("Le mot de passe peut contenir un maximum de 10 caractères");
        } else if (response.error === "error user exist") {
          return this.addNotif("Une compte existe déjà à cette adresse.");
        } else {
          this.addNotif(
            "L'inscription de votre compte professionnel est un succès !"
          );
          this._tentativeConnexionPro(values.mail, values.motDePasse);
        }
        /*else {
          this.addNotif("Un problème est apparu lors de votre insceiption en tant que professionnel. Veuillez réessayer plus tard.")
          this.setState({ hideBtn: false, showBtn: true })
        }*/
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
  }

  _tentativeConnexionPro(mail, motDePasse) {
    var bodyy = {
      blossom: "seConnecter",
      mail: mail,
      motDePasse: motDePasse,
    };

    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        response = JSON.parse(response);

        this.props.cookies.set("user_code", response.user_code, { path: "/" });
        this.props.cookies.set("rang", response.rang, { path: "/" });

        if (this.props.userNonConnecte) {
          this.props.recupUserCode(response.user_code)
        } else {
          this.props.clickHandlerInscription("#profil");
        }

        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
  }

  validationEtape(etape, values, errors) {
    const expr = 'Papayas';
    switch (etape) {
      case 0:
        if (!values.nomAgence) {
          errors.nomAgence = "Obligatoire";
        } else if (!values.numeroAgence) {
          errors.numeroAgence = "Obligatoire";
        } else if (!values.rueAgence) {
          errors.rueAgence = "Obligatoire";
        } else if (!values.codePostaleAgence) {
          errors.codePostaleAgence = "Obligatoire";
        }
        else if (values.codePostaleAgence.length !== 5) {
          errors.codePostaleAgence = "Le code postale est invalide";
        }
        else if (!values.villeAgence) {
          errors.villeAgence = "Obligatoire";
        }

        if ((!errors.nomAgence && !errors.numeroAgence && !errors.rueAgence && !errors.codePostaleAgence && !errors.villeAgence)) {
          this.setState({ etape: 1 });
        }
        break;
      case 1:

        this.setState({ etape: 2 });
        break;
      case 2:
        if (!values.nomDirigeant) {
          errors.nomDirigeant = "Obligatoire";
        } else if (!values.numeroSiren) {
          errors.numeroSiren = "Obligatoire";
        }
        if ((!errors.nomDirigeant && !errors.numeroSiren)) {
          this.setState({ etape: 3 });
        }
        break;
      case 3:
        if (!values.telAgence) {
          errors.telAgence = "Obligatoire";
        } else if (!values.urlSite) {
          errors.urlSite = "Obligatoire";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mailAgence)
        ) {
          errors.mailAgence = "Adresse e-mail invalide";
        }
        if ((!errors.telAgence && !errors.urlSite && !errors.mailAgence)) {
          this.setState({ etape: 4 });
        }
        break;
      case 4:
        break;
      default:
        console.log(`Sorry, we are out of ${expr}.`);
    }


    if (values.telAgence.length < 10) {
      errors.telAgence = "Le numéro est invalide";
    } else if (!values.platformPro) {
      errors.platformPro = "Obligatoire";
    } else if (values.cgu === false) {
      errors.cgu = "Veuillez accepter nos conditions générales d'utilisations";
    }
  }

  _validationFormulaireAgence(values) {
    //SOUMISSION FORMULAIRE

    // TESTER TOUS LE CHAMPS AVANT DE LANCER LA REQUETE


    // let imageLogo = this.state.monLogo;
    // var extLogo = this.state.monExt;

    // let imageAgence = this.state.maPhotoAgence;
    // var extAgence = this.state.monExtAgence; //"jpg"//myString[myString.length - 3] + myString[myString.length - 2] + myString[myString.length - 1];

    // let imageDiri = this.state.maPhotoDir;
    // var extDiri = this.state.monExtDir; //"jpg"//myString[myString.length - 3] + myString[myString.length - 2] + myString[myString.length - 1];

    var bodyy = {
      blossom: "sIncrireAgence",
    };

    if (this.state.monLogo) {
      bodyy.imageLogo = this.state.monLogo;
    }
    if (this.state.monExt) {
      bodyy.extLogo = this.state.monExt;
    }
    if (this.state.maPhotoAgence) {
      bodyy.imageAgence = this.state.maPhotoAgence;
    }
    if (this.state.monExtAgence) {
      bodyy.extAgence = this.state.monExtAgence;
    }
    if (this.state.maPhotoDir) {
      bodyy.imageDiri = this.state.maPhotoDir;
    }
    if (this.state.monExtDir) {
      bodyy.extDiri = this.state.monExtDir;
    }

    /*var bodyy = {
          nom: "Le Beausset Immo",
          numero: "1",
          rue: "rue des olives",
          codePostale: "83330",
          ville: "Le Beausset",
          nomDirigeant: "Corentin Deydier",
          NumeroDeSiret: "123456789",
          mail: "corentin.deydier@gmail.com",
          tel: "1234567890",
          URLSite: "google.com",
          hebergeur: "ac3",
          nomHebergeur: "test",
          prenomHebergeur: "test",
          mailHebergeur: "test@gmail.com",
          idHebergeur: "test",
          cgu: true, 
        }
    */

    bodyy.nom = values.nomAgence.replace("\'", " ");
    bodyy.numero = values.numeroAgence.toString();
    bodyy.rue = values.rueAgence;
    bodyy.codePostale = values.codePostaleAgence;
    bodyy.ville = values.villeAgence;
    bodyy.nomDirigeant = values.nomDirigeant;
    bodyy.NumeroDeSiret = values.numeroSiren.toString();
    bodyy.mail = values.mailAgence;
    bodyy.tel = values.telAgence.toString();
    bodyy.URLSite = values.urlSite;
    bodyy.hebergeur = values.platformPro;
    bodyy.cgu = values.cgu;

    /*
      bodyy.nom = "test"
      bodyy.numero = "2"
      bodyy.rue = "rue"
      bodyy.codePostale = "83330"
      bodyy.ville = "Le Beausset"
      bodyy.nomDirigeant = "corentin deydier"
      bodyy.NumeroDeSiret = "123456"
      bodyy.mail = "corentin.deydier@gmail.com"
      bodyy.tel = "1234567890"
      bodyy.URLSite = "codey.fr"
      bodyy.hebergeur = "ac3"
      bodyy.nomHebergeur = "codey"
      bodyy.prenomHebergeur = "codey"
      bodyy.mailHebergeur = "corentin.deydier@gmail.com"
      bodyy.idHebergeur = "codey"
      bodyy.cgu =  true
    */

    this.setState({ loader: true });
    console.log(bodyy)
    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        if (response.httpstatus !== 200) {
          this.setState({ loader: false });
          return this.addNotif(
            "Un problème est apparu lors de votre inscription, veuillez réessayer plus tard."
          );
        } else {
          this.setState({ loader: false });
          this.addNotif("L'inscription de votre agence est un succès !");
          this.setState({
            reponseInscriptionAgence: response,
            formPro: true,
            formAgence: false,
            idAgence: response.idagence,
          }); // On ajoute les données recu au state
        }
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);

        this.setState({ loader: false });
      });
  }


  imageChange() {
    const reader = new FileReader();
    const fileInput = document.getElementById("file");
    const img = document.getElementById("img");
    let file;

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    fileInput.addEventListener("change", (e) => {
      const f = e.target.files[0];
      file = f;
      reader.readAsDataURL(f);
    });
  }

  render() {
    var logo = document.getElementById("logoo");

    if (logo) {
      const reader = new FileReader();
      const fileInput = document.getElementById("logoo");
      const img = document.getElementById("imgLogoo");

      reader.onload = (e) => {
        img.src = e.target.result;

        var imageDiriBase64 = reader.result.substring(
          reader.result.lastIndexOf("/9j/")
        );
        var ext = reader.result.substring(
          "data:image/".length,
          reader.result.indexOf(";base64")
        );


        this.setState({ monLogo: imageDiriBase64, monExt: ext });
      };
      fileInput.addEventListener("change", (e) => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      });
    }

    var photoAgence = document.getElementById("fileAgence");
    if (photoAgence) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileAgence");
      const img = document.getElementById("imgAgence");

      reader.onload = (e) => {
        img.src = e.target.result;

        var imageDiriBase64 = reader.result.substring(
          reader.result.lastIndexOf("/9j/")
        );
        var ext = reader.result.substring(
          "data:image/".length,
          reader.result.indexOf(";base64")
        );

        this.setState({ maPhotoAgence: imageDiriBase64, monExtAgence: ext });
      };
      fileInput.addEventListener("change", (e) => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      });
    }

    var photoDir = document.getElementById("fileDir");
    if (photoDir) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileDir");
      const img = document.getElementById("imgDir");

      reader.onload = (e) => {
        img.src = e.target.result;

        var imageDiriBase64 = reader.result.substring(
          reader.result.lastIndexOf("/9j/")
        );
        var ext = reader.result.substring(
          "data:image/".length,
          reader.result.indexOf(";base64")
        );

        this.setState({ maPhotoDir: imageDiriBase64, monExtDir: ext });
      };
      fileInput.addEventListener("change", (e) => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      });
    }

    var profilPro = document.getElementById("fileProfilPro");
    if (profilPro) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileProfilPro");
      const img = document.getElementById("imgProfilPro");

      reader.onload = (e) => {
        img.src = e.target.result;

        var imageDiriBase64 = reader.result.substring(
          reader.result.lastIndexOf("/9j/")
        );
        var ext = reader.result.substring(
          "data:image/".length,
          reader.result.indexOf(";base64")
        );

        this.setState({ monProfilPro: imageDiriBase64, monExtProfilPro: ext });
      };

      fileInput.addEventListener("change", (e) => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      });
    }

    return (
      <section className={MainStyles.sousTitre}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        {this.state.loader && (
          <div className={MainStyles.loader}>
            <LottieLoader animationData={animationData} width={300} height={300} />
            <div className={MainStyles.loaderText}>
              L'inscription est en cours, veuillez patienter jusqu'à la
              redirection automatique.
            </div>
          </div>
        )}

        {this.state.formInit && (
          <article className={MainStyles.form}>
            <Formik className={MainStyles.formikContainer}
              initialValues={{ nomAgence: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.nomAgence) {
                  errors.nomAgence = "Obligatoire";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this._validationAgence(values)
                    .then(() => {
                        setSubmitting(false);
                    })
                    .catch(error => {
                        console.error(error);
                        setSubmitting(false);
                    });
                }}
              >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                  <input className={MainStyles.formInput}
                    type="text" name="nomAgence" placeholder="Nom de votre agence"
                    onChange={handleChange} onBlur={handleBlur} value={values.nomAgence}
                  />
                  <div className={MainStyles.formError}>{errors.nomAgence && touched.nomAgence && errors.nomAgence}</div>
                  <button className={MainStyles.formBtn2} type="submit" disabled={isSubmitting}>Valider</button>
                </form>
                <div>{isSubmitting ? "Soumission en cours..." : "Pas en soumission"}</div>
              </>
              )}
            </Formik>
          </article>
        )}

        {this.state.formAgence && (
          <article id="formPasAgence">
            <p >Inscrivez votre agence</p>
            {/*<button onClick={()=>this._validationFormulaireAgence({test:"test"})}>FAKE AGENCE</button>*/}
            {/*<button onClick={()=>{
              this.addNotif("L'inscription de votre agence est un succès !")
              this.setState({formPro: true, formAgence: false, idAgence: "17"}) // On ajoute les données recu au state        
          }}>Fake Inscription agence</button>*/}

            <Formik initialValues={{
              nomAgence: "",
              numeroAgence: "",
              rueAgence: "",
              codePostaleAgence: "",
              villeAgence: "",
              nomDirigeant: "",
              numeroSiren: "",
              mailAgence: "",
              telAgence: "",
              urlSite: "",
              platformPro: "",
              cgu: false,
            }}
              validate={(values) => {
                const errors = {};
                if (!values.nomAgence) {
                  errors.nomAgence = "Obligatoire";
                } else if (!values.numeroAgence) {
                  errors.numeroAgence = "Obligatoire";
                } else if (!values.rueAgence) {
                  errors.rueAgence = "Obligatoire";
                } else if (!values.codePostaleAgence) {
                  errors.codePostaleAgence = "Obligatoire";
                }
                else if (values.codePostaleAgence.length !== 5) {
                  errors.codePostaleAgence = "Le code postale est invalide";
                }
                else if (!values.villeAgence) {
                  errors.villeAgence = "Obligatoire";
                } else if (!values.nomDirigeant) {
                  errors.nomDirigeant = "Obligatoire";
                } else if (!values.numeroSiren) {
                  errors.numeroSiren = "Obligatoire";
                } else if (!values.telAgence) {
                  errors.telAgence = "Obligatoire";
                } else if (values.telAgence.length < 10) {
                  errors.telAgence = "Le numéro est invalide";
                } else if (!values.urlSite) {
                  errors.urlSite = "Obligatoire";
                } else if (!values.platformPro) {
                  errors.platformPro = "Obligatoire";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mailAgence)
                ) {
                  errors.mailAgence = "Adresse e-mail invalide";
                }
                 else if (values.cgu === false) {
                  errors.cgu = "Veuillez accepter nos conditions générales d'utilisations";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._validationFormulaireAgence(values);
                  setSubmitting(false);
                }, 400);
              }}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>

                  {this.state.etape == 0 &&
                    <>
                      <div className={MainStyles.inputContainer}>
                        <input className={MainStyles.formInput}
                          type="text" name="nomAgence" placeholder="Nom de l'agence"
                          onChange={handleChange} onClick={() => { this.setState({ ready: "ready" }) }} onBlur={handleBlur} value={values.nomAgence}
                        />
                        <div className={MainStyles.formError}>{errors.nomAgence && touched.nomAgence && errors.nomAgence}</div>

                        <input className={MainStyles.formInput}
                          type="number" name="numeroAgence" placeholder="Numéro de la rue"
                          onChange={handleChange} onBlur={handleBlur} value={values.numeroAgence}
                        />
                        <div className={MainStyles.formError}>{errors.numeroAgence && touched.numeroAgence && errors.numeroAgence}</div>
                      </div>

                      <div className={MainStyles.inputContainer}>
                        <input className={MainStyles.formInput}
                          type="text" name="rueAgence" placeholder="Rue ou avenue"
                          onChange={handleChange} onBlur={handleBlur} value={values.rueAgence}
                        />
                        <div className={MainStyles.formError}>{errors.rueAgence && touched.rueAgence && errors.rueAgence}</div>

                        <input className={MainStyles.formInput}
                          type="text" name="codePostaleAgence" placeholder="Code postal" maxLength="5"
                          onChange={handleChange} onBlur={handleBlur} value={values.codePostaleAgence}
                        />
                        <div className={MainStyles.formError}>{errors.codePostaleAgence && touched.codePostaleAgence && errors.codePostaleAgence}</div>
                      </div>

                      <div>
                        <input className={MainStyles.formInput}
                          type="text" name="villeAgence" placeholder="Ville"
                          onChange={handleChange} onBlur={handleBlur} value={values.villeAgence}
                        />
                        <div className={MainStyles.formError}>{errors.villeAgence && touched.villeAgence && errors.villeAgence}</div>
                      </div>

                      <button className={MainStyles.formBtn} onClick={() => this.validationEtape(0, values, errors)}>Valider</button>
                    </>
                  }

                  {this.state.etape == 1 &&
                    <>
                      <p className={MainStyles.labelParagraph} style={{ marginTop: "3.5rem" }}>Logotype de l'agence</p>
                      <div>
                        <div className={MainStyles.formPhotoBtn}>
                          <img className={MainStyles.imgFromFile}
                            id="imgLogoo" alt="logo de l'agence"
                            width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'} />
                          <input className={MainStyles.inputFile} id="logoo" type="file" accept=".jpg,.jpeg"
                            onChange={() => { this.setState({ change: "1234" }) }} />
                        </div>
                      </div>

                      <p className={MainStyles.labelParagraph} style={{ marginTop: "3.5rem" }}>Photo de l’agence</p>
                      <div>
                        <div className={MainStyles.formPhotoBtn}>
                          <img className={MainStyles.imgFromFile}
                            id="imgAgence" alt="Agence"
                            width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'} />
                          <input className={MainStyles.inputFile} id="fileAgence" type="file" accept=".jpg,.jpeg" onChange={() => { this.setState({ change: "1239" }) }} />
                        </div>

                        {this.state.monImageAgence && (
                          <div>
                            <h3>{this.state.monImageAgence}</h3>
                            <h3>{this.state.monExtAgence}</h3>
                          </div>
                        )}


                        <p className={MainStyles.labelParagraph} style={{ marginTop: "3.5rem" }}>Photo du dirigeant</p>
                        <div>
                          <div className={MainStyles.formPhotoBtn}>
                            <img className={MainStyles.imgFromFile} id="imgDir" alt="dirigeant de l'agence" width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'} />
                            <input className={MainStyles.inputFile} id="fileDir" type="file" accept=".jpg,.jpeg"
                              onChange={() => { this.setState({ change: "1239" }) }} />
                          </div>
                          {this.state.monImageDir && (
                            <div>
                              <h3>{this.state.monImageDir}</h3>
                              <h3>{this.state.monExtDir}</h3>
                            </div>
                          )}
                        </div>
                      </div>

                      <button className={MainStyles.formBtn} onClick={() => this.validationEtape(1, values, errors)}>Valider</button>
                    </>
                  }

                  {this.state.etape == 2 &&
                    <>
                      <p className={MainStyles.labelParagraph} style={{ marginTop: "3.5rem" }}>Responsable de l’agence</p>

                      <div className={MainStyles.inputContainer}>
                        <input className={MainStyles.formInput}
                          type="text" name="nomDirigeant" placeholder="Nom et prénom du dirigeant"
                          onChange={handleChange} onBlur={handleBlur} value={values.nomDirigeant}
                        />
                        <div className={MainStyles.formError}>{errors.nomDirigeant && touched.nomDirigeant && errors.nomDirigeant}</div>

                        <input className={MainStyles.formInput}
                          type="number" name="numeroSiren" placeholder="SIREN" maxLength="9"
                          onChange={handleChange} onBlur={handleBlur} value={values.numeroSiren}
                        />
                        <div className={MainStyles.formError}>{errors.numeroSiren && touched.numeroSiren && errors.numeroSiren}</div>
                      </div>

                      <button className={MainStyles.formBtn} onClick={() => this.validationEtape(2, values, errors)}>Valider</button>
                    </>
                  }


                  {this.state.etape == 3 &&
                    <>
                      <div className={MainStyles.inputContainer}>
                        <input className={MainStyles.formInput}
                          type="mailAgence" name="mailAgence" placeholder="E-mail de l'agence"
                          onChange={handleChange} onBlur={handleBlur} value={values.mailAgence}
                        />
                        <div className={MainStyles.formError}>{errors.mailAgence && touched.mailAgence && errors.mailAgence}</div>

                        <input className={MainStyles.formInput}
                          type="tel" name="telAgence" maxLength="10" placeholder="Téléphone de l'agence"
                          onChange={handleChange} onBlur={handleBlur} value={values.telAgence}
                        />
                        <div className={MainStyles.formError}>{errors.telAgence && touched.telAgence && errors.telAgence}</div>
                      </div>

                      <div>
                        <input className={MainStyles.formInput}
                          type="urlSite" name="urlSite" placeholder="URL du site de l'agence"
                          onChange={handleChange} onBlur={handleBlur} value={values.urlSite}
                        />
                        <div className={MainStyles.formError}>{errors.urlSite && touched.urlSite && errors.urlSite}</div>
                      </div>

                      <button className={MainStyles.formBtn} onClick={() => this.validationEtape(3, values, errors)}>Valider</button>
                    </>
                  }


                  {this.state.etape == 4 &&
                    <>
                      <p className={MainStyles.labelParagraph} style={{ marginTop: "3.5rem" }}>Votre plateforme immobilière</p>
                      <div className={MainStyles.formInsideFormik}>
                        <p style={{ color: "#332E57" }}>Quelle plateforme utilisez vous ?</p>

                        <div style={{ display: "block" }}>
                          <label style={{ display: "block", margin: "20px 0" }}>
                            <Field className={MainStyles.boutChoix}
                              type="radio" name="platformPro" value="ac3" />
                            <img src={LogoAc3} height={50} />
                          </label>
                          <label style={{ display: "block", margin: "20px 0" }}>
                            <Field type="radio" name="platformPro" value="hector" />
                            <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 200 43.744">
                              <defs>
                                <clipPath id="clip-path">
                                  <rect id="Rectangle_5492" data-name="Rectangle 5492" width="200" height="43.744" fill="none" />
                                </clipPath>
                              </defs>
                              <g id="Groupe_20026" data-name="Groupe 20026" transform="translate(0 0)">
                                <g id="Groupe_20026-2" data-name="Groupe 20026" transform="translate(0 0)" clip-path="url(#clip-path)">
                                  <path id="Tracé_34189" data-name="Tracé 34189" d="M9.355,11.827V24.854A7.564,7.564,0,0,1,16,21.635c5.03,0,9.757,3.27,9.757,11.62V47.037h-9.2V33.3c-.1-2.817-1.509-3.672-3.371-3.672-1.811,0-3.823,1.358-3.873,4.275V47.037H0V11.827Z" transform="translate(0 -3.896)" fill="#535354" />
                                  <path id="Tracé_34190" data-name="Tracé 34190" d="M65.367,36.085c-.252-3.922-8.4-4.073-9,0Zm8.3,5.936H56.311c.655,2.214,3.521,3.169,5.535,3.169a10.3,10.3,0,0,0,6.386-2.012l4.527,5.081a16.439,16.439,0,0,1-11.317,4.124c-7.848,0-14.487-3.873-14.487-12.926s7.092-13.079,13.833-13.079c8.8,0,14.989,5.836,12.876,15.644" transform="translate(-15.466 -8.689)" fill="#535354" />
                                  <path id="Tracé_34191" data-name="Tracé 34191" d="M105.543,11.827V29.482l5.181-7.293h10.311v.4l-8.7,11.267,10.109,12.625v.553H111.98l-6.437-8.652v8.652H96.237V11.827Z" transform="translate(-31.699 -3.896)" fill="#535354" />
                                  <path id="Tracé_34192" data-name="Tracé 34192" d="M150.271,11.814V22.335h5.583v7.093h-5.633v8.147a2.206,2.206,0,0,0,2.465,2.414,5.236,5.236,0,0,0,2.715-.854l2.013,6.84a14.255,14.255,0,0,1-7.143,1.66c-4.929,0-9.355-2.264-9.2-10.061V12.82Z" transform="translate(-46.463 -3.892)" fill="#535354" />
                                  <path id="Tracé_34193" data-name="Tracé 34193" d="M227.189,26.981l.553,2.716a6.659,6.659,0,0,1,6.036-3.32,8.991,8.991,0,0,1,6.54,2.666l-3.721,6.69a5.9,5.9,0,0,0-4.175-1.509c-2.314,0-4.277,1.408-4.277,4.678V51.829h-9.253V26.981Z" transform="translate(-72.099 -8.688)" fill="#535354" />
                                  <path id="Tracé_34194" data-name="Tracé 34194" d="M270.642,14.454l-4.161,7.206a.522.522,0,0,1-.714.191l-.442-.255a.523.523,0,0,1-.191-.714l4.16-7.206a1.54,1.54,0,0,0,0-1.54l-4.16-7.206a.523.523,0,0,1,.191-.714l.442-.255a.524.524,0,0,1,.714.191l4.16,7.206a1.54,1.54,0,0,0,1.334.77H283.3a1.033,1.033,0,0,0,.895-1.55l-5.525-9.57A2.016,2.016,0,0,0,276.925,0H264.351a2.014,2.014,0,0,0-1.746,1.008L256.317,11.9a2.021,2.021,0,0,0,0,2.017l6.288,10.89a2.016,2.016,0,0,0,1.746,1.008h12.575a2.017,2.017,0,0,0,1.747-1.008l5.525-9.57a1.033,1.033,0,0,0-.895-1.55H271.976a1.54,1.54,0,0,0-1.334.77" transform="translate(-84.337 0)" fill="#bed000" />
                                  <path id="Tracé_34195" data-name="Tracé 34195" d="M193.234,28.121q-2.889-1.742-9.447-1.744-6.441,0-9.147,1.565-3.912,2.164-3.912,7.882v7.221q0,5.657,3.912,7.823,2.707,1.565,9.147,1.565t9.447-1.746q3.549-2.105,3.55-7.642V35.824q0-5.6-3.55-7.7m1.57,10.038a6.107,6.107,0,0,1-6.278,4.705,7.666,7.666,0,0,1-4.77-1.856,7.666,7.666,0,0,1-4.77,1.856,6.107,6.107,0,0,1-6.278-4.705.1.1,0,0,1,.174-.08,3.731,3.731,0,0,0,3.293,1.559c1.665,0,4.156-1.573,5.058-2.182a.892.892,0,0,1,.749-.117l1.774.519,1.775-.519a.892.892,0,0,1,.749.117c.9.608,3.392,2.182,5.057,2.182a3.732,3.732,0,0,0,3.293-1.559.1.1,0,0,1,.173.08" transform="translate(-56.235 -8.688)" fill="#535354" />
                                </g>
                              </g>
                            </svg>
                          </label>
                          <label style={{ display: "block", margin: "20px 0" }}>

                            <Field type="radio" name="platformPro" value="apimo" />
                            <svg version="1.1" id="Calque_1" x="0px" y="0px" height="50px" viewBox="0 0 200 67" enable-background="new 0 0 200 67" >
                              <g>
                                <g>
                                  <path fill="#84BB3A" d="M63.335,0.001c-11.045,0-20,8.955-20,20c0,0.006,0.001,0.012,0.001,0.019l-0.001,41.648l0.001-0.001
			c0,2.761,2.239,5,5,5c2.762,0,5.001-2.239,5.001-5l0-24.364c2.944,1.705,6.352,2.696,9.999,2.696c11.046,0,20-8.952,20-19.998
			C83.336,8.956,74.381,0.001,63.335,0.001z M63.335,33.333c-7.336,0-9.979-5.926-9.999-13.252v-0.08h-0.001
			c0-7.363,2.637-13.333,10-13.333c7.364,0,10,5.97,10,13.333C73.336,27.365,70.699,33.333,63.335,33.333z"/>
                                  <path fill="#84BB3A" d="M20,0C8.955,0,0,8.955,0,20c0,11.046,8.955,19.998,20,19.998h3.334c1.841,0,3.333-1.492,3.333-3.333
			s-1.492-3.332-3.333-3.332h0H20C12.636,33.333,10,27.365,10,20C10,12.637,12.636,6.667,20,6.667C27.364,6.667,30,12.638,30,20.001
			l-0.001,15L30,35c0,2.761,2.239,4.999,5,4.999c2.762,0,5-2.238,5-4.999l0.001-14.999C40.001,8.956,31.046,0,20,0z"/>
                                  <circle fill="#84BB3A" cx="91.656" cy="4.989" r="4.987" />
                                </g>
                                <g>
                                  <path fill="#000" d="M180,0.001c-11.046,0-20.001,8.955-20.001,20c0,11.046,8.955,19.998,20.001,19.998s20-8.952,20-19.998
			C200,8.956,191.046,0.001,180,0.001z M180,33.333c-7.363,0-10-5.968-10-13.332c0-7.363,2.637-13.333,10-13.333s10,5.97,10,13.333
			C190,27.365,187.363,33.333,180,33.333z"/>
                                  <path fill="#000" d="M141.666,0.001c-3.848,0-7.347,1.461-10.002,3.842c-2.654-2.38-6.152-3.841-9.999-3.841
			c-8.285,0.001-15.001,6.717-15.001,15.001v6.667c0,0.003,0.001,0.006,0.001,0.009v3.322h-0.001c0,4.603-0.396,8.333-4.999,8.333
			c-4.6,0-4.997-3.729-4.997-8.333v-6.83c0-2.761-2.237-5-5-5c-2.761,0-5,2.239-5,5v6.83c0,8.284,6.716,14.998,14.996,14.998
			c8.284,0,15-6.714,15-14.998v-6.83c0-0.004-0.001-0.009-0.001-0.014v-3.155l0.001,0.001c0-4.604,0.397-8.335,5-8.334
			c4.591-0.001,4.999,3.713,5.001,8.301c0,0.011-0.002,0.021-0.002,0.032l0.001,19.998c0,2.76,2.238,4.999,5,4.999
			c2.76,0,5-2.239,5-4.999V15.004h0.001v-0.001c-0.001-4.603,0.396-8.334,5-8.334c4.603,0,5,3.731,5,8.333l-0.001,0.001
			l-0.001,19.998c0,2.76,2.239,4.999,5.001,4.998c2.762,0,4.999-2.238,4.999-4.998l0.001-19.998l0.001-0.001
			C156.666,6.718,149.95,0.002,141.666,0.001z"/>
                                </g>
                              </g>
                            </svg>
                          </label>
                          <label style={{ display: "block", margin: "20px 0" }}>
                            <Field className={MainStyles.boutChoix}
                              type="radio" name="platformPro" value="twimmo" />
                            <img src={LogoTwimmo} height={50} />
                          </label>

                          <label style={{ display: "block", margin: "20px 0" }}>
                            <Field className={MainStyles.boutChoix}
                              type="radio" name="platformPro" value="autre" />
                            Autre
                          </label>
                        </div>

                      </div>

                      <p style={{ color: "#332E57", padding: "3rem 0" }}>
                        Si vous utilisez une autre plateforme, veuillez nous contacter à l’adresse <a href={'mailto:contact@homecrush.fr'} style={{ textDecoration: "none !important", color: "#332E57" }}>contact@homecrush.fr</a>
                      </p>

                      <label style={{ color: "#332e57", paddingBottom: "3rem" }}>
                        <Field type="checkbox" name="cgu" />
                        <NavLink className={MainStyles.liens} target="_blank" to="/cgu">J'accepte les conditions d'utilisations</NavLink>
                        <div className={MainStyles.formError}>{errors.cgu && touched.cgu && errors.cgu}</div>
                      </label>

                      <button className={MainStyles.formBtn} type="submit" onClick={() => console.log("Bouton cliqué")} disabled={isSubmitting}>Valider</button>
                    </>
                  }
                </form>
              )}
            </Formik>
            <div className={MainStyles.progressBarContainer} >
              
            <div className={MainStyles.progressBar} style={{width:((this.state.etape / 4) * 100)+"%"}}></div>
     
</div>
{/*
 <p style={{fontSize:"0.7em", marginTop:"20px"}}>Vous avez déjà un compte Homecrush ?<br />Connectez-vous.</p>
            <button className={ConnexionStyles.goldenBtn}>Connexion</button>
                */}
          </article>
        )}

        {this.state.formPro && (
          <article>
            <Formik initialValues={{
              nom: "",
              prenom: "",
              mail: "",
              motDePasse: "",
              confirmMotDePasse: "",
              numeroDeCPI: "",
              cgu: false,
            }}
              validate={(values) => {
                const errors = {};
                if (!values.nom) {
                  errors.nom = "Obligatoire";
                }
                if (values.prenom === false) {
                  errors.prenom = "Obligatoire";
                }
                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
                ) {
                  errors.mailAgence = "Adresse e-mail invalide";
                }
                if (!values.motDePasse) {
                  errors.motDePasse = "Obligatoire";
                }
                if (values.confirmMotDePasse !== values.motDePasse) {
                  errors.confirmMotDePasse = "Les mots de passes doivent correspondre";
                }
                if (!values.numeroDeCPI) {
                  errors.confirmMotDePasse = "Obligatoire";
                } else if (values.cgu === false) {
                  errors.cgu = "Veuillez accepter les cgus";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this._inscriptionPro(values);
                  this.setState({ cgu: values.cgu });
                  setSubmitting(false);
                }, 400);
              }} >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                  <div>
                    <input className={MainStyles.formInput}
                      type="nom" name="nom" placeholder="Nom"
                      onChange={handleChange} onBlur={handleBlur} value={values.nom}
                      onClick={() => { this.setState({ rdy: "1234" }) }}
                    />
                    <div className={MainStyles.formError}>{errors.nom && touched.nom && errors.nom}</div>
                  </div>

                  <div>
                    <input className={MainStyles.formInput}
                      type="prenom" name="prenom" placeholder="Prénom"
                      onChange={handleChange} onBlur={handleBlur} value={values.prenom}
                    />
                    <div className={MainStyles.formError}>{errors.prenom && touched.prenom && errors.prenom}</div>
                  </div>

                  <div>
                    <input className={MainStyles.formInput}
                      type="mail" name="mail" placeholder="Mail"
                      onChange={handleChange} onBlur={handleBlur} value={values.mail}
                    />
                    <div className={MainStyles.formError}>{errors.mail && touched.mail && errors.mail}</div>
                  </div>

                  <div>
                    <input className={MainStyles.formInput}
                      type="password" name="motDePasse" placeholder="Mot de passe *"
                      onChange={handleChange} onBlur={handleBlur} value={values.motDePasse}
                    />
                    <p className={MainStyles.formErrorMdp}>*Les caractères spéciaux ne sont pas acceptés</p>
                    <div className={MainStyles.formError}>{errors.motDePasse && touched.motDePasse && errors.motDePasse}</div>
                  </div>

                  <div>
                    <input className={MainStyles.formInput}
                      type="password" name="confirmMotDePasse" placeholder="Confirmation du mdp"
                      onChange={handleChange} onBlur={handleBlur} value={values.confirmMotDePasse}
                    />
                    <div className={MainStyles.formError}>{errors.confirmMotDePasse && touched.confirmMotDePasse && errors.confirmMotDePasse}</div>
                  </div>

                  <div>
                    <input className={MainStyles.formInput}
                      type="input" name="numeroDeCPI" placeholder="N° de CPI"
                      onChange={handleChange} onBlur={handleBlur} value={values.numeroDeCPI}
                    />
                    <div className={MainStyles.formError}>{errors.numeroDeCPI && touched.numeroDeCPI && errors.numeroDeCPI}</div>
                  </div>

                  <p className={MainStyles.labelParagraph} style={{ marginTop: "5.5rem" }}>Photo de profil</p>

                  <div className={MainStyles.formPhotoBtn}>
                    <img className={MainStyles.imgFromFile} id="imgProfilPro" alt="agent professionel" width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'} />

                    <input className={MainStyles.inputFile} id="fileProfilPro" type="file" accept=".jpg,.jpeg" onChange={() => { this.setState({ change: "1239" }) }} />
                  </div>

                  <label style={{ color: "#332E57", marginTop: "2rem", marginBottom: "3rem" }}>
                    <Field type="checkbox" name="cgu" />
                    {errors.cgu && touched.cgu && errors.cgu}
                    J'accepte les politiques de confidentialitées
                  </label>

                  <button className={MainStyles.formBtn} type="submit" onClick={() => console.log("Bouton cliqué")} disabled={isSubmitting}>Valider</button>

                </form>
              )}
            </Formik>
          </article>
        )}
      </section>
    );
  }
}

export default withCookies(FormProfessionel);