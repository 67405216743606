import React, { useEffect, useState } from "react";
import CarouselComp from "../Carousel/CarouselComp";
import pubStyle from "./pub.module.scss";

const Publicite = ({ pubRecu }) => {
  const [showPub, setShowPub] = useState(true);
  
  useEffect(() => {
    const localStorageValue = localStorage.getItem("showPub");
    if (localStorageValue === "false") {
      setShowPub(false);
    }

    const handleBeforeUnload = () => {
      localStorage.removeItem("showPub");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const hidePub = () => {
    setShowPub(false);
    localStorage.setItem("showPub", "false");
  };

  const pubRecuFiltered1 = Array.isArray(pubRecu) ? pubRecu.filter((pub, index) => index % 2 === 0) : [];
  const pubRecuFiltered2 = Array.isArray(pubRecu) ? pubRecu.filter((pub, index) => index % 2 !== 0) : [];

  return (
    <>
      {showPub && (
        <>
          {pubRecuFiltered1.length > 0 && (
            <aside id="pub" className={pubStyle.pubContainer}>
              <div className={pubStyle.closePub} onClick={hidePub}>
                X
              </div>
              <CarouselComp
                autoPlay
                interval={2500}
                className={pubStyle.carrousel}
                source="pub"
                state={pubRecuFiltered1}
                key={showPub + "-1"} 
              />
            </aside>
          )}

          {pubRecuFiltered2.length > 0 && (
            <aside id="pub2" className={pubStyle.pubContainer2}>
              <div className={pubStyle.closePub} onClick={hidePub}>
                X
              </div>
              <CarouselComp
                autoPlay
                interval={2500}
                className={pubStyle.carrousel}
                source="pub"
                state={pubRecuFiltered2}
                key={showPub + "-2"} 
              />
            </aside>
          )}
        </>
      )}
    </>
  );
};

export default Publicite;
