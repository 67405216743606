import React, { useState, useEffect, useRef } from 'react';
import { withCookies } from 'react-cookie';
// import Parametre from './Parametre'
// import ParametrePub from './Compte/ParametrePub'
import MainStyles from '../../App.module.scss';

import LottieLoader from "../Loader/Loader"
import animationData from "../../assets/homecrush-logo.json"

import { Formik } from 'formik';

import { useNavigate } from "react-router-dom";

const MesBiens = (props) => {


  const navigate = useNavigate();

  const [recupInfosCompte, setRecupInfosCompte] = React.useState({
    nom: "_",
    prenom: "_",
  });

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [idSuppr, setIdSuppr] = useState("");
  const [prixSuppr, setPrixSuppr] = useState("");
  const [villeSuppr, setVilleSuppr] = useState("");

  const [showLoader, setShowLoader] = React.useState(false);

  const [listeBienCree, setListeBienCree] = useState([]);

  const [images, setImages] = useState([]);
  const [anciennePhotos, setAnciennePhotos] = useState([]);
  const [nouvellesPhotos, setNouvellesPhotos] = useState([]);

  const [idImgSupp, setIdImgSupp] = useState([]);

  const [currentId, setCurentId] = useState();

  const [refresh, setRefresh] = useState(1);

  const [messageSuppresion, setMessageSuppresion] = useState(false);

  const [usercode, setUsercode] = useState("");

  const filesRef = useRef(undefined);

  const addNotif = (message, etat) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: message,
      level: etat ? etat : "success",
    });
  };


  const openModal = (prix, ville, id) => {
    setShowModal(true)
    setIdSuppr(id)
    setPrixSuppr(prix)
    setVilleSuppr(ville)
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const supprBien = (idSuppr) => {
    setShowLoader(true)

    var bodyy = {
      blossom: "supprimerBien",
      user_code: props.cookies.cookies.user_code,
      idBien: idSuppr
    }
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {
        if (response.httpstatus === 200) {
          document.querySelector("#bien" + idSuppr).style.display = "none"
          setIdSuppr("")
          setPrixSuppr("")
          setVilleSuppr("")
          setShowModal(false)
          setShowLoader(false)
        }
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });

  }

  const getBienCree = () => {

    var bodyy = {
      blossom: "listeBienUser",
      user_code: props.cookies.cookies.user_code,
    }
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {

        
                for (let i = 0; i < response.length; i++) {
        
                  console.log(response[i])
                }
        setListeBienCree(response)

        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }

  useEffect(() => {
    setUsercode(props.cookies.cookies.user_code)
    setRecupInfosCompte({
      nom: props.cookies.cookies.nom,
      prenom: props.cookies.cookies.prenom,
      pp: props.cookies.cookies.pp,
    })
    getBienCree(usercode)
  }, [refresh]);

  const NavigateToBien = bien => {
    navigate(bien.showlink);
  }

  const _modifierBien = (values, bienCree) => {



    //CREATION DU TABLEAU ANCIENNE PHOTOS


    let anciennePhotosTemp = []



    //console.log("anciennePhotos", anciennePhotosTemp)

    setShowLoader(true)
    setShowModal(true)
    var bodyy = bienCree
    bodyy.blossom = "modifBien"
    bodyy.user_code = props.cookies.cookies.user_code


    bodyy.anciennePhotos = bodyy.photo
    bodyy.photo = nouvellesPhotos[bienCree.id] ? nouvellesPhotos[bienCree.id] : ""
    if (values.cp) { bodyy.cp = values.cp }
    if (values.tel) { bodyy.tel = values.tel }
    if (values.rue) { bodyy.rue = values.rue }
    if (values.prix) { bodyy.prix = values.prix }
    if (values.mail) { bodyy.mail = values.mail }
    if (values.titre) { bodyy.titre = values.titre }
    if (values.ville) { bodyy.ville = values.ville }
    if (values.piece) { bodyy.piece = values.piece }
    if (values.financier) { bodyy.financier = values.financier }
    if (values.superficie) { bodyy.superficie = values.superficie }
    if (values.description) { bodyy.description = values.description; bodyy.caracteristiques = values.description }
    if (values.copropriete) { bodyy.copropriete = values.copropriete }
    if (values.typeDeLogement) { bodyy.typeDeLogement = values.typeDeLogement }
    if (values.perfEnergetique) { bodyy.perfEnergetique = values.perfEnergetique }


    //console.log("bodyyModifierBien", bodyy);

    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {
        //console.log(response)


        if (response.httpstatus === 200) {
          //showNotif(response.info, "success")

          setRefresh(Math.random());
          setAnciennePhotos([])
          setNouvellesPhotos([])
          setMessageSuppresion(false)
        }
        else {
          //showNotif(response.info, "error")
        }

        setShowLoader(false)
        setShowModal(false)
        return response;
      })
      .catch(erreur => {
        console.log('ERROR modifBien: ', erreur);
      })

  }

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener('load', (resp) => {

        var base64 = reader.result.substring(reader.result.lastIndexOf("/9j/"));//on enlève tous ce qui se trouve avant /9j/ dans l'image en base 64
        var ext = reader.result.substring("data:image/".length, reader.result.indexOf(";base64"))//On récupère l'extension de l'image

        res({
          base64,
          name: name,
          type: type.substring(6),
          size: size,
          ext
        });
      });
      reader.readAsDataURL(image);
    });
  };

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 10) {
      return alert("Vous pouvez seulement envoyer 10 images au maximum.");
    }

    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      setImages([...images, ...newImages]);
    }

    e.target.value = '';
  };

  const uploadImages = async (e, valueId) => {
    if (e.target.files && e.target.files.length > 10) {
      return alert("Vous pouvez seulement envoyer 10 images au maximum.");
    }



    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);

      let nouvellesPhotosTemp = [...nouvellesPhotos]
      nouvellesPhotosTemp[valueId] = newImages
      setNouvellesPhotos(nouvellesPhotosTemp);

      let imagesTemp = [...images];

      imagesTemp[valueId] = newImages;

      setNouvellesPhotos(imagesTemp);



    }



    // "image-" + i + "-bien-" + bienCree.id
    e.target.value = '';
  };

  const supprimerPhotos = (indexBien, indexPhoto) => {
    //let anciennePhotosTemp = listeBienCree[indexBien].photo[indexPhoto]


    let idImgSuppTemp = idImgSupp;

    idImgSuppTemp[indexPhoto] = indexPhoto;

    setIdImgSupp(idImgSuppTemp)

    setMessageSuppresion(true)




    //document.getElementById("b-" + indexBien + "-p-" + indexPhoto).style.display = "none"
    //document.getElementById("photo" + indexPhoto).style.backgroundColor = "rgba(255,0,0,0.5)"



    let emputedArray = [...listeBienCree];

    emputedArray[indexBien].photo.splice(indexPhoto, 1);

    setListeBienCree(emputedArray)


  }



  return (
    <section className={MainStyles.venteContainer}>

      {showModal && (
        <div>
          <article className={MainStyles.modalWrapper}>
            <div className={MainStyles.modalContainer}>
              <div className={MainStyles.listDeMesBiensClosebtn} onClick={() => closeModal()}>{"X"}</div>


              {showLoader && <LottieLoader animationData={animationData} width={"100%"} height={"auto"} />}
              {!showLoader &&
                <div>
                  <span className={MainStyles.listDeMesBiensPrix} style={{ borderBottom: "none" }}>{prixSuppr} €</span><span className={MainStyles.listDeMesBiensVille}> - {villeSuppr}</span>
                  <div className={MainStyles.dividerModal}></div>
                  <div style={{ color: '#BDA87D', margin: "1rem 2rem 2rem 2rem" }}>Souhaitez-vous vraiment supprimer votre <br /> bien à la vente sur le site Homecrush ?</div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className={MainStyles.btnModal} style={{ backgroundColor: 'transparent', color: '#BDA87D', marginLeft: "0.5rem" }} onClick={() => closeModal()}>Annuler</div>
                    <div className={MainStyles.btnModal} style={{ backgroundColor: '#0DA8DB', color: '#fff', marginLeft: "0.5rem" }} onClick={() => supprBien(idSuppr)}>Valider</div>
                  </div>
                </div>
              }
            </div>

          </article>
        </div>
      )}


      <article className={MainStyles.leftContainer}>
        <img id="imageProfil" className={MainStyles.imageMesBien} alt="votre profil" src={recupInfosCompte.pp} />
        <p className={MainStyles.nomMesBiens}>{recupInfosCompte.nom} {recupInfosCompte.prenom}</p>
      </article>

      <article className={MainStyles.rightContainer}>
        <p className={MainStyles.sousTitre} style={{ paddingBottom: "2rem", paddingTop: "1rem", borderBottom: "1px solid #bda87d" }}>Mes biens en vente</p>



        <section className={MainStyles.listeDeMesBien}>
          {listeBienCree.length > 0 ? listeBienCree.map((bienCree, indexBien) => {
            return (
              <Formik id={"bien" + bienCree.id} className={MainStyles.formikContainer}
                initialValues={{



                  titre: bienCree.titre,
                  typeDeLogement: bienCree.typeDeLogement,
                  superficie: bienCree.superficie,
                  piece: bienCree.piece,
                  rue: bienCree.rue,
                  cp: bienCree.cp,
                  ville: bienCree.ville,
                  description: bienCree.description,
                  copropriete: bienCree.copropriete,
                  financier: bienCree.financier,
                  perfEnergetique: bienCree.perfEnergetique,
                  prix: bienCree.prix,
                  "honoraire": "0",
                  "numMandat": "-1",
                  tel: bienCree.tel,
                  mail: bienCree.mail,

                  description: bienCree.description, url: bienCree.url, pub: bienCree.id, photo: "", extension: ""
                }}
                validate={values => {
                  const errors = {};


                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {


                    _modifierBien(values, bienCree)

                  }, 400);
                }}>

                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit} id={"bien" + bienCree.id} key={indexBien}>


                    <div className={MainStyles.imagesContainer}>
                      {bienCree.photo.length > 0 ? bienCree.photo.map((photo, indexPhoto) => {
                        return (
                          <div supprimer style={{ marginLeft: "10px" }} key={"b-" + indexBien + "-p-" + indexPhoto} id={"b-" + indexBien + "-p-" + indexPhoto}>{/* b-12-p-1 */}
                            <img style={{ borderRadius: "3px" }} height="50" width="auto" src={"https://" + photo} alt={'photo numéro ' + (indexPhoto + 1) + " du bien Homecrush " + bienCree.id} />

                            <div className={MainStyles.boutonSuppImg} onClick={() => supprimerPhotos(indexBien, indexPhoto)}>X</div>
                          </div>
                        )
                      }) : null}
                    </div>
                    {messageSuppresion &&
                      <p>Veuillez cliquer sur "modifier le bien" pour valider les changements.</p>
                    }
                    <input className={MainStyles.inputFileBlueMultiple}
                      id="files"
                      ref={filesRef}
                      type="file"
                      accept="image/jpg,image/jpeg"
                      onChange={(e) => uploadImages(e, bienCree.id)}
                      multiple />


                    {nouvellesPhotos.length > 0 ? nouvellesPhotos[bienCree.id].map((imageObj, i) => {
                      return (
                        <div style={{ marginLeft: "10px", marginTop: "10px" }} key={i}>
                          <img style={{ borderRadius: "3px" }} height="50" width="auto" src={'data:image/jpg;base64,' + imageObj.base64} alt='' />
                        </div>
                      )
                    }) : null}
                    {/*
                    <img onClick={() => NavigateToBien(bienCree)} className={MainStyles.listDeMesBiensImage} src={"https://" + bienCree.photo} />
                */}




                    <p>Désignation du bien</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="titre"
                      placeholder={"Désignation du bien"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.titre}
                    />
                    <p>Type du bien</p>
                    <select
                      className={MainStyles.formInput}
                      type="text"
                      name="typeDeLogement"
                      placeholder={"Type de logement"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.typeDeLogement}
                    >
                      <option value="">{bienCree.typeDeLogement}</option>
                      <option value="MAISON">Maison</option>
                      <option value="APPARTEMENT">Appartement</option>
                      <option value="DEMEURE">Demeure</option>
                      <option value="TERRAIN">Terrain</option>
                    </select>
                    <p>Superficie</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="superficie"
                      placeholder={"Superficie du bien"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.superficie}
                    />
                    <p>Nombres de pièces</p>
                    <select
                      className={MainStyles.formInput}
                      name="piece"
                      placeholder={"Nombre de pièces"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.piece}
                    >
                      <option value="">{bienCree.piece}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6+</option>
                    </select>

                    <p>Numéro et nom de rue</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="rue"
                      placeholder={"Numéro et nom de rue"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rue}
                    />
                    <p>Code postal</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="cp"
                      placeholder={"Code Postal"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cp}
                    />

                    <p>Ville</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="ville"
                      placeholder={"ville"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ville}
                    />
                    <p>Description complète du bien</p>
                    <textarea
                      className={MainStyles.formInput}
                      name="description"
                      placeholder={"description"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    ></textarea>
                    <p>Coproprité</p>
                    <select
                      className={MainStyles.formInput}
                      name="copropriete"
                      placeholder={"Nombre de pièces"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.superficie}
                    >
                      <option value="">{bienCree.piece}</option>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </select>

                    <p>Impot foncier</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="financier"
                      placeholder={"Impot foncier"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.financier}
                    />

                    <p>Performance énergétique</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="perfEnergetique"
                      placeholder={"Performance énergétique"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.perfEnergetique}
                    />
                    <p>Prix de vente</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="prix"
                      placeholder={"Prix de vente"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prix}
                    />

                    <p>Télephone</p>
                    <input
                      className={MainStyles.formInput}
                      type="text"
                      name="tel"
                      placeholder={"Téléphone de contact"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel}
                    />

                    <p>Adresse mail</p>
                    <input
                      className={MainStyles.formInput}
                      type="mail"
                      name="mail"
                      placeholder={"Mail de contact"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mail}
                    />


                    <button className={MainStyles.boutonBleu} type="submit" disabled={isSubmitting}>Modifier le bien</button>

                    <div className={MainStyles.boutonRouge} onClick={() => openModal(bienCree.prix, bienCree.ville, bienCree.id)}>Supprimer le bien</div>

                    <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>

                  </form>
                )}
              </Formik>

            )
          }) : null}
        </section>







      </article>
    </section>
  );
};

export default withCookies(MesBiens);
