import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { withCookies } from "react-cookie";

import Select from "react-select-oss";
import Geocode from "react-geocode";

import AjoutAnnonce from "../components/AjoutAnnonce/AjoutAnnonce";

import MainStyles from "../App.module.scss";

//import NotificationSystem from "react-notification-system";
import notifStyles from "../components/NotifStyles";
import Footer from "../components/Footer/Footer";

const InfosVente = (props) => {

  const [showDepDropdown, setShowDepDropdown] = useState(false);

  const [nonConnecte, setNonConnecte] = useState(true);

  const [maLocalisation, setMaLocalisation] = useState({
    maLocalisation: {
      coords: {
        latitude: undefined,
        longitude: undefined,
      },
    },
  })
  const [monUserCode, setMonUserCode] = useState(undefined);
  const [monRang, setMonRang] = useState(undefined);
  const [showFormPar, setShowFormPar] = useState(false);
  const [dataAnnoncesRecu, setDataAnnoncesRecu] = useState([]);
  const [dataAnnoncesProRecu, setDataAnnoncesProRecu] = useState([]);
  const [typeDeLogement, setTypeDeLogement] = useState(null);

  const [codePostale, setCodePostale] = useState(undefined);
  const [cp, setCp] = useState(undefined);
  const [inProgress, setInProgress] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(undefined);
  const [showList, setShowList] = useState(undefined);
  const [secteur, setSecteur] = useState(undefined);
  const [prix, setPrix] = useState(undefined);
  const [nombrePieces, setNombrePieces] = useState(undefined);
  const [surface, setSurface] = useState(undefined);
  const [ville, setVille] = useState(undefined);



  const [bgButtonColor1, setBgButtonColor1] = useState("transparent");
  const [bgButtonColor2, setBgButtonColor2] = useState("transparent");
  const [bgButtonColor3, setBgButtonColor3] = useState("transparent");
  const [bgButtonColor4, setBgButtonColor4] = useState("transparent");
  const [bgButtonColor5, setBgButtonColor5] = useState("transparent");
  const [textButtonColor1, setTextButtonColor1] = useState("#BDA87D");
  const [textButtonColor2, setTextButtonColor2] = useState("#BDA87D");
  const [textButtonColor3, setTextButtonColor3] = useState("#BDA87D");
  const [textButtonColor4, setTextButtonColor4] = useState("#BDA87D");
  const [textButtonColor5, setTextButtonColor5] = useState("#BDA87D");


  const [Filtre1Visible, setFiltre1Visible] = useState(false);
  const [Filtre2Visible, setFiltre2Visible] = useState(false);
  const [Filtre3Visible, setFiltre3Visible] = useState(false);
  const [Filtre4Visible, setFiltre4Visible] = useState(false);
  const [Filtre5Visible, setFiltre5Visible] = useState(false);


  const refreshInfosVente = () => {
    setNonConnecte(false)
  }

  useEffect(() => {
    if (props.hash === "") {
      setShowFormPar(false)
    }
    if (props.hash === "init") {
      setShowFormPar(true)
    }
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_TOKEN);
    // var monUserCode = props.cookies.get("user_code");
    // var monRang = props.cookies.get("rang");

    if (props.cookies.get("user_code") && props.cookies.get("rang")) {
      var monUserCode = props.cookies.get("user_code");
      var monRang = props.cookies.get("rang");

      setMonUserCode(monUserCode)
      setMonRang(monRang)
      _geolocalisation(monRang);
    }

  }, [nonConnecte]);

  const _geolocalisation = (monRang) => {
    if (monRang === "0") {
      listeAnnoncesClientAsync();
    }

    if (monRang === "1") {
      listeAnnoncesProAsync();
    }
    // MA LOCALISATION
    navigator.geolocation.getCurrentPosition((position) => {
      if (position.coords.latitude) {
        setMaLocalisation({
          maLocalisation: {
            coords: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          },
        });
      }

      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
        .then(
          (response) => {
            var address = response.results[0].address_components[6].short_name;
            if (address !== "00000") {
              setCodePostale(address);
              setCp(address.substr(0, 2))
            } else {
              setCodePostale("00000");
              setCp("00")
            }
          },
          (error) => {
            console.error(error);
          }
        )
        .then((response) => {
          if (monRang === "0") {
            listeAnnoncesClientAsync();
          }
          if (monRang === "1") {
            listeAnnoncesProAsync();
          }
        });
      return position;
    });
  };

  const listeAnnoncesClientAsync = async () => {

    setInProgress(true)
    setError(null)
    var bodyy = {
      blossom: "listeAnnoncesClient",
    };
    bodyy.user_code = props.cookies.get("user_code");
    bodyy.rang = props.cookies.get("rang");
    console.log(bodyy)
    // Récupération des données de l'API

    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        response = JSON.parse(response);
        console.log(response)
        setDataAnnoncesRecu(response); // On ajoute les données recu au state
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });

    // MISE A JOUR DE L'OBJET echantillonRecu DANS LE STATE
    setIsLoading(false);
  };

  const listeAnnoncesProAsync = async () => {
    setInProgress(true)
    setError(null)

    var bodyy = {
      blossom: "listeAnnoncesPro",
    };
    bodyy.user_code = monUserCode;
    bodyy.rang = monRang;

    bodyy.CP = codePostale;

    // Récupération des données de l'API
    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        response = JSON.parse(response);
        if (response.error === "error no home") {
          setShowList(false); // On ajoute les données recu au state
        } else if (response.error === "error forbiden") {
          setShowList(false); // On ajoute les données recu au state
        } else if (response.error !== "error no home") {
          setDataAnnoncesProRecu(response); // On ajoute les données recu au state
          setShowList(true)
        }
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR PRO: " + erreur);
      });

    // MISE A JOUR DE L'OBJET echantillonRecu DANS LE STATE
    setIsLoading(false);
  };

  const _lancerRequete = () => {
    var bodyy = {
      blossom: "listeAnnoncesPro",
    };
    bodyy.user_code = monUserCode;
    bodyy.rang = monRang;

    if (secteur) {
      bodyy.secteur = secteur;
    }
    if (prix) {
      if (prix == "0") {
        bodyy.prixMin = 0;
        bodyy.prixMax = 249999;
      }
      if (prix == "250000") {
        bodyy.prixMin = 250000;
        bodyy.prixMax = 499999;
      }
      if (prix == "500000") {
        bodyy.prixMin = 500000;
        bodyy.prixMax = 999999;
      }

      if (prix == "+") {
        bodyy.prixMin = 1000000;
        //bodyy.prixMax = '+'
      }
    }

    if (nombrePieces) {
      bodyy.nombrePieces = nombrePieces;
    } else {
    }
    if (typeDeLogement) {
      bodyy.typeDeLogement = typeDeLogement;
    } else {
    }
    switch (surface) {
      case "0":
        bodyy.surfaceMin = 0;
        bodyy.surfaceMax = 150;
        break;
      case "151":
        bodyy.surfaceMin = 151;
        bodyy.surfaceMax = 350;
        break;
      case "351":
        bodyy.surfaceMin = 351;
        break;

      case "0A":
        bodyy.surfaceMin = 0;
        bodyy.surfaceMax = 50;
        break;
      case "51":
        bodyy.surfaceMin = 51;
        bodyy.surfaceMax = 150;
        break;
      case "151+":
        bodyy.surfaceMin = 151;
        break;
      default:
      // code block
    }
    // Récupération des données de l'API
    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        response = JSON.parse(response);

        if (response.error === "error no home") {
          setShowList(false); // On ajoute les données recu au state
        } else if (response.error !== "error no home") {

          setDataAnnoncesProRecu(response)
          setShowList(true)
        }
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
  }

  const handleChange = (parameter, value) => {
    if (value === "") {
      value = undefined;
    }
    if (parameter === "secteur") {
      setSecteur(value)
    }

    if (parameter === "prix") {
      setPrix(value);
    }
    if (parameter === "pieces") {
      setNombrePieces(value)
    }
    if (parameter === "surface") {
      setSurface(value)
    }
    if (parameter === "ville") {
      setVille(value)
    }
    if (parameter === "codePostale") {
      setCodePostale(value)
    }
  };

  const outputEvent = (event) => {
    addNotif("Votre annonce à été ajouter avec succès");
    listeAnnoncesClientAsync()
    setShowFormPar(false)
  }

  const handleClickButton1 = () => {
  setShowDepDropdown(prevState => !prevState); // bascule l'état

  if (Filtre1Visible === true) {
    setBgButtonColor1("transparent")
    setTextButtonColor1("#BDA87D")
  } else {
    setBgButtonColor1("0DA8DB")
    setTextButtonColor1("#fff")
    setBgButtonColor2("transparent")
    setTextButtonColor2("#BDA87D")
    setBgButtonColor3("transparent")
    setTextButtonColor3("#BDA87D")
    setBgButtonColor4("transparent")
    setTextButtonColor4("#BDA87D")
    setBgButtonColor5("transparent")
    setTextButtonColor5("#BDA87D")
  }
};

  const handleClickButton2 = () => {
    if (Filtre2Visible === true) {

      setBgButtonColor2("transparent")
      setTextButtonColor2("#BDA87D")

    } else {

      setBgButtonColor2("0DA8DB")
      setTextButtonColor2("#fff")
      setBgButtonColor1("transparent")
      setTextButtonColor1("#BDA87D")
      setBgButtonColor3("transparent")
      setTextButtonColor3("#BDA87D")
      setBgButtonColor4("transparent")
      setTextButtonColor4("#BDA87D")
      setBgButtonColor5("transparent")
      setTextButtonColor5("#BDA87D")

    }
  }
  const handleClickButton3 = () => {
    if (Filtre3Visible === true) {

      setBgButtonColor3("transparent")
      setTextButtonColor3("#BDA87D")
    } else {

      setBgButtonColor3("0DA8DB")
      setTextButtonColor3("#fff")
      setBgButtonColor1("transparent")
      setTextButtonColor1("#BDA87D")
      setBgButtonColor2("transparent")
      setTextButtonColor2("#BDA87D")
      setBgButtonColor4("transparent")
      setTextButtonColor4("#BDA87D")
      setBgButtonColor5("transparent")
      setTextButtonColor5("#BDA87D")
    }
  }
  const handleClickButton4 = () => {
    if (Filtre4Visible === true) {

      setBgButtonColor4("transparent")
      setTextButtonColor4("#BDA87D")
    } else {

      setBgButtonColor4("0DA8DB")
      setTextButtonColor4("#fff")
      setBgButtonColor1("transparent")
      setTextButtonColor1("#BDA87D")
      setBgButtonColor2("transparent")
      setTextButtonColor2("#BDA87D")
      setBgButtonColor3("transparent")
      setTextButtonColor3("#BDA87D")
      setBgButtonColor5("transparent")
      setTextButtonColor5("#BDA87D")
    }
  }
  const handleClickButton5 = () => {
    if (Filtre5Visible === true) {

      setBgButtonColor5("transparent")
      setTextButtonColor5("#BDA87D")
    } else {

      setBgButtonColor5("0DA8DB")
      setTextButtonColor5("#fff")
      setBgButtonColor1("transparent")
      setTextButtonColor1("#BDA87D")
      setBgButtonColor2("transparent")
      setTextButtonColor2("#BDA87D")
      setBgButtonColor3("transparent")
      setTextButtonColor3("#BDA87D")
      setBgButtonColor4("transparent")
      setTextButtonColor4("#BDA87D")

    }
  }

  const addNotif = (message) => {
    {/*const notification = notificationSystem.current;
    notification.addNotification({
      message: message,
      level: "success",
    });*/}
  };



  return (
    <section style={{ width: "95%" }}>
      {/*<NotificationSystem ref={notificationSystem} style={notifStyles} />*/}
      {/* <div className={MainStyles.pageContainer}> */}

      {/** VERSION 2.0 */}
      {/**UTILISATEUR NON CONNECTE 
        {!monUserCode && (
          <article className={MainStyles.pageContainer}>
            <h1 className={MainStyles.pageTitle}>Il semblerait que vous ne soyez pas connecté.</h1>
            <h2 className={MainStyles.pageSubTitle}>Connectez-vous à votre compte pour accéder à cette fonctionnalité.</h2>

            <Link to="/Compte" id="Compte" className={MainStyles.btnLink}>
              <p style={{ marginTop: "0.6rem", marginBottom: "0.7rem", fontSize: 16, color: '#fff', fontFamily: 'HermesReg' }}>Accéder à mon compte</p>
            </Link>

            <Footer />
          </article>
        )}

        {/*****************************/}
      {/**UTILISATEUR NON CONNECTE V2.1 */}
      {!monRang && (
        <article className={MainStyles.form}>
          <AjoutAnnonce nonConnecte={monUserCode ? false : true} refreshInfosVente={refreshInfosVente} clickHandler={outputEvent} />
          {!nonConnecte &&
            <Link to="/Compte" id="Compte" className={MainStyles.btnLink}>
              <p style={{ marginTop: "0.6rem", marginBottom: "0.7rem", fontSize: 16, color: '#fff', fontFamily: 'HermesReg' }}>Accéder à mon compte</p>
            </Link>
          }

          <Footer />
        </article>
      )}

      {/**UTILISATEUR PARTICULIER*/}
      {monRang === "0" && ( //A changé pour 0
        <div >
          {showFormPar && (
            <div>
              <AjoutAnnonce clickHandler={outputEvent} />
              <button className={MainStyles.AjoutAnnonceCloseBtn} onClick={() => setShowFormPar(false)}>Annuler</button>
            </div>
          )}

          {!showFormPar && (
            <article className={MainStyles.pageContainer}>
              <h1 className={MainStyles.pageTitle}>Faire estimer ou vendre un bien</h1>
              <h2 className={MainStyles.pageSubTitle}>Pour faire estimer ou vendre votre bien, confiez-le à un professionnel de votre secteur.</h2>
              {dataAnnoncesRecu.length === 0 && (
                <article className={MainStyles.article} >
                  <p style={{ color: "#bda87d" }}>Vous n’avez pas ajouté d'annonce</p>
                </article>
              )}
              <div className={MainStyles.btnLancerRequete} style={{
                margin: "0 auto", padding: "0.6rem 0.8rem",width:"fit-content"}} onClick={() => setShowFormPar(true)}>Ajouter une annonce</div>

              <div >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "center",
                    marginTop: 50,
                  }}
                >
                  {/* FIN ENTETE */}
                  <div style={{ width: "100%", color: "#BDA87D" }}>
                    <div>
                      <div>
                        {dataAnnoncesRecu.length > 0 &&
                          dataAnnoncesRecu.map(
                            (annonce, index) => {
                              return (
                                <div key={index} className={MainStyles.estimationRecuContainer}>
                                  <p className={MainStyles.estimationMainTitle} style={{ borderBottom: "1px solid #bda87d" }}>{annonce.titre} à {annonce.ville}</p>
                                  <p className={MainStyles.estimationTextDesc}>{annonce.description}</p>
                                  <p className={MainStyles.estimationTextDesc}>{"Tel: " + annonce.numeroTel}</p>
                                  <p className={MainStyles.estimationTextDate}>{annonce.date}</p>
                                </div>
                              );
                            }
                          )}
                      </div>

                      {/*{dataAnnoncesRecu === [] && (
                        <p
                          style={{
                            fontSize: 24,
                            color: "#BDA87D",
                            width: "80%",
                            padding: 20,
                            marginVertical: 10,
                            fontFamily: "PracticeExtraBold",
                          }}
                        >
                          {" "}
                          Vous n'avez pas encore ajouté d'annonce ! :(
                        </p>
                          )}*/}
                    </div>

                    {/* <div
                          style={{
                            height: 1,
                            backgroundColor: "#BDA87D",
                            marginBottom: 30,
                            width: "100%",
                          }}
                        ></div> */}
                  </div>
                </div>
              </div>
            </article>
          )}
          <Footer />
        </div>
      )}

      {/**UTILISATEUR PROFESSIONNEL*/}
      {(monRang === "1" || monRang === "3") && (
        <article className={MainStyles.pageContainer}>
          {/** FILTRES */}
          <h1 className={MainStyles.pageTitle}>Retrouvez les biens de particuliers à estimer</h1>

          <section className={MainStyles.buttonBar}>
          <div onClick={() => { handleClickButton1() }}
            style={{ backgroundColor: bgButtonColor1, color: textButtonColor1 }}
            className={MainStyles.btnFiltre}
          >Departements</div>


          </section>

          {Filtre1Visible && (
          <div style={{position:'relative',zIndex:'5'}}>
            <Select
              placeholder="Choisir votre département"
              styles={customStyles}
              id="secteurSelect"
              options={depOptions}
              onChange={(value) => { handleChange("secteur", value.value) }}
            />
            <div onClick={() => { _lancerRequete("secteur") }}
              className={MainStyles.btnLancerRequetedep}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Lancez la recherche</div>
            </div>
          </div>
        )}
          {Filtre2Visible && (
            <section>
              <Select
                placeholder="Choisir une fourchette de prix"
                styles={customStyles}
                id="priceSelect"
                options={priceOptions}
                onChange={(value) => {
                  handleChange("prix", value.value);
                }}
              />
              <article onClick={() => { _lancerRequete("prix"); }} className={MainStyles.btnLancerRequete}>
                <div className={MainStyles.btnLancerRequeteInnerText}>Lancer la recherche</div>
              </article>
            </section>
          )}
          {Filtre3Visible && (
            <section>
              <Select
                placeholder="Choisir un nombre de pièces"
                styles={customStyles}
                id="piecesSelect"
                options={piecesOptions}
                onChange={(value) => {
                  handleChange("pieces", value.value);
                }}
              />
              <article onClick={() => { _lancerRequete("pieces"); }} className={MainStyles.btnLancerRequete}>
                <div className={MainStyles.btnLancerRequeteInnerText}>Lancer la recherche</div>
              </article>
            </section>
          )}
          {Filtre4Visible && ( //TYPE
            <section>
              <Select
                placeholder="Choisir le type de bien"
                styles={customStyles}
                id="typeSelect"
                options={typeOptions}
                onChange={(value) => {
                  handleChange("type", value.value);
                }}
              />
              <article onClick={() => { _lancerRequete("type"); }} className={MainStyles.btnLancerRequete}>
                <div className={MainStyles.btnLancerRequeteInnerText}>Lancer la recherche</div>
              </article>
            </section>
          )}
          {Filtre5Visible && (
            <section>
              {typeDeLogement === null && (
                <Select
                  placeholder="Choisir une surface"
                  styles={customStyles}
                  id="appartSelect"
                  options={appartOptions}
                  onChange={(value) => {
                    handleChange("surface", value.value);
                  }}
                />
              )}

              {typeDeLogement === "appartement" && (
                <Select
                  placeholder="Choisir une surface"
                  styles={customStyles}
                  id="appartSelect"
                  options={appartOptions}
                  onChange={(value) => {
                    handleChange("surface", value.value);
                  }}
                />
              )}
              {typeDeLogement === "maison" && (
                <Select
                  placeholder="Choisir une surface"
                  styles={customStyles}
                  id="maisonSelect"
                  options={maisonOptions}
                  onChange={(value) => {
                    handleChange("surface", value.value);
                  }}
                />
              )}
              <article onClick={() => { _lancerRequete("surface"); }} className={MainStyles.btnLancerRequete}>
                <div className={MainStyles.btnLancerRequeteInnerText}>Lancer la recherche</div>
              </article>
            </section>
          )}
          {!showList && (
            <h3 className={MainStyles.pageSubTitle} style={{ marginTop: "80px" }}>Il n'y pas encore d'annonce dans votre département</h3>
          )}
          {showList && (
            <section>
              {dataAnnoncesProRecu.map((item, index) => {
                return (
                  <article key={index} className={MainStyles.estimationRecuContainer}>
                    <p className={MainStyles.estimationMainTitle}>{item.titre} à {item.ville}</p>

                    <p className={MainStyles.estimationTextDesc}>{item.description}</p>

                    <p className={MainStyles.estimationTextDesc}>surface: {item.surface}</p>

                    <p className={MainStyles.estimationTextDesc}>type de logement: {item.typeDeLogement}</p>

                    <p className={MainStyles.estimationTextDesc}>Merci de me contacter au {item.numeroTel}</p>

                    <p className={MainStyles.estimationTextDate}>{item.date}</p>
                  </article>
                );
              })}
            </section>
          )}
        </article>
      )}
    </section>
  );

}

const depOptions = [
  { value: "", label: "Choisir votre département" },
  { value: "01", label: "01 - Ain" },
  { value: "02", label: "02 - Aisne" },
  { value: "03", label: "03 - Allier" },
  { value: "04", label: "04 - Alpes-de-Haute-Provence" },
  { value: "05", label: "05 - Hautes-Alpes" },
  { value: "06", label: "06 - Alpes-Maritimes" },
  { value: "07", label: "07 - Ardèche" },
  { value: "08", label: "08 - Ardennes" },
  { value: "09", label: "09 - Ariège" },
  { value: "10", label: "10 - Aube" },
  { value: "11", label: "11 - Aude" },
  { value: "12", label: "12 - Aveyron" },
  { value: "13", label: "13 - Bouches-du-Rhone" },
  { value: "14", label: "14 - Calvados" },
  { value: "15", label: "15 - Cantal" },
  { value: "16", label: "16 - Charente" },
  { value: "17", label: "17 - Charente-Maritime" },
  { value: "18", label: "18 - Cher" },
  { value: "19", label: "19 - Corrèze" },
  { value: "20", label: "20 - Corse-du-Sud" },
  { value: "21", label: "21 - Haute-Corse" },
  { value: "22", label: "22 - Côte-d'Or" },
  { value: "23", label: "23 - Côtes d'Armor" },
  { value: "24", label: "24 - Creuse" },
  { value: "25", label: "25 - Dordogne" },
  { value: "26", label: "26 - Doubs" },
  { value: "27", label: "27 - Drôme" },
  { value: "28", label: "28 - Eure" },
  { value: "29", label: "29 - Eure-et-Loir" },
  { value: "30", label: "30 - Finistère" },
  { value: "31", label: "31 - Haute-Garonne" },
  { value: "32", label: "32 - Gers" },
  { value: "33", label: "33 - Gironde" },
  { value: "34", label: "34 - Hérault" },
  { value: "35", label: "35 - Ille-et-Vilaine" },
  { value: "36", label: "36 - Indre" },
  { value: "37", label: "37 - Indre-et-Loire" },
  { value: "38", label: "38 - Isère" },
  { value: "39", label: "39 - Jura" },
  { value: "40", label: "40 - Landes" },
  { value: "41", label: "41 - Loir-et-Cher" },
  { value: "42", label: "42 - Loire" },
  { value: "43", label: "43 - Haute-Loire" },
  { value: "44", label: "44 - Loire-Atlantique" },
  { value: "45", label: "45 - Loiret" },
  { value: "46", label: "46 - Lot" },
  { value: "47", label: "47 - Lot-et-Garonne" },
  { value: "48", label: "48 - Lozère" },
  { value: "49", label: "49 - Maine-et-Loire" },
  { value: "50", label: "50 - Manche" },
  { value: "51", label: "51 - Marne" },
  { value: "52", label: "52 - Haute-Marne" },
  { value: "53", label: "53 - Mayenne" },
  { value: "54", label: "54 - Meurthe-et-Moselle" },
  { value: "55", label: "55 - Meuse" },
  { value: "56", label: "56 - Morbihan" },
  { value: "57", label: "57 - Moselle" },
  { value: "58", label: "58 - Nièvre" },
  { value: "59", label: "59 - Nord" },
  { value: "60", label: "60 - Oise" },
  { value: "61", label: "61 - Orne" },
  { value: "62", label: "62 - Pas-de-Calais" },
  { value: "63", label: "63 - Puy-de-Dôme" },
  { value: "64", label: "64 - Pyrénées-Atlantiques" },
  { value: "65", label: "65 - Hautes-Pyrénées" },
  { value: "66", label: "66 - Pyrénées-Orientales" },
  { value: "67", label: "67 - Bas-Rhin" },
  { value: "68", label: "68 - Haut-Rhin" },
  { value: "69", label: "69 - Rhône" },
  { value: "70", label: "70 - Haute-Saône" },
  { value: "71", label: "71 - Saône-et-Loire" },
  { value: "72", label: "72 - Sarthe" },
  { value: "73", label: "73 - Savoie" },
  { value: "74", label: "74 - Haute-Savoie" },
  { value: "75", label: "75 - Paris" },
  { value: "76", label: "76 - Seine-Maritime" },
  { value: "77", label: "77 - Seine-et-Marne" },
  { value: "78", label: "78 - Yvelines" },
  { value: "79", label: "79 - Deux-Sèvres" },
  { value: "80", label: "80 - Somme" },
  { value: "81", label: "81 - Tarn" },
  { value: "82", label: "82 - Tarn-et-Garonne" },
  { value: "83", label: "83 - Var" },
  { value: "84", label: "84 - Vaucluse" },
  { value: "85", label: "85 - Vendée" },
  { value: "86", label: "86 - Vienne" },
  { value: "87", label: "87 - Haute-Vienne" },
  { value: "88", label: "88 - Vosges" },
  { value: "89", label: "89 - Yonne" },
  { value: "90", label: "90 - Territoire de Belfort" },
  { value: "91", label: "91 - Essonne" },
  { value: "92", label: "92 - Hauts-de-Seine" },
  { value: "93", label: "93 - Seine-St-Denis" },
  { value: "94", label: "94 - Val-de-Marne" },
  { value: "95", label: "95 - Val-D'Oise" },
];
const priceOptions = [
  { value: "", label: "Choisir votre prix" },
  { value: "0", label: "0 - 249 999 €" },
  { value: "250000", label: "250 000 - 499 999 €" },
  { value: "500000", label: "500 000 - 999 999 €" },
  { value: "+", label: "+ de 1 000 000 €" },
];
const piecesOptions = [
  { value: "", label: "Choisir nombre de pièces" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6+", label: "6+" },
];
const typeOptions = [
  { value: "", label: "Choisir le type de bien" },
  { value: "maison", label: "maison" },
  { value: "appartement", label: "appartement" },
];

const appartOptions = [
  { value: "", label: "Surface" },
  { value: "0A", label: "0 m² - 50 m²" },
  { value: "51", label: "51 m² - 150 m²" },
  { value: "151+", label: "+ 151 m²" },
];
const maisonOptions = [
  { value: "", label: "Surface" },
  { value: "0", label: "0 m² - 150 m²" },
  { value: "151", label: "151 m² - 350 m²" },
  { value: "351", label: "+ 351 m²" },
];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted #BDA87D",
    color: state.isSelected ? "#fff" : "#332E57",
    backgroundColor: state.isSelected ? "#BDA87D" : "#fff",
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: "100%",
    display: "flex",
    borderBottom: "1px solid #BDA87D",
  }),
  placeholder: () => ({
    color: "#9f99c7",
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),
  svg: () => ({
    color: "#9f99c7",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "#fff";

    return { ...provided, opacity, transition, color };
  },
};

export default withCookies(InfosVente);
