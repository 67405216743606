import React, { Component } from 'react';
import { Formik } from 'formik';
import { withCookies } from 'react-cookie';

import MainStyles from '../App.module.scss';

import NotificationSystem from 'react-notification-system';
import notifStyles from "./NotifStyles"


class Parametre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recupInfosCompte: {
        nom: '_',
        prenom: '_',
      }
    };
    this.notificationSystem = React.createRef();
  }
  componentDidMount() {

    var user_code = this.props.cookies.get('user_code')
    var rang = this.props.cookies.get('rang')
    this.setState({ rang: rang })

    if (rang >= "0") {
      var bodyy = {
        blossom: "monProfil",
        user_code,
      }


      fetch(
        'https://api.homecrush.fr/control_room.php',
        {
          method: 'POST',
          body: JSON.stringify(bodyy)

        })
        .then(response => response.json())
        .then(response => {

          response = JSON.parse(response)


          this.setState({ numeroCPI: response.numeroCPI, idAgence: response.idagence })

          return response;
        })
        .catch(erreur => {
          console.log('ERROR: ' + erreur);
        });

      this.setState({ user_code, rang })
    }
  }


  envoieLienPlateforme = (blossom, param, values) => {
    var bodyy = {
      blossom: blossom,
      [param]: values.lien,
      user_code: this.state.user_code,
    }

    if (this.state.idAgence) {
      bodyy.idagence = this.state.idAgence
    }


    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {

        if (response.httpstatus === 200) {

          this.addNotif("Votre lien a bien été récupéré par Homecrush.")
        }
        if (response.error === "ok") {

          this.addNotif("Une erreur est survenue lors l'envoi du lien. Veuillez essayer ultérieurement.", "error")
        }
        console.log(response)


        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });

  }



  _changerChamps = (blossom, param, values) => {

    if (blossom === "modifierMotDePasse") {
      var bodyy = {
        blossom: blossom,
        motDePasse: values.actuel,
        nouveauMotDePasse: values.mdp,
        user_code: this.state.user_code,
      }

    }
    else {
      var bodyy = {
        blossom: blossom,
        [param]: values.field,
        user_code: this.state.user_code,
      }
    }
    if (this.state.idAgence) {
      bodyy.idagence = this.state.idAgence
    }



    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {

        response = JSON.parse(response)

        if (response.error === "error wrong mdp") {
          return alert("mauvais identifiant")
        }
        if (response.error === "ok") {
          alert("Votre changement a été enregistré avec succès")
        }

        //
        window.location.reload()


        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });

  }

  addNotif = (message, etat) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: message,
      level: etat ? etat : "success"
    });
  };

  _changerMail = (blossom, values) => {
    var bodyy = {
      blossom: blossom,
      user_code: this.state.user_code,
      ancienMail: values.ancienMail,
      mail: values.mail,
    }
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {
        if (response.info === "Le mail n'a pas été modifié, mail et/ou usercode non trouvé(s).") {
          this.addNotif("L'adresse rentrée ne correspond avec l'adresse du compte", "error")
        }
        if (response.info === "Le mail a été modifié avec succès.") {
          this.addNotif("Votre adresse email liée au compte à été modifiée avec succès", "success")
        }
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }


  async _changerImage(blossom, param) {

    let bodyy = {
      blossom: blossom,
      user_code: this.state.user_code,
    }
    if (this.state.idAgence) {
      bodyy.idagence = this.state.idAgence
    }


    if (param === "lelogo") {
      bodyy.image = this.state.monLogo
      bodyy.extension = this.state.monExt
    }

    if (param === "profilPro") {
      bodyy.image = this.state.monProfilPro
      bodyy.extension = this.state.monExtProfilPro
    }

    if (param === "profilPar") {
      bodyy.image = this.state.monProfilPar
      bodyy.extension = this.state.monExtProfilPar
    }

    if (param === "photoagence") {
      bodyy.image = this.state.maPhotoAgence
      bodyy.extension = this.state.monExtAgence
    }

    if (param === "photodir") {
      bodyy.image = this.state.maPhotoDir
      bodyy.extension = this.state.monExtDir
    }


    await fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyy),
      })
      .then(response => response.json())
      .then(response => {

        if (response.httpstatus === 200) {
          this.addNotif('Votre image à été modifié avec succès')
        }
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });

  }

  _ajouterPub = (values) => {
    if (!this.state.imagePub) {
      return alert("Veuillez télécharger une photo pour votre publicité.")
    }
    var bodyy = {
      blossom: "ajoutInfo",
      user_code: this.state.user_code,
      titre: values.titre,
      description: values.desc,
      image: this.state.imagePub,
      extension: this.state.extImagePub
    }
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy),
      })
      .then(response => response.json())
      .then(response => {
        console.log(response)
        return response
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }

  render() {

    var rang = this.props.cookies.get("rang")
    var pp = this.props.cookies.get("pp")
    var prenom = this.props.cookies.get("prenom")
    var nom = this.props.cookies.get("nom")

    var logo = document.getElementById("logo")
    if (logo) {
      const readerLogo = new FileReader();
      const fileInput = document.getElementById("logo");
      const img = document.getElementById("imgLogo");


      if (img) {
        readerLogo.onload = e => {
          img.src = e.target.result;

          var imageLogoBase64 = readerLogo.result.substring(readerLogo.result.lastIndexOf("/9j/"));
          var ext = readerLogo.result.substring("data:image/".length, readerLogo.result.indexOf(";base64"))


          this.setState({ monLogo: imageLogoBase64, monExt: ext })

        }
        fileInput.addEventListener('change', e => {
          const f = e.target.files[0];
          readerLogo.readAsDataURL(f);
        })
      }
    }

    var profilPro = document.getElementById("fileProfilPro")
    if (profilPro) {
      const readerPro = new FileReader();
      const fileInput = document.getElementById("fileProfilPro");
      const img = document.getElementById("imgProfilPro");


      if (img) {
        readerPro.onload = e => {
          img.src = e.target.result;

          var imageProBase64 = readerPro.result.substring(readerPro.result.lastIndexOf("/9j/"));
          var ext = readerPro.result.substring("data:image/".length, readerPro.result.indexOf(";base64"))

          this.setState({ monProfilPro: imageProBase64, monExtProfilPro: ext })

        }
        fileInput.addEventListener('change', e => {
          const f = e.target.files[0];
          readerPro.readAsDataURL(f);
        })
      }

    }

    var profilPar = document.getElementById("fileProfilPar")
    if (profilPar) {
      const readerPar = new FileReader();
      const fileInput = document.getElementById("fileProfilPar");
      const imgPar = document.getElementById("imgProfilPar");

      if (imgPar) {
        readerPar.onload = (e) => {
          imgPar.src = e.target.result;

          var imageParBase64 = readerPar.result.substring(readerPar.result.lastIndexOf("/9j/"));
          var ext = readerPar.result.substring("data:image/".length, readerPar.result.indexOf(";base64"))


          this.setState({ monProfilPar: imageParBase64, monExtProfilPar: ext })

        }
        fileInput.addEventListener('change', e => {
          const f = e.target.files[0];
          readerPar.readAsDataURL(f);
        })

      }
    }

    var photoAgence = document.getElementById("fileAgence")
    if (photoAgence) {
      const readerAgence = new FileReader();
      const fileInput = document.getElementById("fileAgence");
      const img = document.getElementById("imgAgence");


      if (img) {
        readerAgence.onload = e => {
          img.src = e.target.result;

          var imagePhotoAgenceBase64 = readerAgence.result.substring(readerAgence.result.lastIndexOf("/9j/"));
          var ext = readerAgence.result.substring("data:image/".length, readerAgence.result.indexOf(";base64"))


          this.setState({ maPhotoAgence: imagePhotoAgenceBase64, monExtAgence: ext })

        }
        fileInput.addEventListener('change', e => {
          const f = e.target.files[0];
          readerAgence.readAsDataURL(f);
        })
      }
    }

    var photoDir = document.getElementById("fileAgence")
    if (photoDir) {
      const readerDiri = new FileReader();
      const fileInput = document.getElementById("fileDir");
      const img = document.getElementById("imgDir");


      if (img) {
        readerDiri.onload = e => {
          img.src = e.target.result;

          var imageDiriBase64 = readerDiri.result.substring(readerDiri.result.lastIndexOf("/9j/"));
          var ext = readerDiri.result.substring("data:image/".length, readerDiri.result.indexOf(";base64"))

          this.setState({ maPhotoDir: imageDiriBase64, monExtDir: ext })

        }
        fileInput.addEventListener('change', e => {
          const f = e.target.files[0];
          readerDiri.readAsDataURL(f);
        })
      }
    }

    var imagePub = document.getElementById("imagePub")
    if (imagePub) {
      const readerPub = new FileReader();
      const fileInput = document.getElementById("fileImagePub");
      const img = document.getElementById("imagePub");


      if (img) {
        readerPub.onload = e => {
          img.src = e.target.result;

          var imagePubBase64 = readerPub.result.substring(readerPub.result.lastIndexOf("/9j/"));
          var ext = readerPub.result.substring("data:image/".length, readerPub.result.indexOf(";base64"))


          this.setState({ imagePub: imagePubBase64, extImagePub: ext })

        }
        fileInput.addEventListener('change', e => {
          const f = e.target.files[0];
          readerPub.readAsDataURL(f);
        })
      }
    }

    return (
      <section className={MainStyles.sousTitre}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        <p className={MainStyles.mainTitle}>Paramètres</p>

        <article className={MainStyles.form2}>
          <div>
            <p className={MainStyles.labelParagraphFirst}>Modifier votre nom</p>

            <Formik className={MainStyles.formikContainer}
              initialValues={{ field: '' }}
              validate={values => {
                const errors = {};
                if (!values.field) {
                  errors.field = 'Obligatoire';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this.setState({ nom: values.field })
                  this._changerChamps("modifierNom", "nouveauNom", values)
                  setSubmitting(false);
                }, 400);
              }}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                  <div className={MainStyles.inputBtnContainer}>
                    <input className={MainStyles.formInput}
                      type="input"
                      name="field"
                      placeholder={nom}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.field}
                    />
                    <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>
                  </div>
                  <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                </form>
              )}
            </Formik>
          </div>

          <div>
            <p className={MainStyles.labelParagraph}>Modifier votre prénom</p>

            <Formik className={MainStyles.formikContainer}
              initialValues={{ field: '' }}
              validate={values => {
                const errors = {};
                if (!values.field) {
                  errors.field = 'Obligatoire';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  this.setState({ nom: values.field })
                  this._changerChamps("modifierPrenom", "nouveauPrenom", values)
                  setSubmitting(false);
                }, 400);
              }}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                  <div className={MainStyles.inputBtnContainer}>
                    <input className={MainStyles.formInput}
                      type="input"
                      name="field"
                      placeholder={prenom}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.field}
                    />
                    <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>&gt;</button>
                  </div>

                  <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
                </form>
              )}
            </Formik>
          </div>
        </article>

        {/**MODIF ADRESSE MAIL */}
        <article className={MainStyles.form2}>
          <p className={MainStyles.labelParagraph}>Modifier E-mail du compte</p>
          <Formik className={MainStyles.formikContainer}
            initialValues={{ mail: '', ancienMail: '' }}
            validate={values => {
              const errors = {};
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)) {
                errors.mail = 'Adresse e-mail invalide';
              }
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.ancienMail)) {
                errors.ancienMail = 'Adresse e-mail invalide';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                this._changerMail("modifierMail", values)
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className={MainStyles.formInsideFormik}>
                <input className={MainStyles.formInput}
                  type="mail"
                  name="ancienMail"
                  placeholder={"Adresse E-mail actuelle"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ancienMail}
                />
                <div className={MainStyles.formError}>{errors.ancienMail && touched.ancienMail && errors.ancienMail}</div>

                <input className={MainStyles.formInput}
                  type="mail"
                  name="mail"
                  placeholder={"Nouvelle adresse email"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mail}
                />
                <div className={MainStyles.formError}>{errors.mail && touched.mail && errors.mail}</div>
                <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>Valider</button>
              </form>
            )}
          </Formik>
        </article>

        {/**MODIF MOT DE PASSE */}
        <article className={MainStyles.form2}>
          <p className={MainStyles.labelParagraph} style={{ marginTop: "40px" }}>Modifier votre mot de passe</p>
          <Formik className={MainStyles.formikContainer}
            initialValues={{ mdp: '', verif: '' }}
            validate={values => {
              const errors = {};
              if (!values.mdp) {
                errors.mdp = 'Obligatoire';
              }
              if (!values.verif) {
                errors.verif = 'Obligatoire';
              }
              if (values.mdp !== values.verif) {
                errors.verif = 'Les mots de passe ne correspondents pas';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                this._changerChamps("modifierMotDePasse", "nouveauMotDePasse", values)
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className={MainStyles.formInsideFormik}>
                <input className={MainStyles.formInput}
                  type="password"
                  name="actuel"
                  placeholder={"Mot de passe actuel"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.actuel}
                />
                <div className={MainStyles.formError}>{errors.actuel && touched.actuel && errors.actuel}</div>

                <input className={MainStyles.formInput}
                  type="password"
                  name="mdp"
                  placeholder={"Nouveau mot de passe *"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mdp} />
                <div className={MainStyles.formError}>{errors.mdp && touched.mdp && errors.mdp}</div>

                <input className={MainStyles.formInput}
                  type="password"
                  name="verif"
                  placeholder={'Confirmation du mdp'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.verif} />
                <div className={MainStyles.formError}>{errors.verif && touched.verif && errors.verif}</div>
                
                <p className={MainStyles.formErrorMdp}>*Les caractères spéciaux ne sont pas acceptés</p>

                <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>Valider</button>
              </form>
            )}
          </Formik>
        </article>
      </section>
    )
  }
};



export default withCookies(Parametre);