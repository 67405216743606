import React, { useEffect, useState } from 'react';
import { useNavigate , useLocation  } from "react-router-dom";
import { withCookies } from 'react-cookie';

import { scroller } from "react-scroll";
import Select from 'react-select-oss'
import Geocode from "react-geocode";

import mapboxgl from 'mapbox-gl';

import NotifModal from "../components/NotifModal/NotifModal";
import DerniersBiensScroller from "../components/DerniersBiensScroller/DerniersBiensScroller"
import MaMap from "../components/Map/maMap"
import BanniereImage from '../components/BanniereImage/BanniereImage';
import Footer from "../components/Footer/Footer";
import Publicite from '../components/Pub/Pub'

import MainStyles from '../App.module.scss';

import EstimationAchat from '../components/EstimationAchat/EstimationAchat';

import banniereStyles from '../components/BanniereImage/BanniereImage.module.scss'
// import CarouselComp from '../components/Carousel/CarouselComp'

import LottieLoader from "../components/Loader/Loader"
import animationData from "../assets/homecrush-logo.json"

import CookieConsent from "react-cookie-consent";

import { createStore } from "redux";

//partage de la liste des departements avec le composant EstimationAchat;
import depOptions from '../data/departements.js';

import logoHektor from "../../src/assets/Homecrush-site-web-accueil-logo-partenaire-hektor.png"
import logoAC3 from "../../src/assets/Homecrush-site-web-accueil-logo-partenaire-ac3.png"
import logoApimo from "../../src/assets/Homecrush-site-web-accueil-logo-partenaire-apimo.png"
import logoTwimmo from "../../src/assets/Homecrush-site-web-accueil-logo-partenaire-twimmo.png"


import { Formik } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";

import { HashLink } from 'react-router-hash-link';


const storeBien = createStore((state = {
  echantillonRecu: 0
}, action) => {
  switch (action.type) {
    case ("RESET"):
      return {
        echantillonRecu: 0
      };
    case ("SET_ECHANTILLON"):
      return {
        echantillonRecu: action.echantillonRecu,
      };
    default:
      return state;
  }
});


const store = createStore((state = {
  codePostale: "00000",
  maLocalisation: {
    coords: {
      latitude: 0,
      longitude: 0,
    }
  }
}, action) => {
  switch (action.type) {
    case ("SET_CP"):
      return {
        codePostale: action.codePostale,
        maLocalisation: state.maLocalisation
      };
    case ("SET_COORDS"):
      return {
        codePostale: state.codePostale,
        maLocalisation: action.maLocalisation

      };
    default:
      return state;

  }
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


const priceOptions = [
  { value: "", label: "Prix non défini" },
  { value: "0", label: "0 - 249 999 €" },
  { value: "250000", label: "250 000 - 499 999 €" },
  { value: "500000", label: "500 000 - 999 999 €" },
  { value: "+", label: "+ de 1 000 000 €" },
]
const piecesOptions = [
  { value: "", label: "Pièces non définies" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6+", label: "6+" },
]
const typeOptions = [
  { value: "", label: "Type non défini" },
  { value: "maison", label: "maison" },
  { value: "appartement", label: "appartement" },
  { value: "demeure", label: "demeure" },
  { value: "terrain", label: "terrain" },
]
const appartOptions = [
  { value: "", label: "Surface non définie" },
  { value: "0A", label: "0 m² - 50 m²" },
  { value: "51", label: "51 m² - 150 m²" },
  { value: "151+", label: "+ 151 m²" },
]
const maisonOptions = [
  { value: "", label: "Surface non définie" },
  { value: "0", label: "0 m² - 150 m²" },
  { value: "151", label: "151 m² - 350 m²" },
  { value: "351+", label: "+ 351 m²" },
]
const terrainOptions = [
  { value: "", label: "Surface non définie" },
  { value: "0", label: "0 m² - 1000 m²" },
  { value: "1000", label: "1000 m² - 3500 m²" },
  { value: "3510", label: "+ 3510 m²" },
]
const vendeurOptions = [
  { value: "", label: "Vendeur non défini" },
  { value: 1, label: "Profesionnel" },
  { value: 0, label: "Particulier" }
]

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted #BDA87D',
    color: state.isSelected ? '#fff' : '#332E57',
    backgroundColor: state.isSelected ? '#BDA87D' : '#fff',
    padding: 20,
  }),
  control: () => ({
    width: "100%",
    display: "flex",
    borderBottom: "1px solid #BDA87D"
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  indicatorContainer: () => ({
    color: "#9f99c7"
  }),
  placeholder: () => ({
    color: "#9f99c7"
  }),
  svg: () => ({
    color: "#9f99c7"
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = "#9f99c7"

    return { ...provided, opacity, transition, color };
  }
}

function Accueil(props) {

  const navigate = useNavigate();

  const [cp, setCp] = React.useState("00");
  const [codePostale, setCodePostale] = React.useState("00000");
  const [typeVendeur, setTypeVendeur] = React.useState(undefined);
  const [maLocalisation, setMaLocalisation] = React.useState({
    coords: {
      latitude: store.getState().maLocalisation.coords.latitude ? store.getState().maLocalisation.coords.latitude : "48.866667",
      longitude: store.getState().maLocalisation.coords.longitude ? store.getState().maLocalisation.coords.longitude : "2.333333"
    }
  });
  const [echantillonRecu, setEchantillonRecu] = React.useState([{}]);
  const [fitBounds, setFitBounds] = React.useState(undefined);
  const [center, setCenter] = React.useState([-0.109970527, 51.52916347]);
  const [zoom, setZoom] = React.useState([11]);
  const [station, setStation] = React.useState(undefined);
  const [stations, setStations] = React.useState({});
  const [hideMap, setHideMap] = React.useState(false);
  const [showMap, setShowMap] = React.useState(false);
  const [showPub, setShowPub] = React.useState(false);
  const [showList, setShowList] = React.useState(false);
  const [Filtre1Visible, setFiltre1Visible] = React.useState(true);
  const [Filtre2Visible, setFiltre2Visible] = React.useState(false);
  const [Filtre3Visible, setFiltre3Visible] = React.useState(false);
  const [Filtre4Visible, setFiltre4Visible] = React.useState(false);
  const [Filtre5Visible, setFiltre5Visible] = React.useState(false);
  const [Filtre6Visible, setFiltre6Visible] = React.useState(false);
  const [textButtonColor1, setTextButtonColor1] = React.useState("#ffffff");
  const [textButtonColor2, setTextButtonColor2] = React.useState("#ffffff");
  const [textButtonColor3, setTextButtonColor3] = React.useState("#ffffff");
  const [textButtonColor4, setTextButtonColor4] = React.useState("#ffffff");
  const [textButtonColor5, setTextButtonColor5] = React.useState("#ffffff");
  const [textButtonColor6, setTextButtonColor6] = React.useState("#ffffff");
  const [geoDenied, setGeoDenied] = React.useState(null);
  const [geoloc, setGeoloc] = React.useState(null);
  const [premiereRequete, setPremiereRequete] = React.useState(false);
  const [premiereRequetePasse, setPremiereRequetePasse] = React.useState(false);

  const [bgButtonColor1, setBgButtonColor1] = React.useState("#0DA8DB");
  const [bgButtonColor2, setBgButtonColor2] = React.useState("#BDA87D");
  const [bgButtonColor3, setBgButtonColor3] = React.useState("#BDA87D");
  const [bgButtonColor4, setBgButtonColor4] = React.useState("#BDA87D");
  const [bgButtonColor5, setBgButtonColor5] = React.useState("#BDA87D");
  const [bgButtonColor6, setBgButtonColor6] = React.useState("#BDA87D");

  const [monUserCode, setMonUserCode] = React.useState("");
  const [monRang, setMonRang] = React.useState("");
  const [arretRecherche, setArretRecherche] = React.useState(false);

  const [secteur, setSecteur] = React.useState("00");
  const [prix, setPrix] = React.useState("000000");
  const [nombrePieces, setNombrePieces] = React.useState(null);
  const [surface, setSurface] = React.useState(null);
  const [ville, setVille] = React.useState("");
  const [typeDeLogement, setTypeDeLogement] = React.useState(null);
  const [filtreProPar, setFiltreProPar] = React.useState(null);

  const [inProgress, setInProgress] = React.useState(true);
  const [latKm, setLatKm] = React.useState("0");
  const [lonKm, setLonKm] = React.useState("0");

  const [pubRecu, setPubRecu] = React.useState([]);

  const [cookieConsent, setCookieConsent] = React.useState(false);

  const [arrayRecu, setArrayRecu] = React.useState([]);
  const [showMessage, setShowMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [notif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [notifType, setNotifType] = useState("");

  const [derniersCriteres, setDerniersCriteres] = useState(null);

  const [departementLabel, setDepartementLabel] = useState('');

  const [paginationInfo, setPaginationInfo] = useState({ totalPages: 0, currentPage: 1 });

  const showNotif = (message, type) => {
    setNotif(true);
    setNotifMsg(message);
    setNotifType(type);


    setTimeout(() => {
      setNotif(false);
    }, 3000);
  };


  // A VOIR COMMENT AJOUTER LE SYSTEME DE NOTIFICATION DANS UNE COMPOSANT FONCTIONNEL
  //this.notificationSystem = React.createRef();

  const doSomeThingOnParent = () => {
    scroller.scrollTo("liste", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    scroller.scrollTo("listeNotFound", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  const location = useLocation();

    React.useEffect(() => {
        if (location.hash === "#contactSection") {
            const contactSection = document.getElementById("contactSection");
            const yOffset = -window.innerHeight / 2 + contactSection.clientHeight / 2; 
            const y = contactSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, [location]);
    
  useEffect(() => {
    _listeInfos()
    if (storeBien.getState().echantillonRecu !== 0) {
      setEchantillonRecu(storeBien.getState().echantillonRecu)
      setShowList(true)
      setPremiereRequetePasse(true)
      setHideMap(false)
      setShowMap(true)

      getPerm()
      lancerPremiereRequete(codePostale);
    } else {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_TOKEN);
      setMonUserCode(props.cookies.get('user_code'))
      setMonRang(props.cookies.get('rang'))
      if (monUserCode === undefined) {
      } else {
        setMonUserCode(monUserCode)
        setMonRang(monRang)
        setShowMap(false)
        setHideMap(true)
        setShowList(true)
      }
      getPerm()
    }
    if (window.location.hash === '#contactSection') {
      const element = document.getElementById('contactSection');
      if (element) {
        const offset = 30; // Utiliser une valeur négative pour déplacer le scroll plus haut
        const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
    
  }, []);


  const getPerm = () => {

    if (navigator.permissions) {

      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        const permission = result.state;
        if (permission === 'granted' || permission === 'prompt') {
          _geolocalisation();

        } else {
          _geolocalisation(false);
        }
      });
    } else if (navigator.geolocation) {
      _geolocalisation();
    }
  }
  const _geolocalisation = (geoAccess) => {
    // MA LOCALISATION
    if (geoAccess === false) {
      setMaLocalisation({
        coords: {
          latitude: "48.866667",
          longitude: "2.333333",
        }
      })
      store.dispatch({
        type: "SET_COORDS",
        maLocalisation: {
          coords: {
            latitude: "48.866667",
            longitude: "2.333333",
          }
        }

      });

      setHideMap(true)
      setShowMap(false)


    }

    if (navigator.geolocation) {
      if (store.getState().maLocalisation.coords.latitude == 0) {
        navigator.geolocation.getCurrentPosition(function (position) {

          if (position.coords.latitude) {
            setMaLocalisation({
              coords: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              }
            })

            store.dispatch({
              type: "SET_COORDS",
              maLocalisation: {
                coords: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }
              }

            });
          }

          if (store.getState().codePostale === "00000") {
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
              response => {
                let codePostal = null;

                for (let i = 0; i < response.results[0].address_components.length; i++) {
                  const component = response.results[0].address_components[i];

                  for (let j = 0; j < component.types.length; j++) {
                    if (component.types[j] === "postal_code") {
                      codePostal = component.short_name;
                      break;
                    }
                  }

                  if (codePostal) {
                    break;
                  }
                }

                if (codePostal && codePostal !== "00000") {
                  setCodePostale(codePostal);
                  const cp = codePostal.substr(0, 2);
                  setCp(cp);

                  // SET_CP redux
                  store.dispatch({
                    type: "SET_CP",
                    codePostale: codePostal
                  });
                }

                lancerPremiereRequete(codePostal, position);

              },
              error => {
                console.error(error);

              }
            );
          } else {
            let cp = store.getState().codePostale
            cp = cp.substring(0, 2)

            setCodePostale(store.getState().codePostale)
            setCp(cp)
          }
          return position
        }, function (error) {
          setHideMap(false)
          setShowMap(true)
          console.log("Error", error);
        },
          function error(msg) { console.log('Please enable your GPS position feature.'); },
          { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        );
      } else {
        setMaLocalisation(store.getState().maLocalisation)
        setHideMap(false)
        setShowMap(true)
      }
    } else {
      setHideMap(false)
      setShowMap(true)
    }
  };

  const [reloadCarousel, setReloadCarousel] = useState(false);

  const lancerPremiereRequete = (codePostale, position) => {

    setPremiereRequete(true)

    if (codePostale !== "00000") {
      var bodyy = {
        blossom: "biensMap2",
        CP: codePostale//84310
      }
      fetch(
        "https://api.homecrush.fr/control_room.php",
        {
          method: 'POST',
          body: JSON.stringify(bodyy)
        })
        .then(response => response.json())
        .then(response => {
          if (response.error === "error no home") {

            setShowList(false)
            setEchantillonRecu(response) // On ajoute les données recu au state

          } else {
            setEchantillonRecu(response) // On ajoute les données recu au state
            setShowList(true)
            setPremiereRequetePasse(true) // On ajoute les données recu au state

            storeBien.dispatch({
              type: "SET_ECHANTILLON",
              echantillonRecu: response
            });
          }
          return response
        })
        .then((response) => {
          setHideMap(false)
          setShowMap(true)
          _traitementDonnées(response, position)
        })
        .catch(erreur => {
          console.log('Bien map ERROR: ' + erreur);
        });
    }

  }
  //Récupération des pubs
  const _listeInfos = () => {
    var bodyy2 = {
      blossom: "listeInfos",
    }
    if (props.cookies.get('user_code')) {
      bodyy2.user_code = props.cookies.get('user_code')
    } else {
      bodyy2.user_code = '0'
    }
    // Récupération des données de l'API
    fetch(
      "https://api.homecrush.fr/control_room.php",
      {
        method: 'POST',
        body: JSON.stringify(bodyy2)
      })
      .then(response => response.json())
      .then(response => {
        response = JSON.parse(response)
        setPubRecu(response) // On ajoute les données recu au state
        return response
      })
      .catch(erreur => {
        console.log('ERROR CHARGEMENT PUB: ' + erreur);
      });
  }

const updateDistance = (bien, position) => {
  // Vérifier si toutes les coordonnées nécessaires sont présentes
  if (!bien || !position || !bien.latitude || !bien.longitude || !position.coords.latitude || !position.coords.longitude) {
    return; // Sortir de la fonction si les coordonnées ne sont pas complètes
  }

  const lat1 = position.coords.latitude;
  const lon1 = position.coords.longitude;
  const lat2 = bien.latitude;
  const lon2 = bien.longitude;

  // Appel de la fonction de calcul de la distance
  bien.distance = calculateDistance(lat1, lon1, lat2, lon2);
};


const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Rayon de la Terre en km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
            Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c; // Distance en kilomètres
};


const _traitementDonnées = (data, userLocation) => {
  if (!userLocation || !userLocation.coords || !userLocation.coords.latitude || !userLocation.coords.longitude) {
    return;
  }
  if (Array.isArray(data) && data.length > 0) {
    data.forEach(bien => {
      if (bien.latitude && bien.longitude) {
        updateDistance(bien, userLocation);
    } else {
      console.log(`Coordonnées manquantes pour le bien ID ${bien.id}`);
  
      let rue = bien.rue ? bien.rue : "";
      let adresse = `${rue} ${bien.codePostale} ${bien.ville}, France`;
  
      Geocode.fromAddress(adresse).then(
        response => {
          const coords = response.results[0].geometry.location;
          _envoieCoord(bien, coords);
          bien.latitude = coords.lat;
          bien.longitude = coords.lng;
          updateDistance(bien, userLocation);
        },
        error => {
          console.error(`Erreur de géocodage pour le bien ID ${bien.id}:`, error);
        });
      }
    });
  } else {
    console.log("Aucun bien à traiter");
  }
};

  const _envoieCoord = (echantillonRecu, coords) => {
    //Envoi des coordonnées au serveur

    var bodyy = {
      blossom: "envoiCoord",
      latitude: coords.lat,
      longitude: coords.lng,
      idBien: echantillonRecu.id
    }
    // Récupération des données de l'API
    fetch(
      "https://api.homecrush.fr/control_room.php",
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {
        console.log('envoiCoord ::', response)
        return response
      })
      .catch(erreur => {
        console.log('Bien map ERROR: ' + erreur);
      });

  }

  //const [limite, setLimite] = useState(40);
  //const [offset, setOffset] = useState(0);

  const renderPaginationButtons = () => {
    const buttons = [];
    
    for (let i = 1; i <= paginationInfo.totalPages; i++) {
      buttons.push(
        <button key={i} onClick={() => _lancerRequete(derniersCriteres, i)}>
          {i}
        </button>
      );
    }
    
    return <div>{buttons}</div>;
  };
const [rechercheCriteria, setRechercheCriteria] = useState({});

const handleCriteriaChange = (field, value) => {  
  setRechercheCriteria(prevState => ({
    ...prevState,
    [field]: value
  }));
};

  const _lancerRequete = (criteria, page = 1) => {
    

    let requestCriteria = {...rechercheCriteria};

    if(criteria.secteur){      
      // met à jour l'intitulé du département de la recherche
      setDepartementLabel(depOptions[criteria.secteur].label);
    }

    requestCriteria.page = page;  // Ajoute le numéro de la page aux critères

    // Met à jour l'état avec les derniers critères de recherche
    setDerniersCriteres(requestCriteria);

    // Initialisation des états avant l'appel API
    setShowMap(false);
    setHideMap(true);
    setArrayRecu([]);
    setArretRecherche(true);
    setShowMessage(false);
    setMessage("");

    // Préparation du corps de la requête
    let bodyy = {
      blossom: "biensMap2",
      ...requestCriteria // Intègre les critères de requête (incluant la page) dans le corps de la requête
    };

    if (criteria.query) {
    bodyy.secteur = criteria.query;
    }

    if ((secteur !== "00" && secteur !== undefined)) {
      bodyy.secteur = secteur
    }
    if (prix) {
      if (prix === "0") {
        bodyy.prixMin = 0
        bodyy.prixMax = 249999
      }
      if (prix === "250000") {
        bodyy.prixMin = 250000
        bodyy.prixMax = 499999
      }
      if (prix === "500000") {
        bodyy.prixMin = 500000
        bodyy.prixMax = 999999
      }
      if (prix === '+') {
        bodyy.prixMin = 1000000
      }
    }

    if (criteria.budget) {
    if (criteria.budget === "palier1") {
        bodyy.prixMin = 0;
        bodyy.prixMax = 249999;
    }
    if (criteria.budget === "palier2") {
        bodyy.prixMin = 250000;
        bodyy.prixMax = 499999;
    }
    if (criteria.budget === "palier3") {
        bodyy.prixMin = 500000;
        bodyy.prixMax = 999999;
    }
    if (criteria.budget === "palier4") {
        bodyy.prixMin = 1000000;
    }
}

    if (criteria.secteur) {
      bodyy.secteur = criteria.secteur;
    }

    if (nombrePieces) {
      bodyy.nombrePieces = nombrePieces
    }
    if (typeDeLogement) {
      bodyy.typeDeLogement = typeDeLogement
    }

    if (criteria.typeDeLogement) {
      bodyy.typeDeLogement = criteria.typeDeLogement
    }

    if (typeVendeur === 0) bodyy.pro = typeVendeur;
    if (typeVendeur === 1) bodyy.pro = typeVendeur;

    switch (surface) {
      case "0":
        bodyy.surfaceMin = 0
        bodyy.surfaceMax = 150
        break;
      case "151":
        bodyy.surfaceMin = 151
        bodyy.surfaceMax = 350
        break;
      case "351+":
        bodyy.surfaceMin = 351
        break;

      case "0A":
        bodyy.surfaceMin = 0
        bodyy.surfaceMax = 50
        break;
      case "51":
        bodyy.surfaceMin = 51
        bodyy.surfaceMax = 150
        break;
      case '151+':
        bodyy.surfaceMin = 151
        break;
      default:
      // code block
    }

    switch (criteria.surface) {
      case "palier1":
        bodyy.surfaceMin = 0;
        bodyy.surfaceMax = 50;
        break;
      case "palier2":
        bodyy.surfaceMin = 51;
        bodyy.surfaceMax = 100;
        break;
      case "palier3":
        bodyy.surfaceMin = 101;
        bodyy.surfaceMax = 150;
        break;
      case "palier4":
        bodyy.surfaceMin = 151;
        break;
      default:
        // code block
    }

    
// Récupération des données de l'API
fetch("https://api.homecrush.fr/control_room.php", {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyy) // Remplacez `bodyy` par vos critères de recherche actuels.
})
.then(response => response.json())
.then(data => {
    console.log('Data reçue de l\'API:', data);
    
    // Traitement des biens
    const biens = Object.values(data).filter(item => typeof item === "object" && item !== data.pagination);
    const paginationData = data.pagination;
    setEchantillonRecu(biens);
    setShowList(biens.length > 0);
    setHideMap(false);
    setShowMap(biens.length >= 0);

    scroller.scrollTo('mapHomePage', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });

    // Calculer la distance si la géolocalisation est autorisée et disponible
    if (maLocalisation && maLocalisation.coords && maLocalisation.coords.latitude && maLocalisation.coords.longitude) {
        _traitementDonnées(biens, maLocalisation);
    }

    setReloadCarousel(prev => !prev);

    // Suite du traitement de la pagination
    let totalPages = 1; // Valeur par défaut de 1 page
    let currentPage = 1;

    if (data.pagination && Array.isArray(data.pagination) && data.pagination.length > 0) {
        totalPages = data.pagination.length; // Nombre total de pages
        const activePage = data.pagination.find(page => Object.values(page).includes(true));
        currentPage = Object.keys(activePage)[0] === "1" ? 1 : parseInt(Object.keys(activePage)[0].replace('?p=', ''));
    }

    setPaginationInfo({
        totalPages: totalPages,
        currentPage: currentPage,
        paginationData: paginationData,
    });
})
.catch(error => {
    console.error('Erreur lors de la requête:', error);
    setEchantillonRecu([]);
    setShowList(false);
    setHideMap(false);
    setShowMap(false);
});

};


useEffect(() => {
  setShowList(echantillonRecu.length > 0 && echantillonRecu.some(bien => bien.id !== undefined));
}, [echantillonRecu]);


const extractPageNumber = (key) => {
  const match = key.match(/\?p=(\d+)/); // Chercher pour des nombres après ?p=
  return match ? parseInt(match[1], 10) : null; // Convertir le résultat en nombre
};

const generatePageRange = (currentPage, totalPages, paginationData) => {
  let range = [];
  const delta = 2;

  if (!paginationData) {
    console.error("paginationData n'est pas défini.");
    return range;
  }

  const pageNumbers = Array.from(new Set(paginationData.map(item => extractPageNumber(Object.keys(item)[0]))))
                            .filter(num => num !== null)
                            .sort((a, b) => a - b);

  // Assurer que totalPages correspond au dernier numéro de page de pageNumbers
  totalPages = pageNumbers[pageNumbers.length - 1];

  if (pageNumbers.length <= 7) {
    range = pageNumbers;
  } else {
    range.push(1); // Première page

    let startPage = Math.max(2, currentPage - delta);
    let endPage = Math.min(totalPages - 1, currentPage + delta);

    if (currentPage - delta > 2) {
      range.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      range.push(i);
    }

    if (currentPage + delta < totalPages - 1) {
      range.push('...');
    }

    range.push(totalPages); // Dernière page
  }

  return range;
};




const Pagination = ({ currentPage, totalPages, onPageChange, paginationData }) => {
  const pageRange = generatePageRange(currentPage, totalPages, paginationData);

  return (
    <div className={MainStyles.pagination}>
      {currentPage > 1 && (
        <button
          className={`${MainStyles.button} ${MainStyles.prevButton}`}
          onClick={() => onPageChange(currentPage - 1)}
        >
          {'<'}
        </button>
      )}

      {pageRange.map((page, index) => {
        const isCurrentPage = page === currentPage;
        const isEllipsis = page === '...';

        if (isEllipsis) {
          return <span key={index} className={MainStyles.ellipsis}>...</span>;
        }

        return (
          <button
            key={index}
            disabled={isCurrentPage}
            className={`${MainStyles.button} ${isCurrentPage ? MainStyles.activePage : ''}`}
            onClick={() => !isCurrentPage && onPageChange(page)}
          >
            {page}
          </button>
        );
      })}

      {currentPage < totalPages-1 && (
        <button
          className={`${MainStyles.button} ${MainStyles.nextButton}`}
          onClick={() => onPageChange(currentPage + 1)}
        >
          {'>'}
        </button>
      )}
    </div>
  );
};

  const handleClickButton1 = () => {
    if (Filtre1Visible === true) {
      setFiltre1Visible(false)

      setBgButtonColor1('#BDA87D')
      setTextButtonColor1('white')
    }
    else {
      setFiltre1Visible(true)
      setFiltre2Visible(false)
      setFiltre3Visible(false)
      setFiltre4Visible(false)
      setFiltre5Visible(false)
      setFiltre6Visible(false)

      setBgButtonColor1('#0DA8DB')
      setTextButtonColor1('#fff')
      setBgButtonColor2('#BDA87D')
      setTextButtonColor2('#fff')
      setBgButtonColor3('#BDA87D')
      setTextButtonColor3('#fff')
      setBgButtonColor4('#BDA87D')
      setTextButtonColor4('#fff')
      setBgButtonColor5('#BDA87D')
      setTextButtonColor5('#fff')
      setBgButtonColor6('#BDA87D')
      setTextButtonColor6('#fff')
    };
  }
  const handleClickButton2 = () => {
    if (Filtre2Visible === true) {
      setFiltre2Visible(false)
      setBgButtonColor1('#BDA87D')
      setTextButtonColor2('white')
    }
    else {
      setFiltre2Visible(true)
      setFiltre1Visible(false)
      setFiltre3Visible(false)
      setFiltre4Visible(false)
      setFiltre5Visible(false)
      setFiltre6Visible(false)

      setBgButtonColor2('#0DA8DB')
      setTextButtonColor2('#fff')
      setBgButtonColor1('#BDA87D')
      setTextButtonColor1('#fff')
      setBgButtonColor3('#BDA87D')
      setTextButtonColor3('#fff')
      setBgButtonColor4('#BDA87D')
      setTextButtonColor4('#fff')
      setBgButtonColor5('#BDA87D')
      setTextButtonColor5('#fff')
      setBgButtonColor6('#BDA87D')
      setTextButtonColor6('#fff')
    };
  }
  const handleClickButton3 = () => {
    if (Filtre3Visible === true) {
      setFiltre3Visible(false)
      setBgButtonColor3('#BDA87D')
      setTextButtonColor3('white')
    }
    else {
      setFiltre3Visible(true)
      setFiltre1Visible(false)
      setFiltre2Visible(false)
      setFiltre4Visible(false)
      setFiltre5Visible(false)
      setFiltre6Visible(false)

      setBgButtonColor3('#0DA8DB')
      setTextButtonColor3('#fff')
      setBgButtonColor1('#BDA87D')
      setTextButtonColor1('#fff')
      setBgButtonColor2('#BDA87D')
      setTextButtonColor2('#fff')
      setBgButtonColor4('#BDA87D')
      setTextButtonColor4('#fff')
      setBgButtonColor5('#BDA87D')
      setTextButtonColor5('#fff')
      setBgButtonColor6('#BDA87D')
      setTextButtonColor6('#fff')
    };
  }
  const handleClickButton4 = () => {
    if (Filtre4Visible === true) {
      setFiltre4Visible(false)
      setBgButtonColor4('#BDA87D')
      setTextButtonColor4('white')
    }
    else {
      setFiltre4Visible(true)
      setFiltre1Visible(false)
      setFiltre2Visible(false)
      setFiltre3Visible(false)
      setFiltre5Visible(false)
      setFiltre6Visible(false)

      setBgButtonColor4('#0DA8DB')
      setTextButtonColor4('#fff')
      setBgButtonColor1('#BDA87D')
      setTextButtonColor1('#ff')
      setBgButtonColor2('#BDA87D')
      setTextButtonColor2('#fff')
      setBgButtonColor3('#BDA87D')
      setTextButtonColor3('#fff')
      setBgButtonColor5('#BDA87D')
      setTextButtonColor5('#fff')
      setBgButtonColor6('#BDA87D')
      setTextButtonColor6('#fff')
    };
  }
  const handleClickButton5 = () => {
    if (Filtre5Visible === true) {
      setFiltre5Visible(false)
      setBgButtonColor5('#BDA87D')
      setTextButtonColor5('white')
    }
    else {
      setFiltre5Visible(true)
      setFiltre1Visible(false)
      setFiltre2Visible(false)
      setFiltre3Visible(false)
      setFiltre4Visible(false)
      setFiltre6Visible(false)

      setBgButtonColor5('#0DA8DB')
      setTextButtonColor5('#fff')
      setBgButtonColor1('#BDA87D')
      setTextButtonColor1('#fff')
      setBgButtonColor2('#BDA87D')
      setTextButtonColor2('#fff')
      setBgButtonColor3('#BDA87D')
      setTextButtonColor3('#fff')
      setBgButtonColor4('#BDA87D')
      setTextButtonColor4('#fff')
      setBgButtonColor6('#BDA87D')
      setTextButtonColor6('#fff')
    };

  }
  const handleClickButton6 = () => {
    if (Filtre6Visible === true) {
      setFiltre6Visible(false)
      setBgButtonColor6('#BDA87D')
      setTextButtonColor6('white')
    }
    else {
      setFiltre6Visible(true)
      setFiltre1Visible(false)
      setFiltre2Visible(false)
      setFiltre3Visible(false)
      setFiltre4Visible(false)
      setFiltre5Visible(false)

      setBgButtonColor6('#0DA8DB')
      setTextButtonColor6('#fff')
      setBgButtonColor1('#BDA87D')
      setTextButtonColor1('#fff')
      setBgButtonColor2('#BDA87D')
      setTextButtonColor2('#fff')
      setBgButtonColor3('#BDA87D')
      setTextButtonColor3('#fff')
      setBgButtonColor4('#BDA87D')
      setTextButtonColor4('#fff')
      setBgButtonColor5('#BDA87D')
      setTextButtonColor5('#fff')
    };

  }

  const movePub = () => {
    if (showPub === false) {
      document.getElementById("pub").style.bottom = "0"

      setShowPub(true)
    } else {
      document.getElementById("pub").style.display = "none"
      setShowPub(false)
    }
  }

  const _sendContactForm = (values) => {
    let bodyy = {
      "blossom": "contactForm",
      "user_code": monUserCode ? monUserCode : process.env.REACT_APP_USERCODE_HOMECRUSH,
      "nomSender": "N",
      "mail": values.message,
      "mailObject": values.objet,
      "mailSender": values.mail
    }
    console.log("bodyyuserrequete", bodyy)
    fetch(
      "https://api.homecrush.fr/control_room.php",
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {
        console.log(response)
        return response
      })
      .catch(erreur => {
        console.log('ERROR LANCER REQUETE: ' + erreur);
      });
  }
  
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


  const handleChange = (parameter, value) => {
    if (value === "") {
      value = undefined
    }
    if (parameter === "secteur") {
      setSecteur(value)
    }
    if (parameter === "prix") {
      setPrix(value)
    }

    if (parameter === "pieces") {
      setNombrePieces(value)
    }
    if (parameter === "surface") {
      setSurface(value)
    }
    if (parameter === "ville") {
      setVille(value)
    }
    if (parameter === "type") {
      setTypeDeLogement(value)
    }
    if (parameter === "codePostale") {
      setCodePostale(value)
    }

    if (parameter === "vendeur") {
      setTypeVendeur(value)
    }
  }


  const NavigateToBien = bien => {    
    navigate(bien.showlink);
  }

 const handleDepartementChange = (selectedOption) => {
  console.log("handleDep change")
  setDepartementLabel(selectedOption.label); // Juste pour mettre à jour l'affichage
};



  return (
    <>
    <style>
        {`.grecaptcha-badge { visibility: hidden; }`}
      </style>
    
    <section className={MainStyles.pageContainer}>
      {notif && (
        <NotifModal notif={notif} notifType={notifType} notifMsg={notifMsg} />
      )}
      <div className={MainStyles.heroSection}>
        <h1 className={MainStyles.pageTitle} style={{marginTop : '3rem'}}>Annonces immobilières de <span className={MainStyles.boldText}>Professionnels</span><br className={MainStyles.pageTitle}></br> Notre petit plus ! </h1><span className={MainStyles.pageTitle}>La proximité immédiate</span>
        <h2 className={MainStyles.pageSubTitle}>Affichage instantané, des biens immobiliers</h2>
        {/*
        <div className={MainStyles.logoContainer}>
          <a href="https://www.la-boite-immo.com/logiciel-immobilier/gestion-biens-immobiliers" target="_blank" rel="noopener noreferrer">
              <img src={logoHektor} height="75px" alt="Logo Hektor" style={{ marginRight: "20px" }}/>
          </a>
          <a href="https://www.ac3-groupe.com/" target="_blank" rel="noopener noreferrer">
              <img src={logoAC3} height="75px" alt="Logo AC3" style={{ marginRight: "30px" }}/>
          </a>
          <a href="https://apimo.net/fr/" target="_blank" rel="noopener noreferrer">
              <img src={logoApimo} height="75px" alt="Logo Apimo" style={{ marginRight: "20px" }} />
          </a>
          <a href="https://www.twimmo.com/accueil.html" target="_blank" rel="noopener noreferrer">
              <img src={logoTwimmo} height="75px" alt="Logo Twimmo" />
          </a>
      </div>*/}
      </div>

      <div className={MainStyles.pos}><EstimationAchat onSearch={_lancerRequete} departementLabel={departementLabel} /></div>
        

      <h3 className={MainStyles.pageSubTitle}>Les dix derniers biens sur la <span className={MainStyles.doree}>Plateforme</span></h3>

      {/* SCROLL BIEN A LA UNE*/}
      <DerniersBiensScroller />

      <div className={MainStyles.banniereContainer}>
      <BanniereImage
        title={<><span><strong styles ="font-size: 1.5em;">Particulier ?</strong> Déposez votre demande d'estimation et un </span>professionnel<span> vous contactera !</span></>}
        textButton="Je trouve un professionnel"
        link={"/InfosVentes"}
        id="bannerCompte"
        image={"Image1"}
      />

        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/Homecrush-site-web-trouver-le-bien-ideal-pourvous-sur-homecrush.jpg)` }} className={banniereStyles.bannerContainer}>
        <div className={banniereStyles.bannerTitle}>Trouvez le <strong styles="font-size: 1.5em;">Bien idéal</strong><br></br> pour vous sur Homecrush !</div>
          <div
            style={{ width: "auto", lineHeight: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            className={banniereStyles.bannerButton}
            onClick={() =>{_lancerRequete("secteur"); document.getElementById('mapSection').scrollIntoView({behavior: "smooth", block: "start"})}}
          >Voir nos annonces</div>
        </div>
        <BanniereImage
          title="Professionnel, proposez votre bien à la vente sur Homecrush"
          textButton="Je m'inscris"
          link={props.cookies.get('user_code') ? "/Ventes" : "/Compte"}
          id="bannerCompte"
          image={"Image2"}
        />

      </div>

      <div className={MainStyles.articleMarginRight}>
        <h3 id="mapSection" className={MainStyles.pageSubTitle} style={{ marginTop: "80px" }}>Voir nos annonces par <span className={MainStyles.doree}>filtre</span></h3>
        {/** FILTRES */}
        <article className={MainStyles.buttonBar}>
          <div onClick={() => { handleClickButton1() }}
            style={{ backgroundColor: bgButtonColor1, color: textButtonColor1 }}
            className={MainStyles.btnFiltre}>Départements</div>

          <div onClick={() => { handleClickButton2() }}
            style={{ backgroundColor: bgButtonColor2, color: textButtonColor2 }}
            className={MainStyles.btnFiltre}>Prix</div>

          <div onClick={() => { handleClickButton3() }}
            style={{ backgroundColor: bgButtonColor3, color: textButtonColor3 }}
            className={MainStyles.btnFiltre}>Nbr. de pièces</div>

          <div onClick={() => { handleClickButton4() }}
            style={{ backgroundColor: bgButtonColor4, color: textButtonColor4 }}
            className={MainStyles.btnFiltre}
          >Type</div>

          <div onClick={() => { handleClickButton5() }}
            style={{ backgroundColor: bgButtonColor5, color: textButtonColor5 }}
            className={MainStyles.btnFiltre}
          >Surface</div>

          {/*<div onClick={() => { handleClickButton6() }}
            style={{ backgroundColor: bgButtonColor6, color: textButtonColor6 }}
            className={MainStyles.btnFiltre}
    >Vendeurs</div>*/}
        </article>

        {Filtre1Visible && (
          <article className={MainStyles.containerFiltre}>
            <Select
              className={MainStyles.selectFiltre}
              placeholder="Département"
              styles={customStyles}
              id="secteurSelect"
              options={depOptions}
              onChange={(selectedOption) => {
                console.log("setDepartementLabel 1 : ", selectedOption.label);
                setDepartementLabel(selectedOption.label);  // Mettre à jour l'affichage du nom du département
                handleChange("secteur", selectedOption.value);  // Mettre à jour la valeur du secteur pour les requêtes API
              }}
            />
            <div onClick={() => { _lancerRequete("secteur") }} className={MainStyles.btnLancerRequete}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Chercher</div>
            </div>
          </article>
        )}
        {Filtre2Visible && (
          <article className={MainStyles.containerFiltre}>
            <Select
              className={MainStyles.selectFiltre}
              placeholder="Prix"
              styles={customStyles}
              id="priceSelect"
              options={priceOptions}
              onChange={(value) => {
                handleChange("prix", value.value);
              }}
            />
            <div onClick={() => { _lancerRequete("prix") }} className={MainStyles.btnLancerRequete}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Chercher</div>
            </div>
          </article>
        )}
        {Filtre3Visible && (
          <article className={MainStyles.containerFiltre}>
            <Select
              className={MainStyles.selectFiltre}
              placeholder="Nbr. de pièces"
              styles={customStyles}
              id="piecesSelect"
              options={piecesOptions}
              onChange={(value) => {
                handleChange("pieces", value.value);
              }}
            />
            <div onClick={() => { _lancerRequete("pieces") }} className={MainStyles.btnLancerRequete}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Chercher</div>
            </div>
          </article>
        )}
        {Filtre4Visible && ( //TYPE
          <article className={MainStyles.containerFiltre}>
            <Select
              className={MainStyles.selectFiltre}
              placeholder="Type de bien"
              styles={customStyles}
              id="typeSelect"
              options={typeOptions}
              onChange={(value) => {
                handleChange("type", value.value);
              }}
            />
            <div onClick={() => { _lancerRequete("type") }} className={MainStyles.btnLancerRequete}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Chercher</div>
            </div>
          </article>
        )}
        {Filtre5Visible && (
          <article className={MainStyles.containerFiltre}>
            {typeDeLogement === null && (
              <Select
                className={MainStyles.selectFiltre}
                placeholder="Surface"
                styles={customStyles}
                id="appartSelect"
                options={appartOptions}
                onChange={(value) => {
                  handleChange("surface", value.value);
                }}
              />
            )}

            {typeDeLogement === "appartement" && (
              <Select
                className={MainStyles.selectFiltre}
                placeholder="Surface"
                styles={customStyles}
                id="appartSelect"
                options={appartOptions}
                onChange={(value) => {
                  handleChange("surface", value.value);
                }}
              />
            )}
            {typeDeLogement === "maison" && (
              <Select
                className={MainStyles.selectFiltre}
                placeholder="Surface"
                styles={customStyles}
                id="maisonSelect"
                options={maisonOptions}
                onChange={(value) => {
                  handleChange("surface", value.value);
                }}
              />
            )}
            {typeDeLogement === "demeure" && (
              <Select
                className={MainStyles.selectFiltre}
                placeholder="Surface"
                styles={customStyles}
                id="maisonSelect"
                options={maisonOptions}
                onChange={(value) => {
                  handleChange("surface", value.value);
                }}
              />
            )}
            {typeDeLogement === "terrain" && (
              <Select
                className={MainStyles.selectFiltre}
                placeholder="Surface"
                styles={customStyles}
                id="maisonSelect"
                options={terrainOptions}
                onChange={(value) => {
                  handleChange("surface", value.value);
                }}
              />
            )}
            <div onClick={() => { _lancerRequete("surface") }} className={MainStyles.btnLancerRequete}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Chercher</div>
            </div>
          </article>
        )}

        {/*Filtre6Visible && (
          <article className={MainStyles.containerFiltre}>
            {filtreProPar === null && (
              <Select
                className={MainStyles.selectFiltre}
                placeholder="Vendeur"
                styles={customStyles}
                id="vendeurSelect"
                options={vendeurOptions}
                onChange={(value) => {
                  handleChange("vendeur", value.value);
                }}
              />
            )}

            <div onClick={() => { _lancerRequete("vendeur") }} className={MainStyles.btnLancerRequete}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Chercher</div>
            </div>
          </article>
        )*/}
        {hideMap && (
          <article className={MainStyles.loader}>
            <LottieLoader animationData={animationData} width={300} height={300} />
          </article>
        )}

        {showMap && (
          <article id="mapHomePage" style={{ height: "auto", padding: "20px 0 6rem 0" }}>
            <MaMap location={maLocalisation} echantillonRecu={echantillonRecu} />

            {
              showList && echantillonRecu.length > 0 ? (
                <>
                  <Pagination 
                    currentPage={paginationInfo.currentPage} 
                    totalPages={paginationInfo.totalPages}
                    onPageChange={nouvellePage => _lancerRequete(derniersCriteres, nouvellePage)}
                    paginationData={paginationInfo.paginationData} // Ensure this prop is passed correctly
                  />


                  {departementLabel && (
                    <div className={MainStyles.departementHeader}>
                      Résultats pour le département : {departementLabel}
                    </div>
                  )}
                  <div className={MainStyles.listeWrapperAccueil} id="liste">
                    {
                      echantillonRecu
                        .sort((a, b) => a.prix - b.prix)  // Ajoutez cette ligne pour trier par prix
                        .map((bien, index) => (
                          <article key={index} style={{ cursor: "pointer" }} onClick={() => NavigateToBien(bien)}>
                            <div className={MainStyles.listeContainerAccueil}>
                              <div className={MainStyles.distanceItem}>
                                <svg style={{ height: "2rem" }} xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                                  {/* SVG content omitted for brevity */}
                                </svg>
                                {bien.distance === undefined ? "non défini" : `${bien.distance.toFixed(0)} km`}
                              </div>
                              <img className={MainStyles.itemImage} src={bien.image?.[0] ? `https://${bien.image[0]}` : bien.photoData || bien.photoExternalLink?.[0] || "https://api.homecrush.fr/imgDefaut/default.jpeg"} alt="Image du bien"/>
                              <div className={MainStyles.listHeadAccueil}>
                                <span className={MainStyles.prixItem}>{new Intl.NumberFormat('fr-FR', { style: 'currency', maximumFractionDigits: 0, currency: 'EUR' }).format(parseInt(bien.prix))}</span>
                                <span className={MainStyles.surfaceItem}>{" - " + bien.surface + "m²"}</span>
                              </div>
                              <div className={MainStyles.divider}></div>
                              <span className={MainStyles.itemVille}>{bien.ville}</span>
                            </div>
                          </article>
                        ))
                    }
                  </div>
                  <Pagination 
                    currentPage={paginationInfo.currentPage} 
                    totalPages={paginationInfo.totalPages}
                    onPageChange={nouvellePage => _lancerRequete(derniersCriteres, nouvellePage)}
                    paginationData={paginationInfo.paginationData} // Ensure this prop is passed correctly
                  />
                </>
              ) : (
                <div className={MainStyles.messageDefaut} id="listeNotFound">Aucun bien trouvé.</div>
              )
            }

          </article>
        )}

        <div id="contactSection" className={MainStyles.contactCard}>
          <div>
            <div className={MainStyles.contactBannerTitle}>Vous avez une question ?</div>
            <div className={MainStyles.contactBannerParag}>N'hésitez pas à nous contacter via notre formulaire de contact</div>
          </div>
          <Formik className={MainStyles.formikContactContainer}
            initialValues={{ field: '' }}
            validate={values => {
              const errors = {};
              if (!values.mail) {
                errors.mail = 'Obligatoire';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
              ) {
                errors.emailRecup = 'Veuillez saisir une adresse mail valide';
              }

              if (!values.objet) {
                errors.objet = 'Obligatoire';
              }
              if (!values.message) {
                errors.message = 'Obligatoire';
              }
              console.log("ERROR ", errors);
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                console.log("Change : ", values);
                _sendContactForm(values)
              }, 400);
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form className={MainStyles.formInsideFormikContact} onSubmit={handleSubmit}>

                <input
                  className={MainStyles.contactFormInput}
                  type="input"
                  name="mail"
                  placeholder={"Mail"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mail}
                />
                <input className={MainStyles.contactFormInput}
                  type="input"
                  name="objet"
                  placeholder={"Objet"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.objet}
                />
                <textarea className={MainStyles.contactFormInput}
                  type="textarea"
                  name="message"
                  placeholder={"Message"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <button style={{ marginRight: '20%', marginLeft: 'auto' }} className={MainStyles.buttonGold} type="submit" disabled={isSubmitting}>Envoyer</button>
                <ReCAPTCHA
                  sitekey="6LdZKickAAAAABtpbdM_aNDDrfKIMUmz4Kcfhkp0"
                  size="invisible"
                  onChange
                />

                <div className={MainStyles.formError}>{errors.field && touched.field && errors.field}</div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {pubRecu && (<Publicite pubRecu={pubRecu ? pubRecu : undefined} />)}

      <CookieConsent
        location="bottom"
        buttonText="Accepter"
        cookieName="cookieConsent"
        
        style={{ borderRadius: "2rem 2rem 0rem 0rem", border: "1px solid rgb(189, 168, 125)", background: "white", zIndex: 16000 }}
        buttonStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.7rem",
          paddingLeft: "0.8rem",
          paddingRight: "0.8rem",
          marginRight: "3rem",
          color: "white",
          fontFamily: "HermesReg",
          fontSize: "17px",
          backgroundColor: " rgb(13, 168, 219)",
          border: "1px solid #bda87d",
          borderRadius: "50px",
          zIndex: 16000
        }}
        expires={150}
        onAccept={() => { setCookieConsent(true); }}
      >
        <span style={{ backgroundColor: "white", color: "#332e57", fontFamily: "HermesReg", fontSize: "17px" }}>Nous utilisons les cookies pour vous fournir le meilleur service sur notre site Web. Si vous poursuivez votre navigation en acceptant les cookies vous pourrez bénéficier pleinement des options et fonctionnalités de notre site.</span>
      </CookieConsent>
      <Footer />
    </section>
    </>
  );
}


export default withCookies(Accueil);
