import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { Formik } from 'formik';

import MainStyles from "../../App.module.scss";

import NotificationSystem from 'react-notification-system';
import notifStyles from "../NotifStyles"

import NotifModal from "../NotifModal/NotifModal";

import LogoAc3 from "../../assets/logo-plateforme/Logo-ac3.png"

const ParametreAgence = (props) => {

  const [userCode, setUserCode] = useState("");
  const [rang, setRang] = useState("");

  const [showForm, setShowForm] = useState("AC3")


  const [toggleClassAc3, setToggleClassAc3] = useState(true)
  const [toggleClassHektor, setToggleClassHektor] = useState(false)
  const [toggleClassApimo, setToggleClassApimo] = useState(false)

  const [idAgence, setIdAgence] = useState("");


  const [notif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [notifType, setNotifType] = useState("");

  useEffect(() => {

    setUserCode(props.cookies.get('user_code'))
    setRang(props.cookies.get('rang'))


    if ((rang == "1" || rang == "3")) {
      var bodyy = {
        blossom: "monProfil",
        userCode,
      }

      fetch(
        'https://api.homecrush.fr/control_room.php',
        {
          method: 'POST',
          body: JSON.stringify(bodyy)

        })
        .then(response => response.json())
        .then(response => {

          response = JSON.parse(response)


          setIdAgence(response.idAgence)
          // setPlateformeAgence ajouter plus tard pour savoir quel plateforme a l'agence
          return response;
        })
        .catch(erreur => {
          console.log('ERROR: ' + erreur);
        });


    }

  }, [])

  const showNotif = (message, type) => {
    setNotif(true);
    setNotifMsg(message);
    setNotifType(type);


    setTimeout(() => {
      setNotif(false);
    }, 6000);
  };

  const envoieLienPlateforme = (blossom, param, values) => {
    var bodyy = {
      blossom: blossom,
      [param]: values.lien,
      user_code: userCode,
    }

    if (idAgence) {
      bodyy.idagence = idAgence
    }


    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {

        if (response.httpstatus === 200) {

          //addNotif("Votre lien a bien été récupéré par Homecrush.")
        }
        if (response.error === "ok") {

          //addNotif("Une erreur est survenue lors l'envoi du lien. Veuillez essayer ultérieurement.", "error")
        }

        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });

  }



  const verifTokenApimo = (blossom, values) => {
    var bodyy = {
      blossom: "verifTokenApimo",
      token: values.token,
      idProvider: values.idProvider,
      idAgence: values.idApimo,
      user_code: userCode,
    }

    console.log(bodyy)
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {

        console.log(response)

        // Afficher response.info a l'utilisateur

        if (response.httpstatus === 200) {
          // LE TOKEN EST BON
          showNotif(response.info, "success")
          apimoInsereToken(blossom, values)

        }
        else {

          //afficher message d'erreur
          showNotif(response.info, "error")
        }


        return response;
      })
      .catch(erreur => {
        console.log(erreur);
      });

  }



  const apimoInsereToken = (blossom, values) => {
    var bodyy = {
      blossom: blossom,
      token: values.token,
      idProvider: values.idProvider,
      idAgence: values.idApimo,
      user_code: userCode,
    }

    if (idAgence) {
      bodyy.idagence = idAgence
    }

    console.log(bodyy)

    showNotif("Votre token a bien été validé par apimo. Le chargement de vos biens est en cours, vous devriez voir apparaître vos biens sur homecrush dans quelques minutes.", "success")


    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {

        response = JSON.parse(response)
        console.log(response)

        // Afficher response.info a l'utilisateur

        if (response.info === 200) {
          // afficher message de succès

          showNotif(response.info, "success")

        
          //addNotif("Votre lien a bien été récupéré par Homecrush.")
        }
        else {
          showNotif(response.info, "error")
        }
        

        return response;
      })
      .catch(erreur => {
        console.log(erreur);
      });

  }




  return (
    <section className={MainStyles.sousTitre} style={{ paddingBottom: "1.5rem" }}>

      {notif && (
        <NotifModal notif={notif} notifType={notifType} notifMsg={notifMsg} />
      )}

      <p className={MainStyles.mainTitle}>Choisissez votre plateforme</p>

      <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
        <button className={toggleClassAc3 ? MainStyles.btnFiltreActive : MainStyles.btnFiltre} onClick={() => {
          setShowForm("AC3");
          setToggleClassAc3(true);
          setToggleClassHektor(false);
          setToggleClassApimo(false);
        }}><img height="30px" width="auto" src={LogoAc3} /></button>
        <button className={toggleClassHektor ? MainStyles.btnFiltreActive : MainStyles.btnFiltre} onClick={() => {
          setShowForm("HEKTOR");
          setToggleClassAc3(false);
          setToggleClassHektor(true);
          setToggleClassApimo(false);
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 200 43.744">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_5492" data-name="Rectangle 5492" width="200" height="43.744" fill="none" />
              </clipPath>
            </defs>
            <g id="Groupe_20026" data-name="Groupe 20026" transform="translate(0 0)">
              <g id="Groupe_20026-2" data-name="Groupe 20026" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Tracé_34189" data-name="Tracé 34189" d="M9.355,11.827V24.854A7.564,7.564,0,0,1,16,21.635c5.03,0,9.757,3.27,9.757,11.62V47.037h-9.2V33.3c-.1-2.817-1.509-3.672-3.371-3.672-1.811,0-3.823,1.358-3.873,4.275V47.037H0V11.827Z" transform="translate(0 -3.896)" fill="#535354" />
                <path id="Tracé_34190" data-name="Tracé 34190" d="M65.367,36.085c-.252-3.922-8.4-4.073-9,0Zm8.3,5.936H56.311c.655,2.214,3.521,3.169,5.535,3.169a10.3,10.3,0,0,0,6.386-2.012l4.527,5.081a16.439,16.439,0,0,1-11.317,4.124c-7.848,0-14.487-3.873-14.487-12.926s7.092-13.079,13.833-13.079c8.8,0,14.989,5.836,12.876,15.644" transform="translate(-15.466 -8.689)" fill="#535354" />
                <path id="Tracé_34191" data-name="Tracé 34191" d="M105.543,11.827V29.482l5.181-7.293h10.311v.4l-8.7,11.267,10.109,12.625v.553H111.98l-6.437-8.652v8.652H96.237V11.827Z" transform="translate(-31.699 -3.896)" fill="#535354" />
                <path id="Tracé_34192" data-name="Tracé 34192" d="M150.271,11.814V22.335h5.583v7.093h-5.633v8.147a2.206,2.206,0,0,0,2.465,2.414,5.236,5.236,0,0,0,2.715-.854l2.013,6.84a14.255,14.255,0,0,1-7.143,1.66c-4.929,0-9.355-2.264-9.2-10.061V12.82Z" transform="translate(-46.463 -3.892)" fill="#535354" />
                <path id="Tracé_34193" data-name="Tracé 34193" d="M227.189,26.981l.553,2.716a6.659,6.659,0,0,1,6.036-3.32,8.991,8.991,0,0,1,6.54,2.666l-3.721,6.69a5.9,5.9,0,0,0-4.175-1.509c-2.314,0-4.277,1.408-4.277,4.678V51.829h-9.253V26.981Z" transform="translate(-72.099 -8.688)" fill="#535354" />
                <path id="Tracé_34194" data-name="Tracé 34194" d="M270.642,14.454l-4.161,7.206a.522.522,0,0,1-.714.191l-.442-.255a.523.523,0,0,1-.191-.714l4.16-7.206a1.54,1.54,0,0,0,0-1.54l-4.16-7.206a.523.523,0,0,1,.191-.714l.442-.255a.524.524,0,0,1,.714.191l4.16,7.206a1.54,1.54,0,0,0,1.334.77H283.3a1.033,1.033,0,0,0,.895-1.55l-5.525-9.57A2.016,2.016,0,0,0,276.925,0H264.351a2.014,2.014,0,0,0-1.746,1.008L256.317,11.9a2.021,2.021,0,0,0,0,2.017l6.288,10.89a2.016,2.016,0,0,0,1.746,1.008h12.575a2.017,2.017,0,0,0,1.747-1.008l5.525-9.57a1.033,1.033,0,0,0-.895-1.55H271.976a1.54,1.54,0,0,0-1.334.77" transform="translate(-84.337 0)" fill="#bed000" />
                <path id="Tracé_34195" data-name="Tracé 34195" d="M193.234,28.121q-2.889-1.742-9.447-1.744-6.441,0-9.147,1.565-3.912,2.164-3.912,7.882v7.221q0,5.657,3.912,7.823,2.707,1.565,9.147,1.565t9.447-1.746q3.549-2.105,3.55-7.642V35.824q0-5.6-3.55-7.7m1.57,10.038a6.107,6.107,0,0,1-6.278,4.705,7.666,7.666,0,0,1-4.77-1.856,7.666,7.666,0,0,1-4.77,1.856,6.107,6.107,0,0,1-6.278-4.705.1.1,0,0,1,.174-.08,3.731,3.731,0,0,0,3.293,1.559c1.665,0,4.156-1.573,5.058-2.182a.892.892,0,0,1,.749-.117l1.774.519,1.775-.519a.892.892,0,0,1,.749.117c.9.608,3.392,2.182,5.057,2.182a3.732,3.732,0,0,0,3.293-1.559.1.1,0,0,1,.173.08" transform="translate(-56.235 -8.688)" fill="#535354" />
              </g>
            </g>
          </svg>
        </button>
        <button className={toggleClassApimo ? MainStyles.btnFiltreActive : MainStyles.btnFiltre} onClick={() => {
          setShowForm("APIMO");
          setToggleClassAc3(false);
          setToggleClassHektor(false);
          setToggleClassApimo(true);
        }}>
          <svg version="1.1" id="Calque_1" x="0px" y="0px" height="30px" viewBox="0 0 200 67" enable-background="new 0 0 200 67" >
            <g>
              <g>
                <path fill="#84BB3A" d="M63.335,0.001c-11.045,0-20,8.955-20,20c0,0.006,0.001,0.012,0.001,0.019l-0.001,41.648l0.001-0.001
			c0,2.761,2.239,5,5,5c2.762,0,5.001-2.239,5.001-5l0-24.364c2.944,1.705,6.352,2.696,9.999,2.696c11.046,0,20-8.952,20-19.998
			C83.336,8.956,74.381,0.001,63.335,0.001z M63.335,33.333c-7.336,0-9.979-5.926-9.999-13.252v-0.08h-0.001
			c0-7.363,2.637-13.333,10-13.333c7.364,0,10,5.97,10,13.333C73.336,27.365,70.699,33.333,63.335,33.333z"/>
                <path fill="#84BB3A" d="M20,0C8.955,0,0,8.955,0,20c0,11.046,8.955,19.998,20,19.998h3.334c1.841,0,3.333-1.492,3.333-3.333
			s-1.492-3.332-3.333-3.332h0H20C12.636,33.333,10,27.365,10,20C10,12.637,12.636,6.667,20,6.667C27.364,6.667,30,12.638,30,20.001
			l-0.001,15L30,35c0,2.761,2.239,4.999,5,4.999c2.762,0,5-2.238,5-4.999l0.001-14.999C40.001,8.956,31.046,0,20,0z"/>
                <circle fill="#84BB3A" cx="91.656" cy="4.989" r="4.987" />
              </g>
              <g>
                <path fill="#000" d="M180,0.001c-11.046,0-20.001,8.955-20.001,20c0,11.046,8.955,19.998,20.001,19.998s20-8.952,20-19.998
			C200,8.956,191.046,0.001,180,0.001z M180,33.333c-7.363,0-10-5.968-10-13.332c0-7.363,2.637-13.333,10-13.333s10,5.97,10,13.333
			C190,27.365,187.363,33.333,180,33.333z"/>
                <path fill="#000" d="M141.666,0.001c-3.848,0-7.347,1.461-10.002,3.842c-2.654-2.38-6.152-3.841-9.999-3.841
			c-8.285,0.001-15.001,6.717-15.001,15.001v6.667c0,0.003,0.001,0.006,0.001,0.009v3.322h-0.001c0,4.603-0.396,8.333-4.999,8.333
			c-4.6,0-4.997-3.729-4.997-8.333v-6.83c0-2.761-2.237-5-5-5c-2.761,0-5,2.239-5,5v6.83c0,8.284,6.716,14.998,14.996,14.998
			c8.284,0,15-6.714,15-14.998v-6.83c0-0.004-0.001-0.009-0.001-0.014v-3.155l0.001,0.001c0-4.604,0.397-8.335,5-8.334
			c4.591-0.001,4.999,3.713,5.001,8.301c0,0.011-0.002,0.021-0.002,0.032l0.001,19.998c0,2.76,2.238,4.999,5,4.999
			c2.76,0,5-2.239,5-4.999V15.004h0.001v-0.001c-0.001-4.603,0.396-8.334,5-8.334c4.603,0,5,3.731,5,8.333l-0.001,0.001
			l-0.001,19.998c0,2.76,2.239,4.999,5.001,4.998c2.762,0,4.999-2.238,4.999-4.998l0.001-19.998l0.001-0.001
			C156.666,6.718,149.95,0.002,141.666,0.001z"/>
              </g>
            </g>
          </svg>
        </button>
      </div>

      {(rang === "1" || rang === "3") &&
        <article className={MainStyles.form}>

          {(showForm === "AC3" || showForm === "HEKTOR") &&
            <div>
              <p className={MainStyles.labelParagraphFirst}>Ajouter le lien de la plateforme</p>

              <Formik className={MainStyles.formikContainer}
                initialValues={{ lien: '' }}
                validate={values => {
                  const errors = {};
                  if (!values.lien) {
                    errors.lien = 'Obligatoire';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    envoieLienPlateforme("modifierFlux", "flux", values)
                    setSubmitting(false);
                  }, 400);
                }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="lien" placeholder="Lien de la plateforme"
                        onChange={handleChange} onBlur={handleBlur} value={values.lien}
                      />
                    </div>
                    <div className={MainStyles.formError}>{errors.lien && touched.lien && errors.lien}</div>
                    <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>Ajouter</button>
                  </form>
                )}
              </Formik>
            </div>
          }

          {showForm === "APIMO" &&
            <div>

              <p className={MainStyles.labelParagraphFirst}>APIMO</p>
              <Formik className={MainStyles.formikContainer}
                initialValues={{ lien: '' }}
                validate={values => {
                  const errors = {};
                  if (!values.token) {
                    errors.token = 'Obligatoire';
                  }
                  if (!values.idProvider) {
                    errors.idProvider = 'Obligatoire';
                  }
                  if (!values.idApimo) {
                    errors.idApimo = 'Obligatoire';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    console.log("test")
                    verifTokenApimo("verifTokenApimo", values)
                    setSubmitting(false);
                  }, 400);
                }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>

                    <p>Renseignez votre Token Apimo</p>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="token" placeholder="token Apimo"
                        onChange={handleChange} onBlur={handleBlur} value={values.token}
                      />
                    </div>
                    <div className={MainStyles.formError}>{errors.token && touched.token && errors.token}</div>

                    <p>Renseignez votre idProvider</p>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="idProvider" placeholder="idProvider"
                        onChange={handleChange} onBlur={handleBlur} value={values.idProvider}
                      />
                    </div>
                    <div className={MainStyles.formError}>{errors.idProvider && touched.idProvider && errors.idProvider}</div>

                    <p>Renseignez l'ID de votre agence sur apimo</p>
                    <div className={MainStyles.inputBtnContainer}>
                      <input className={MainStyles.formInput}
                        type="input" name="idApimo" placeholder="id Apimo"
                        onChange={handleChange} onBlur={handleBlur} value={values.idApimo}
                      />
                    </div>
                    <div className={MainStyles.formError}>{errors.idApimo && touched.idApimo && errors.idApimo}</div>
                    <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting}>Envoyer</button>
                  </form>
                )}
              </Formik>
            </div>
          }



        </article>
      }
    </section>
  )

};


export default withCookies(ParametreAgence);