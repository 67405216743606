import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Field, Form } from 'formik';
import { withCookies } from 'react-cookie';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import MainStyles from "../../App.module.scss";

import NotifModal from "../NotifModal/NotifModal";
import Connexion from '../Connexion/Connexion';

import Inscription from '../Inscription/Inscription';


const AjoutAnnonce = (props) => {

  const navigate = useNavigate();


  const [showSeConnecterSinscrire, setShowSeConnecterSinscrire] = useState(false);
  const [shouldSendValues, setShouldSendValues] = useState(false);


  const [etape1, setEtape1] = useState(true);
  const [etape2, setEtape2] = useState(false);
  const [etape3, setEtape3] = useState(false);
  const [etape4, setEtape4] = useState(false);
  const [etape5, setEtape5] = useState(false);

  const [notif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [notifType, setNotifType] = useState("");

  const [showInscription, setShowInscription] = useState(true);
  const [showConnexion, setShowConnexion] = useState(false);
  const [showPlateform, setShowPlateform] = useState(true);
  const [showParam, setShowParam] = useState(true);

  const [coproFields, setCoproFields] = useState({
    nb_lot: 0,
    charge_annuelles: 0,
    procedureEnCours: false,
  });
  

  const [bodyyAnnonceNonConnecte, setBodyyAnnonceNonConnecte] = useState({})

  const [monUserCode, setMonUserCode] = useState(undefined);
  const [monRang, setMonRang] = useState('');

//const [errTitre, setErrTitre] = useState('');
//const [errNumeroAgence, setErrNumeroAgence] = useState('');
//const [errVille, setErrVille] = useState('');
//const [errDescription, setErrDescription] = useState('');
//const [errNumeroTel, setErrNumeroTel] = useState('');
//const [errCodePostale, setErrCodePostale] = useState('');
//const [errTypeDeLogement, setErrTypeDeLogement] = useState('');
//const [errsuperficie, setErrsuperficie] = useState('');

  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [progress, setProgress] = useState(0);

  const [acceptedFiles, setAcceptedFiles] = useState([]);

  

  const handleDrop = (newFiles) => {
    const updatedFiles = newFiles.map((file) => {
      return { file, base64String: null };
    });

    Promise.all(
      updatedFiles.map((fileObj) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            fileObj.base64String = reader.result.split(',')[1];
            resolve();
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(fileObj.file);
        });
      })
    )
      .then(() => {
        setAcceptedFiles([...acceptedFiles, ...updatedFiles]);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite lors de la conversion en base 64 :", error);
      });
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...acceptedFiles];
    updatedFiles.splice(index, 1);
    setAcceptedFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (newFiles) => {
      const filteredFiles = newFiles.filter((file) => {
        return file.type === "image/jpeg" || file.type === "image/png";
      });
      handleDrop(filteredFiles);
    }
  });

  const files = acceptedFiles.map((file, index) => (
    <li key={index}>
      {file.file.name} - {file.file.size} bytes
      <button onClick={() => handleRemoveFile(index)}>Supprimer</button>
    </li>
  ));
  
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const style = useMemo(() => ({
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  useEffect(() => {
    var monUserCode = props.cookies.get('user_code');
    var monRang = props.cookies.get('rang');
    setMonUserCode(monUserCode)
    setMonRang(monRang)

    // Mettre à jour la progression en fonction de l'étape actuelle
    if (etape5) {
      setProgress(100);
    } else if (etape4) {
      setProgress(75);
    } else if (etape3) {
      setProgress(50);
    } else if (etape2) {
      setProgress(25);
    } else if (etape1) {
      setProgress(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [etape1, etape2, etape3, etape4, etape5]);


  const showNotif = (message, type) => {
    setNotif(true);
    setNotifMsg(message);
    setNotifType(type);


    setTimeout(() => {
      setNotif(false);
    }, 3000);
  };

  const _ajoutAnnonce = (values) => {
    var bodyy = {
      blossom: "soumettreAnnonce",
    }
    
    if (monUserCode) {
      bodyy.user_code = monUserCode
    }

    if (values.nom) {
      bodyy.nom = values.nom
    } else {
      throw new Error('Le nom est obligatoire');
    }

    if (values.prenom) {
      bodyy.prenom = values.prenom
    }

    if (values.tel) {
      bodyy.tel = values.tel
    } else {
      throw new Error('Le numéro de téléphone est obligatoire');
    }

    if (values.description) {
      bodyy.description = values.description
    }

    if (values.mail) {
      bodyy.mail = values.mail
    } else {
      throw new Error('Le mail est obligatoire');
    }

    var date = ('0' + new Date().getDate()).slice(-2); //Current Date
    var month = ('0' + (new Date().getMonth() + 1)).slice(-2); //Current Month
    var year = new Date().getFullYear(); //Current Year

    bodyy.dateAnnonce = date + '/' + month + '/' + year

    if (values.ville) {
      bodyy.ville = values.ville
    } else {
      throw new Error('La ville est obligatoire');
    }

    if (values.cp) {
      bodyy.cp = values.cp
    } else {
      throw new Error('Le code postal est obligatoire');
    }

    if (values.superficie) {
      bodyy.superficie = values.superficie
    }

    if (typeof values.copropriete !== 'undefined') {
      bodyy.copropriete = values.copropriete
    } else {
      throw new Error('Le champ copropriété est obligatoire');
    }

    if (values.copropriete) {
      if (values.nb_lot === null || values.nb_lot === undefined) {
        throw new Error('Le nombre de lot est obligatoire si le bien est en copropriété');
      } else {
        bodyy.nb_lot = values.nb_lot;
      }
          
      if (values.charge_annuelles === null || values.charge_annuelles === undefined) {
        throw new Error('Les charges annuelles sont obligatoires si le bien est en copropriété');
      } else {
        bodyy.charge_annuelles = values.charge_annuelles;
      }
    
      if (typeof values.procedureEnCours !== 'undefined') {
        bodyy.procedureEnCours = values.procedureEnCours;
      } else {
        throw new Error('Le champ procédure en cours est obligatoire si le bien est en copropriété');
      }
    } else {
      // Si la copropriété est fausse, les valeurs par défaut seront utilisées
      bodyy.nb_lot = 0;
      bodyy.charge_annuelles = 0;
      bodyy.procedureEnCours = false;
    }
    

    if (values.photos) {
      bodyy.photos = values.photos
    }

    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy),
      })
      .then((response) => response.json())
      .then(response => {
        if (response.status === "Succès") {
          showNotif("Votre annonce a bien été enregistrée", "success");
          props.clickHandler("succès")
        } else {
          addNotif("Une erreur est survenue lors de l'ajout de votre annonce")
        }
      })
      .catch(erreur => {
        console.log('erreur ajout annonce: ' + erreur);
      });
}



  const _ajoutAnnonceNonConnecte = (user_code) => {
    var bodyy = {
      blossom: "soumettreAnnonce",
      user_code: user_code,
      nom: "",  // Ajoutez la valeur appropriée
      prenom: "",  // Ajoutez la valeur appropriée
      tel: "",  // Ajoutez la valeur appropriée
      description: "", // Ajoutez la valeur appropriée
      mail: "", // Ajoutez la valeur appropriée
      dateAnnonce: "", // Ajoutez la valeur appropriée
      ville: "", // Ajoutez la valeur appropriée
      superficie: "", // Ajoutez la valeur appropriée
      copropriete: "", // Ajoutez la valeur appropriée
      nb_lot: "", // Ajoutez la valeur appropriée si copropriété est vraie
      charge_annuelles: "", // Ajoutez la valeur appropriée si copropriété est vraie
      procedureEnCours: "", // Ajoutez la valeur appropriée si copropriété est vraie
      photos: [], // Ajoutez la valeur appropriée
      cp: "", // Ajoutez la valeur appropriée
    }
  
    if (bodyy.copropriete) {
      bodyy.nb_lot = ""; // Ajoutez la valeur appropriée si copropriété est vraie
      bodyy.charge_annuelles = ""; // Ajoutez la valeur appropriée si copropriété est vraie
      bodyy.procedureEnCours = ""; // Ajoutez la valeur appropriée si copropriété est vraie
    }
  
    // Ajoutez le reste des champs obligatoires et facultatifs ici, en fonction des valeurs fournies dans le formulaire
    // ...
  
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy),
      })
      .then(response => {
        if (response.statusText === "OK") {
          props.clickHandler("succès")
          props.refreshInfosVente(false)
        } else {
          addNotif("Une erreur est survenue lors de l'ajout de votre annonce")
        }
      })
      .catch(erreur => {
        console.log('erreur ajout annonce: ' + erreur);
      });
  }
  

  const addNotif = message => {
    /*
    const notification = notificationSystem.current;
    notification.addNotification({
      message: message,
      level: 'success'
    });*/
  };

  const toggle = () => {

    showNotif("Il ne vous reste plus qu'à vous authentifier !", "success");
    showSeConnecterSinscrire ? setShowSeConnecterSinscrire(false) : setShowSeConnecterSinscrire(true)
  }
  const createBodyy = () => {
    setBodyyAnnonceNonConnecte({ "blossom": "soumettreAnnonce", "titre": "testJeudi", "rue": "1 avenue du prado", "ville": "marseille", "description": "description", "numeroTel": "1029384756", "date": "28/04/2022", "codePostale": "12344", "typeDeLogement": "maison", "superficie": "123" })
    toggle()
  }

  const recupUserCode = (user_code) => {
    setMonUserCode(user_code)

    if (user_code) {
      _ajoutAnnonceNonConnecte(user_code)
    }
  }
  const alertUserCode = (user_code) => {
    alert(user_code)
  }



  return (
    <article style={{ marginTop: "2rem", marginBottom: "4rem", width: "100%" }}>
      {/*<NotificationSystem ref={notificationSystem} style={notifStyles} />*/}

      <h1 className={MainStyles.pageTitle}>Un professionnel de votre secteur vous contactera.</h1>
      {!showSeConnecterSinscrire &&
        <h2 className={MainStyles.pageSubTitle}>Pour faire estimer ou vendre votre bien, confiez-le à un professionnel de votre secteur. Un professionnel vous contactera pour une visite sur place si désiré.</h2>
      }
      {showSeConnecterSinscrire &&
        <h2 className={MainStyles.pageSubTitle}>Connectez-vous / Inscrivez vous pour que votre bien soit enregistrer et qu'un professionnel vous contacte. C'est gratuit !</h2>
      }
      
      {props.nonConnecte &&
        <div>
          {/*
          <p>{JSON.stringify(bodyyAnnonceNonConnecte)}</p>
          <p>{JSON.stringify(monUserCode)}</p>
          <button onClick={() =>
            showNotif("Votre annonce a bien été enregistrée", "success")}>toggle</button>
          <button onClick={() => createBodyy()}>createBodyy</button>
          <button onClick={() => alertUserCode()}>alertUserCode</button>
          <button onClick={() => props.refreshInfosVente(false)}>nonConnecte</button>
          */}
          {notif && (
            <NotifModal notif={notif} notifType={notifType} notifMsg={notifMsg} />
          )}
          {showSeConnecterSinscrire &&
            <div>
              <div className={MainStyles.buttonBar} style={{ marginBottom: "80px" }}>
                <span className={MainStyles.btnFiltre}
                  style={{
                    backgroundColor: showInscription ? '#0DA8DB' : 'transparent',
                    color: showInscription ? '#fff' : '#BDA87D',
                  }}
                  onClick={() => {
                    setShowInscription(true)
                    setShowConnexion(false)
                    setShowPlateform(false)
                    setShowParam(false)
                  }
                  }>Inscription</span>

                <span className={MainStyles.btnFiltre}
                  style={{
                    backgroundColor: showConnexion ? '#0DA8DB' : 'transparent',
                    color: showConnexion ? '#fff' : '#BDA87D',
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {


                    setShowInscription(false)
                    setShowConnexion(true)
                    setShowPlateform(false)
                    setShowParam(false)
                  }}>Connexion</span>
              </div>
            </div>
          }
        </div>
      }<article style={{ marginTop: "4rem", marginBottom: "4rem", width: "100%" }}>
      {!showSeConnecterSinscrire && (
        <Formik
        initialValues={{
          nom: "",
          prenom: "",
          tel: "",
          mail: "",
          ville: "",
          description: "",
          superficie: "",
          copropriete: "non",
          nb_lot: 0, 
          charge_annuelles: 0,
          procedureEnCours: false,
          photos: [],
          cp: "",
        }}        
        validate={(values) => {
          const errors = {};
          if (etape1) {
            if (!values.nom) {
              errors.nom = "Ce champ est obligatoire";
            }
            if (!values.tel) {
              errors.tel = "Ce champ est obligatoire";
            }
            if (!values.mail) {
              errors.mail = "Ce champ est obligatoire";
            }
          }
      
          if (etape2) {
            if (!values.ville) {
              errors.ville = "Ce champ est obligatoire";
            }
            if (values.copropriete === "oui" && !values.nb_lot) {
              errors.nb_lot = "Ce champ est obligatoire";
            }
            if (values.copropriete === "oui" && !values.charge_annuelles) {
              errors.charge_annuelles = "Ce champ est obligatoire";
            }
            if (!values.procedureEnCours) {
              errors.procedureEnCours = "Ce champ est obligatoire";
            }
            if (!values.cp) {
              errors.cp = "Ce champ est obligatoire";
            } 
          }
    
          //Ajouter les autres validations ici, en fonction des valeurs fournies dans le formulaire
          return errors;
        }}
          
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // Fusion des valeurs de votre formulaire avec l'état `coproFields`
            const finalValues = {
              ...values,
              ...coproFields,
            };
        
            // Conversion de 'copropriete' en booléen
            finalValues.copropriete = finalValues.copropriete === 'oui' ? true : false;
        
            if (finalValues.description === '') {
              finalValues.description = "Pas de description";
            }
        
            if (finalValues.superficie === '') {
              finalValues.superficie = "0";
            }
        
            if (!values.copropriete) {
              finalValues.nb_lot = 0;
              finalValues.charge_annuelles = 0;
              finalValues.procedureEnCours = "Pas en copropriété";
            }
        
            // Ajoutez chaque photo au tableau `finalValues.photos`
            for (let fileObj of acceptedFiles) {
              const photo = {
                format: fileObj.file.type.split('/')[1],
                width: fileObj.file.width, // Ajustez selon les métadonnées de l'image si disponibles
                height: fileObj.file.height, // Ajustez selon les métadonnées de l'image si disponibles
                base64: 'data:' + fileObj.file.type + ';base64,' + fileObj.base64String,
              };
              finalValues.photos.push(photo);
            }
        
            if (etape3) {
              // Validation des données à l'étape 3
              if (finalValues.nom && finalValues.tel && finalValues.superficie && finalValues.adresse) {
                // Soumission du formulaire à l'étape 3
                _ajoutAnnonce(finalValues); // Utilisez `finalValues` au lieu de `values`
                setSubmitting(false);
        
                // Passage à l'étape 4
                setEtape3(false);
                setEtape4(true);
              } else {
                // Gérer les erreurs de validation à l'étape 3
                // Vous pouvez afficher un message d'erreur ou effectuer d'autres actions nécessaires
                setSubmitting(false);
              }
            } else if (etape4) {
              // Soumission finale du formulaire à l'étape 4
              _ajoutAnnonce(finalValues); // Utilisez `finalValues` au lieu de `values`
              setSubmitting(false);
        
              // Passage à l'étape 5
              setEtape4(false);
              setEtape5(true);
            } else {
              // Passage à l'étape suivante (étape 2)
              setEtape1(false);
              setEtape2(true);
              setSubmitting(false);
            }
          }, 400);
        }}        

        >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  alignItems: 'center', marginTop :'-5vh' }}>
          <form onSubmit={handleSubmit} className={MainStyles.form} style={{minHeight: '500px', height: 'fit-content', maxWidth: '500px', width: '100%', margin: '0', marginRight: 0, background: 'linear-gradient(to bottom, #544E7B, #3F3A63)', padding: '20px', zIndex: 1, position: 'relative', paddingBottom: '0px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }}>
            <div className={MainStyles.progressBarContainer}>
              <div className={MainStyles.progressBar} style={{ width: `${progress}%` }}>
            </div>
            </div>
              {etape1 && (
                <div>
                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="nom"
                      placeholder="Nom *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nom}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: Dupont</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.nom && touched.nom && errors.nom}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="prenom"
                      placeholder="Prénom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prenom}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: Jean</span>
                    </div>
                  </div>

                  {values.prenom === "" && (
                    <input type="hidden" name="prenom" value={null} />
                  )}

                  <div className={MainStyles.formError}>
                    {errors.prenom && touched.prenom && errors.prenom}
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="tel"
                      placeholder="Téléphone *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: 0123456789</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.tel && touched.tel && errors.tel}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="mail"
                      placeholder="Email *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mail}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: example@mail.com</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.mail && touched.mail && errors.mail}</div>

                  <button 
                    className={MainStyles.nextButton} 
                    type="button" 
                    onClick={() => {
                      if (values.nom && values.tel && values.mail) {
                        setEtape1(false);
                        setEtape2(true);
                      
                    }}
                  }
                  >
                    Suivant
                  </button>
                  <p className={MainStyles.formErrorMdp}>Les champs suivis d'un * sont obligatoires.</p>
                </div>
              )}

              {etape2 && (
                <div>
                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="description"
                      placeholder="Description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex:  </span>
                    </div>
                    {values.description === "" && (
                    <input type="hidden" name="description" value={null} />
                  )}
                  </div>
                  <div className={MainStyles.formError}>{errors.description && touched.description && errors.description}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="superficie"
                      placeholder="superficie"
                      type="number"
                      pattern="[0-9]*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.superficie}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: 100 m²</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.superficie && touched.superficie && errors.superficie}</div>


                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="ville"
                      placeholder="Ville *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ville}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: Belle maison T4 dans une résidence calme...</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.ville && touched.ville && "Ce champ est obligatoire"}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem", display: "block" }}
                      name="cp"
                      type="number"
                      pattern="\d{5}"
                      maxLength="5"
                      placeholder="Code postal *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cp}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: 13001 </span>
                    </div>
                  </div>

                  <div className={MainStyles.formError}>{errors.cp && touched.cp && "Ce champ est obligatoire"}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="adresse"
                      placeholder="Adresse"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.adresse}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: 1 avenue du Prado</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.adresse && touched.adresse && errors.adresse}</div>


                  <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
                    <span>Copropriété :</span>
                    <label>
                      <Field
                        type="radio"
                        name="copropriete"
                        value="oui"
                        checked={values.copropriete === "oui"}
                        onChange={handleChange}
                      />
                      Oui
                    </label>
                    <label>
                    <Field
                      type="radio"
                      name="copropriete"
                      value="non"
                      checked={values.copropriete === "non"}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === "non") {
                          setCoproFields({
                            nb_lot: 0,
                            charge_annuelles: 0,
                            procedureEnCours: false,
                          });
                        }
                      }}
                    />
                      Non
                    </label>
                  </div>
                  <div className={MainStyles.formError}>{errors.copropriete && touched.copropriete && errors.copropriete}</div>

                  {values.copropriete === "oui" ? (
                    <div>
                      <div style={{ display: "flex" }}>
                        <input
                          className={MainStyles.formInput}
                          style={{ marginTop: "2rem" }}
                          name="nb_lot"
                          placeholder="Nombre de lots"
                          type="number"
                          pattern="[0-9]*"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nb_lot}
                        />
                        <div className={MainStyles.tooltipHelper}>
                          i
                          <span className={MainStyles.tooltipTextHelper}>Ex: 10</span>
                        </div>
                      </div>
                      <div className={MainStyles.formError}>{errors.nb_lot && touched.nb_lot && errors.nb_lot}</div>

                      <div style={{ display: "flex" }}>
                        <input
                          className={MainStyles.formInput}
                          style={{ marginTop: "2rem" }}
                          name="charge_annuelles"
                          placeholder="Charges annuelles"
                          type="number"
                          pattern="[0-9]*"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.charge_annuelles}
                        />
                        <div className={MainStyles.tooltipHelper}>
                          i
                          <span className={MainStyles.tooltipTextHelper}>Ex: 1000 €</span>
                        </div>
                      </div>
                      <div className={MainStyles.formError}>
                        {errors.charge_annuelles && touched.charge_annuelles && errors.charge_annuelles}
                      </div>

                      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
                        <span>Procédure :</span>
                        <label>
                          <Field
                            type="radio"
                            name="procedureEnCours"
                            value="oui"
                            checked={values.procedureEnCours === "oui"}
                            onChange={handleChange}
                          />
                          Oui
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="procedureEnCours"
                            value="non"
                            checked={values.procedureEnCours === "non"}
                            onChange={handleChange}
                          />
                          Non
                        </label>
                      </div>
                      <div className={MainStyles.formError}>{errors.procedureEnCours && touched.procedureEnCours && errors.procedureEnCours}</div>
                    </div>
                  ) : null}
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <button
                    className={MainStyles.prevButton}
                    type="button"
                    onClick={() => {
                      setEtape2(false);
                      setEtape1(true);
                    }}
                  >
                    &#8592;
                  </button>
                  <button
                    className={MainStyles.nextButton}
                    type="submit"
                    onClick={() => {
                      if (values.ville && values.copropriete && values.cp && (values.copropriete === 'non' || (values.nb_lot && values.charge_annuelles && values.procedureEnCours))) {
                        setEtape2(false);
                        setEtape3(true);
                      }
                    }}
                  >
                    Suivant
                  </button>
                </div>
                <p className={MainStyles.formErrorMdp}>Les champs suivis d'un * sont obligatoires.</p>
              </div>
              )}

              {etape3 && (
                <div>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Glissez et déposez des fichiers ici ou cliquez pour sélectionner</p>
                  </div>
                  <aside>
                    <h4>Fichiers</h4>
                    <ul>{files}</ul>
                  </aside>

                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <button
                    className={MainStyles.prevButton}
                    type="button"
                    onClick={() => {
                      setEtape3(false);
                      setEtape2(true);
                    }}
                    disabled={isSubmitting}
                  >
                    &#8592;
                  </button>
                  <button
                    className={MainStyles.nextButton}
                    type="submit"
                    onClick={() => {
                      setEtape3(false);
                      setEtape4(true);
                    }}
                  >
                    Suivant
                  </button>
                </div>
                <p className={MainStyles.formErrorMdp}>Les champs suivis d'un * sont obligatoires.</p>
              </div>
              )}

              {etape4 && (
                <div style={{ color: 'white' }}>
                  <h2>Récapitulatif des données</h2>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Nom : {values.nom}</span>
                    <span>Surface : {values.superficie} m²</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <span>Téléphone : {values.tel}</span>
                    <span>Code postal : {values.cp}</span>
                  </div>

                  <p>Images:</p>
                  <div style={{ display: 'flex', marginTop: '2rem', maxWidth: '300px', margin: '0 auto', marginBottom: '2rem' }}>
                    {acceptedFiles.length === 1 && (
                      <div style={{ width: '100%', height: '200px', margin: '0 auto' }}>
                        <img src={`data:image/jpeg;base64,${acceptedFiles[0].base64String}`} alt="Main" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                    )}

                    {acceptedFiles.length === 2 && (
                      <>
                        <div style={{ width: '50%', height: '200px', marginRight: '1rem' }}>
                          <img src={`data:image/jpeg;base64,${acceptedFiles[0].base64String}`} alt="First" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                        <div style={{ width: '50%', height: '200px' }}>
                          <img src={`data:image/jpeg;base64,${acceptedFiles[1].base64String}`} alt="Second" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                      </>
                    )}

                    {acceptedFiles.length >= 3 && (
                      <>
                        <div style={{ width: '200px', height: '200px', marginRight: '1rem' }}>
                          <img src={`data:image/jpeg;base64,${acceptedFiles[0].base64String}`} alt="Main" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                        <div style={{ width: '100px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                          <div style={{ height: '95px' }}>
                            <img src={`data:image/jpeg;base64,${acceptedFiles[1]?.base64String}`} alt="Secondary 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                          </div>
                          <div style={{ height: '95px' }}>
                            <img src={`data:image/jpeg;base64,${acceptedFiles[2]?.base64String}`} alt="Secondary 2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <button
                    className={MainStyles.prevButton}
                    type="button"
                    onClick={() => {
                      setEtape4(false);
                      setEtape3(true);
                    }}
                    disabled={isSubmitting}
                  >
                  &#8592;
                  </button>
                  <button
                    className={MainStyles.nextButton}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Valider
                  </button>
                </div>
                <p className={MainStyles.formErrorMdp}>Les champs suivis d'un * sont obligatoires.</p>
              </div>
              )}
              {etape5 && (
                <div>
                  <p>Merci de votre confiance ! Un professionnel vous contactera rapidement.</p>
                  <button onClick={() => navigate('/')}>Retour à l'accueil</button>
                  {/*<button onClick={() => navigate('/InfosVentes')}>Aller à la page d'estimation</button>*/}
                </div>
              )}
            </form>
            {/* Sidebar des étapes */}
            <div className={MainStyles.sidebar_step} style={{height:"400px", overflow: "auto"}}>
              <div className={etape1 ? MainStyles.activeStep : MainStyles.step}>
                  <div className={MainStyles.stepContent}>
                      <div className={MainStyles.stepDot}></div>
                      <div className={MainStyles.stepText}>Vos informations</div>
                  </div>
                  {etape1 && <p>Système de diffusion et de recherche d'annonces immobilières de particuliers.</p>}
              </div>

            <div className={etape2 ? MainStyles.activeStep : MainStyles.step}>
                <div className={MainStyles.stepContent}>
                    <div className={MainStyles.stepDot}></div>
                    <div className={MainStyles.stepText}>Caractéristiques</div>
                </div>
                {etape2 && <p>Système de diffusion et de<br></br> recherche d'annonces.</p>}
            </div>
            <div className={etape3 ? MainStyles.activeStep : MainStyles.step}>
                <div className={MainStyles.stepContent}>
                    <div className={MainStyles.stepDot}></div>
                    <div className={MainStyles.stepText}>Images</div>
                </div>
                {etape3 && <p>Système de diffusion et de<br></br> recherche d'annonces.</p>}
            </div>
            <div className={etape4 ? MainStyles.activeStep : MainStyles.step}>
                <div className={MainStyles.stepContent}>
                    <div className={MainStyles.stepDot}></div>
                    <div className={MainStyles.stepText}>Validation</div>
                </div>
                {etape4 && <p>Système de diffusion et de<br></br> recherche d'annonces.</p>}
            </div>
          </div>
        </div>
        )}
          
        </Formik>
        )}
         </article>
     </article>
 )};

export default withCookies(AjoutAnnonce);