import React, { useState, useEffect, useRef } from 'react';

import maMapStyles from './maMap.module.scss'
import { Link } from "react-router-dom";

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


var ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' }
];

function formatNumber(n) {
  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      return (n / ranges[i].divider).toString() + ranges[i].suffix;
    }
  }
  return n.toString();
}

const styles = {
  width: "100%",
  height: "60vh",
  borderRadius: "1rem",
  border: "1px solid #BDA87D"
};

const MaMap = (props) => {
  var location = props.location


  const [selected, setSelected] = useState(null);

  var markers = [];

  const map = useRef(null);
  const mapContainer = useRef(null);


  // Create a popup
  var popup = new mapboxgl.Popup();
  // Set an event listener that will fire
  // any time the popup is opened
  popup.setHTML("<h3>Vous êtes ici</h3>")


  var positionMarker = new mapboxgl.Marker({ color: "#BDA87D" })
    .setLngLat([location.coords.longitude, location.coords.latitude])


  const initializeMap = ({ mapContainer }) => {

    map.current = new mapboxgl.Map({
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      container: mapContainer.current,
      style: "mapbox://styles/perroquet/ck6ban4ya36pm1imgjjtioomg", // stylesheet location
      center: [location.coords.longitude, location.coords.latitude],
      zoom: 10,
      //minZoom: 5,
      maxZoom: 13,
      scrollZoom:false,//eviter intéraction au scroll de l'utilisateur
    });
    
    positionMarker.setPopup(popup) // add popup
      .addTo(map.current);

    map.current.on("load", () => {
      map.current.resize();
    });
  }

useEffect(() => {
  // Supprimez tous les marqueurs existants
  markers.forEach(marker => marker.remove());
  markers = []; // Réinitialiser le tableau des marqueurs après les avoir supprimés

  if (!map.current) initializeMap({ mapContainer });

  let tableauPosition = [];
  
  // Vérifiez si props.echantillonRecu est un tableau avant de continuer
  if (Array.isArray(props.echantillonRecu)) {
    props.echantillonRecu.forEach((bien, i) => {
      // Créez un div pour chaque marqueur
      var markerDiv = document.createElement('div');
      markerDiv.className = maMapStyles.marker;
      
      // Formattez et ajoutez le prix au div du marqueur
      var tempPrice = parseInt(bien.prix);
      var prixFactor = formatNumber(tempPrice);
      markerDiv.innerHTML = `<span>${prixFactor}€</span>`;

      // Définir la longitude et la latitude pour le marqueur
      let lng = bien.coords ? parseFloat(bien.coords.lng) : parseFloat(bien.longitude);
      let lat = bien.coords ? parseFloat(bien.coords.lat) : parseFloat(bien.latitude);

      // Ajoutez des positions valides au tableauPosition
      if (!isNaN(lng) && !isNaN(lat)) {
        tableauPosition.push([lng, lat]);
        
        // Création du marqueur et ajout au div précédent
        markers[i] = new mapboxgl.Marker(markerDiv)
          .setLngLat([lng, lat])
          .addTo(map.current);
        
        // Ajout de l'événement 'click' au div du marqueur
        markerDiv.addEventListener('click', (e) => {          
          // suppression parametre curve qui empeche le flyTo de fonctionner avec un zoom en même temps
          map.current.flyTo({ center: [lng, lat], zoom: 12, speed: 0.5 });          
          setSelected(bien);
        });
      }
    });
  } else {
    console.log("props.echantillonRecu n'est pas un tableau");
  }
  
  // Ajustez la vue de la carte pour afficher tous les marqueurs
  if (tableauPosition.length > 1) {
    var bounds = tableauPosition.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(tableauPosition[0], tableauPosition[0]));
    map.current.fitBounds(bounds, {
      padding: { top: 50, bottom: 50, left: 50, right: 50 },
      easing(t) {
        return t * (2 - t);
      }
    });
  } else if (tableauPosition.length === 1) {    
    map.current.flyTo({ center: tableauPosition[0], speed: 0.2, curve: 0 });
  }
}, [props.echantillonRecu]);

  var location = props.location


  return (
    <div className={maMapStyles.mapFlexContainer}>
      <div style={{ flex: 1 }}>
        <div style={{ flexDirection: "row", display: "flex", position: "relative" }}>
          <div style={{ width: "-webkit-fill-available" }} ref={el => (mapContainer.current = el)} style={styles} />
          <div style={{
            display: "flex", flexDirection: "row", position: "absolute",
            top: "0",
            right: "0",
            height: "-webkit-fill-available"
          }}>
            <div className={maMapStyles.btnCarteContainer}>
              <div className={maMapStyles.btnCarte} onClick={() => { map.current.setCenter([location.coords.longitude, location.coords.latitude]) }}>
                <svg style={{ padding: "0.5rem" }} width="100%" id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#fff"}>
                    <path d="M108.42,29.86A38.86,38.86,0,0,0,94.63,18.73a42.29,42.29,0,0,0-35.07.05A38.92,38.92,0,0,0,45.8,29.94a54.81,54.81,0,0,0-10.74,34,68.76,68.76,0,0,0,4.78,21.88A246.45,246.45,0,0,0,56,120.1c5.47,9.83,10,16.89,10.48,17.65l1.28.28,6.33-4.1.3-1.28c-.07-.1-7.69-11.9-15.14-26.39a198.46,198.46,0,0,1-11-24.92,56,56,0,0,1-3.75-17.42,45.23,45.23,0,0,1,8.71-28.1,29.47,29.47,0,0,1,10.48-8.48,32.56,32.56,0,0,1,26.86,0A29.51,29.51,0,0,1,101,35.76a45.35,45.35,0,0,1,8.79,28.08,56.27,56.27,0,0,1-3.7,17.42,200.81,200.81,0,0,1-11,25c-7.43,14.5-15,26.32-15.05,26.43l.27,1.28L86.7,138l1.28-.27c.64-1,8.45-13.23,16.09-28.33a212,212,0,0,0,10.44-23.72,68.57,68.57,0,0,0,4.71-21.89A54.75,54.75,0,0,0,108.42,29.86Zm-36.09,103h0l-.14-.22Z" />
                    <path d="M92.05,60.41A14.94,14.94,0,1,0,87.69,71,14.94,14.94,0,0,0,92.05,60.41Zm-18.77,3.9a5.47,5.47,0,1,1,7.74,0A5.48,5.48,0,0,1,73.28,64.31Z" />
                  </g>
                </svg>
              </div>
              <div className={maMapStyles.btnCarte} onClick={() => { map.current.zoomIn({ duration: 1000 }) }}>
                <svg style={{ padding: "0.5rem" }} width="100%" id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#fff"}>
                    <polygon points="78.8 25.5 71.2 25.5 70.25 26.45 70.25 123.55 71.2 124.5 78.8 124.5 79.75 123.55 79.75 26.45 78.8 25.5" />
                    <polygon points="62.28 70.25 26.45 70.25 25.5 71.2 25.5 78.8 26.45 79.75 62.28 79.75 63.23 78.8 63.23 71.2 62.28 70.25" />
                    <polygon points="123.55 70.25 87.72 70.25 86.77 71.2 86.77 78.8 87.72 79.75 123.55 79.75 124.5 78.8 124.5 71.2 123.55 70.25" />
                  </g>
                </svg>
              </div>
              <div className={maMapStyles.btnCarte} onClick={() => { map.current.zoomOut({ duration: 1000 }) }}>
                <svg style={{ padding: "0.5rem" }} width="100%" id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                  <g fill={"#fff"}>
                    <polygon points="123.55 70.25 26.45 70.25 25.5 71.2 25.5 78.8 26.45 79.75 123.55 79.75 124.5 78.8 124.5 71.2 123.55 70.25" />
                  </g>
                </svg>
              </div>

            </div>
            <div>
              {selected &&
                <div style={{
                  width: "18rem",
                  height: "fit-content"
                }} className={maMapStyles.selectedContainer}>
                  <img 
                      alt={"façade du bien N°" + selected.id} 
                      src={selected.image && selected.image.length > 0 
                          ? `https://${selected.image[0]}` 
                          : selected.photoExternalLink && selected.photoExternalLink.length > 0 
                          ? selected.photoExternalLink[0] 
                          : "https://api.homecrush.fr/imgDefaut/default.jpeg"} 
                      style={{ width: "100%", maxHeight: "12rem", objectFit: "cover" }} 
                  />
                  <div className={maMapStyles.infosContainer}>
                    <div style={{ borderBottom: "1px solid #BDA87D", marginBottom: "1rem" }}>
                      <span className={maMapStyles.price}>{new Intl.NumberFormat('fr-FR', { style: 'currency', maximumFractionDigits: 0, currency: 'EUR' }).format(selected.prix)}</span>
                      <span className={maMapStyles.surface}> - {selected.surface} m²</span>
                    </div>
                    <div className={maMapStyles.ville}>{selected.ville}</div>

                    <div>
                      <svg width="2rem" id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                        <g fill={"#0DA8DB"}>
                          <polygon points="42.61 63.89 42.61 63.89 42.61 63.88 42.61 63.89" />
                          <path d="M108.42,29.86A38.86,38.86,0,0,0,94.63,18.73a42.29,42.29,0,0,0-35.07.05A38.92,38.92,0,0,0,45.8,29.94a54.81,54.81,0,0,0-10.74,34,68.76,68.76,0,0,0,4.78,21.88A246.45,246.45,0,0,0,56,120.1c5.47,9.83,10,16.89,10.48,17.65l1.28.28,6.33-4.1.3-1.28c-.07-.1-7.69-11.9-15.14-26.39a198.46,198.46,0,0,1-11-24.92,56,56,0,0,1-3.75-17.42,45.23,45.23,0,0,1,8.71-28.1,29.47,29.47,0,0,1,10.48-8.48,32.56,32.56,0,0,1,26.86,0A29.51,29.51,0,0,1,101,35.76a45.35,45.35,0,0,1,8.79,28.08,56.27,56.27,0,0,1-3.7,17.42,200.81,200.81,0,0,1-11,25c-7.43,14.5-15,26.32-15.05,26.43l.27,1.28L86.7,138l1.28-.27c.64-1,8.45-13.23,16.09-28.33a212,212,0,0,0,10.44-23.72,68.57,68.57,0,0,0,4.71-21.89A54.75,54.75,0,0,0,108.42,29.86Zm-36.09,103h0l-.14-.22Z" />
                          <path d="M92.05,60.41A14.94,14.94,0,1,0,87.69,71,14.94,14.94,0,0,0,92.05,60.41Zm-18.77,3.9a5.47,5.47,0,1,1,7.74,0A5.48,5.48,0,0,1,73.28,64.31Z" />
                        </g>
                      </svg>

                      <div className={maMapStyles.distou}>{selected.distance ? selected.distance.toFixed(2) : "N/A"} km</div>

                    </div>

                  </div>
                  <Link
                    className={maMapStyles.btn}
                    to={{
                      pathname: selected.showlink,
                      state: {
                        data: selected,
                      }
                    }}
                  >
                    Coordonnées du vendeur
                  </Link>
                </div>

              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default MaMap