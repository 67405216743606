import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MainStyles from "../../App.module.scss";


const CarouselComp = (props) => {

  const encartPub: CSSProperties = {
    cursor: "pointer",
  };
  const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 40,
    height: 40,
    fontSize: '16pt',
    cursor: 'pointer',
    background: 'white',
    border: '1px solid #bda87d',
    borderRadius: 50,
  };
  const indicatorStyles: CSSProperties = {
    background: '#fff',
    width: 14,
    height: 14,
    borderRadius: 14,
    border: "1px solid #BDA87D",
    display: 'inline-block',
    margin: '0 8px',
    position: "relative",
    bottom: "20px"
  };

  const arrowPubStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    bottom: 10,
    width: 40,
    height: 40,
    fontSize: '16pt',
    cursor: 'pointer',
    background: 'white',
    border: '1px solid #bda87d',
    borderRadius: 50,
  };
  const indicatorPubStyles: CSSProperties = {
    background: '#fff',
    width: 14,
    height: 14,
    borderRadius: 14,
    border: "1px solid #BDA87D",
    display: 'inline-block',
    margin: '0 8px',
    position: "relative",
    bottom: 10,
  };


  const [selected, setSelected] = React.useState(0);

  React.useEffect(() => {
    setTimeout(function () {
      setSelected(1)
    }, 2500);
  }, [props]);

  return (
    <div>
      {!props.source &&
        <Carousel
          zIndex={1}
          showThumbs={false}
          infiniteLoop
          // dynamicHeight
          statusFormatter={(current, total) => ``}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                {"<"}
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                {">"}
              </button>
            )
          }
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{ ...indicatorStyles, background: '#0DA8DB', border: "3px solid white ", position: "relative", bottom: "20px" }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                style={indicatorStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >

          {props.state.length >= 1 &&
            props.state.map((image, index) => {
              if(!props.state[index].match(/http/))
                props.state[index] = "https://" + props.state[index];
              return <a href={image.url ? image.url : "https://google.com"} target="_blank" rel="noreferrer" key={index}>
                {props.state[index].length >= 11 &&
                  <img className={MainStyles.CarouselMobile} alt={'photo' + index} src={props.state[index]} />
                }
                {props.state[index].length <= 11 &&
                  <img className={MainStyles.CarouselMobile} alt={'photo' + index} src={process.env.PUBLIC_URL + '/images/default.jpeg'} />
                }
              </a>
            })
          }

        </Carousel>
      }

      {props.source && props.state &&
        <Carousel
          zIndex={1}
          selectedItem={selected}
          //autoPlay={props.autoPlay}
          interval={2500}
          showThumbs={false}
          infiniteLoop
          statusFormatter={(current, total) => ``}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowPubStyles, left: 10 }}>
                {"<"}
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowPubStyles, right: 10 }}>
                {">"}
              </button>
            )
          }
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li style={{ ...indicatorPubStyles, background: '#0DA8DB', border: "3px solid white ", position: "relative", }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                style={indicatorPubStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >

          {props.state.length >= 1 &&
            props.state.map((image, index) => {
              if(!props.state[index].image.match(/http/))
                props.state[index].image = "https://" + props.state[index].image;
              return (
                <div style={encartPub} onClick={() => window.open(image.url ? image.url : "https://google.com")} target="_blank" rel="noreferrer" key={index}>
                  <img alt={'pub ' + index} style={{ /*Mediaqueries de 20rem pour le mobile*/height: "100%", width: "100%", objectFit: "contain" }} src={props.state[index].image} />
                  <div className="legend"><p>{props.state[index].titre}</p></div>
                </div>
              )
            })
          }
        </Carousel>
      }
    </div>
  );
};


export default CarouselComp;