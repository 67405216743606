import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { withCookies } from "react-cookie";

import Select from "react-select-oss";
import Geocode from "react-geocode";

import AjoutAnnonce from "../components/AjoutAnnonce/AjoutAnnonce";

import MainStyles from "../App.module.scss";

//import NotificationSystem from "react-notification-system";
import notifStyles from "../components/NotifStyles";
import Footer from "../components/Footer/Footer";


import { Formik, Field } from 'formik';

import ConnexionStyles from '../components/Connexion/Connexion.module.scss'


const MotDePasseOublie = (props) => {


    const [nonConnecte, setNonConnecte] = useState(true);


    const [ville, setVille] = useState(undefined);


    const [showMessageEnvoye, setShowMessageEnvoye] = useState(false);

    useEffect(() => {

    }, []);

    const _lancerRequete = (emailRecup) => {
        var bodyy = {
            blossom: "resetPass",
            email: emailRecup,
            user_code: process.env.REACT_APP_USERCODE_HOMECRUSH
        };
        // Récupération des données de l'API
        fetch("https://api.homecrush.fr/control_room.php", {
            method: "POST",
            body: JSON.stringify(bodyy),
        })
            .then((response) => response.json())
            .then((response) => {
                return response;
            })
            .catch((erreur) => {
                console.log("ERROR: " + erreur);
            });

    }

    const changerVue = () => {
    }

    return (
        <section style={{ width: "100%" }}>

            <article className={MainStyles.pageContainer}>

                <h1 className={MainStyles.pageTitle}>Mot de passe oublié</h1>
                {!showMessageEnvoye &&

                    <section className={ConnexionStyles.formContainer} style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        borderTop: "1px solid rgb(189, 168, 125)",
                        borderLeft: "1px solid rgb(189, 168, 125)",
                        borderRight: "1px solid rgb(189, 168, 125)", borderRadius: '1rem'
                    }}>
                        <article>
                            <svg id="Calque_1" style={{ height: "auto", width: "12rem" }} data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 741.8 249.56">
                                <g fill={"#BDA87D"}>
                                    <path d="M399.28,135.19c-6.22-6.22-16-16-25.31-25.3h-1.36l-5.45,5.45v1.37L393.46,143l4.21,4.22H399l5.45-5.45V140.4Z" />
                                    <path d="M378.85,159.76c-2.1-2.15-4.73-4.82-7.47-7.56L354.13,135c-10.08-10.07-19.78-19.76-23.3-23.29-3.06-3.05-4.85-6.27-5.19-9.32s.79-5.73,3.31-8.25,5.3-3.64,8.25-3.31,6.27,2.13,9.32,5.18h1.37l5.45-5.45V89.17c-4.62-4.62-9.83-7.37-15.07-8-5.93-.66-11.51,1.44-16.14,6.07-3,3-4.74,8.09-4.47,11.31L302.33,83.35H301l-5.44,5.47v1.36L324,118.51l23.3,23.28L364.56,159c2.71,2.71,5.32,5.36,7.41,7.48l0,.05c1.32,1.35,2.41,2.45,3.14,3.18h1.36l5.46-5.45v-1.36c-.72-.71-1.77-1.79-3.06-3.1Z" />
                                    <path d="M347.24,153.8c-3.05,3-6.28,4.85-9.32,5.18s-5.73-.78-8.25-3.3a11.14,11.14,0,0,1-3.27-6.34c-.43-3.61,1.3-7.39,5.14-11.24v-1.36l-5.45-5.45h-1.36c-4.62,4.61-7.37,9.82-8,15.07-.66,5.92,1.44,11.5,6.07,16.13a19.71,19.71,0,0,0,14.06,6.18c5.95,0,11.88-2.78,17.15-8v-1.37l-5.46-5.45Z" />
                                    <path d="M343.35,114l5.46,5.45h1.36l22.65-22.64c3.05-3.06,6.27-4.85,9.32-5.19s5.73.79,8.25,3.31c3.06,3.06,4,6.5,2.88,10.22a19.2,19.2,0,0,1-4.75,7.35v1.37l5.45,5.45h1.36c10.05-10,10.79-22.3,1.88-31.21-4.63-4.63-10.21-6.73-16.14-6.07-5.24.58-10.45,3.33-15.07,8l-22.65,22.65Z" />
                                </g>
                            </svg>
                            <p className={ConnexionStyles.formText}>Veuillez entrer votre adresse mail, si l'adresse existe, nous vous enverrons un mail contenant un lien pour modifier votre mot de passe.</p>
                        </article>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validate={values => {
                                const errors = {};
                                if (!values.emailRecup) {
                                    errors.email = 'Obligatoire';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailRecup)
                                ) {
                                    errors.emailRecup = 'Adresse e-mail invalide';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    _lancerRequete(values.emailRecup)

                                    setShowMessageEnvoye(true)
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
                                <form onSubmit={handleSubmit}>
                                    <section className={ConnexionStyles.form}>
                                        <article className={ConnexionStyles.formArticle}>
                                            <input className={ConnexionStyles.formInput}
                                                type="email"
                                                name="emailRecup"
                                                placeholder="Adresse email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.emailRecup}
                                            />
                                        </article>


                                        <article className={ConnexionStyles.formError}>
                                            {errors.emailRecup && touched.emailRecup && errors.emailRecup}
                                        </article>
                                    </section>

                                    <section>

                                        <button className={ConnexionStyles.formBtn} type="submit" disabled={isSubmitting}>Recevoir un lien de récupération</button>
                                    </section>
                                </form>
                            )}
                        </Formik>
                    </section>
                }
                {showMessageEnvoye &&

                    <section className={ConnexionStyles.formContainer} style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        borderTop: "1px solid rgb(189, 168, 125)",
                        borderLeft: "1px solid rgb(189, 168, 125)",
                        borderRight: "1px solid rgb(189, 168, 125)", borderRadius: '1rem'
                    }}>
                        <article>
                            <svg id="Calque_1" style={{ height: "auto", width: "12rem" }} data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 741.8 249.56">
                                <g fill={"#BDA87D"}>
                                    <path d="M399.28,135.19c-6.22-6.22-16-16-25.31-25.3h-1.36l-5.45,5.45v1.37L393.46,143l4.21,4.22H399l5.45-5.45V140.4Z" />
                                    <path d="M378.85,159.76c-2.1-2.15-4.73-4.82-7.47-7.56L354.13,135c-10.08-10.07-19.78-19.76-23.3-23.29-3.06-3.05-4.85-6.27-5.19-9.32s.79-5.73,3.31-8.25,5.3-3.64,8.25-3.31,6.27,2.13,9.32,5.18h1.37l5.45-5.45V89.17c-4.62-4.62-9.83-7.37-15.07-8-5.93-.66-11.51,1.44-16.14,6.07-3,3-4.74,8.09-4.47,11.31L302.33,83.35H301l-5.44,5.47v1.36L324,118.51l23.3,23.28L364.56,159c2.71,2.71,5.32,5.36,7.41,7.48l0,.05c1.32,1.35,2.41,2.45,3.14,3.18h1.36l5.46-5.45v-1.36c-.72-.71-1.77-1.79-3.06-3.1Z" />
                                    <path d="M347.24,153.8c-3.05,3-6.28,4.85-9.32,5.18s-5.73-.78-8.25-3.3a11.14,11.14,0,0,1-3.27-6.34c-.43-3.61,1.3-7.39,5.14-11.24v-1.36l-5.45-5.45h-1.36c-4.62,4.61-7.37,9.82-8,15.07-.66,5.92,1.44,11.5,6.07,16.13a19.71,19.71,0,0,0,14.06,6.18c5.95,0,11.88-2.78,17.15-8v-1.37l-5.46-5.45Z" />
                                    <path d="M343.35,114l5.46,5.45h1.36l22.65-22.64c3.05-3.06,6.27-4.85,9.32-5.19s5.73.79,8.25,3.31c3.06,3.06,4,6.5,2.88,10.22a19.2,19.2,0,0,1-4.75,7.35v1.37l5.45,5.45h1.36c10.05-10,10.79-22.3,1.88-31.21-4.63-4.63-10.21-6.73-16.14-6.07-5.24.58-10.45,3.33-15.07,8l-22.65,22.65Z" />
                                </g>
                            </svg>
                            <p className={ConnexionStyles.formText}>Si l'adresse existe, un email contenant un lien pour modifier votre mot de passe vous a été envoyé.<br></br>Merci de vérifier dans vos spams.</p>
                        </article>

                    </section>
                }
                <div>
                    <Footer />
                </div>
            </article>

        </section>
    );

}

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        color: state.selectProps.menuColor,
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted #BDA87D",
        color: state.isSelected ? "#fff" : "#332E57",
        backgroundColor: state.isSelected ? "#BDA87D" : "#fff",
        padding: 20,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: "100%",
        display: "flex",
        borderBottom: "1px solid #BDA87D",
    }),
    placeholder: () => ({
        color: "#9f99c7",
    }),

    indicatorSeparator: () => ({
        display: "none",
    }),
    svg: () => ({
        color: "#9f99c7",
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        const color = "#fff";

        return { ...provided, opacity, transition, color };
    },
};

export default withCookies(MotDePasseOublie);
