import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import FormParticulier from './FormParticulier'
import FormProfessionel from './FormProfessionel'

import InscriptionStyles from './Inscription.module.scss';


//props userNonConnecte={true} recupUserCode={recupUserCode}
class Inscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPar: true,
      showPro: false
    };
    this.retourProfil = this.retourProfil.bind(this)
  }

  showPar() {
    this.setState({ showPar: true, showPro: false })
  }

  showPro() {
    this.setState({ showPro: true, showPar: false })
  }

  retourProfil() {
    this.props.clickHandler('#profil')
  }
  render() {
    return (

      <section className={InscriptionStyles.titre}>


        <div className={InscriptionStyles.sousTitre}>
          Formulaires d'inscription
          {!this.props.userNonConnecte &&
            <article className={InscriptionStyles.formArticle}>
              <button style={this.state.showPar ? { backgroundColor: "#0DA8DB", color: "#fff" } : {}}
                className={InscriptionStyles.butt1}
                onClick={() => this.showPar()}>
                Particulier
              </button>
              <button style={this.state.showPro ? { backgroundColor: "#0DA8DB", color: "#fff" } : {}}
                className={InscriptionStyles.butt1}
                onClick={() => this.showPro()}>
                Professionnel
              </button>
            </article>
          }</div>



        {this.state.showPar &&
          <article className={InscriptionStyles.form}>
            <FormParticulier clickHandlerInscription={this.retourProfil} userNonConnecte={this.props.userNonConnecte} recupUserCode={this.props.recupUserCode} />
          </article>
        }

        {this.state.showPro &&
          <FormProfessionel clickHandlerInscription={this.retourProfil} userNonConnecte={this.props.userNonConnecte} recupUserCode={this.props.recupUserCode} />
        }

      </section>

    )
  }
};



export default withCookies(Inscription);