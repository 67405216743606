import React, { Component } from 'react';

import { Formik, Field } from 'formik';

import { NavLink } from "react-router-dom";
import { withCookies } from 'react-cookie';

import MainStyles from "../../App.module.scss";

import NotificationSystem from 'react-notification-system';
import notifStyles from "../NotifStyles"


class FormParticulier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recupInfosCompte: {
        nom: '_',
        prenom: '_'
      }
    };
    this.notificationSystem = React.createRef();
  }

  _tentativeConnexion = (values) => {
    var bodyy = {
      blossom: "seConnecter",
      mail: this.state.mail,
      motDePasse: this.state.motDePasse
    }

    fetch('https://api.homecrush.fr/control_room.php', {
      method: 'POST',
      body: JSON.stringify(bodyy)

    })
      .then(response => response.json())
      .then(response => {

        response = JSON.parse(response)
        if (response.error === "error wrong mdp") {
          return this.addNotif("identifiants incorrects", "error")
        }
        if (response.error === "error no user") {
          return this.addNotif("identifiants incorrects", "error")
        }
        else {
          var d = new Date();
          d.setTime(d.getTime() + (3600000));

          this.props.cookies.set('user_code', response.user_code, {
            path: '/',
            expires: d
          })
          this.props.cookies.set('rang', response.rang, {
            path: '/',
            expires: d
          })

          this.props.history.push('/compte'); // Redirection vers la page "Compte"
        }
        
        if (this.props.userNonConnecte) {
          this.props.recupUserCode(response.user_code)
        } else {
          this.props.clickHandlerInscription('#profil')
        }
        return response;
      })
      .catch(erreur => { console.log('ERROR: ' + erreur) });
  }

  _inscription = (values) => {
    if (values.cgu === false) {
      return this.addNotif("Veuillez accepter les cgus")
    }
    var bodyy = {
      blossom: "sInscrireClient",
      cgu: "true"
    };

    if (values.nom) {
      bodyy.nom = values.nom;
      this.setState({ errNom: '' });
    } else {
      this.setState({ errNom: 'Veuillez remplir ce champs' });
    }

    if (values.prenom) {
      bodyy.prenom = values.prenom;
      this.setState({ errPrenom: '' });
    } else {
      this.setState({ errPrenom: 'Veuillez remplir ce champs' });
    }

    if (values.mail) {
      bodyy.mail = values.mail;
      this.setState({ mail: values.mail, errMail: '' });
    } else {
      this.setState({ errMail: 'Veuillez remplir ce champs' });
    }

    if (values.motDePasse) {
      bodyy.motDePasse = values.motDePasse;
      this.setState({ motDePasse: values.motDePasse, errMotDePasse: '' });
    } else {
      this.setState({ errMotDePasse: 'Veuillez remplir ce champs' });
    }

    // Récupération des données de l'API
    fetch('https://api.homecrush.fr/control_room.php', {
      method: 'POST',
      body: JSON.stringify(bodyy) 
    })
    .then(response => response.json())
    .then(response => {
      response = JSON.parse(response);

      switch (response.error) {
        case "ok":
          this.addNotif("Votre inscription est un succès ! Vous pouvez maintenant vous connecter.", "success"); // Message de succès
          break;
        case "error user exist":
          this.addNotif("Un compte existe déjà à cette adresse", "error");
          break;
        case "error mail":
          this.addNotif("Votre mail n'est pas valide", "error");
          break;
        case "error mdp":
          this.addNotif("Le mot de passe peut contenir un maximum de 10 caractères", "error");
          break;
        default:
          return response;
      }
      
      
    })
    .catch(erreur => {
      console.log('ERROR INSCRIPTION PARTICULIER: ' + erreur);
    });
}


  addNotif = (message, level) => {
    console.log("Adding notification:", message, level); // Ajout de cette ligne
    this.notificationSystem.current.addNotification({
      message: message,
      level: level ? level : "success"
    });
  };


  render() {

    return (
      <section className={MainStyles.sousTitre}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        <Formik className={MainStyles.formikContainer}
          initialValues={{
            nom: '',
            prenom: '',
            mail: '',
            motDePasse: '',
            cgu: false
          }}
          validate={values => {
            const errors = {};
            if (!values.nom) {
              errors.nom = 'Obligatoire';
            } else if (values.prenom === false) {
              errors.prenom = 'Obligatoire';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)) {
              errors.mail = 'Adresse e-mail invalide';
            } else if (!values.motDePasse) {
              errors.motDePasse = 'Obligatoire';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              this._inscription(values)
              this.setState({ cgu: values.cgu })
              setSubmitting(false);
            }, 400);
          }}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
              <div className={MainStyles.inputContainer}>
                <input className={MainStyles.formInput} type="nom" name="nom" placeholder="Nom" onChange={handleChange} onBlur={handleBlur} value={values.nom} />
                <p className={MainStyles.formError}>{errors.nom && touched.nom && errors.nom}</p>

                <input className={MainStyles.formInput} type="prenom" name="prenom" placeholder="Prénom" onChange={handleChange} onBlur={handleBlur} value={values.prenom} />
                <p className={MainStyles.formError}>{errors.prenom && touched.prenom && errors.prenom}</p>
              </div>

              <div className={MainStyles.inputContainer}>
              <input className={MainStyles.formInput} type="mail" name="mail" placeholder="E-mail" onChange={handleChange} onBlur={handleBlur} value={values.mail} />
              <p className={MainStyles.formError}>{errors.mail && touched.mail && errors.mail}</p>

                <input className={MainStyles.formInput} type="password" name="motDePasse" placeholder="Mot de passe*" onChange={handleChange} onBlur={handleBlur} value={values.motDePasse} />
                <p className={MainStyles.formError}>{errors.motDePasse && touched.motDePasse && errors.motDePasse}</p>
              </div>
                <p className={MainStyles.formErrorMdp}>*Les caractères spéciaux ne sont pas acceptés</p>

              <label style={{ marginTop: "1rem" }}>
                <Field style={{ border: "1px solid #bda87d" }} type="checkbox" name="cgu" />
                <NavLink className={MainStyles.liens} target="_blank" to="/cgu">J'accepte les conditions d'utilisations</NavLink>
              </label>
              <p className={MainStyles.formError}>{errors.cgu && touched.cgu && errors.cgu}</p>

              <button className={MainStyles.formBtn2} type="submit" disabled={isSubmitting}>Valider</button>
            </form>
          )}
        </Formik>
      </section>
    )
  }
};



export default withCookies(FormParticulier);