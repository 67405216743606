import React, { Component } from "react";
import { withCookies } from "react-cookie";

import MainStyles from '../App.module.scss';
import Footer from "../components/Footer/Footer";

class Cgu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: true,
      showInscription: false,
    };
  }

  render() {
    const { cookies } = this.props;

    return (
      <section className={MainStyles.pageContainer}>
        <h1 className={MainStyles.pageTitle}>Les mentions légales d'Homecrush</h1>

        <article  className={MainStyles.article}>
          <span>ÉDITION DU SITE :</span>
          <span>Le site <a style={{ color: "#bda87d", textDecoration: "none" }} target="_blank" href="www.homecrush.fr">www.homecrush.fr</a> est édité par la SAS VIVRE EN PROVENCE Immobilier au capital de 40 000 €,  36 Avenue Jean Monnet 84310 MORIERES LES AVIGNON inscrit au RCS d’AVIGNON sous le N° 491779278.</span>
          <span>Responsable de la publication : Mr CLAUDEL Patrick.</span>
          <span><a style={{ color: "#bda87d", textDecoration: "none" }}
            href="mailto:contact@homecrush.fr">
            contact@homecrush.fr{" "}
          </a></span>
          <span>Numéro de TVA intracommunautaire : FR50491779278</span>
        </article>
          
        <article  className={MainStyles.article}>
          <span>HÉBERGEMENT DU SITE :</span>
          <span>Ce site est hébergé par la société OVH :<a href="http://www.ovh.com/" target="_blank" rel="noopener noreferrer">http://www.ovh.com/</a> </span>
          <span>Le nom de domaine est hébergé par la société OVH: <a href="http://www.ovh.com/" target="_blank" rel="noopener noreferrer">http://www.ovh.com/</a></span>
          <span>OVH SAS au capital de 10 069 020 € RCS Lille Métropole 424 761 419 00045</span>
          <span>Siège social : 2 rue Kellermann - 59100 Roubaix - France.</span>
          <span>Code APE : 2620Z</span>
          <span>Identification intracommunautaire : FR 22 -424-761-419</span>
        </article>

        <article  className={MainStyles.article}>
          <span>LOI INFORMATIQUE ET SÉCURITÉ :</span>
          <span>Conformément à la loi Informatique et libertés du 6 janvier 1978, les internautes dont les données personnelles sont traitées par la SAS VIVRE EN PROVENCE immobilier ont le droit d’accéder à leurs données et le droit de demander la rectification, la mise à jour et la suppression de leurs données personnelles en écrivant à l’adresse postale de l’éditeur du site.
          </span>
        </article>

        <article  className={MainStyles.article}>
          <span>DONNÉES PERSONNELLES :</span>
          <span>Les données personnelles sont conservées pour une durée de 5 ans et, le cas échéant, jusqu’à ce que l’internaute fasse une demande de retrait via le formulaire de contact. Les informations nominatives ne sont jamais transmises à des tiers.</span>
        </article>

        <article  className={MainStyles.article}>
          <span>PROPRIÉTÉ INTELLECTUELLE :</span>
          <span>L’ensemble de ce site relève de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés.</span>
          <span>La reproduction ou représentation, intégrale ou partielle, de ce site sur un support électronique ou tout autre support quel qu’il soit est formellement interdite sauf autorisation expresse du directeur de publication.</span>
        </article>

        <article  className={MainStyles.article}>
          <span>FORCE MAJEURE :</span>
          <span>La responsabilité de l’éditeur du site ne pourra être engagée en cas de force majeure ou de faits indépendants de sa volonté.</span>
        </article>

        <article  className={MainStyles.article}>
          <span>APPLICATION DE LA LOI :</span>
          <span>Le site <a style={{ color: "#bda87d", textDecoration: "none" }} target="_blank" href="www.homecrush.fr">www.homecrush.fr</a> est régi par la loi Française.</span>
        </article>

        <article  className={MainStyles.article}>
          <span>MODIFICATION DES MENTIONS LÉGALES :</span>
          <span>L’éditeur se réserve le droit de modifier, librement et à tout moment, les mentions légales du site. L’utilisation du site constitue l’acceptation des mentions légales en vigueur.</span>
        </article>

        <article  className={MainStyles.article}>
          <span>DESIGN ET DÉVELOPPEMENT :</span>
          <span>PERROQUET Adresse : 37 rue Guibal 13001 MARSEILLE. Site Web : <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="https://www.perroquet.eu">www.perroquet.eu</a></span>
        </article>

        <Footer />
      </section>
    );
  }
}

export default withCookies(Cgu);
