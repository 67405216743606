import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import MainStyles from "../App.module.scss"
import Footer from "../components/Footer/Footer";

import LottieLoader from "../components/Loader/Loader"
import animationData from "../assets/homecrush-logo.json"

import { useCookies } from 'react-cookie';

import { withCookies } from 'react-cookie';
import { useLocation } from "react-router-dom";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted #BDA87D",
    color: state.isSelected ? "#fff" : "#332E57",
    backgroundColor: state.isSelected ? "#BDA87D" : "#fff",
    padding: 20,
  }),
  control: () => ({
    width: "100%",
    display: "flex",
    borderBottom: "1px solid #BDA87D",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorContainer: () => ({
    color: "#9f99c7",
  }),
  placeholder: () => ({
    color: "#9f99c7",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "#fff";
    return { ...provided, opacity, transition, color };
  },
};
const options = [
  { value: "", label: "Tous les départements" },
  { value: "01", label: "01 - Ain" },
  { value: "02", label: "02 - Aisne" },
  { value: "03", label: "03 - Allier" },
  { value: "04", label: "04 - Alpes-de-Haute-Provence" },
  { value: "05", label: "05 - Hautes-Alpes" },
  { value: "06", label: "06 - Alpes-Maritimes" },
  { value: "07", label: "07 - Ardèche" },
  { value: "08", label: "08 - Ardennes" },
  { value: "09", label: "09 - Ariège" },
  { value: "10", label: "10 - Aube" },
  { value: "11", label: "11 - Aude" },
  { value: "12", label: "12 - Aveyron" },
  { value: "13", label: "13 - Bouches-du-Rhone" },
  { value: "14", label: "14 - Calvados" },
  { value: "15", label: "15 - Cantal" },
  { value: "16", label: "16 - Charente" },
  { value: "17", label: "17 - Charente-Maritime" },
  { value: "18", label: "18 - Cher" },
  { value: "19", label: "19 - Corrèze" },
  { value: "20", label: "20 - Corse" },
  { value: "21", label: "21 - Côte-d'Or" },
  { value: "22", label: "22 - Côtes d'Armor" },
  { value: "23", label: "23 - Creuse" },
  { value: "24", label: "24 - Dordogne" },
  { value: "25", label: "25 - Doubs" },
  { value: "26", label: "26 - Drôme" },
  { value: "27", label: "27 - Eure" },
  { value: "28", label: "28 - Eure-et-Loir" },
  { value: "29", label: "29 - Finistère" },
  { value: "30", label: "30 - Gard" },
  { value: "31", label: "31 - Haute-Garonne" },
  { value: "32", label: "32 - Gers" },
  { value: "33", label: "33 - Gironde" },
  { value: "34", label: "34 - Hérault" },
  { value: "35", label: "35 - Ille-et-Vilaine" },
  { value: "36", label: "36 - Indre" },
  { value: "37", label: "37 - Indre-et-Loire" },
  { value: "38", label: "38 - Isère" },
  { value: "39", label: "39 - Jura" },
  { value: "40", label: "40 - Landes" },
  { value: "41", label: "41 - Loir-et-Cher" },
  { value: "42", label: "42 - Loire" },
  { value: "43", label: "43 - Haute-Loire" },
  { value: "44", label: "44 - Loire-Atlantique" },
  { value: "45", label: "45 - Loiret" },
  { value: "46", label: "46 - Lot" },
  { value: "47", label: "47 - Lot-et-Garonne" },
  { value: "48", label: "48 - Lozère" },
  { value: "49", label: "49 - Maine-et-Loire" },
  { value: "50", label: "50 - Manche" },
  { value: "51", label: "51 - Marne" },
  { value: "52", label: "52 - Haute-Marne" },
  { value: "53", label: "53 - Mayenne" },
  { value: "54", label: "54 - Meurthe-et-Moselle" },
  { value: "55", label: "55 - Meuse" },
  { value: "56", label: "56 - Morbihan" },
  { value: "57", label: "57 - Moselle" },
  { value: "58", label: "58 - Nièvre" },
  { value: "59", label: "59 - Nord" },
  { value: "60", label: "60 - Oise" },
  { value: "61", label: "61 - Orne" },
  { value: "62", label: "62 - Pas-de-Calais" },
  { value: "63", label: "63 - Puy-de-Dôme" },
  { value: "64", label: "64 - Pyrénées-Atlantiques" },
  { value: "65", label: "65 - Hautes-Pyrénées" },
  { value: "66", label: "66 - Pyrénées-Orientales" },
  { value: "67", label: "67 - Bas-Rhin" },
  { value: "68", label: "68 - Haut-Rhin" },
  { value: "69", label: "69 - Rhône" },
  { value: "70", label: "70 - Haute-Saône" },
  { value: "71", label: "71 - Saône-et-Loire" },
  { value: "72", label: "72 - Sarthe" },
  { value: "73", label: "73 - Savoie" },
  { value: "74", label: "74 - Haute-Savoie" },
  { value: "75", label: "75 - Paris" },
  { value: "76", label: "76 - Seine-Maritime" },
  { value: "77", label: "77 - Seine-et-Marne" },
  { value: "78", label: "78 - Yvelines" },
  { value: "79", label: "79 - Deux-Sèvres" },
  { value: "80", label: "80 - Somme" },
  { value: "81", label: "81 - Tarn" },
  { value: "82", label: "82 - Tarn-et-Garonne" },
  { value: "83", label: "83 - Var" },
  { value: "84", label: "84 - Vaucluse" },
  { value: "85", label: "85 - Vendée" },
  { value: "86", label: "86 - Vienne" },
  { value: "87", label: "87 - Haute-Vienne" },
  { value: "88", label: "88 - Vosges" },
  { value: "89", label: "89 - Yonne" },
  { value: "90", label: "90 - Territoire de Belfort" },
  { value: "91", label: "91 - Essonne" },
  { value: "92", label: "92 - Hauts-de-Seine" },
  { value: "93", label: "93 - Seine-St-Denis" },
  { value: "94", label: "94 - Val-de-Marne" },
  { value: "95", label: "95 - Val-D'Oise" },
];
const Agences = (props) => {
  const [monUserCode, setMonUserCode] = useState("")
  const [dataRecu, setDataRecu] = useState("");
  const [echantillonRecu, setEchantillonRecu] = useState("");

  const [Filtre1Visible, setFiltre1Visible] = useState(false);
  const [Filtre2Visible, setFiltre2Visible] = useState(false);
  const [Filtre3Visible, setFiltre3Visible] = useState(false);
  const [bgButtonColor1, setBgButtonColor1] = useState("transparent");
  const [textButtonColor1, setTextButtonColor1] = useState("#BDA87D");
  const [bgButtonColor2, setBgButtonColor2] = useState("transparent");
  const [textButtonColor2, setTextButtonColor2] = useState("#BDA87D");
  const [bgButtonColor3, setBgButtonColor3] = useState("transparent");
  const [textButtonColor3, setTextButtonColor3] = useState("#BDA87D");
  const [bgButtonColor4, setBgButtonColor4] = useState("transparent");
  const [textButtonColor4, setTextButtonColor4] = useState("#BDA87D");
  const [secteur, setSecteur] = useState("");
  const [ville, setVille] = useState("");
  const [codePostale, setCodePostale] = useState("");
  const [showList, setShowList] = useState(false);
  const [showIndicator, setShowIndicator] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState("");
  const [autocompleteCities, setAutocompleteCities] = useState([]);
  const [autocompleteErr, setAutocompleteErr] = useState("");

  var location = useLocation()
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  const handleCityChange = async (e) => {
  };

  useEffect(() => {
    setMonUserCode(props.cookies.get('user_code') ? props.cookies.get('user_code') : process.env.REACT_APP_USERCODE_HOMECRUSH)
    /**
     * SI PAS DE COOKIE
     * => REQUETE POUR RECUPERER LA LISTE DES VILLES
     * SINON
     * METTRE DASN LE STATE LE COOKIE DES VILLES
     */
    if (cookies.suggestionsVille) {
      setAutocompleteCities(cookies.suggestionsVille)
    } else {
      recupVillesAgences()
    }

    // Further parsing:
    const params = new URLSearchParams(location.search);
    const q = params.get("ville"); // is the number 123
    let bodyy = {
      blossom: "listAgences",
    }
    var desire = location.search.slice(1).split("&")[0].split("=")[1]
    if (location.search) {

      bodyy.typeFiltre = "ville"
      bodyy.filtre = q
    }

    // Récupération des données de l'API
     fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
    .then((response) => response.json())
    .then((response) => {
      response = JSON.parse(response);
      // Assurez-vous que la réponse est un tableau et contient des données
      if (Array.isArray(response) && response.length > 0) {
        // Tri des données par exemple par code postal dans un ordre croissant
        const sortedData = response.sort((a, b) => a.codePostale.localeCompare(b.codePostale));
        setDataRecu(sortedData);
        setEchantillonRecu(sortedData);
      } else {
        // Gérer le cas où aucune agence n'est trouvée ou une erreur survient
        setDataRecu([]);
        setEchantillonRecu([]);
        setMessage("Nous n'avons pas trouvé d'agence pour ce département");
      }
      setShowList(true);
      setShowIndicator(false);
    })
    .catch((erreur) => {
      console.error("ERROR: ", erreur);
      // Gérer l'erreur
    });
    // MISE A JOUR DE L'OBJET echantillonRecu DANS LE STATE
    //Fonction de traitement des données
    //_attemptListesAgencesAsync(echantillonRecu);

  }, []);
  const _attemptListesAgencesAsync = async (response) => {
    // MISE A JOUR DE L'OBJET echantillonRecu DANS LE STATE
    setEchantillonRecu(response)
  };

  const handleClickButton1 = () => {
    if (Filtre1Visible === true) {
      setFiltre1Visible(false)
      setBgButtonColor1("transparent")
      setTextButtonColor1("#BDA87D")
    } else {
      setFiltre1Visible(true)
      setBgButtonColor1("fff")
      setTextButtonColor1("#0DA8DB")
      setFiltre2Visible(false)
      setBgButtonColor2("transparent")
      setTextButtonColor2("#BDA87D")
      setFiltre3Visible(false)
      setBgButtonColor3("transparent")
      setTextButtonColor3("#BDA87D")
    }
  }
  const handleClickButton2 = () => {
    if (Filtre2Visible === true) {
      setFiltre2Visible(false)
      setBgButtonColor2("transparent")
      setTextButtonColor2("#BDA87D")
    } else {
      setFiltre2Visible(true)
      setBgButtonColor2("fff")
      setTextButtonColor2("#0DA8DB")
      setFiltre1Visible(false)
      setBgButtonColor1("transparent")
      setTextButtonColor1("#BDA87D")
      setFiltre3Visible(false)
      setBgButtonColor3("transparent")
      setTextButtonColor3("#BDA87D")
    }
  }
  const handleClickButton3 = () => {
    if (Filtre3Visible === true) {
      setFiltre3Visible(false)
      setBgButtonColor3("transparent")
      setTextButtonColor3("#BDA87D")
    } else {
      setFiltre3Visible(true)
      setBgButtonColor3("fff")
      setTextButtonColor3("#0DA8DB")
      setFiltre1Visible(false)
      setBgButtonColor1("transparent")
      setTextButtonColor1("#BDA87D")
      setFiltre2Visible(false)
      setBgButtonColor2("transparent")
      setTextButtonColor2("#BDA87D")
    }
  }

  const _lancerRequete = (typeFiltre) => {

    setShowList(false)
    setShowIndicator(true)
    setMessage("")
    var bodyy = {
      blossom: "listAgences",
    };
    bodyy.typeFiltre = typeFiltre;

    if (typeFiltre === "secteur") {
      bodyy.filtre = secteur;
    }
    if (typeFiltre === "ville") {
      bodyy.filtre = ville;
    }
    if (typeFiltre === "codePostale") {
      bodyy.filtre = codePostale;
    }
    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        response = JSON.parse(response);
        if (response.error === "error no agency") {
          setEchantillonRecu([]);
          setDataRecu([]);
          setMessage("Nous n'avons pas trouvé d'agence pour ce département");
        } else {
          // Tri des données par exemple par nom d'agence
          response.sort((a, b) => a.codePostale.localeCompare(b.codePostale));
          setDataRecu(response);
          setEchantillonRecu(response);
        }
        setShowList(true);
        setShowIndicator(false);
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
    //Fonction de traitement des données
    _attemptListesAgencesAsync(dataRecu);

    //    setRefresh(!refresh)
  }

  const recupVillesAgences = () => {
    var bodyy = {
      blossom: "villesAgences",
      user_code: monUserCode?monUserCode:process.env.REACT_APP_USERCODE_HOMECRUSH
    };
    fetch("https://api.homecrush.fr/control_room.php", {
      method: "POST",
      body: JSON.stringify(bodyy),
    })
      .then((response) => response.json())
      .then((response) => {
        let tableauVille = []
        response.map((ville, index) => {
          tableauVille = [response[index].ville, ...tableauVille]
        })
        if (!response.error) {
          setCookie("suggestionsVille", tableauVille, { maxAge: 8600 })
          setAutocompleteCities(cookies.suggestionsVille)
        }
        return response;
      })
      .catch((erreur) => {
        console.log("ERROR: " + erreur);
      });
  }


  const handleChange = (parameter, value) => {
    if (value === "") {
      value = undefined;
    }
    if (parameter === "secteur") {
      setSecteur(value)
    }
    if (parameter === "ville") {
      setVille(value)

      if (!ville) return;

      const res = ville;
      !autocompleteCities.includes(value) &&
        res.features &&
        setAutocompleteCities(res.features.map((place) => place.place_name));
      res.error ? setAutocompleteErr(res.error) : setAutocompleteErr("");

    }
    if (parameter === "codePostale") {
      setCodePostale(value);
    }
  };

  const handleImageError = (e) => {
    e.target.style.backgroundImage = `url(https://api.homecrush.fr/imgDefaut/default.jpeg)`;
  };

  return (
    <section className={MainStyles.pageContainer}>
      <h1 className={MainStyles.pageTitle}>Les professionnels de votre secteur</h1>
      <h2 className={MainStyles.pageSubTitle}>Retrouvez ici les agences immobilières proches de chez vous. Affinez votre recherche par département, ville ou code postal.</h2>
      {/* Bande défilante boutons de filtre */}
      <article className={MainStyles.buttonBar}>
        <div onClick={() => { handleClickButton1() }}
          style={{ backgroundColor: bgButtonColor1, color: textButtonColor1 }}
          className={MainStyles.btnFiltre}
        >Départements</div>

        <div onClick={() => { handleClickButton2() }}
          style={{ backgroundColor: bgButtonColor2, color: textButtonColor2 }}
          className={MainStyles.btnFiltre}
        >Ville</div>

        <div onClick={() => { handleClickButton3() }}
          style={{ backgroundColor: bgButtonColor3, color: textButtonColor3 }}
          className={MainStyles.btnFiltre}
        >Code Postal</div>
      </article>

      {/* Les Views des differents filtres qui s'affichent au clic du bouton */}
      <article style={{ width: "100%", alignContent: "center" }}>
        {Filtre1Visible && (
          <div style={{position:'relative',zIndex:'5'}}>
            <Select
              placeholder="Choisir votre département"
              styles={customStyles}
              id="secteurSelect"
              options={options}
              onChange={(value) => { handleChange("secteur", value.value) }}
            />
            <div onClick={() => { _lancerRequete("secteur") }}
              className={MainStyles.btnLancerRequetedep}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Lancez la recherche</div>
            </div>
          </div>
        )}
        {Filtre2Visible && (
          <div style={{ borderWidth: 0, marginTop: -4, marginBottom: 30 ,position:'relative',zIndex:'1'}}>

            <form>
              <input
                list="places"
                type="text"
                id="ville"
                name="ville"
                className={MainStyles.suggestionsVille}
                style={{ margintop: 3, marginLeft: 5, color: "#9f99c7", background: "transparent", border: "none", fontFamily: "HermesReg", height: 40, fontSize: 16 }}
                placeholder="Entrez la ville"
                onChange={(event) => handleChange("ville", event.target.value)}
                value={ville}
                autoComplete="off"
                //pattern={autocompleteCities.join("|")}

              />
              <datalist id="places">
                {autocompleteCities.map((ville, i) => (
                  <option key={i}>{ville}</option>
                ))}
              </datalist>


              <div style={{ height: 1, width: "auto", backgroundColor: "#BDA87D", marginBottom: 0, }}></div>
              <div onClick={() => { _lancerRequete("ville") }} className={MainStyles.btnLancerRequetedep}>
                <div className={MainStyles.btnLancerRequeteInnerText}>Lancez la recherche</div>
              </div>

            </form>


          </div>
        )}
        {Filtre3Visible && (
          <div style={{ borderWidth: 0, marginTop: -4, marginBottom: 30 , position:'relative',zIndex:'1' }}>
            <label>
              <input
                className={MainStyles.Voulle}
                type="text"
                name="name"
                style={{ margintop: 3, marginLeft: 5, color: "#9f99c7", background: "transparent", border: "none", fontFamily: "HermesReg", height: 40, fontSize: 16 }}
                placeholder="Entrez le code postal"
                onChange={(event) => handleChange("codePostale", event.target.value)}
                value={codePostale}
              />
            </label>
            <div
              style={{
                height: 1,
                width: "auto",
                backgroundColor: "#BDA87D",
                marginBottom: 0,
              }}
            ></div>
            <div onClick={() => { _lancerRequete("codePostale") }} className={MainStyles.btnLancerRequetedep}>
              <div className={MainStyles.btnLancerRequeteInnerText}>Lancez la recherche</div>
            </div>
          </div>
        )}

        {showIndicator && (
          <div style={{ height: 200, justifyContent: "center" }}>
            <LottieLoader animationData={animationData} width={300} height={300} />
          </div>
        )}

        {Filtre1Visible && (
          <div>
            <div style={{ fontsize: 16, color: "#BDA87D" }}>
              {message}
            </div>
          </div>
        )}
        <section className={MainStyles.listeWrapperAgence}>
          {showList && (
            <article className={MainStyles.listeContainerAgence}>
              {dataRecu.map((agence, index) => {
                if (agence) {
                  return (
                    <Link key={index} className={MainStyles.listeItem}
                      to={{ pathname: agence.agenceShowlink, }}
                      state={{ agence }}
                    >
                      <div>
                        <h3 className={MainStyles.itemTitle}>{agence.nom}</h3>
                        <span className={MainStyles.itemVilleAgences}>{agence.codePostale +" "+ agence.ville}</span>
                      </div>
                      <div 
                          className={MainStyles.itemImageAgence} 
                          
                      >
                        <img src={ agence.logo ? ( agence.logo.startsWith("https://") ? agence.logo : `https://${agence.logo}`) 
                                  : 'https://api.homecrush.fr/imgDefaut/default.jpeg'} />
                      </div>
                    </Link>
                  );
                }
                return null;
              })}
            </article>
          )}
        </section>

      </article>

      <Footer />
    </section>
  );

}

export default withCookies(Agences);