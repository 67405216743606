import React from 'react';
import { withCookies } from 'react-cookie';
import { NavLink } from "react-router-dom";

import Styles from './BanniereImage.module.scss'


function BanniereImage(props) {

    // const [cookies, setCookie] = useCookies(['user_code']);
    // let navigate = useNavigate()
    // console.log(props.link);

    return (
        <>
            {props.image === "Image1" && <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/Homecrrush-site-web-photo-1.jpg)` }} className={Styles.bannerContainer}>
            <div className={Styles.bannerTitle}>{props.title}</div>
                <NavLink
                    style={{ width: "auto",  lineHeight: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}
                    className={Styles.bannerButton}
                    to={props.link}
                    props={props.transferProps}
                    id={props.id}
                >{props.textButton}
                </NavLink>
            </div>
            }
            
            {props.image === "Image2" && <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/Homecrush-site-web-photo-2.jpeg)` }} className={Styles.bannerContainer}>
                <div className={Styles.bannerTitle}>{props.title}</div>
                <NavLink
                    style={{ width: "auto", lineHeight: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                    className={Styles.bannerButton}
                    to={props.link}
                    props={props.transferProps}
                    id={props.id}
                >{props.textButton}
                </NavLink>
            </div>
            }
        </>
    );
}


export default withCookies(BanniereImage);