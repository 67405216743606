import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import Geocode from "react-geocode";

import MainStyles from "../App.module.scss";

import NotificationSystem from 'react-notification-system';
import notifStyles from "../components/NotifStyles"

import { Link } from "react-router-dom";
import Footer from '../components/Footer/Footer';

import no_image from "../assets/no-image.png";

class Coeur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: true,
      showInscription: false,
      maLocalisation: {
        coords: {
          latitude: 0,
          longitude: 0
        }
      },
      echantillonRecu: [],
      showDisconnect: false,
      showNoCoeur: false,
      showModal: false,
    };
  }

  componentDidMount() {

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_TOKEN);
    var monUserCode = this.props.cookies.get('user_code');

    this.setState({ isLoading: true })

    // run function that updates the data on entering the screen
    var bodyy = {
      blossom: "listeCoeur",
      user_code: monUserCode
    }

    if (bodyy.user_code === undefined) {
      this.setState({ showDisconnect: true })
    }
    else {
      // Récupération liste coups de coeur
      fetch(
        "https://api.homecrush.fr/control_room.php",
        {
          method: 'POST',
          body: JSON.stringify(bodyy)
        })
        .then(response => response.json())
        .then(response => {
          console.log(response)
          if (response.length === undefined) {
            this.setState({ showMessage: true, isLoading: false })
            this.setState({ echantillonRecu: [] })
            this.forceUpdate()
          }
          else {
            this.setState({ showMessage: false, echantillonRecu: response }) // On ajoute les données recu au state
            this.forceUpdate()
            //this._traitementDonnée()
            return response
          }
        }
        )
        .then(response => {
          this._geolocalisation()
        })
        .catch(erreur => {
          console.log('Erreur liste Coeur: ' + erreur);
        });
      //this._attemptCoupsdecoeurAsync();
    }
  }

  _geolocalisation = () => {
    // MA LOCALISATION
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        maLocalisation: {
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }
        }
      })

      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        response => {
          var address = response.results[0].address_components[6].short_name;
          if (address !== "00000") {
            this.setState({ codePostale: address, cp: address.substr(0, 2) })
          }
        },
        error => {
          console.error(error);
        }
      );

      this.setState({ maLocalisation: position });
      this._traitementDonnées()
      return position
    },
      function error(msg) { console.log('Please enable your GPS position feature.'); },
      { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
    );
  }

  _traitementDonnées() {
    var echantillonRecu = this.state.echantillonRecu
    if (this.state.echantillonRecu === []) {
      this.setState({ showNoCoeur: true })
    }

    // ========== BOUCLE DE CONVERSION ADRESSE => COORDONNEES
    for (let i = 0; i <= this.state.echantillonRecu.length - 1; i++) {
      if (this.state.arretRecherche) {
        return;
      }

      if (this.state.maLocalisation.coords.latitude === 0) {
        let items = [...this.state.echantillonRecu];
        let item = { ...items[i] };
        item.distance = "?";
        items[i] = item;

        return this.setState({ echantillonRecu: items, inProgress: false });
      }

      var rue = echantillonRecu[i].rue ? echantillonRecu[i].rue : ""
      var adresse = rue + ' ' + echantillonRecu[i].codePostale + ' ' + echantillonRecu[i].ville + ', France'


      Geocode.fromAddress(adresse).then(
        response => {
          var echantillonRecu = this.state.echantillonRecu

          var coords = response.results[0].geometry.location

          // 1. Make a shallow copy of the items
          let items = [...this.state.echantillonRecu];
          // 2. Make a shallow copy of the item you want to mutate
          let item = { ...items[i] };
          // 3. Replace the property you're intested in
          item.coords = coords;
          // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
          items[i] = item;
          // 5. Set the state to our new copy

          echantillonRecu[i].imageEnAvant = 'https://' + echantillonRecu[i].imageEnAvant;

          function distanceFunc(lat1, lon1, lat2, lon2) {
            var p = 0.017453292519943295;
            var c = Math.cos;
            var a = 0.5 - c((lat2 - lat1) * p) / 2 +
              c(lat1 * p) * c(lat2 * p) *
              (1 - c((lon2 - lon1) * p)) / 2;
            return (12742 * Math.asin(Math.sqrt(a))) | 0;
          }

          let distance = distanceFunc(this.state.maLocalisation.coords.latitude, this.state.maLocalisation.coords.longitude, coords.lat, coords.lng)

          items = [...this.state.echantillonRecu];
          item = { ...items[i] };
          item.distance = distance;
          items[i] = item;

          items.sort(function (a, b) { return a.dist - b.dist });

          this.setState({ echantillonRecu: items, inProgress: false });
        },
        error => {
          console.error(error);
        }
      );

    }

    this.setState({ showMap: true, hideMap: false, })

  };

  _attemptCoupsdecoeurAsync = () => { };


  _suppCoups = (idBien, idAc3, idHector, idHomecrush) => {
    var monUserCode = this.props.cookies.get('user_code');
    this.setState({ isLoading: true })
    var bodyy = {
      blossom: "suppCoeur",
      user_code: monUserCode,
      idBien,
      idAc3,
      idHector,
      idHomecrush
    }
    // Récupération liste coups de coeur
    fetch("https://api.homecrush.fr/control_room.php", {
      method: 'POST',
      body: JSON.stringify(bodyy)
    })
      .then(response => response.json())
      .then(response => {
        this.setState({ reponseSupp: response, isLoading: false }) // On ajoute les données recu au state
        if (response.httpstatus === 200) {
          document.querySelector("#bien" + idBien).style.display = "none"
          
          // 1. Supprimez le bien du tableau echantillonRecu
          const updatedEchantillonRecu = this.state.echantillonRecu.filter(b => b.id !== idBien);
          
          // 2. Mettez à jour l'état avec le nouveau tableau
          this.setState({ echantillonRecu: updatedEchantillonRecu, showModal: false });
        }
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
    }
  
  addNotif = (message, level) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: message,
      level: level ? level : "success"
    });
  };

  render() {
    return (
      <section className={MainStyles.pageContainer}>

        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />


        {/**UTILISATEUR NON CONNECTE */}
        {
          this.state.showDisconnect &&
          <article style={{ marginBottom: '25vh' }}>
            <h1 className={MainStyles.pageTitle}>Il semblerait que vous ne soyez pas connecté.</h1>
            <h2 className={MainStyles.pageSubTitle}>Connectez-vous à votre compte pour accéder à cette fonctionnalité.</h2>
            <Link to="/Compte" id="Compte" className={MainStyles.btnLink}>
              <p style={{ marginTop: "0.6rem", marginBottom: "0.7rem", fontSize: 16, color: '#fff', fontFamily: 'HermesReg' }}>Accéder à mon compte</p>
            </Link>
          </article>
        }

        {!this.state.showDisconnect &&
          <article style={{position: 'relative', zIndex: '1'}}>
            <h1 className={MainStyles.pageTitle}>Votre sélection de coup de cœur </h1>

            {this.state.echantillonRecu.length === 0 &&
              <div style={{ borderBottom: "1px solid #bda87d" }}>
                <h3 className={MainStyles.pageSubTitle}>Vous n’avez pas encore de biens coup de coeur</h3>
                <Link className={MainStyles.btnLink} style={{ margin: "3rem auto", padding: "0.6rem 0.8rem" }}
                  to="/" id="retourAccueil">Parcourez l'application
                </Link>
              </div>
            }
            {this.state.showModal === true &&
              <article className={MainStyles.modalWrapper}>
                <div className={MainStyles.modalContainer}>
                  <div className={MainStyles.listDeMesBiensClosebtn} onClick={() => this.setState({ showModal: false })}>{"X"}</div>
                  <div style={{ color: '#BDA87D', margin: "1rem 2rem 2rem 2rem" }}>Souhaitez-vous vraiment supprimer ce bien de vos coups de coeurs ?</div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className={MainStyles.btnModal} style={{ backgroundColor: 'transparent', color: '#BDA87D', marginLeft: "0.5rem" }} onClick={() => this.setState({ showModal: false })}>Annuler</div>
                    <div className={MainStyles.btnModal} style={{ backgroundColor: '#0DA8DB', color: '#fff', marginLeft: "0.5rem" }} onClick={() => this._suppCoups(this.state.currentId, this.state.idAc3, this.state.idHector, this.state.idHomecrush)}>Valider</div>
                  </div>
                </div>
              </article>
            }

            <section className={MainStyles.listeWrapper}>
              {this.state.echantillonRecu.length >= 1 && this.state.echantillonRecu.map((bien, i) => {
                return (
                  <article id={"bien" + bien.id}>



                    <div className={MainStyles.listeContainer}>
                      <Link className={MainStyles.listeHeader}
                        to={{ pathname: bien.showlink }}>
                        <span className={MainStyles.prixItem}>{bien.prix + " € "}</span>
                        <span className={MainStyles.surfaceItem}>{" - " + bien.surface + " m\u00b2"}</span>
                      </Link>

                      <div onClick={() => this.setState({ showModal: true, currentId: bien.id, idAc3: bien.idAc3, idHector: bien.idHector, idHomecrush: bien.idHomecrush })} style={{ cursor: "pointer" }}>
                        <svg className={MainStyles.svgItem} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
                          <g fill={"#BDA87D"}>
                            <polygon points="35.62 30.24 30.24 35.62 30.24 36.96 113.04 119.76 114.38 119.76 119.76 114.38 119.76 113.04 36.96 30.24 35.62 30.24" /><polygon points="62.65 80.63 30.24 113.04 30.24 114.38 35.62 119.76 36.96 119.76 69.37 87.35 69.37 86.01 63.99 80.63 62.65 80.63" /><polygon points="113.04 30.24 80.63 62.65 80.63 63.99 86.01 69.37 87.35 69.37 119.76 36.96 119.76 35.62 114.38 30.24 113.04 30.24" />
                          </g>
                        </svg>
                      </div>
                    </div>


                    <Link className={MainStyles.listeItem}
                      to={{ pathname: bien.showlink }}>
                      <div className={MainStyles.infosItem}>
                        <div className={MainStyles.leftItem}>
                          <div>
                            <div>{bien.ville}</div>
                            <div>{bien.agence}</div>
                          </div>
                          <div className={MainStyles.distanceItem}>
                            <svg style={{ height: '2rem' }} id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
                              <g fill={"#0DA8DB"}>
                                <polygon points="42.61 63.89 42.61 63.89 42.61 63.88 42.61 63.89" />
                                <path d="M108.42,29.86A38.86,38.86,0,0,0,94.63,18.73a42.29,42.29,0,0,0-35.07.05A38.92,38.92,0,0,0,45.8,29.94a54.81,54.81,0,0,0-10.74,34,68.76,68.76,0,0,0,4.78,21.88A246.45,246.45,0,0,0,56,120.1c5.47,9.83,10,16.89,10.48,17.65l1.28.28,6.33-4.1.3-1.28c-.07-.1-7.69-11.9-15.14-26.39a198.46,198.46,0,0,1-11-24.92,56,56,0,0,1-3.75-17.42,45.23,45.23,0,0,1,8.71-28.1,29.47,29.47,0,0,1,10.48-8.48,32.56,32.56,0,0,1,26.86,0A29.51,29.51,0,0,1,101,35.76a45.35,45.35,0,0,1,8.79,28.08,56.27,56.27,0,0,1-3.7,17.42,200.81,200.81,0,0,1-11,25c-7.43,14.5-15,26.32-15.05,26.43l.27,1.28L86.7,138l1.28-.27c.64-1,8.45-13.23,16.09-28.33a212,212,0,0,0,10.44-23.72,68.57,68.57,0,0,0,4.71-21.89A54.75,54.75,0,0,0,108.42,29.86Zm-36.09,103h0l-.14-.22Z" />
                                <path d="M92.05,60.41A14.94,14.94,0,1,0,87.69,71,14.94,14.94,0,0,0,92.05,60.41Zm-18.77,3.9a5.47,5.47,0,1,1,7.74,0A5.48,5.48,0,0,1,73.28,64.31Z" />
                              </g>
                            </svg>
                            {bien.distance + "km"}
                          </div>
                        </div>

                        <div className={MainStyles.rightItem}>
                          <div className={MainStyles.agentItem}>
                            <div>Proposé par</div>
                            <div>{bien.nomAgent}</div>
                          </div>
                          <div className={MainStyles.telItem}>{bien.telAgent}</div>
                        </div>
                      </div>
                      <img className={MainStyles.itemImage} alt={'image du bien aimé'} src={"https://" + (bien.imageEnAvant ? bien.imageEnAvant : no_image)} />
                      
                    </Link>
                  </article>
                )
              })}
            </section>
          </article>
        }
        <Footer />
      </section >
    )
  }
}

export default withCookies(Coeur);