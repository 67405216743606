import React, { Component } from 'react';
import { Formik } from 'formik';

import MainStyles from "../../App.module.scss";

import NotificationSystem from 'react-notification-system';
import notifStyles from "../NotifStyles"

import { withCookies } from 'react-cookie';

import LottieLoader from "../Loader/Loader"
import animationData from "../../assets/homecrush-logo.json"

class ParametrePub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recupInfosCompte: {
        nom: '_',
        prenom: '_'
      },
      showLoader: false
    };
    this.notificationSystem = React.createRef();
  }
  componentDidMount() {

    var user_code = this.props.cookies.get('user_code')
    var rang = this.props.cookies.get('rang')
    this.setState({ rang: rang })

    if (rang >= "0") {
      var bodyy = {
        blossom: "monProfil",
        user_code,
      }


      fetch(
        'https://api.homecrush.fr/control_room.php',
        {
          method: 'POST',
          body: JSON.stringify(bodyy)

        })
        .then(response => response.json())
        .then(response => {

          response = JSON.parse(response)


          this.setState({ numeroCPI: response.numeroCPI, idAgence: response.idagence })

          return response;
        })
        .catch(erreur => {
          console.log('ERROR: ' + erreur);
        });

      this.setState({ user_code, rang })
    }
  }

  addNotif = (message, etat) => {
    this.notificationSystem.current.addNotification({
      message: message,
      level: etat ? etat : "success"
    });
  };

  async _changerImage(blossom, param) {
    let bodyy = {
      blossom: blossom,
      user_code: this.state.user_code,
    }

    if (param === "imagePub") {
      bodyy.image = this.state.imagePub
      bodyy.extension = this.state.extImagePub
    }

    await fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy),
      })
      .then(response => response.json())
      .then(response => {

        response = JSON.parse(response)
        if (response.httpstatus === 200) {
          this.addNotif('Votre image à été modifié avec succès')
        }
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });

  }

  _ajouterPub = (values) => {

    this.setState({ showLoader: true })
    if (!this.state.imagePub) {
      this.setState({ showLoader: false })
      return alert("Veuillez télécharger une photo pour votre publicité.")
    }
    var bodyy = {
      blossom: "ajoutInfo",
      user_code: this.state.user_code,
      titre: values.titre,
      url: values.url,
      description: values.desc,
      image: this.state.imagePub,
      extension: this.state.extImagePub
    }


    console.log(bodyy)
    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy),
      })
      .then(response => response.json())
      .then(response => {
        console.log(response)
        if (response.httpstatus === '200') {
          this.setState({ showLoader: false })
          this.props.refreshList()
        }
        return response
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }

  render() {
    var rang = this.props.cookies.get("rang")

    var imagePub = document.getElementById("imagePub")
    if (imagePub) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileImagePub");
      const img = document.getElementById("imagePub");

      reader.onload = e => {
        img.src = e.target.result;

        var imagePubBase64 = reader.result.substring(reader.result.lastIndexOf("/9j/"));
        var ext = reader.result.substring("data:image/".length, reader.result.indexOf(";base64"))

        this.setState({ imagePub: imagePubBase64, extImagePub: ext })
      }
      fileInput.addEventListener('change', e => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      })
    }
    return (
      <section className={MainStyles.pubLeftContainer}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        {rang === "3" &&
          <article style={{ width: "100%" }}>
            <p className={MainStyles.sousTitre}>Parametres</p>

            <p className={MainStyles.labelParagraphFirst}>Ajouter une photo</p>

            <div>
              <img style={{ paddingTop: "1.5rem" }} id="imagePub" alt="publicité" width={100} src={process.env.PUBLIC_URL + '/images/default.jpeg'} />

              {!this.state.monImagePub &&
                <input className={MainStyles.inputFile} type="file" accept="image/jpg,image/jpeg" id="fileImagePub" />
              }
              {this.state.monImagePub &&
                <div className={MainStyles.formBtn} onClick={() => { this._changerImage("modifierImagePub", "imagePub") }}>Confirmer</div>
              }
            </div>

            <p className={MainStyles.labelParagraphFirst}>Ajouter un titre</p>

            <Formik className={MainStyles.formikContainer}
              initialValues={{ titre: '', desc: '' }}
              validate={values => {
                const errors = {};
                if (!values.titre) {
                  errors.titre = 'Obligatoire';
                }
                if (!values.desc) {
                  errors.desc = 'Obligatoire';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this._ajouterPub(values);
                setSubmitting(false);
              }}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form className={MainStyles.formInsideFormik} onSubmit={handleSubmit}>
                  <div>
                    <input className={MainStyles.formInput} type="input" name="titre" maxLength={30} placeholder="titre de la publicité" onChange={handleChange} onBlur={handleBlur} value={values.titre} />
                    <div className={MainStyles.formError}>{errors.titre && touched.titre && errors.titre}</div>
                  </div>

                  <div style={{ paddingBottom: "1rem", paddingTop: "2.5rem" }}>
                    <p>Ajouter une lien</p>

                    <div style={{ display: "flex" }}>
                      <input className={MainStyles.formInput} type="input" name="url" maxLength={200} placeholder="https://homecrush.fr" onChange={handleChange} onBlur={handleBlur} value={values.url} />
                      <div className={MainStyles.tooltipHelper}>i
                        <span className={MainStyles.tooltipTextHelper}>ex: https://homecrush.fr</span>
                      </div>
                    </div>
                    <div className={MainStyles.formError}>{errors.url && touched.url && errors.url}</div>
                  </div>




                  <div style={{ paddingBottom: "1rem", paddingTop: "2rem" }}>
                    <p>Ajouter une description</p>
                    <input className={MainStyles.formInput} type="input" name="desc" maxLength={100} placeholder="description de la publicité" onChange={handleChange} onBlur={handleBlur} value={values.desc} />
                    <div className={MainStyles.formError}>{errors.desc && touched.desc && errors.desc}</div>

                    {!this.state.showLoader && <button className={MainStyles.formBtn} type="submit" disabled={isSubmitting} style={{ margin: "2rem auto 1rem auto" }}>Créer une publicité</button>}

                    {this.state.showLoader && <LottieLoader animationData={animationData} width={"100%"} height={"auto"} />}
                  </div>
                </form>
              )}
            </Formik>
          </article>
        }
      </section>
    )
  }
};



export default withCookies(ParametrePub);