import React, { Component } from 'react';
import NotifModalCss from './NotifModal.module.scss';

export default class NotifModals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      level: "",
    }

    const modalSuccess = document.getElementById('notifModal');

    if(this.state.level == "success"){
      modalSuccess.style.backgroundColor = "#bda87d";
      modalSuccess.style.borderTop = "5px solid white";
    } 
    if (this.state.level == "error") {
      modalSuccess.style.backgroundColor = "red";
      modalSuccess.style.borderTop = "5px solid #bda87d";
      modalSuccess.style.zIndex = "99999";
    }
  }

  render() {
    return (
      <div className={NotifModalCss.modalContainer} id="notifModal">
        <p className={NotifModalCss.paragraph}>{this.state.message}</p>
      </div>
    )
  }
}
