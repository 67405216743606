import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';



import MainStyles from "../../App.module.scss";

const InsertProperty = (props) => {

  const navigate = useNavigate();
  const [usercode, setUsercode] = useState("");
  const [monRang, setMonRang] = useState("");

  const [progress, setProgress] = useState(0);

  const [etape1, setEtape1] = useState(true);
  const [etape2, setEtape2] = useState(false);
  const [etape3, setEtape3] = useState(false);
  const [etape4, setEtape4] = useState(false);
  const [etape5, setEtape5] = useState(false);



  useEffect(() => {
    setUsercode(props.cookies.cookies.user_code);
    setMonRang(props.cookies.cookies.rang);

    let progress;
    if (etape5) {
      progress = 100;
    } else if (etape4) {
      progress = 75;
    } else if (etape3) {
      progress = 50;
    } else if (etape2) {
      progress = 25;
    } else if (etape1) {
      progress = 0;
    }
    setProgress(progress);
}, [props.cookies.cookies.user_code, props.cookies.cookies.rang, etape1, etape2, etape3, etape4, etape5]);


  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const [notif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [notifType, setNotifType] = useState("");

  const handleRemoveFile = (index) => {
    const updatedFiles = [...acceptedFiles];
    updatedFiles.splice(index, 1);
    setAcceptedFiles(updatedFiles);
  };
  const handleDrop = (newFiles) => {
    const updatedFiles = newFiles.map((file) => {
      return { file, base64String: null };
    });

    Promise.all(
      updatedFiles.map((fileObj) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            fileObj.base64String = reader.result.split(',')[1];
            resolve();
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(fileObj.file);
        });
      })
    )
      .then(() => {
        setAcceptedFiles([...acceptedFiles, ...updatedFiles]);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite lors de la conversion en base 64 :", error);
      });
  };

  const showNotif = (message, type) => {
    setNotif(true);
    setNotifMsg(message);
    setNotifType(type);


    setTimeout(() => {
      setNotif(false);
    }, 3000);
  };

  const addNotif = message => {
    /*
    const notification = notificationSystem.current;
    notification.addNotification({
      message: message,
      level: 'success'
    });*/
  };

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (newFiles) => {
      const filteredFiles = newFiles.filter((file) => {
        return file.type === "image/jpeg" || file.type === "image/png";
      });
      handleDrop(filteredFiles);
    }
  });


    const [coproFields, setCoproFields] = useState({
    nbLot: 0,
    chargesAnnuelles: 0,
    procedureEnCours: false,
  });

  const files = acceptedFiles.map((file, index) => (
    <li key={index}>
      {file.file.name} - {file.file.size} bytes
      <button onClick={() => handleRemoveFile(index)}>Supprimer</button>
    </li>
  ));
  
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const [images, setImages] = useState([]);

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener('load', () => {
        res({
          base64: reader.result,
          name: name,
          type: type.substring(6),
          size: size,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      console.log(newImages);
      setImages([...images, ...newImages]);
    }

    e.target.value = '';
  };

  const insertProperty = async (values) => {
    var bodyy = {
      blossom: "insererBien",
      user_code: usercode,
    }

    if (values.nom) {
      bodyy.nom = values.nom
    } else {
      throw new Error('Le nom est obligatoire');
    }

    if (values.prenom) {
      bodyy.prenom = values.prenom
    }

    if (values.tel) {
      bodyy.tel = values.tel
    } else {
      throw new Error('Le numéro de téléphone est obligatoire');
    }

    if (values.titre) {
      bodyy.titre = values.titre
    }

    if (values.mail) {
      bodyy.mail = values.mail
    } else {
      throw new Error('Le mail est obligatoire');
    }

    if (values.ville) {
      bodyy.ville = values.ville
    } else {
      throw new Error('La ville est obligatoire');
    }

    if(values.cp) {
      bodyy.cp = values.cp
    }

    if (values.superficie) {
      bodyy.superficie = values.superficie
    }

    if (values.perfEnergetique === "") {
      throw new Error('La performance énergétique est obligatoire');
    } else {
      bodyy.perfEnergetique = values.perfEnergetique;
    }
  

    if (values.emission) {
      bodyy.emission = values.emission
    }

    if (typeof values.copropriete !== 'undefined') {
      bodyy.copropriete = values.copropriete
    } else {
      throw new Error('Le champ copropriété est obligatoire');
    }


  if (values.copropriete) {
    if (values.nbLot === null || values.nbLot === undefined) {
      throw new Error('Le nombre de lot est obligatoire si le bien est en copropriété');
    } else {
      bodyy.nbLot = values.nbLot;
    }
        
    if (values.chargesAnnuelles === null || values.chargesAnnuelles === undefined) {
      throw new Error('Les charges annuelles sont obligatoires si le bien est en copropriété');
    } else {
      bodyy.chargesAnnuelles = values.chargesAnnuelles;
    }
  
    if (typeof values.procedureEnCours !== 'undefined') {
      bodyy.procedureEnCours = values.procedureEnCours;
    } else {
      throw new Error('Le champ procédure en cours est obligatoire si le bien est en copropriété');
    }
  } else {
    // Si la copropriété est fausse, les valeurs par défaut seront utilisées
    bodyy.nbLot = 0;
    bodyy.chargesAnnuelles = 0;
    bodyy.procedureEnCours = false;
  }

  if (images.length > 0) {
    bodyy.photos = images.map(img => img.base64);
  }  

  if (values.prix) {
    bodyy.prix = values.prix
  }

  fetch(
    'https://api.homecrush.fr/control_room.php',
    {
      method: 'POST',
      body: JSON.stringify(bodyy),
    })
    .then((response) => response.json())
    .then(response => {
      if (response.status === "Succès") {
        showNotif("Votre annonce a bien été enregistrée", "success");
        props.clickHandler("succès")
      } else {
        addNotif("Une erreur est survenue lors de l'ajout de votre annonce")
      }
    })
    .catch(erreur => {
      console.log('erreur ajout annonce: ' + erreur);
    });
};


  return (
    <article style={{ marginTop: "4rem", marginBottom: "2rem", width: "100%" }}>
      <Formik
        initialValues={{
          nom: '',
          prenom: '',
          tel: '',
          mail: '',
          ville: '',
          titre: '',
          perfEnergetique: '',
          emission: '', // peut-être que cela devrait être un nombre aussi, vérifiez dans la documentation de l'API
          superficie: '',
          copropriete: 'non',
          nbLot: 0,
          chargesAnnuelles: 0,
          procedureEnCours: false,
          photos: [],
          cp: '',
          prix: '',
        }}        
        validate={(values) => {
          const errors = {};
          if (etape1) {
            if (!values.nom) {
              errors.nom = 'Ce champ est obligatoire';
            }
            if (!values.tel) {
              errors.tel = 'Ce champ est obligatoire';
            }
            if (!values.mail) {
              errors.mail = 'Ce champ est obligatoire';
            }
          }
          if (etape2) {
            if (!values.titre) {
              errors.titre = 'Ce champ est obligatoire';
            }
            if (!values.perfEnergetique) {
              errors.perfEnergetique = 'Ce champ est obligatoire';
            }
            if (!values.ville) {
              errors.ville = 'Ce champ est obligatoire';
            }
            if (!values.cp) {
              errors.cp = 'Ce champ est obligatoire';
            }
            if (values.copropriete === 'oui' && !values.nbLot) {
              errors.nbLot = 'Ce champ est obligatoire';
            }
            if (values.copropriete === 'oui' && !values.chargesAnnuelles) {
              errors.chargesAnnuelles = 'Ce champ est obligatoire';
            }
            if (values.copropriete === 'oui' && !values.procedureEnCours) {
              errors.procedureEnCours = 'Ce champ est obligatoire';
            }
            if(!values.prix) {
              errors.prix = 'Ce champ est obligatoire';
            }
          }
          return errors;
        }}
      
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // Fusion des valeurs de votre formulaire avec l'état `coproFields`
            const finalValues = {
              ...values,
              ...coproFields,
            };
        
            console.log(finalValues);
            // Conversion de 'copropriete' en booléen
            finalValues.copropriete = finalValues.copropriete === 'oui' ? true : false;
        
            if (finalValues.superficie === '') {
              finalValues.superficie = "0";
            }
        
            // Assignez une valeur à finalValues.emission indépendamment de values.emission
            finalValues.emission = values.emission || "Pas d'information";


            if (!values.copropriete) {
              finalValues.nbLot = 0;
              finalValues.chargesAnnuelles = 0;
              finalValues.procedureEnCours = "Pas en copropriété";
            }

            // Ajoutez chaque photo au tableau `finalValues.photos`
            for (let imgObj of images) {
              const photo = {
                format: imgObj.type,
                name: imgObj.name,
                size: imgObj.size,
                base64: imgObj.base64,
              };
              finalValues.photos.push(photo);
            }        
        
            if (etape3) {
              // Validation des données à l'étape 3
              if (finalValues.nom && finalValues.tel && finalValues.superficie && finalValues.adresse) {
                // Soumission du formulaire à l'étape 3
                insertProperty(finalValues); // Utilisez `finalValues` au lieu de `values`
                setSubmitting(false);
        
                // Passage à l'étape 4
                setEtape3(false);
                setEtape4(true);
              } else {
                // Gérer les erreurs de validation à l'étape 3
                // Vous pouvez afficher un message d'erreur ou effectuer d'autres actions nécessaires
                setSubmitting(false);
              }
            } else if (etape4) {
              // Soumission finale du formulaire à l'étape 4
              insertProperty(finalValues); // Utilisez `finalValues` au lieu de `values`
              setSubmitting(false);
            } else {
              // Passage à l'étape suivante (étape 2)
              setEtape1(false);
              setEtape2(true);
              setSubmitting(false);
            }
          }, 500);
        }}
        
        >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, validateForm, submitForm }) => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  alignItems: 'center', marginTop :'-5vh' }}>
          <form onSubmit={handleSubmit} className={MainStyles.form} style={{minHeight: '500px', height: 'fit-content', maxWidth: '500px', width: '100%', margin: '0', marginRight: 0, background: 'linear-gradient(to bottom, #544E7B, #3F3A63)', padding: '20px', zIndex: 1, position: 'relative', paddingBottom: '0px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }}>
            <div className={MainStyles.progressBarContainer}>
              <div className={MainStyles.progressBar} style={{ width: `${progress}%` }}>
            </div>
            </div>
              {etape1 && (
                <div>
                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="nom"
                      placeholder="Nom *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nom}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: Dupont</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.nom && touched.nom && errors.nom}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="prenom"
                      placeholder="Prénom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prenom}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: Jean</span>
                    </div>
                  </div>

                  {values.prenom === "" && (
                    <input type="hidden" name="prenom" value={null} />
                  )}

                  <div className={MainStyles.formError}>
                    {errors.prenom && touched.prenom && errors.prenom}
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="tel"
                      placeholder="Téléphone *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: 0123456789</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.tel && touched.tel && errors.tel}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="mail"
                      placeholder="Email *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mail}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: example@mail.com</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.mail && touched.mail && errors.mail}</div>

                  <button 
                    className={MainStyles.nextButton} 
                    type="button" 
                    onClick={() => {
                      if (values.nom && values.tel && values.mail) {
                        setEtape1(false);
                        setEtape2(true);
                    }}
                  }
                  >
                    Suivant
                  </button>
                  <p className={MainStyles.formErrorMdp}>Les champs suivis d'un * sont obligatoires.</p>
                </div>
              )}

              {etape2 && (
                <div>
                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="titre"
                      placeholder="Titre *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.titre}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex:  </span>
                    </div>
                    {values.titre === "" && (
                    <input type="hidden" name="titre" value={null} />
                  )}
                  </div>
                  <div className={MainStyles.formError}>{errors.titre && touched.titre && errors.titre}</div>

                  <div style={{ display: "flex" }}>
                    <select
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="perfEnergetique"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.perfoEnergetique}
                    >
                      <option value="" disabled selected>Performance Energetique*</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                      <option value="F">F</option>
                      <option value="G">G</option>
                    </select>
                    <div className={MainStyles.tooltipHelper}>i
                      <span className={MainStyles.tooltipTextHelper}>Ex: A, B, C...</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.perfEnergetique && touched.perfEnergetique && errors.perfEnergetique}</div>


                  <div style={{ display: "flex" }}>
                    <input
                        className={MainStyles.formInput}
                        style={{ marginTop: "2rem" }}
                        name="superficie"
                        placeholder="Superficie (m²) *"
                        type="number"
                        min="0"
                        pattern="[0-9]*"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.superficie}
                    />
                    <div className={MainStyles.tooltipHelper}>
                        i
                        <span className={MainStyles.tooltipTextHelper}>Ex: 100 m²</span>
                    </div>
                </div>
                <div className={MainStyles.formError}>{errors.superficie && touched.superficie && errors.superficie}</div>


                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="ville"
                      placeholder="Ville *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ville}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: Marseille</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.ville && touched.ville && "Ce champ est obligatoire"}</div>

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="cp"
                      placeholder="Code postal *"
                      type="number"
                      min="01000"
                      max="99999"
                      pattern="[0-9]*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cp}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: 13001</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.cp && touched.cp && errors.cp}</div>

                  <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
                    <span>Copropriété :</span>
                    <label>
                      <Field
                        type="radio"
                        name="copropriete"
                        value="oui"
                        checked={values.copropriete === "oui"}
                        onChange={handleChange}
                      />
                      Oui
                    </label>
                    <label>
                    <Field
                      type="radio"
                      name="copropriete"
                      value="non"
                      checked={values.copropriete === "non"}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === "non") {
                          setCoproFields({
                            nbLot: 0,
                            chargesAnnuelles: 0,
                            procedureEnCours: false,
                          });
                        }
                      }}
                    />
                      Non
                    </label>
                  </div>
                  <div className={MainStyles.formError}>{errors.copropriete && touched.copropriete && errors.copropriete}</div>

                  {values.copropriete === "oui" ? (
                    <div>
                      <div style={{ display: "flex" }}>
                        <input
                          className={MainStyles.formInput}
                          style={{ marginTop: "2rem" }}
                          name="nbLot"
                          placeholder="Nombre de lots *"
                          type="number"
                          min="0"
                          pattern="[0-9]*"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nbLot}
                        />
                        <div className={MainStyles.tooltipHelper}>
                          i
                          <span className={MainStyles.tooltipTextHelper}>Ex: 10</span>
                        </div>
                      </div>
                      <div className={MainStyles.formError}>{errors.nbLot && touched.nbLot && errors.nbLot}</div>

                      <div style={{ display: "flex" }}>
                        <input
                          className={MainStyles.formInput}
                          style={{ marginTop: "2rem" }}
                          name="chargesAnnuelles"
                          placeholder="Charges annuelles *"
                          type="number"
                          min="0"
                          pattern="[0-9]*"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.chargesAnnuelles}
                        />
                        <div className={MainStyles.tooltipHelper}>
                          i
                          <span className={MainStyles.tooltipTextHelper}>Ex: 1000 €</span>
                        </div>
                      </div>
                      <div className={MainStyles.formError}>
                        {errors.chargesAnnuelles && touched.chargesAnnuelles && errors.chargesAnnuelles}
                      </div>

                      <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
                        <span>Procédure :</span>
                        <label>
                          <Field
                            type="radio"
                            name="procedureEnCours"
                            value="oui"
                            checked={values.procedureEnCours === "oui"}
                            onChange={handleChange}
                          />
                          Oui
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="procedureEnCours"
                            value="non"
                            checked={values.procedureEnCours === "non"}
                            onChange={handleChange}
                          />
                          Non
                        </label>
                      </div>
                      <div className={MainStyles.formError}>{errors.procedureEnCours && touched.procedureEnCours && errors.procedureEnCours}</div>
                    </div>
                  ) : null}

                  <div style={{ display: "flex" }}>
                    <input
                      className={MainStyles.formInput}
                      style={{ marginTop: "2rem" }}
                      name="prix"
                      placeholder="Prix *"
                      type="number"
                      min="0"
                      pattern="[0-9]*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prix}
                    />
                    <div className={MainStyles.tooltipHelper}>
                      i
                      <span className={MainStyles.tooltipTextHelper}>Ex: 100 000 €</span>
                    </div>
                  </div>
                  <div className={MainStyles.formError}>{errors.prix && touched.prix && errors.prix}</div>
                  <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    
                    
                    <button
                      className={MainStyles.prevButton}
                      type="button"
                      onClick={() => {
                        setEtape2(false);
                        setEtape1(true);
                      }}
                    >
                      Retour
                    </button>
                    <button
                      className={MainStyles.nextButton}
                      type="submit"
                      onClick={() => {
                        if (
                          values.ville && 
                          values.cp &&
                          values.prix &&
                          values.perfEnergetique && 
                          values.titre && 
                          values.copropriete && 
                          (values.copropriete === 'non' || 
                          (values.nbLot && values.chargesAnnuelles && values.procedureEnCours))
                        ) {
                          
                          setEtape2(false);
                          setEtape3(true);
                        }
                      }}
                    >
                      Suivant
                    </button>
                  </div>
                  <p className={MainStyles.formErrorMdp}>Les champs suivis d'un * sont obligatoires.</p>
                </div>
              )}

            {etape3 && (
                <div>
                  {/* Cette partie est la section de téléchargement d'images */}
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} onChange={uploadImage} /> {/* Ajout du onChange ici */}
                    <p>Glissez et déposez des fichiers ici ou cliquez pour sélectionner</p>
                  </div>
                  <aside>
                    <h4>Fichiers</h4>
                    <ul>{files}</ul>
                  </aside>
                  <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <button
                    className={MainStyles.prevButton}
                    type="button"
                    style={{ marginTop: "2rem", margin: "auto" }}
                    onClick={() => {
                      setEtape3(false);
                      setEtape2(true);
                    }}
                    disabled={isSubmitting}
                  >
                    Retour
                  </button>
                  <button
                    className={MainStyles.nextButton}
                    type="submit"
                    style={{ marginTop: "2rem", margin: "auto" }}
                    onClick={() => {
                      setEtape3(false);
                      setEtape4(true);
                    }}
                  >
                    Suivant
                  </button>
                  </div>
                  <p className={MainStyles.formErrorMdp}>Les champs suivis d'un * sont obligatoires.</p>
                </div>
              )}

              {etape4 && (
                <div>
                  <h2>Récapitulatif des données</h2>
                  <p>Nom: {values.nom}</p>
                  <p>Téléphone: {values.tel}</p>
                  <p>superficie: {values.superficie}</p>
                  <p>Images:</p>
                  {acceptedFiles.length > 0 && (
                    <ul>
                      {acceptedFiles.map((file, index) => (
                        <li key={index}>{file.file.name}</li>
                      ))}
                    </ul>
                  )}
                  <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <button
                    className={MainStyles.prevButton}
                    type="button"
                    style={{ marginTop: "2rem", margin: "auto" }}
                    disabled={isSubmitting}
                    onClick={() => {
                      setEtape4(false);
                      setEtape3(true);
                    }}
                  >
                    Retour
                  </button>
                  <button
                    className={MainStyles.nextButton}
                    type="button"
                    style={{ marginTop: "2rem", margin: "auto" }}
                    disabled={isSubmitting}
                    onClick={async () => {
                      const isFormValid = await validateForm();
                      if (isFormValid) {
                          await submitForm();
                      }
                      setEtape4(false);
                      setEtape5(true);
                  }}
                  >
                    Valider
                  </button>
                </div>
                </div>
              )}
              {etape5 && (
                <div>
                  <p>Merci de votre confiance ! Un professionnel vous contactera rapidement.</p>
                  <button onClick={() => navigate('/')}>Retourner à l'accueil</button>
                  <button onClick={() => navigate('/Ventes')}>Vendre un autre bien</button>
                </div>
              )}

            </form>
            {/* Sidebar des étapes */}
          <div className={MainStyles.sidebar_step} style={{height:"400px", overflow: "auto"}}>
          <div className={etape1 ? MainStyles.activeStep : MainStyles.step}>
              <div className={MainStyles.stepContent}>
                  <div className={MainStyles.stepDot}></div>
                  <div className={MainStyles.stepText}>Vos informations</div>
              </div>
              {etape1 && <p>Système de diffusion et de<br></br> recherche d'annonces<br></br> immobiliéres de particuliers.</p>}
          </div>

          <div className={etape2 ? MainStyles.activeStep : MainStyles.step}>
              <div className={MainStyles.stepContent}>
                  <div className={MainStyles.stepDot}></div>
                  <div className={MainStyles.stepText}>Caractéristiques</div>
              </div>
              {etape2 && <p>Système de diffusion et de<br></br> recherche d'annonces.</p>}
          </div>
          <div className={etape3 ? MainStyles.activeStep : MainStyles.step}>
              <div className={MainStyles.stepContent}>
                  <div className={MainStyles.stepDot}></div>
                  <div className={MainStyles.stepText}>Images</div>
              </div>
              {etape3 && <p>Système de diffusion et de<br></br> recherche d'annonces.</p>}
          </div>
          <div className={etape4 ? MainStyles.activeStep : MainStyles.step}>
              <div className={MainStyles.stepContent}>
                  <div className={MainStyles.stepDot}></div>
                  <div className={MainStyles.stepText}>Validation</div>
              </div>
              {etape4 && <p>Système de diffusion et de<br></br> recherche d'annonces.</p>}
          </div>
        </div>
      </div>
      )}
      </Formik>
    </article>
  );
};

export default withCookies(InsertProperty);

//<button onClick={() => navigate('/Ventes')}>Vendre un autre bien</button> fait de la D