import React, { useEffect } from "react";
import Styles from "./ScrollerHorizontal.module.scss";

import { withCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";


function ScrollerHorizontal(props) {
    const [items, setItems] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    let navigate = useNavigate()

    var user_code = props.cookies.get('user_code')


    useEffect(() => {
        setItems(props.data)
    }, [props.data]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);


    const handleClick = (id, showlink) =>
        ({ getItemById, scrollToItem }) => {

            navigate(showlink)
            const itemSelected = isItemSelected(id);

            setSelected((currentSelected) =>
                itemSelected
                    ? currentSelected.filter((el) => el !== id)
                    : currentSelected.concat(id)
            );
        };


    return (
        <div className={Styles.container}>
        <ScrollMenu className={Styles.scroller} LeftArrow={LeftArrow} RightArrow={RightArrow}>
            {items.map(({ id, prix, photo, photoExternalLink, ville, showlink, pro }) => (
                <Card
                    itemId={id} // NOTE: itemId is required for track items
                    title={id}
                    pro={pro}
                    prix={prix}
                    ville={ville}
                    photo={photo}
                    photoExternalLink={photoExternalLink}
                    key={id}
                    showlink={showlink}
                    onClick={handleClick(id, showlink)}
                    selected={isItemSelected(id)}
                />
            ))}
        </ScrollMenu>
        <div className={Styles.fader}></div>
        </div>
    );
};

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    return (
        <div className={Styles.arrowLeft} disabled={isFirstItemVisible} onClick={() => scrollPrev()}><span>{"<"}</span></div>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        <div className={Styles.arrowRight} disabled={isLastItemVisible} onClick={() => scrollNext()}><span>{">"}</span></div>
    );
}

function Card({ onClick, selected, prix, photo, photoExternalLink, ville, pro, itemId, showlink }) {
    let navigate = useNavigate()

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, l => l.toUpperCase());
    }

    const visibility = React.useContext(VisibilityContext);

   // Logique pour déterminer l'URL de l'image
        let photoUrl;
        if (photo && photo.length > 0) {
            photoUrl = "https://" + photo[0];
        } else if (photoExternalLink && photoExternalLink.length > 0) {
            photoUrl = photoExternalLink[0]; // Utiliser la première image externe, ou vous pouvez faire une logique supplémentaire
        } else {
            photoUrl = "https://api.homecrush.fr/imgDefaut/default.jpeg"; 
        }


    return (
        <div className={Styles.card} onClick={() => {
            navigate(showlink, itemId);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        } style={{ width: '250px' }} tabIndex={0}>
            <div className={Styles.cardEach}>
                {pro === "1" &&
                    <div className={Styles.etiquette}>Pro</div>
                }
                <img className={Styles.cardImage} src={photoUrl} alt={itemId} />
                <div className={Styles.prix}>{new Intl.NumberFormat('fr-FR', { style: 'currency', maximumFractionDigits: 0, currency: 'EUR' }).format(parseInt(prix))}</div>
                <div className={Styles.divider}></div>
                <div className={Styles.ville}>{capitalizeWords(ville.toLowerCase())}</div>
            </div>
        </div>
    );
}


export default withCookies(ScrollerHorizontal);
