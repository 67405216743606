import React, { Component } from "react";
import { withCookies } from "react-cookie";

import MainStyles from '../App.module.scss';
import Footer from "../components/Footer/Footer";
import RechercheBienForm from "../components/Recherche/RechercheBienForm";



class Recherche extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: true,
      showInscription: false,
    };
  }

  render() {
    const { cookies } = this.props;

    // Style pour le dégradé de couleur en fond du reste de la page
    const gradientBackgroundStyle = {
      marginTop : '2rem',
    };
 
    return (
      <section className={MainStyles.pageContainer}>
        {/* Contenu avec dégradé de couleur */}
        <div style={gradientBackgroundStyle}>
          <h1 className={MainStyles.pageTitle2}>Je recherche un</h1>
          <h1 className={MainStyles.boldTextdoree}>Bien à l'achat</h1>
          <RechercheBienForm />
          <Footer />
        </div>
      </section>
    );
  }
}

export default withCookies(Recherche);
