import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import HttpsRedirect from 'react-https-redirect';

import { CookiesProvider } from 'react-cookie';

import { createBrowserHistory } from "history";

import { BrowserRouter } from 'react-router-dom';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import TagManager from 'react-gtm-module'

let initialStore = { state: 0 }
const countReducer = function () { };
const store = createStore(countReducer, initialStore);

const history = createBrowserHistory();

const tagManagerArgs = {
  gtmId: 'G-FMBTS3P0TG'
}
TagManager.initialize(tagManagerArgs)



ReactDOM.render(
  <CookiesProvider>
    <React.StrictMode>
      <BrowserRouter history={history}
        forceRefresh={false}>
        <Provider store={store}>
          <HttpsRedirect>
            <App />
          </HttpsRedirect>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </CookiesProvider>,
  document.getElementById('root')
);
