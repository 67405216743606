import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import MainStyles from '../App.module.scss';

import NotificationSystem from 'react-notification-system';
import notifStyles from "../components/NotifStyles";

import Connexion from '../components/Connexion/Connexion'
import Inscription from '../components/Inscription/Inscription'
import Profil from '../components/Compte/Profil'
import MesPubs from '../components/Compte/MesPubs'
import ParametreAgence from '../components/Compte/ParametreAgence'
import Plateforme from '../components/Compte/Plateforme';
import Footer from '../components/Footer/Footer';

class Compte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: true,
      showInscription: false,
    };

    this.notificationSystem = React.createRef();
    this.detectAnchor = this.detectAnchor.bind(this)
  }

  componentDidMount() {
    var user_code = this.props.cookies.get('user_code')
    if (user_code) {
      this.setState({ showProfil: true, showConnexion: false, showInscription: false, showParam: false })
    }
  }

  showInscriptionFunction = () => {
    this.setState({ showProfil: false, showConnexion: false, showInscription: true, showParam: false })
  }

  detectAnchor(event) {
    if (event === "#profil") {
      this.setState({ showProfil: true, showConnexion: false, showInscription: false, showParam: false })
    }

  }

  addNotif = () => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: 'Vous avez bien été déconnecté',
      level: 'success'
    });
  };

  deconnexion() {
    const { cookies } = this.props;

    cookies.remove("user_code", { path: "/" })
    cookies.remove("rang", { path: "/" })
    cookies.remove("pp", { path: "/" })
    cookies.remove("prenom", { path: "/" })
    cookies.remove("nom", { path: "/" })

    this.addNotif()
    this.setState({ showProfil: false, showConnexion: true, showInscription: false, showPlateform: false, showParam: false, showBien: false })
  }

  render() {
    const { cookies } = this.props;

    var rang = this.props.cookies.get("rang")
    return (
      <div className={MainStyles.pageContainerCompte}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        {cookies.get('user_code') === undefined &&
          <>
            <h1 className={MainStyles.pageTitle}>Connectez ou inscrivez-vous à notre site web.</h1>
            <p className={MainStyles.article}>Découvrez cette nouvelle application immobilière « Homecrush » accessible pour tous, qui vous séduira, facilitera vos recherches immobilières.</p>
          </>
        }
        {cookies.get('user_code') === undefined &&
          <div className={MainStyles.buttonBar} style={{ marginBottom: "80px" }}>
            <span className={MainStyles.btnFiltre}
              style={{
                backgroundColor: this.state.showConnexion ? '#0DA8DB' : 'transparent',
                color: this.state.showConnexion ? '#fff' : '#BDA87D',
                marginRight: "0.5rem",
              }}
              onClick={() => this.setState({ showConnexion: true, showInscription: false, showPlateform: false, showParam: false })}>Connexion</span>

            <span className={MainStyles.btnFiltre}
              style={{
                backgroundColor: this.state.showInscription ? '#0DA8DB' : 'transparent',
                color: this.state.showInscription ? '#fff' : '#BDA87D',
              }}
              onClick={() => this.setState({ showConnexion: false, showInscription: true, showPlateform: false, showParam: false })}>Inscription</span>
          </div>
        }

        {cookies.get('user_code') !== undefined &&
          <section>
            <h1 className={MainStyles.pageTitle}>Bienvenue {cookies.get('prenom')},</h1>
            <h2 className={MainStyles.pageSubTitle}>Retrouvez les paramètres de votre compte et vos bien en vente.</h2>
          </section>
        }


        {cookies.get('user_code') !== undefined &&
          <div className={MainStyles.buttonBar} style={{ marginBottom: "80px" }}>
            <span className={MainStyles.btnFiltre} style={{ color: "#ffffff" }} onClick={() => this.deconnexion()}>Déconnexion</span>

            <span className={MainStyles.btnFiltre}
              style={{
                backgroundColor: this.state.showProfil ? '#0DA8DB' : 'transparent',
                color: this.state.showProfil ? '#fff' : '#BDA87D',
              }}
              onClick={() => this.setState({ showConnexion: false, showInscription: false, showPlateform: false, showParam: false, showBien: false, showProfil: true, showParamPub: false, showParamAgence: false })}>Mon profil</span>

            {(rang === "1" || rang === "3") &&
              <span className={MainStyles.btnFiltre}
                style={{
                  backgroundColor: this.state.showParamAgence ? '#0DA8DB' : 'transparent',
                  color: this.state.showParamAgence ? '#fff' : '#BDA87D',
                }}
                onClick={() => this.setState({ showConnexion: false, showInscription: false, showPlateform: false, showParam: false, showBien: false, showProfil: false, showParamPub: false, showParamAgence: true })}>Mon agence</span>
            }
            {(rang === "1" || rang === "3") &&
              <span className={MainStyles.btnFiltre}
                style={{
                  backgroundColor: this.state.showPlateform ? '#0DA8DB' : 'transparent',
                  color: this.state.showPlateform ? '#fff' : '#BDA87D',
                }}
                onClick={() => this.setState({ showConnexion: false, showInscription: false, showPlateform: true, showParam: false, showBien: false, showProfil: false, showParamPub: false, showParamAgence: false })}>Ma plateforme</span>
            }

            {(rang === "3") &&
              <span className={MainStyles.btnFiltre}
                style={{
                  backgroundColor: this.state.showParamPub ? '#0DA8DB' : 'transparent',
                  color: this.state.showParamPub ? '#fff' : '#BDA87D',
                }}
                onClick={() => this.setState({ showConnexion: false, showInscription: false, showPlateform: false, showParam: false, showBien: false, showProfil: false, showParamPub: true, showParamAgence: false })}>Ajouter une pub</span>
            }
          </div>
        }

        <div style={{
          backgroundColor: (this.state.showProfil || this.state.showBien || this.state.showParamPub) ? "transparent" : "#fff",
          borderTop: (this.state.showProfil || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
          borderLeft: (this.state.showProfil || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
          borderRight: (this.state.showProfil || this.state.showBien || this.state.showParamPub) ? "0px solid #bda87d" : "1px solid #bda87d",
        }} className={MainStyles.mainContainerCompte}>

          {this.state.showConnexion && <Connexion clickHandler={this.detectAnchor} showInscription={this.showInscriptionFunction} />}
          {this.state.showInscription && <Inscription clickHandler={this.detectAnchor} />}

          {this.state.showProfil && <Profil />}

          {this.state.showParamAgence && <ParametreAgence clickHandler={this.detectAnchor} />}
          {this.state.showParamPub && <MesPubs clickHandler={this.detectAnchor} />}
          {this.state.showPlateform && <Plateforme clickHandler={this.detectAnchor} />}
        </div>


        <Footer />
      </div>
    )
  }
};

export default withCookies(Compte);