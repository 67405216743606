import React, { useEffect } from 'react'
import NotifModalCss from './NotifModal.module.scss'

const NotifModal = ({ notif, notifMsg, notifType }) => {

  useEffect(() => {
    const modalSuccess = document.getElementById('notifModal');

    if(notifType === "success"){
      modalSuccess.style.backgroundColor = "#bda87d";
      modalSuccess.style.borderTop = "5px solid white";
    } 
    if (notifType === "error") {
      modalSuccess.style.backgroundColor = "red";
      modalSuccess.style.borderTop = "5px solid #bda87d";
      modalSuccess.style.zIndex = "99999";    }
  }, [notifType])

  return (
    <aside className={NotifModalCss.modalContainer} id="notifModal" notif={notif} notifType={notifType} 
    style={{right: notif ? "10px" : "0px", transform: notif ? "translateX(0%)" : "translateX(100%)", maxWidth: notif ? "350px" : "0px"}}>
      <p className={NotifModalCss.paragraph} notifMsg={notifMsg}>{notifMsg}</p>
    </aside>
  )
}

export default NotifModal