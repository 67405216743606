import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import NotificationSystem from 'react-notification-system';

import MainStyles from "../../App.module.scss";
import notifStyles from "../NotifStyles"
import Parametre from '../Parametre'

import LottieLoader from "../Loader/Loader"
import animationData from "../../assets/homecrush-logo.json"


// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice'
//   }
// };


class Profil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recupInfosCompte: {
        nom: '_',
        prenom: '_',
      },
      loading: false
    };

    this.notificationSystem = React.createRef();
  }

  addNotif = (message, etat) => {
    this.notificationSystem.current.addNotification({
      message: message,
      level: etat ? etat : "success"
    });
  };

  recupInfoCompte = () => { }

  componentDidMount() {
    var bodyy = {
      blossom: "monProfil",
      user_code: this.props.allCookies.user_code,
    }

    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {
        response = JSON.parse(response)
        response.photoDeProfil = 'https://' + response.photoDeProfil;
        this.setState({ recupInfosCompte: response, numeroCPI: response.numeroCPI, idAgence: response.idagence });

        if (response.nom !== "") {
          this.props.cookies.set('nom', response.nom,
          { path: '/' })
        }
        if (response.prenom !== "") {
          this.props.cookies.set('prenom', response.prenom,
          { path: '/' })
        }

        if (response.photoDeProfil !== "") {
          this.props.cookies.set('pp', response.photoDeProfil,
          { path: '/' })
        }
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });

    if (this.props.allCookies.rang !== "0") {
      bodyy = {
        blossom: "monAgence",
        user_code: this.props.allCookies.user_code,
      }

      fetch(
        'https://api.homecrush.fr/control_room.php',
        {
          method: 'POST',
          body: JSON.stringify(bodyy)
        })
        .then(response => response.json())
        .then(response => {
          response = JSON.parse(response)

          if (response.photoAgence !== undefined) {
            response.photoAgence = 'https://' + response.photoAgence
          }
          if (response.logo !== undefined) {
            response.logo = 'https://' + response.logo
          }
          if (response.imageDirigeant !== undefined) {
            response.imageDirigeant = 'https://' + response.imageDirigeant
          }
          this.setState({ recupInfosAgence: response, }) // On ajoute les données recu au state
          return response;
        })
        .catch(erreur => {
          console.log('ERROR: ' + erreur);
        });
    }
  }


  _changerImage = (blossom, param) => {
    var bodyy = {
      blossom: blossom,
      user_code: this.props.allCookies.user_code,
    }
    if (blossom === "modifierPhotoProfilPro") {
      if (this.state.idAgence) {
        bodyy.idagence = this.state.idAgence
      }
    }
    // if (blossom === "modifierPhotoProfil") {
    //   if (bodyy.user_code) {
    //     bodyy.user_code = this.state.idAgence
    //   }
    // }
    if (param === "lelogo") {
      bodyy.image = this.state.monLogo
      bodyy.extension = this.state.monExt
    }

    if (param === "profilPro") {
      bodyy.image = this.state.monProfilPro
      bodyy.extension = this.state.monExtProfilPro
    }

    if (param === "profilPar") {
      bodyy.image = this.state.monProfilPar
      bodyy.extension = this.state.monExtProfilPar
    }

    if (param === "photoagence") {
      bodyy.image = this.state.maPhotoAgence
      bodyy.extension = this.state.monExtAgence
    }

    if (param === "modifierImageDirigeant") {
      bodyy.image = this.state.maPhotoDir
      bodyy.extension = this.state.monExtDir
    }

    this.setState({ loading: true })

    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)
      })
      .then(response => response.json())
      .then(response => {


        if (response.httpstatus === 200) {
          this.addNotif(response.info)
          this.setState({ loading: false, monProfilPro: null, monProfilPar: null })
        } else {
          this.addNotif(response.info, "error")
          this.setState({ loading: false })
        }
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }

  render() {
    var profilPro = document.getElementById("fileProfilProfil")
    if (profilPro) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileProfilProfil");
      const img = document.getElementById("imageProfil");


      reader.onload = e => {
        img.src = e.target.result;

        var imageDiriBase64 = reader.result.substring(reader.result.lastIndexOf("/9j/"));//on enlève tous ce qui se trouve avant /9j/ dans l'image en base 64
        var ext = reader.result.substring("data:image/".length, reader.result.indexOf(";base64"))//On récupère l'extension de l'image


        this.setState({ monProfilPro: imageDiriBase64, monExtProfilPro: ext })

      }
      fileInput.addEventListener('change', e => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      })

    }

    var profilPar = document.getElementById("fileProfilPar")
    if (profilPar) {
      const reader = new FileReader();
      const fileInput = document.getElementById("fileProfilPar");

      reader.onload = e => {
        const img = document.getElementById("imageProfil");
        img.src = e.target.result;

        var imageDiriBase64 = reader.result.substring(reader.result.lastIndexOf("/9j/"));
        var ext = reader.result.substring("data:image/".length, reader.result.indexOf(";base64"))


        this.setState({ monProfilPar: imageDiriBase64, monExtProfilPar: ext })

      }
      fileInput.addEventListener('change', e => {
        const f = e.target.files[0];
        reader.readAsDataURL(f);
      })

    }


    return (
      <section className={MainStyles.profilContainer}>

        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        <article className={MainStyles.leftPart}>
          <img id="imageProfil" className={MainStyles.image} alt="votre profil" src={this.state.recupInfosCompte.photoDeProfil} />
          <p className={MainStyles.nom}>{this.state.recupInfosCompte.nom} {this.state.recupInfosCompte.prenom}</p>


          {this.props.allCookies.rang === "0" &&
            <div>
              {!this.state.monProfilPar &&
                <input className={MainStyles.inputFileBlue} type="file" accept="image/jpg,image/jpeg" id="fileProfilPar" />
              }

              {this.state.monProfilPar &&
                <div>
                  {!this.state.loading &&
                    <div id="boutonConfirmer" className={MainStyles.formBtn} onClick={() => { this._changerImage("modifierPhotoProfil", "profilPar") }}>Confirmer</div>
                  }
                </div>
              }
              {this.state.loading &&
                <div className={MainStyles.loader}>
                  <LottieLoader animationData={animationData} width={300} height={300} />
                </div>
              }
            </div>
          }



          {(this.props.allCookies.rang === "1" || this.props.allCookies.rang === "3") &&
            <div>
            
              {!this.state.monProfilPro &&
                <input className={MainStyles.inputFileBlue} type="file" accept="image/jpg,image/jpeg" id="fileProfilProfil" />
              }
              {this.state.monProfilPro &&
                <div>
                  {!this.state.loading &&
                    <div className={MainStyles.formBtn} onClick={() => { this._changerImage("modifierPhotoProfilPro", "profilPro") }}>Confirmer</div>
                  }
                </div>
              }
              {this.state.loading &&
                <div className={MainStyles.loader}>
                  <LottieLoader animationData={animationData} width={300} height={300} />
                </div>
              }
            </div>
          }
        </article>

        <article className={MainStyles.rightPart}>
          <Parametre clickHandler={this.detectAnchor} />
        </article>
      </section>
    )
  }
};



export default withCookies(Profil);