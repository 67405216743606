  import React, { useState, useEffect } from "react";
  import { withCookies } from "react-cookie";
  import { useNavigate, useLocation } from "react-router-dom";
  import Collapsible from "react-collapsible";

  import MainStyles from "../App.module.scss";

  import CarouselComp from "../components/Carousel/CarouselComp";
  import NotifModal from "../components/NotifModal/NotifModal";
  import Footer from "../components/Footer/Footer";

  import ScrollerHorizontal from "../components/ScrollerHorizontal/ScrollerHorizontal"

  const MandatCollap = (props) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>{props.title}</div>
        {props.isActive ? (
          <div style={{ color: props.isActive ? "#9F99C7" : "#BDA87D" }}>-</div>
        ) : (
          <div style={{ color: props.isActive ? "#9F99C7" : "#BDA87D" }}>+</div>
        )}
      </div>
    );
  };

  function Bien(props) {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataMail, setDataMail] = useState(undefined)
    const [dataTel, setDataTel] = useState(undefined)
    const [dataImage, setDataImage] = useState(undefined)

    const [dataUser, setDataUser] = useState(undefined)

    const [isFalseDescr, setIsFalseDescr] = useState(false);
    const [isFalseMandat, setIsFalseMandat] = useState(false);
    const [isFalseCopro, setIsFalseCopro] = useState(false);
    const [isFalseImpot, setIsFalseImpot] = useState(false);
    const [isFalsePerf, setIsFalsePerf] = useState(false);

    const [initCoups, setInitCoups] = useState(false);

    const [couleurLogo, setCouleurLogo] = useState(false);
    const [couleurLogoBg, setCouleurLogoBg] = useState(false);

    const [reponseAdd, setReponseAdd] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [reponseSupp, setReponseSupp] = useState(false);

    const [notif, setNotif] = useState(false);
    const [notifMsg, setNotifMsg] = useState("");
    const [notifType, setNotifType] = useState("");

    const [biensAgent, setBiensAgent] = useState([])

    const [nombreLike, setNombreLike] = useState(0);

    const showNotif = (message, type) => {
      setNotif(true);
      setNotifMsg(message);
      setNotifType(type);

      setTimeout(() => {
        setNotif(false);
      }, 3000);
    };

    const location = useLocation();

    useEffect(() => {
      if (location.state === null) {
        var bodyy = {
          blossom: location.pathname + location.search,
        };

        fetch("https://api.homecrush.fr/control_room.php", {
          method: "POST",
          body: JSON.stringify(bodyy),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response[0].photoExternalLink) {
              response[0].externalImages = response[0].photoExternalLink;
              response[0].cleanedPhotoLinks = response[0].photoExternalLink.map(link => link.replace(/^http?:\/\//, ''));
            } 
            else if (!response[0].imagesBien || !response[0].photoData) {
                response[0].externalImages = [];
                response[0].cleanedPhotoLinks = [];
            }

            response[0].imagesBien = response[0].photo;
            response[0].imageAgent = response[0].photoAgent;
            response[0].imageLogo = response[0].imageLogo;
            delete response[0].photo;
            delete response[0].photoAgent;
            setData(response[0]); // On ajoute les données recu au state
            _verifCoeur(response[0].id, response[0].idHomecrush, response[0].idAc3, response[0].idHector, response[0].idApimo);
            if (response[0].createur) {
              var bodyy2 = {
                blossom: "getUser",
                user_code: response[0].createur
              };
              fetch("https://api.homecrush.fr/control_room.php", {
                method: "POST",
                body: JSON.stringify(bodyy2),
              })
                .then((response) => response.json())
                .then((response) => {
                  if (response[0].mail) {
                    setDataMail(response[0].mail);
                  }
                  if (response[0].telephone) {
                    setDataTel(response[0].telephone);
                  }
                  if (response[0].image) {
                    setDataImage('https://' + response[0].image);
                  }
                  setDataUser(response[0])
                })
                .catch((erreur) => {
                  console.log("ERROR: " + erreur);
                });
            }
            getBiensAgentAsync()
            getLike(response)
          })
          .catch((erreur) => {
            console.log("ERROR: " + erreur);
          });
      } else {
        setData(location.state); // On ajoute les données recu au state
      }
    }, [location.pathname]);


    async function getBiensAgentAsync() {

      var pathArray = location.pathname.split('/');
      var bodyy = {
        blossom: "/" + pathArray[1] + "/" + pathArray[2] + "/" + pathArray[3],
      };

      const response = await fetch("https://api.homecrush.fr/control_room.php", {
        method: "POST",
        body: JSON.stringify(bodyy),
      })

      const data = await response.json();

      setBiensAgent(data)



      return data;
    }



    const _verifCoeur = (id, idHomecrush, idAc3, idHektor, idApimo) => {
      const { cookies } = props;
      var usrcd = cookies.get("user_code");

      var bodyy = {
        blossom: "verifCoeur",
        user_code: usrcd,
        idBien: id,
        idAc3: idAc3 ? idAc3 : "",
        idHomecrush: idHomecrush ? idHomecrush : "",
        idHector: idHektor ? idHektor : "",
        idApimo: idApimo ? idApimo : ""
      };
      if (usrcd && usrcd !== null) {
        // Récupération des données de l'API
        fetch("https://api.homecrush.fr/control_room.php", {
          method: "POST",
          body: JSON.stringify(bodyy),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("Bien aimé ?", response)
            if (response.info === "false") {
              setInitCoups(false);
              setCouleurLogo("#332E57");
              setCouleurLogoBg("#fff");
            } else if (response.info === "true") {
              setInitCoups(true);
              setCouleurLogo("#fff");
              setCouleurLogoBg("#0da8db");
            }
            return response;
          })
          .catch((erreur) => {
            console.log("ERROR: " + erreur);
          });
      }
    };

    const _switchCoups = (id, idAc3, idHomecrush, idHektor, idApimo) => {
      const { cookies } = props;
      var usrcd = cookies.get("user_code");
      if (usrcd === "" || usrcd === undefined) {
        showNotif("Veuillez vous connecter pour ajouter ce bien à votre liste", "error");
        setInitCoups(false);
        setCouleurLogo("#332E57");
      } else {
        if (initCoups === true) {
          _suppCoups(id, idAc3, idHomecrush, idHektor, idApimo);
          setInitCoups(false);
          setCouleurLogo("#332E57");
          setCouleurLogoBg("#fff");
          showNotif("Bien supprimé de votre liste", "success");
        } else {
          _ajoutCoups(id, idAc3, idHomecrush, idHektor, idApimo);
          setInitCoups(true);
          setCouleurLogo("#fff");
          setCouleurLogoBg("#0da8db");
          showNotif("Bien enregistré dans votre liste", "success");
        }
      }
    };

    const _ajoutCoups = (id, idAc3, idHomecrush, idHektor, idApimo) => {
      const { cookies } = props;
      var usrcd = cookies.get("user_code");
      var bodyy = {
        blossom: "addCoeur",
        user_code: usrcd,
        idBien: id,
        idAc3: idAc3 ? idAc3 : "",
        idHomecrush: idHomecrush ? idHomecrush : "",
        idHector: idHektor ? idHektor : "",
        idApimo: idApimo ? idApimo : "-1"
      };
      if (bodyy.user_code === null) {
        showNotif("Veuillez vous connecter pour ajouter ce bien à votre liste", "error");
      } else {
        console.log("bodyy", bodyy)
        // Récupération des données de l'API
        fetch("https://api.homecrush.fr/control_room.php", {
          method: "POST",
          body: JSON.stringify(bodyy),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response)
            setReponseAdd(response);
            setIsLoading(false);
            setNombreLike(nombreLike + 1)
            return response;
          })
          .catch((erreur) => {
            console.log(erreur);
          });
      }
    };

    const _suppCoups = (id, idAc3, idHomecrush, idHektor, idApimo) => {
      const { cookies } = props;
      var usrcd = cookies.get("user_code");
      var bodyy = {
        blossom: "suppCoeur",
        user_code: usrcd,
        idBien: id,
        idAc3: idAc3 ? idAc3 : "",
        idHomecrush: idHomecrush ? idHomecrush : "",
        idHector: idHektor ? idHektor : "",
        idApimo: idApimo ? idApimo : ""
      };

      // Récupération liste coups de coeur
      fetch("https://api.homecrush.fr/control_room.php", {
        method: "POST",
        body: JSON.stringify(bodyy),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response)
          setReponseSupp(response);
          setIsLoading(false);
          setNombreLike(nombreLike - 1)
          return response;
        })
        .catch((erreur) => {
          console.log("ERROR: " + erreur);
        });
      //_attemptCoupsdecoeurAsync()
    };

    const switchIsFalse = (value) => {
      var res;
      if (value === "descr") {
        isFalseDescr === true ? (res = false) : (res = true);
        setIsFalseDescr(res);
      }
      if (value === "mandat") {
        isFalseMandat === true ? (res = false) : (res = true);
        setIsFalseMandat(res);
      }
      if (value === "copro") {
        isFalseCopro === true ? (res = false) : (res = true);
        setIsFalseCopro(res);
      }
      if (value === "impot") {
        isFalseImpot === true ? (res = false) : (res = true);
        setIsFalseImpot(res);
      }
      if (value === "perf") {
        isFalsePerf === true ? (res = false) : (res = true);
        setIsFalsePerf(res);
      }
    };


    const getLike = (data) => {

      const { cookies } = props;
      var usrcd = cookies.get("user_code");

      // Vérifiez si l'utilisateur est connecté
      if (!usrcd) {
          console.log("L'utilisateur n'est pas connecté. Abandon de getLike.");
          return; // Sortez de la fonction si l'utilisateur n'est pas connecté
      }

      console.log("DATA", data)
      let id = data[0].id
      let idAc3 = data[0].idAc3
      let idHomecrush = data[0].idHomecrush
      let idHector = data[0].idHector
      let idApimo = data[0].idApimo
      var usrcd = cookies.get("user_code");

      var bodyy = {
        blossom: "nombreLikeCoeur",
        user_code: usrcd,
        idBien: id,
        idAc3: idAc3 ? idAc3 : "-1",
        idHomecrush: idHomecrush ? idHomecrush : "-1",
        idHector: idHector ? idHector : "-1",
        idApimo: idApimo ? idApimo : "-1"
      };
      fetch("https://api.homecrush.fr/control_room.php", {
        method: "POST",
        body: JSON.stringify(bodyy),
      })
        .then((response) => response.json())
        .then((response) => {
          setNombreLike(parseInt(response))

        })
        .catch((erreur) => {
          console.log("ERROR: " + erreur);
        });
    }

    const goBack = () => {
      props.history.goBack();
    };

    if (data.copropriete === "RAS") {
      data.copropriete = "Non"
    } else if (data.copropriete === "1") {
      data.copropriete = "Oui"
    }
    else if (data.copropriete === "0") {
      data.copropriete = "Non"
    }

    var newCodePos = "";
    if (data.cpAgence && data.cpAgence.length == 4) {
      newCodePos = 0 + data.cpAgence;
    } else {
      newCodePos = data.cpAgence;
    }

    var newUrl = data.URLSite || "";
    if (newUrl.startsWith("http://")) {
        newUrl = newUrl.replace("http://", "https://");
    } else if (!newUrl.startsWith("https://")) {
        newUrl = "https://" + newUrl;
    }
    
    let completeBase64 = (data.photoData && Array.isArray(data.photoData)) ? data.photoData.join('') : data.photoData;

    return (
      <section id="bien" className={MainStyles.pageContainer}>
        {notif && (
          <NotifModal notif={notif} notifType={notifType} notifMsg={notifMsg} />
        )}

        {data && (
          <article>
            {/* CAROUSEL */}
            <div style={{ borderRadius: 30, overflow: "hidden", backgroundColor: "#fff", position:"relative", zIndex : "1", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {data.imagesBien && data.imagesBien.length > 0 ? (
                <CarouselComp state={data.imagesBien} style={{ borderRadius: 30, overflow: "hidden", width: "auto", maxHeight: "400px", position:"relative", zIndex : "1"}} />
              ) : data.completeBase64 ? (
                <img src={data.completeBase64} alt="Photo du bien" style={{ borderRadius: 30, overflow: "hidden", width: "auto", maxHeight: "400px", position:"relative", zIndex: "1"}} 
                  onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = "https://api.homecrush.fr/imgDefaut/default.jpeg";
                  }}
                />

                ) : data.cleanedPhotoLinks && data.cleanedPhotoLinks.length > 0 ? (
                  <CarouselComp state={data.cleanedPhotoLinks} style={{ borderRadius: 30, overflow: "hidden", width: "auto", maxHeight: "400px", position:"relative", zIndex : "1"}} />
                ) : (              
                <p>Aucune image disponible</p>
              )}
            </div>
            {/* INFOS SUR L'ANNONCE */}
            <section className={MainStyles.BienInfosContainer}>
              <article className={MainStyles.BienInfosHeader}>
                <p>
                  <span className={MainStyles.price}>{data.prix + " € "}</span>
                  {data.surface && (
                    <span style={{ color: "#BDA87D", fontWeight: "bold" }} className={MainStyles.surperficie}>{" - " + data.surface}m² </span>
                  )}
                  {data.superficie && (
                    <span style={{ color: "#BDA87D", fontWeight: "bold" }} className={MainStyles.surperficie}> {" - " + data.superficie}m² </span>
                  )}
                </p>
                <div style={{ display: "flex" }}>
                  <div className={MainStyles.BienInfosCoeur} onClick={() => _switchCoups(data.id, data.idAc3, data.idHomecrush, data.idHector, data.idApimo)} style={{ backgroundColor: couleurLogoBg }}>
                    <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
                      <g style={{ fill: couleurLogo }}>
                        <path d="M39.78,67.9a14.44,14.44,0,0,1-2.64-20.24,14.79,14.79,0,0,1,2.38-2.43,15.73,15.73,0,0,1,20.07-1.07c.29.2.6.42.86.64l.65-.64.64-.64,3.85-3.85.64-.64.64-.64a22.29,22.29,0,0,0-2.07-1.62A25,25,0,0,0,47,32.23,23.76,23.76,0,0,0,33,39a26.79,26.79,0,0,0-7.72,14.72,24.39,24.39,0,0,0,8,20.49C45,86.42,78.25,117.65,78.58,118l1.28,0,5-5.27,0-1.29C83.45,110.08,51.13,79.7,39.78,67.9Z" />
                        <path d="M117.48,39.86a24.15,24.15,0,0,0-29.87-3.78A73.5,73.5,0,0,0,69.1,50.25C63.36,56,52.81,65.39,52.71,65.49l-.07,1.27,4.81,5.41,1.28.07c.44-.39,10.81-9.64,16.76-15.6a65.2,65.2,0,0,1,16.1-12.46,15.43,15.43,0,0,1,19.49,2,15.25,15.25,0,0,1,3,18A51.44,51.44,0,0,1,103,80.06L85,98v1.27l5.12,5.12h1.27l17.93-17.93a60.72,60.72,0,0,0,13.17-18.93A24.09,24.09,0,0,0,117.48,39.86Z" />
                      </g>
                    </svg>
                  </div>
                  <div className={MainStyles.compteurLike}>{nombreLike}</div>
                </div>
              </article>

              <article className={MainStyles.InfosDetails}>
                <p className={MainStyles.ville}>{data.cp + " " + data.ville}</p>

                <div className={MainStyles.InfosLayout}>
                  <div className={MainStyles.collapsible}>
                    <Collapsible
                      className={MainStyles.onglet}
                      onTriggerOpening={() => switchIsFalse("descr")}
                      onTriggerClosing={() => switchIsFalse("descr")}
                      triggerDisabled={false}
                      easing={"ease-out"}
                      trigger={
                        <MandatCollap title="Descriptif du bien" isActive={props.isFalseDescr} />
                      }
                      classParentString={MainStyles.Collapsible}
                    >
                      <p style={{ whiteSpace: "pre-line" }}>{data.description}</p>
                    </Collapsible>

                    {(data.pro !== "0" && data.numMandat !== "-1") &&
                      <Collapsible
                        className={MainStyles.onglet}
                        onTriggerOpening={() => switchIsFalse("mandat")}
                        onTriggerClosing={() => switchIsFalse("mandat")}
                        triggerDisabled={false}
                        easing={"ease-out"}
                        trigger={
                          <MandatCollap title="Numéro de mandat" isActive={props.isFalseMandat} />
                        }
                        classParentString={MainStyles.Collapsible}
                      >
                        <p>{data.numMandat}</p>
                      </Collapsible>
                    }

                    <Collapsible
                      className={MainStyles.onglet}
                      onTriggerOpening={() => switchIsFalse("copro")}
                      onTriggerClosing={() => switchIsFalse("copro")}
                      triggerDisabled={false}
                      easing={"ease-out"}
                      trigger={
                        <MandatCollap title="Copropriété" isActive={props.isFalseCopro} />
                      }
                      classParentString={MainStyles.Collapsible}
                    >
                      <p>{data.copropriete}</p>
                    </Collapsible>

                    <Collapsible
                      className={MainStyles.onglet}
                      onTriggerOpening={() => switchIsFalse("impot")}
                      onTriggerClosing={() => switchIsFalse("impot")}
                      triggerDisabled={false}
                      easing={"ease-out"}
                      trigger={
                        <MandatCollap title="Impôt foncier" isActive={props.isFalseImpot} />
                      }
                      classParentString={MainStyles.Collapsible}
                    >
                      <p>{data.financier}</p>
                    </Collapsible>

                    <Collapsible
                      className={MainStyles.onglet}
                      onTriggerOpening={() => switchIsFalse("perf")}
                      onTriggerClosing={() => switchIsFalse("perf")}
                      triggerDisabled={false}
                      easing={"ease-out"}
                      trigger={
                        <MandatCollap title="Performance énergétique" isActive={props.isFalsePerf} />
                      }
                      classParentString={MainStyles.Collapsible}
                    >
                      <p>{data.perfEnergetique}</p>
                    </Collapsible>
                    <div>
                      <p style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                        Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site Géorisques :
                        <a href="https://www.georisques.gouv.fr" target="_blank" rel="noopener noreferrer"> www.georisques.gouv.fr</a>.
                      </p>
                    </div>
                  </div>


                  {data.pro === "0" &&
                    <div className={MainStyles.proposerLayout}>
                      <img className={MainStyles.proposerImg} alt={"agent du bien"} src={data.imageAgent ? 'https://' + data.imageAgent : (`${process.env.PUBLIC_URL + '/images/default.jpeg'}`)} />
                      <p className={MainStyles.proposerParNom}>Proposé par : <span style={{ textTransform: "capitalize" }}>Particulier</span></p>

                      <div className={MainStyles.proposerCoordonnees}>
                        <a
                          href={"tel:+" + (dataUser ? dataUser.telephone : data.telAgence)}
                          className={MainStyles.proposerCoord}>{dataUser ? dataUser.telephone : ""}</a>
                        <a href={"mailto:" + (dataUser ? dataUser.mail : "") + "?subject=Votre bien m'intéresse" + "&body= Bonjour Madame, Monsieur, je suis intéressé par votre bien. Voici mes coordonnées : "}
                          className={MainStyles.proposerCoord}>{dataUser ? dataUser.mail : ""}</a>
                      </div>

                      <a className={MainStyles.proposerAnchor}
                        href={"mailto:" + (dataUser ? dataUser.mail : "") + "?subject=Votre bien m'intéresse" + "&body= Bonjour Madame, Monsieur, je suis intéressé par votre bien. Voici mes coordonnées : "}
                      >Contacter le vendeur</a>
                    </div>
                  }

                  {data.pro === "1" &&
                    <div className={MainStyles.proposerLayout}>
                      <img className={MainStyles.proposerImg} alt={"agent du bien"} 
                        src={data.createur === null 
                            ? (data.imageAgent && data.imageAgent.startsWith("https://") ? data.imageAgent : "https://" + data.imageAgent) 
                            : dataImage} 
                        onError={(e) => {
                            e.target.onerror = null; 
                            e.target.src = `${process.env.PUBLIC_URL + '/images/default.jpeg'}`;
                        }} 
                    />


                      <p className={MainStyles.proposerParNom}>Proposé par : <span style={{ textTransform: "capitalize" }}>{data.createur === null ? data.nomAgent : dataUser ? dataUser.nom + " " + dataUser.prenom : ""}</span></p>

                      <div className={MainStyles.proposerCoordonnees}>
                        <a
                          href={"tel:+" + (data.createur === null ? data.tel : dataTel ? dataTel : data.telAgence)} className={MainStyles.proposerCoord}>{data.createur === null ? data.tel : dataTel ? dataTel : data.telAgence}</a>
                        <a

                          href={"mailto:" + (data.createur === null ? data.mail : dataMail) + "?subject=Votre bien m'intéresse" + "&body= Bonjour Madame, Monsieur, je suis intéressé par votre bien. Voici mes coordonnées : "}
                          className={MainStyles.proposerCoord}>{data.createur === null ? data.mail : dataMail}</a>
                      </div>
                      {data.createur === null ?
                        <a className={MainStyles.proposerAnchor}
                          href={"mailto:" + data.mail + "?subject=Votre bien m'intéresse" + "&body= Bonjour Madame, Monsieur, je suis intéressé par votre bien. Voici mes coordonnées : "}
                        >Contacter le vendeur</a> :

                        <a className={MainStyles.proposerAnchor}
                          href={"mailto:" + dataMail + "?subject=Votre bien m'intéresse" + "&body= Bonjour Madame, Monsieur, je suis intéressé par votre bien. Voici mes coordonnées : "}
                        >Contacter le vendeur</a>
                      }
                    </div>
                  }
                </div>
              </article>
            </section>

            {/* SECTION SCROLL BIEN DE L'AGENT*/}
            <section style={{ marginTop: "80px" }}>
              <h3 className={MainStyles.pageSubTitle}>Les autres biens proposés par {data.createur === null ? data.nomAgent : dataUser ? dataUser.nom + " " + dataUser.prenom : ""}</h3>
              <ScrollerHorizontal className={MainStyles.ScrollerHorizontal} data={biensAgent} />
            </section>

            {/* SECTION AGENCE */}
            {
              data.pro !== "0" && data.nomAgence !== "Homecrush" &&
              <section className={MainStyles.agenceContainer}>
                <article className={MainStyles.agenceHead}>
                  {!data.imageLogo && (
                    <img src={data.imageAgence ? "https://" + data.imageAgence : (process.env.PUBLIC_URL + '/images/default.jpeg')} alt={"devanture de l'agence " + data.nomAgence} className={MainStyles.agenceImgLogo} />
                  )}
                  {data.imageLogo && (
                    <img src={data.imageLogo && data.imageLogo.startsWith("https://") ? data.imageLogo : "https://" + data.imageLogo} alt={"devanture de l'agence " + data.nomAgence} className={MainStyles.agenceImgLogo} />
                  )}
                </article>

                <article className={MainStyles.divContainer}>
                  <div className={MainStyles.agenceAdresse}>
                    <span className={MainStyles.spanBold}>Agence immobilière</span>
                    <span>{data.agence ? data.agence : data.nomAgence}</span>
                    <span>{data.adresseAgence ? (data.adresseAgence + ",") : (data.numeroAgence + " " + data.rueAgence + ",")}</span>
                    <span>{data.cpAgence ? (newCodePos + " " + data.villeAgence) : (data.codePostaleAgence + " " + data.villeAgence)}</span>
                  </div>
                  <div className={MainStyles.agenceCoordonnees}>

                    <a href={"tel:" + data.telAgence}>{data.telAgence}</a>
                    <a href={"mailto:" + data.mailAgence}>{data.mailAgence}</a>
                    <span><a className={MainStyles.agenceWebsite}
                      href={data.createur === null ?
                        newUrl : newUrl
                      } target="_blank" rel="noreferrer noopener"
                    >Site web</a></span>
                  </div>
                </article>
              </section>
            }
          </article >
        )
        }

        <Footer />
      </section >
    );
  }

  export default withCookies(Bien);