// Importation des dépendances nécessaires
import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Icône pour le bouton
import backToTopButtonStyles from './backToTopButton.module.scss'; // Importation des styles spécifiques

// Définition du composant BackToTopButton
const BackToTopButton = () => {
    // État pour gérer la visibilité du bouton
    const [isVisible, setIsVisible] = useState(false);

    // Fonction pour basculer la visibilité du bouton
    const toggleVisibility = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Effet pour ajouter et nettoyer l'écouteur d'événements de défilement
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Fonction pour animer le défilement jusqu'au haut de la page
    const scrollToTop = () => {
        // Utilisation de scrollTo avec behavior smooth pour une meilleure compatibilité
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Rendu du bouton avec gestion de la visibilité et des styles
    return (
        <button
            onClick={scrollToTop} // Gestionnaire d'événements pour le clic sur le bouton
            className={backToTopButtonStyles.backToTopButton} // Application des styles spécifiques
            style={{
                display: isVisible ? 'block' : 'none', // Gestion de l'affichage du bouton
            }}
        >
            <FaArrowUp /> {/* Icône du bouton */}
        </button>
    );
};

// Exportation du composant pour utilisation ailleurs dans l'application
export default BackToTopButton;
