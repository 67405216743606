import React, { Component } from 'react';
import { Formik, Field } from 'formik';

import ConnexionStyles from './Connexion.module.scss'

import NotificationSystem from 'react-notification-system';
import notifStyles from "../NotifStyles"

import { withCookies } from 'react-cookie';
import CookieConsent from "react-cookie-consent";
import NotifModal from '../NotifModal/NotifModal';



class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieConsent: props.cookies.get('cookieConsent') || false,
      recupInfosCompte: {
        nom: '_',
        prenom: '_'
      }
    };
    this.notificationSystem = React.createRef();
  }

  addNotif = (message, level) => {
    this.notificationSystem.current.addNotification({
      message: message,
      level: level ? level : "success"
    });
  };

  NotifPopUp = (msg, test) => {


    <NotifModal type={test}>{msg}</NotifModal>;
    return;
    // return (<NotifModal message={msg} type={test}></NotifModal>)
  }

  _tentativeConnexion = (values) => {
    var cookieConsent = this.props.cookies.get('cookieConsent')
    this.setState({ cookieConsent })
    console.log(cookieConsent)
    if (this.state.cookieConsent !== "true") {
      return this.addNotif("Veuillez accepter l'utilisation de cookie pour vous connecter", "error")

    }
    var bodyy = {
      blossom: "seConnecter",
      mail: values.email,
      motDePasse: values.password
    }

    fetch(
      'https://api.homecrush.fr/control_room.php',
      {
        method: 'POST',
        body: JSON.stringify(bodyy)

      })
      .then(response => response.json())
      .then(response => {
        console.log(response)
        response = JSON.parse(response)
        if (response.error === "error wrong mdp") {
          return this.addNotif("identifiants incorrects", "error")
        }
        if (response.error === "error mdp") {
          return this.addNotif("identifiants incorrects", "error")
        }
        if (response.error === "error no user") {
          return this.addNotif("identifiants incorrects", "error")
        }
        else {
          var d = new Date();
          if (this.state.souv) {
            d.setTime(d.getTime() + (31536000000));
          } else {
            d.setTime(d.getTime() + (3600000));
          }
          this.props.cookies.set('user_code', response.user_code,
            {
              path: '/',
              expires: d
            })
          this.props.cookies.set('rang', response.rang,
            {
              path: '/',
              expires: d
            })


          var bodyy = {
            blossom: "monProfil",
            user_code: this.props.allCookies.user_code,
          }

          fetch(
            'https://api.homecrush.fr/control_room.php',
            {
              method: 'POST',
              body: JSON.stringify(bodyy)

            })
            .then(response => response.json())
            .then(response => {
              response = JSON.parse(response)

              response.photoDeProfil = 'https://' + response.photoDeProfil
              var d = new Date();
              if (this.state.souv) {
                d.setTime(d.getTime() + (31536000000));
              } else {
                d.setTime(d.getTime() + (3600000));
              }
              this.props.cookies.set('pp', response.photoDeProfil,
                {
                  path: '/',
                  expires: d
                })
              this.props.cookies.set('nom', response.nom,
                {
                  path: '/',
                  expires: d
                })
              this.props.cookies.set('prenom', response.prenom,
                {
                  path: '/',
                  expires: d
                })


              return response;
            })
            .catch(erreur => {
              console.log('ERROR: ' + erreur);
            });
        }
        if (this.props.userNonConnecte) {
          this.props.recupUserCode(response.user_code)
        } else {
          this.props.clickHandler('#profil')
        }
        return response;
      })
      .catch(erreur => {
        console.log('ERROR: ' + erreur);
      });
  }



  render() {
    return (
      <section className={ConnexionStyles.formContainer}>
        <NotificationSystem ref={this.notificationSystem} style={notifStyles} />

        <p className={ConnexionStyles.sousTitre}>Formulaires de connexion</p>


        <Formik
          initialValues={{ email: '', password: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Obligatoire';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Adresse e-mail invalide';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              this.setState({ souv: values.souv })
              this._tentativeConnexion(values)
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
            <form onSubmit={handleSubmit}>
              <section className={ConnexionStyles.form}>
                <article className={ConnexionStyles.formArticle}>
                  <input className={ConnexionStyles.formInput}
                    type="email"
                    name="email"
                    placeholder="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </article>

                <article className={ConnexionStyles.formError}>
                  {errors.email && touched.email && errors.email}
                </article>

                <article className={ConnexionStyles.formArticle}>
                  <input className={ConnexionStyles.formInput}
                    type="password"
                    name="password"
                    placeholder="mot de passe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </article>

                <article className={ConnexionStyles.formError}>
                  {errors.password && touched.password && errors.password}
                </article>
              </section>

              <section>
                <label className={ConnexionStyles.labelCheck}>
                  <Field className={ConnexionStyles.formCheck} type="checkbox" name="souv" />
                  Se souvenir de ma connexion</label>

                <article className={ConnexionStyles.formError}>
                  {errors.souv && touched.souv && errors.souv}
                </article>

                <button className={ConnexionStyles.formBtn} type="submit" disabled={isSubmitting}>Connexion</button>
              </section>
            </form>
          )}
        </Formik>


        <a href="/mot-de-passe-oublie" className={ConnexionStyles.formText} style={{ textDecoration: "underline", marginTop: "2rem", fontSize: "0.7rem" }} >Mot de passe oublié ?</a>


        <p className={ConnexionStyles.smallText}>Vous n’avez pas encore de compte ?<br />Rejoignez Homecrush !</p>
        <button className={ConnexionStyles.goldenBtn} onClick={()=>this.props.showInscription()}>Inscription</button>


        {this.state.cookieConsent !== true &&
          <CookieConsent
            location="bottom"
            buttonText="Accepter"
            cookieName="cookieConsent"
            className={ConnexionStyles.cookieContainer}
            style={{ backgroundColor: "#fff" }}
            buttonStyle={{ paddingTop: "0.6rem", paddingBottom: "0.7rem", paddingLeft: "0.8rem", paddingRight: "0.8rem", marginRight: "3rem", color: "white", fontFamily: "HermesReg", fontSize: "17px", backgroundColor: " rgb(13, 168, 219)", border: "1px solid #bda87d", borderRadius: "50px" }}
            expires={150}
            onAccept={() => { this.setState({ cookieConsent: true }) }}>
            <span className={ConnexionStyles.cookieSpan}>Nous utilisons les cookies pour vous fournir le meilleur service sur notre site Web. Si vous poursuivez votre navigation en acceptant les cookies vous pourrez bénéficier pleinement des options et fonctionnalités de notre site.</span>
          </CookieConsent>
        }
      </section>
    )
  }
};



export default withCookies(Connexion);