// MaintenancePage.js

import React from 'react';
import icon from '../assets/icon1.png';
import logo from '../assets/maintenance.png'; // Assurez-vous que le chemin du logo est correct
import './MaintenancePage.scss';
import Footer from "../components/Footer/Footer";

const MaintenancePage = () => (
  <div className="maintenance-page">
    <img src={icon} alt="Icon" className="icon" />
    <img src={logo} alt="Logo" className="logo" />
    <div className="message-container">
      <h1>Le site est en cours de Maintenance</h1>
      <p>Ce site web est en cours de maintenance programmée.<br />Cette interruption ne devrait pas tarder.</p>
    </div>
    <footer className="footer">
    <p>Homecrush 2023 ©</p>
    <p>Tous droits de reproduction réservés.</p>
  </footer>
  </div>
);

export default MaintenancePage;
