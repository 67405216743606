import React, { Component } from 'react';

import { withCookies } from 'react-cookie';

import MainStyles from '../App.module.scss';
import Footer from '../components/Footer/Footer';

class Cgu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: true,
      showInscription: false,
    };
  }

  render() {
    const { cookies } = this.props;

    return (
      <section className={MainStyles.pageContainer}>
          <h1 className={MainStyles.pageTitleCgu}>Conditions Générales d'Utilisation</h1>

          <article className={MainStyles.article}>
            <span className={MainStyles.pageSubTitleCgu}>En vigueur au 7/01/2024</span>
            <span>Les présentes conditions générales d'utilisation (dites « <span className={MainStyles.boldTextCgu}>CGU</span> ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par <span className={MainStyles.boldTextCgu}> VIVRE EN PROVENCE Immobilier </span>et de définir les conditions d’accès et d’utilisation des services par « <span className={MainStyles.boldTextCgu}>l'Utilisateur</span> ».</span>
            <span>Les présentes CGU sont accessibles sur le site à la rubrique « <span className={MainStyles.boldTextCgu}>CGU</span> »</span>
          </article>

          <article className={MainStyles.article}>
            <span>Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur accepte expressément <span className={MainStyles.boldTextCgu}>les présentes CGU en cochant la case précédant le texte suivant :</span><br></br><br></br> « Je reconnais avoir lu et compris les CGU et je les accepte ».</span><br></br>
            <span>En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site.</span>
            <span>www.homecrush.fr se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.</span>
          </article>

          <article className={MainStyles.article}>
            <span>ARTICLE 1 : Les mentions légales</span><br></br>
            <span>L'édition du site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a> est assurée par la Société <span className={MainStyles.boldTextCgu}>SAS VIVRE EN PROVENCE Immobilier </span> au capital de 40.000 euros, immatriculée au RCS de Avignon sous le numéro 491779278, dont le siège social est situé au 36 Avenue Jean Monnet 84310 Morières Les Avignon.</span>
            <span>Adresse électronique : <a style={{ color:"#bda87d", textDecoration:'none'}} href="mailto:contact@homecrush.fr">contact@homecrush.fr. </a></span>
            <span>Le Directeur de la publication est : Mr Claudel Patrick</span>
            <span>L'hébergeur du site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a> est la société sas OVH, dont le siège est situé à l’adresse : 2 Rue Kellermann 59100 Roubaix</span>
            </article>

          <article className={MainStyles.article}>
            <span>ARTICLE 2 : Accès au site</span><br></br>
            <span>Le site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a> permet à l'Utilisateur un accès gratuit aux services suivants : Application et site internet.</span>
            <span>Application et site internet système de diffusion et de parution et recherche d’annonces immobilières de professionnels, à la vente, au moyen d’un site internet et d’une application mobile.</span>
            <span>Par département, secteur, lieu défini, zone, choix d'un endroit en situation instantanée.
            Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge</span>
            <span>L’Utilisateur n'a pas accès aux services réservés. L’Utilisateur s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse électronique.</span>
            <span>Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a>. Dans ces cas,l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.</span>
            <span>L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.</span>
          </article>

          <article className={MainStyles.article}>
            <span>ARTICLE 3 : Collecte des données</span><br></br>
            <span>Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.</span>
            <span>Le site est déclaré à la CNIL sous le numéro _______________.</span>
            <span>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.</span>
            <span>L'Utilisateur exerce ce droit : par mail à l'adresse mail <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href={"mailto:contact@homecrush.fr"}>contact@homecrush.fr</a> ou via son espace personnel.</span>
          </article>

          <article className={MainStyles.article}>
            <span>ARTICLE 4 : Propriété intellectuelle</span><br></br>
            <span>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.</span>
            <span>La marque homecrush est une marque déposée par Claudel Patrick. <span className={MainStyles.boldTextCgu}>Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est totalement prohibée.</span><br></br> L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus.</span>
            <span>Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.</span>
            <span>Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.</span>
            <span>Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.</span>
          </article>

          <article className={MainStyles.article}>
            <span>ARTICLE 5 : Responsabilité</span><br></br>
            <span>Les sources des informations diffusées sur le site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a> sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.<br></br> Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle.</span>
            <span>Malgré des mises à jour régulières, le site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a> ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. <br></br>De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans
            ce site.</span>
            <span>Le site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a> ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.</span>
            <span>La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</span>
          </article>

          <article  className={MainStyles.article}>
            <span>ARTICLE 6 : Liens hypertextes</span><br></br>
            <span>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a>. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.</span>
          </article>

          <article  className={MainStyles.article}>
            <span>ARTICLE 7 : Cookies</span><br></br>
            <span>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
            <br></br>Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a>.</span>
            <span>Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. <br></br>Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.</span>
            <span>L’information contenue dans les cookies est utilisée pour améliorer le site <a style={{ color:"#bda87d", textDecoration:'none'}} target="_blank" href="www.homecrush.fr">www.homecrush.fr </a>.</span>
            <span>En naviguant sur le site, L’Utilisateur les accepte. <br></br>L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.</span>
          </article>

          <article className={MainStyles.article}>
            <span>ARTICLE 8 : Droit applicable et juridiction compétente</span><br></br>
            <span>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.</span>
            <span>Pour toute question relative à l’application des CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l'article 1.</span>
          </article>
        
        <Footer/>
      </section>
    )
  }
};



export default withCookies(Cgu);