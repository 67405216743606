import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EstimationAchat.scss';
import { PositionOptions } from 'mapbox-gl';

import depOptions from '../../data/departements.js';

function EstimationAchat(props) {  
  const [activeTab, setActiveTab] = useState('acheter');

  return (
    <div className="estimation-achat"> 
      <div className="buttons">
        <button className={activeTab === 'estimer' ? 'tab-button active' : 'tab-button'} onClick={() => setActiveTab('estimer')}>Faire estimer mon bien</button>
        <button className={activeTab === 'acheter' ? 'tab-button active' : 'tab-button'} onClick={() => setActiveTab('acheter')}>Achat</button>
        <button className={activeTab === 'vendre' ? 'tab-button active' : 'tab-button'} onClick={() => setActiveTab('vendre')}>Je vends mon bien</button>
      </div>

    <div className="content-background">  
      {activeTab === 'estimer' && <EstimerComponent />}
      {activeTab === 'acheter' && <AcheterComponent onSearch={props.onSearch} />}
      {activeTab === 'vendre' && <VendreComponent />}

    </div>
    </div>
  );
}

function EstimerComponent() {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
 
  const handleSearch = () => {
    navigate('/InfosVentes', { state: { query: searchQuery } });
  };

  return (
    <div>
      <div className="search-bar">
        <input type="text" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} placeholder="Département" />
        <button onClick={handleSearch}>Envoi</button>
      </div>
    </div>
  );
}

function AcheterComponent(props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBudget, setSelectedBudget] = useState('');
  const [selectedSurface, setSelectedSurface] = useState('');
  const [propertyTypes, setPropertyTypes] = useState({
    appartement: false,
    maison: false,
    terrain: false,
  });
  const navigate = useNavigate();

  const resetSearchCriteria = () => {
    setSearchQuery('');
    setSelectedBudget('');
    setSelectedSurface('');
    setPropertyTypes({
      appartement: false,
      maison: false,
      terrain: false,
    });
  };

  const handleSearch = () => {
    let typeDeLogement = null;
    if (propertyTypes.maison) {
      typeDeLogement = 'maison';
    } else if (propertyTypes.appartement) {
      typeDeLogement = 'appartement';
    } else if (propertyTypes.terrain) {
      typeDeLogement = 'terrain';
    }

    const criteria = {
      secteur: searchQuery === 'Tous les départements' ? "00" : searchQuery,
      budget: selectedBudget,
      surface: selectedSurface,
      propertyTypes: propertyTypes,
      typeDeLogement: typeDeLogement
    };

    props.onSearch(criteria);  // Utilisez la fonction reçue via les props
    resetSearchCriteria();  // Réinitialiser les critères après la recherche
  };

  
  const handleCheckboxChange = (type) => {
    console.log("Avant:", propertyTypes);
    const newPropertyTypes = { ...propertyTypes, [type]: !propertyTypes[type] };
    setPropertyTypes(newPropertyTypes);
    console.log("Après:", newPropertyTypes);
  };

  if(props.departementLabel){
    console.log("SET setSearchQuery as ",props.departementLabel.value); 
    setSearchQuery(parseInt(props.departementLabel.value));
  }

  const handleDepartmentChange = (e) => {    
    setSearchQuery(parseInt(e.target.value));
  };

  return (
    <div className="acheter-component" >
      {/* Liste déroulante pour les départements */}
      <div className="search-bar">
        <select value={searchQuery} onChange={handleDepartmentChange} className="departement-select">
          {depOptions.map(option => (
            <option key={option.value}value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <button onClick={handleSearch}>Rechercher</button>
      </div>
      <div className="filter-options">
      <div className="checkboxes">

        <label className={propertyTypes.maison ? 'checkbox-label checked' : 'checkbox-label'} htmlFor="maison">
          <input type="checkbox" id="maison" checked={propertyTypes.maison} onChange={() => handleCheckboxChange('maison')} />
          Maison
        </label>

        <label className={propertyTypes.appartement ? 'checkbox-label checked' : 'checkbox-label'} htmlFor="appartement">
          <input type="checkbox" id="appartement" checked={propertyTypes.appartement} onChange={() => handleCheckboxChange('appartement')} />
          Appartement
        </label>

        <label className={propertyTypes.terrain ? 'checkbox-label checked' : 'checkbox-label'} htmlFor="terrain">
          <input type="checkbox" id="terrain" checked={propertyTypes.terrain} onChange={() => handleCheckboxChange('terrain')} />
          Terrain
        </label>
        
      </div>
        <div className="budget-surface-container">
          <div className="dropdown budget">
            <select value={selectedBudget} onChange={e => setSelectedBudget(e.target.value)}>
              <option value="" className="default-option">Budget</option>
              <option value="palier1">0 - 249 999 €</option>
              <option value="palier2">250 000 - 499 999 €</option>
              <option value="palier3">500 000 - 999 999 €</option>
              <option value="palier4">+ de 1 000 000 € </option>
              {/* Ajouter d'autres options de budget ici */}
            </select>
          </div>
          <div className="dropdown surface">
            <select value={selectedSurface} onChange={e => setSelectedSurface(e.target.value)}>
              <option value="" className="default-option">Surface</option>
              <option value="palier1">0 m² - 50 m²</option>
              <option value="palier2">51 m² - 100 m²</option>
              <option value="palier3">101 m² - 150 m²</option>
              <option value="palier4">+ de 151 m²</option>
              {/* Ajouter d'autres options de surface ici */}
            </select>
          </div>
        </div>
      </div>
      <div>
        <button className="advanced-search" onClick={handleSearch}>Recherche avancée</button>
      </div>
    </div>
  );
}

function VendreComponent() {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
 
  const handleSearch = () => {
    navigate('/Ventes', { state: { query: searchQuery } });
  };

  return (
    <div>
      <div className="search-bar">
        <input type="text" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} placeholder="Département" />
        <button onClick={handleSearch}>Envoi</button>
      </div>
    </div>
  );
}

export default EstimationAchat;
