var notifStyles = {
    NotificationItem: { // Override the notification item
      DefaultStyle: { // Applied to every notification, regardless of the notification level
        margin: '10px 5px 2px 1px',
        backgroundColor: "#fff",
        borderTop: "2px solid #bda87d",
        borderRadius:"5px",
        zIndex: "160000",
        position: "relative",
      },
  
      success: { // Applied only to the success notification item
        color: '#332E57',
        backgroundColor: "#BDA87D",
        zIndex: "160000",
        position: "relative",
      },
      error: { // Applied only to the success notification item
        color: '#332E57',
        backgroundColor: "#d33",
        zIndex: "160000",
        position: "relative",
      },
    },
  
    Action: {
      DefaultStyle: {
        background: '#ffffff',
        borderRadius: '2px',
        padding: '6px 20px',
        fontWeight: 'bold',
        margin: '10px 0 0 0',
        border: 0,
        zIndex: "16000",
        position: "relative",
      },
    },
    Dismiss: {
      DefaultStyle: {
        cursor: 'pointer',
        fontFamily: 'Arial',
        fontSize: '17px',
        position: 'absolute',
        top: '4px',
        right: '5px',
        lineHeight: '15px',
        backgroundColor: '#332E57',
        color: '#BDA87D',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
        zIndex: "160000",        
        position: "relative",
      },
  
      success: {
        color: '#BDA87D',
        backgroundColor: '#332E57'
      },
  
      error: {
        color: '#BDA87D',
        backgroundColor: '#332E57'
      },
  
      warning: {
        color: '#BDA87D',
        backgroundColor: '#332E57'
      },
  
      info: {
        color: '#BDA87D',
        backgroundColor: '#332E57'
      }
    }
  }

  export default notifStyles