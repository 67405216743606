import React, { useState, createContext, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { FaRegComment , FaQuestion ,FaUserCircle } from "react-icons/fa";

import NavbarStyles from "./navbar.module.scss";
import "./navbar.module.scss";

import Aside from './Aside';

import backgroundImage from '../../assets/haut_de_page.png';

const MainNav = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  // Style pour l'image du haut de la page

  const toggle = () => {
    setIsOpen(prevState => !prevState);
}


  const viewportContext = createContext({});

  const ViewportProvider = ({children}) => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowRezise = () => {
      setWidth(window.innerWidth);
    }

    useEffect(() => {
      window.addEventListener('resize', handleWindowRezise);
      return () => window.removeEventListener('resize', handleWindowRezise);
    }, [])

    return (
      <viewportContext.Provider value={{ width }}>
        {children}
      </viewportContext.Provider>
    );
  }

  const useViewport = () => {
    const { width } = useContext(viewportContext);
    return { width };
  }

  const MobileComponent = () => 
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 108.9 88.7" style={{ height: "2rem", width: "auto", alignSelf: "center", display: "flex", justifyContent: "center" }}>
      <g fill={"#BDA87D"}>
        <path d="M103.7,54.1c-6.2-6.2-16-16-25.3-25.3H77l-5.5,5.4v1.4l26.4,26.3l4.2,4.2h1.3l5.5-5.4v-1.4L103.7,54.1z" />
        <path d="M83.3,78.7c-2.1-2.1-4.7-4.8-7.5-7.6L58.6,53.9C48.5,43.8,38.8,34.1,35.3,30.6c-3.1-3.1-4.9-6.3-5.2-9.3 s0.8-5.7,3.3-8.2s5.3-3.6,8.2-3.3s6.3,2.1,9.3,5.2h1.4l5.5-5.4V8.1c-4.6-4.6-9.8-7.4-15.1-8c-5.9-0.7-11.5,1.4-16.1,6.1 c-3,3-4.7,8.1-4.5,11.3L6.7,2.3H5.4L0,7.8v1.4l28.4,28.3l23.3,23.3L69,78c2.7,2.7,5.3,5.4,7.4,7.5v0.1c1.3,1.4,2.4,2.4,3.1,3.2h1.4 l5.5-5.4V82C85.6,81.1,84.6,80.1,83.3,78.7L83.3,78.7z"/>
        <path d="M51.6,72.7c-3,3-6.3,4.9-9.3,5.2s-5.7-0.8-8.2-3.3c-1.8-1.7-2.9-3.9-3.3-6.3c-0.4-3.6,1.3-7.4,5.1-11.2v-1.4 l-5.5-5.4H29c-4.6,4.6-7.4,9.8-8,15.1c-0.7,5.9,1.4,11.5,6.1,16.1c3.7,3.9,8.7,6.1,14.1,6.2c6,0,11.9-2.8,17.1-8v-1.4l-5.5-5.4 L51.6,72.7z"/>
        <path d="M47.8,32.9l5.5,5.4h1.4l22.6-22.6c3-3.1,6.3-4.8,9.3-5.2s5.7,0.8,8.2,3.3c3.1,3.1,4,6.5,2.9,10.2 c-1,2.8-2.6,5.3-4.8,7.3v1.4l5.5,5.4h1.4c10-10,10.8-22.3,1.9-31.2c-4.6-4.6-10.2-6.7-16.1-6.1c-5.2,0.6-10.5,3.3-15.1,8L47.9,31.5 L47.8,32.9L47.8,32.9z"/>
      </g>
    </svg>;

  const DesktopComponent = () => 
  <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 576.7 88.7" style={{ height: "1.75rem", width: "auto", alignSelf: "center", display: "flex", justifyContent: "center" }}>
    <g fill={"#BDA87D"}>
      <path d="M316.8,54.1c-6.2-6.2-16-16-25.3-25.3h-1.4l-5.5,5.4v1.4L311,61.9l4.2,4.2h1.3l5.5-5.4v-1.4L316.8,54.1z" />
      <path d="M296.4,78.7c-2.1-2.1-4.7-4.8-7.5-7.6l-17.2-17.2c-10.1-10.1-19.8-19.8-23.3-23.3c-3.1-3.1-4.9-6.3-5.2-9.3 s0.8-5.7,3.3-8.2s5.3-3.6,8.2-3.3s6.3,2.1,9.3,5.2h1.4l5.5-5.4V8.1c-4.6-4.6-9.8-7.4-15.1-8c-5.9-0.7-11.5,1.4-16.1,6.1 c-3,3-4.7,8.1-4.5,11.3L219.8,2.3h-1.3l-5.4,5.5v1.4l28.4,28.3l23.3,23.3L282.1,78c2.7,2.7,5.3,5.4,7.4,7.5v0.1 c1.3,1.4,2.4,2.4,3.1,3.2h1.4l5.5-5.4V82C298.7,81.1,297.7,80.1,296.4,78.7L296.4,78.7z"/>
      <path d="M264.7,72.7c-3,3-6.3,4.9-9.3,5.2s-5.7-0.8-8.2-3.3c-1.8-1.7-2.9-3.9-3.3-6.3c-0.4-3.6,1.3-7.4,5.1-11.2v-1.4 l-5.5-5.4h-1.4c-4.6,4.6-7.4,9.8-8,15.1c-0.7,5.9,1.4,11.5,6.1,16.1c3.7,3.9,8.7,6.1,14.1,6.2c6,0,11.9-2.8,17.1-8v-1.4l-5.5-5.4 L264.7,72.7z"/>
      <path d="M260.9,32.9l5.5,5.4h1.4l22.6-22.6c3-3.1,6.3-4.8,9.3-5.2s5.7,0.8,8.2,3.3c3.1,3.1,4,6.5,2.9,10.2 c-1,2.8-2.6,5.3-4.8,7.3v1.4l5.5,5.4h1.4c10-10,10.8-22.3,1.9-31.2c-4.6-4.6-10.2-6.7-16.1-6.1c-5.2,0.6-10.5,3.3-15.1,8L261,31.5 L260.9,32.9L260.9,32.9z"/>
    </g>
    <g fill={"#FFF"}>
      <path d="M22.6,25.8c-4.2-0.1-8.3,1.2-11.7,3.7L10.3,30V18.4L9,17H1.3L0,18.4v49.8l1.3,1.4H9l1.3-1.4v-20 c0-2.7,0.7-5.3,2.1-7.6c2.2-3.3,5.9-5.3,9.9-5.2c4.5,0,7.8,2.1,9.1,5.7c0.7,2.1,0.9,4.2,0.8,6.4v20.6l1.3,1.4h7.6l1.3-1.4V47.9 c0-5.6-1-10-3-13.4C36.2,29,30,25.9,22.6,25.8"/>
      <path d="M74.9,61.5c-7,0-12.3-5.6-12.3-13s5.3-13,12.3-13s12.4,5.6,12.4,13S82,61.5,74.9,61.5 M74.9,25.8 c-12.5,0-22.6,10.1-22.6,22.6S62.4,71,74.9,71l0,0c12.5,0,22.6-10.1,22.6-22.6S87.4,25.8,74.9,25.8L74.9,25.8"/>
      <path d="M147.9,25.8c-4,0-7.8,1.9-10.2,5.1l-0.3,0.4l-0.3-0.4c-2.5-3.3-6.4-5.2-10.5-5.1c-2.8,0-5.5,1-7.7,2.7l-0.6,0.5 v-0.7l-1.4-1.3h-7.1l-1.4,1.3v39.9l1.4,1.4h7.6l1.4-1.4V42.7c-0.1-1.4,0.2-2.8,0.9-4c1.2-2,3.4-3.2,5.7-3.2c2.1,0,3.4,1.1,4.1,3.3 c0.2,1.2,0.3,2.5,0.3,3.8v25.6l1.4,1.4h7.6l1.4-1.4V43.3c0-1.5,0.3-3,0.9-4.3c1.1-2.2,3.3-3.5,5.8-3.5c1.9,0,3.7,1.3,4.1,3.2 c0.3,1.3,0.4,2.7,0.4,4.1v25.5l1.4,1.4h7.6l1.4-1.4V42.4c0-1.7-0.2-6.1-1.6-9.3C157.9,28.5,153.4,25.8,147.9,25.8"/>
      <path d="M204.2,43.3H183l0.2-0.5c1.6-4.6,6-7.6,10.8-7.5c4.7-0.1,8.9,3,10.1,7.5L204.2,43.3z M193.9,25.8 c-12.5,0-21.9,9.8-21.9,22.7s9.5,22.6,22.1,22.6c5.8,0,11.4-2.3,15.6-6.4v-2l-5.2-4.7h-1.8c-2.4,2.3-5.6,3.5-8.9,3.4 c-5,0-9.4-3.4-10.8-8.3l-0.2-0.5H210c2.7,0.1,4.9-2.1,4.9-4.7c0-0.1,0-0.1,0-0.2C214.9,35.3,205.9,25.8,193.9,25.8"/>
      <path d="M356.7,35.5c3.6,0,7,1.6,9.2,4.4l1.8,0.2l6.1-4.1l0.3-2c-4.2-5.3-10.7-8.3-17.4-8.2 c-12.4-0.1-22.5,9.9-22.6,22.4c0,0.1,0,0.2,0,0.3c0,12.5,10.2,22.6,22.7,22.6c6.7,0,13-2.9,17.2-8.1l-0.3-2l-6-4.1l-1.8,0.2 c-2.2,2.8-5.6,4.4-9.2,4.3c-7,0-12.3-5.6-12.3-13S349.6,35.4,356.7,35.5"/>
      <path d="M397.5,28.1L397.5,28.1c-1,0.4-1.9,0.9-2.8,1.5l-0.6,0.4v-1.6l-1.4-1.3h-7.4l-1.4,1.3v39.9l1.4,1.4h7.6l1.4-1.4 V45c-0.2-2.8,1.1-5.5,3.4-7.2c2.3-1.6,5.1-2.4,7.9-2.4c3.2,0,5.5,0.7,7.1,2h1.7l5.6-4.6l0.1-2c-3.5-3.4-8.3-5.2-13.2-5.1 C403.7,25.7,400.5,26.5,397.5,28.1"/>
      <path d="M461.9,27l-1.3,1.3v20.4c0,2.7-0.7,5.3-2.1,7.6c-2.2,3.3-5.9,5.3-9.9,5.2c-4.5,0-7.8-2.1-9.1-5.7 c-0.6-2.1-0.9-4.2-0.8-6.4v-21l-1.3-1.3h-7.6l-1.4,1.3v20.7c0,5.6,1,10,3,13.4c3.4,5.5,9.6,8.6,17,8.6c4.2,0.1,8.4-1.3,11.8-3.8 l0.6-0.5v1.4l1.3,1.4h7.5l1.3-1.4V28.3l-1.3-1.3H461.9z"/>
      <path d="M511,45.1L511,45.1c-1.8-0.4-5.5-1.1-8.5-1.6l-2.9-0.5c-6.3-1.2-6.2-3.6-6.1-4.5c0.2-3.3,6.8-3.3,9.6-3.2 c3.6,0.1,7.2,1.2,10.3,3.1l1.8-0.3l4.1-5.6l-0.3-1.9c-4.3-2.8-9.2-4.2-15.3-4.6c-11.2-0.7-16.3,3-18.5,6.3c-1.2,1.7-1.9,3.7-2,5.8 c-0.4,4.3,1.7,8.5,5.3,10.9c2.6,1.7,5.5,2.8,8.5,3.4l3.7,0.7h0.2c3.7,0.7,6.8,1.2,7.9,1.5c4.2,1.1,4.8,2,4.7,3.8 c-0.1,1.1-1,1.9-3.4,2.8c-2.6,0.7-5.3,0.9-8,0.7c-6.5-0.4-9.7-2.5-12.2-4.8h-1.8l-5.5,5v2c2.6,2.4,8.4,6.6,18.4,7.2 c1.1,0.1,2.1,0.1,3.2,0.1c3.6,0.1,7.2-0.5,10.6-1.7c5.7-2.2,8.8-5.9,9.1-10.8C524.5,48.3,515.1,46,511,45.1"/>
      <path d="M573.7,34.5L573.7,34.5c-3.4-5.5-9.6-8.7-17-8.7c-4.2-0.1-8.3,1.2-11.6,3.7l-0.6,0.5V18.4L543,17h-7.6l-1.3,1.3 v49.8l1.3,1.4h7.6l1.3-1.4v-20c0-2.7,0.7-5.3,2.1-7.6c2.2-3.3,5.9-5.3,9.9-5.2c4.5,0,7.8,2.1,9.1,5.7c0.6,2.1,0.9,4.2,0.8,6.4V68 l1.3,1.4h7.6l1.3-1.4V47.9C576.7,42.3,575.7,37.9,573.7,34.5"/>
    </g>
  </svg>;

  const MyComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <MobileComponent /> : <DesktopComponent />
  }

  const { cookies } = props;
  var maPP = cookies.get('pp');
  var monPrenom = cookies.get('prenom');
  var monNom = cookies.get('nom');

  return (
<header style={{ position: 'relative', height: '50px', overflow: 'visible' }}>
    <div style={{ position: 'absolute', top: 0, left: '-50px', right: 0, height: '500px',borderRadius: '0px' , backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', zIndex: 0 }}></div>
      <ViewportProvider>
      <nav className={NavbarStyles.whiteHeader} style={{ display: 'flex', justifyContent: 'space-between', background: 'linear-gradient(to bottom, rgba(51, 46, 87, 1), rgba(51, 46, 87, 0.75), rgba(51, 46, 87, 0))' }}>

        <div onClick={toggle} className={NavbarStyles.burgerMenu}>
          {!isOpen && 
            <svg style={{ height: '2rem', width: "auto" }} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
              <g fill={"#332E57"}>
                <polygon points="123.55 69.75 26.45 69.75 25.5 70.7 25.5 78.3 26.45 79.25 123.55 79.25 124.5 78.3 124.5 70.7 123.55 69.75" />
                <polygon points="123.55 30.75 26.45 30.75 25.5 31.7 25.5 39.3 26.45 40.25 123.55 40.25 124.5 39.3 124.5 31.7 123.55 30.75" />
                <polygon points="123.55 108.75 26.45 108.75 25.5 109.7 25.5 117.3 26.45 118.25 123.55 118.25 124.5 117.3 124.5 109.7 123.55 108.75" />
              </g>
            </svg>
          }
          
          {isOpen && 
            <svg style={{ height: '2rem', width: "auto" }} id="Calque_1" data-name="Calque 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150">
              <g fill={"#332E57"}>
                <polygon points="35.62 30.24 30.24 35.62 30.24 36.96 113.04 119.76 114.38 119.76 119.76 114.38 119.76 113.04 36.96 30.24 35.62 30.24" />
                <polygon points="62.65 80.63 30.24 113.04 30.24 114.38 35.62 119.76 36.96 119.76 69.37 87.35 69.37 86.01 63.99 80.63 62.65 80.63" />
                <polygon points="113.04 30.24 80.63 62.65 80.63 63.99 86.01 69.37 87.35 69.37 119.76 36.96 119.76 35.62 114.38 30.24 113.04 30.24" />
              </g>
            </svg> 
          }
        </div>

        <div className={NavbarStyles.styleheader}>
          <Link to="/">
            <MyComponent />
          </Link>
        </div>
            
        <div style={{ display: 'flex', justifyContent: 'flex-end' , marginLeft: 'auto' }}>
        <Link 
          className={NavbarStyles.navLinkHeader}
          to="/Compte"
          id="Compte"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30px',
            width: 'auto',
            borderRadius: '15px', // Arrondir légèrement les angles
            backgroundColor: '#A099C7', // Couleur d'arrière-plan spécifiée
            color: '#fff', // Texte en blanc
            alignSelf: 'center', // Force l'alignement vertical
            marginRight: '3vw',
          }}
        >
          <div className={NavbarStyles.infosContainer}>
              <span className={NavbarStyles.texte_desktop}>{monPrenom && monNom ? `${monPrenom} ${monNom}` : "Connexion/Inscription"}</span>
              <FaUserCircle className={NavbarStyles.icone_mobile} />
          </div>

        </Link>

        <Link 
          className={NavbarStyles.roundBtn} 
          style={{marginRight: '5px'}}
          to="/#contactSection"
        >
          <FaRegComment size={20}/>
      </Link>

        <Link 
          className={NavbarStyles.roundBtn} 
          to="/Tutoriel"
        >
          <FaQuestion size={15}/> {/* Augmenter la taille de l'icône */}
        </Link>
      </div>
    </nav>

    <Aside key={isOpen.toString()} isOpen={isOpen} toggle={toggle} />
    </ViewportProvider>
    </header>
  )
}

export default withCookies(MainNav)
